import {Inject, Injectable, Injector} from '@angular/core';
import * as Rollbar from 'rollbar';
import {CLiCSService} from '../clics.service';
import {EventsService} from '../events/events.service';
import {ModeControllerProvider} from '../mode-controller/mode-controller';
import {CLiCSColorFormula} from '../../../lib/color-formula';
import {CLiCSProduct} from '../../../lib/product';
import {CLiCSAppType} from '../../../lib/app-type';
import {CLiCSFormula} from '../../../lib/formula';
import {RollbarService} from '../../../lib/rollbar';
import {CLiCSColorSession} from "../../../lib/session";

/*
  This provider maintains a list of products available for creating formulas and encapsulates various defaults as well
   as rules for creating a hair color formula. This provider also stores the data for presenting steps for an
   hair color "application". Both the product list and the steps content database are checked for updates periodically.
*/
@Injectable({
  providedIn: 'root',
})
export class MixingBowlProvider {
  clicsService: any;
  modeCtrl: any;
  developerComponentIds: number[] = [26, 27, 28, 98, 99];  // TODO: update via manifest a priori values used
  products: CLiCSProduct[] = [];
  app_types: CLiCSAppType[] = [];  // Application types
  default_formula_amt = 40;

  readonly screenDisclaimer: string = 'Device screens vary. Digital color shown may not accurately represent the final color on real hair.';

  constructor(private injector: Injector,
              private events: EventsService,
              @Inject(RollbarService) public rollbar: Rollbar) {
    this.clicsService = this.injector.get(CLiCSService);
    this.modeCtrl = this.injector.get(ModeControllerProvider);
    this.clicsService.validateLogin().then((success) => {
      if (success == false) {
        events.publish('navrequest', {top: 'logout', page: 'LOGOUT'});
      } else {
        this.loadFromLocal();
      }
    });
    this.default_formula_amt = this.modeCtrl.default_amt;
  }

  // Load settings from local storage
  loadFromLocal(): void {
    this.clicsService.apiGetProducts().then((products) => {
      if (!!products)
        this.products = products;
    });
    this.clicsService.apiGetAppTypes().then((app_types) => {
      if (!!app_types)
        this.app_types = app_types;
    });
  }

  // Checks that one or more products are loaded else retrieves them from local storage or the server
  assertProducts(): Promise<boolean> {
    let valid_products = false;
    if (!!this.products) {
      const gw_prods = this.products.filter((el) => { return el.manufacturer == 'Goldwell' });
      if (this.clicsService.mobileAppType() == 'goldwell') {
        if (gw_prods.length > 24 && this.products.length > (gw_prods.length + 1)) {
          valid_products = true;
        }
      } else {
        if (gw_prods.length == 0 && this.products.length > 25) {
          valid_products = true;
        }
      }

      // One more check...
      const cools = this.prodNameByType('Cool', true);
      if (cools.length == 0) {
        valid_products = false;
      }
    }

    if (!valid_products || localStorage.getItem('products') == null) {
      this.products.length = 0;
      return this.clicsService.apiGetProducts(true).then((products) => {
        this.products.length = 0;
        if (!!products && products.length > 0) {
          for (let product of products) {
            this.products.push(new CLiCSProduct(product));
          }
          return true;
        } else {
          this.rollbar.error("MixingBowl could not retrieve products",
            {
              user_token: this.clicsService.current_user.api_token,
              login_token: this.clicsService.login_token
            }
          );
          console.error('MixingBowl could not retrieve products');
          return false;
        }
      });
    } else {
      return Promise.resolve(true);
    }
  }

  // Search for a product by name
  findProduct(productName: string): CLiCSProduct {
    let result: CLiCSProduct = null;
    this.assertProducts();
    result = this.products.find((prod) => {
      return (prod.name == productName || prod.common.includes(productName));
    });
    return result;
  }

  // Search for an app type by ident or null
  findAppType(ident: string): CLiCSAppType {
    let result: CLiCSAppType;
    result = this.app_types.find((app_type) => {
      return (app_type.ident == ident);
    });
    return result;
  }

  // Get a list of products by their type ("Base", "Cool", "Warm", "Dev")
  // Returns array of { name: 'xxx', description: 'yyy' }
  prodNameByType(product_type: string, omit_pre_check: boolean = false): any[] {
    let result: any[] = [];
    let type = '???';
    let sub = null;
    if (omit_pre_check == false) {
      this.assertProducts();
    }

    switch (product_type) {
      case 'Base':
        type = 'Base Tone';
        sub = null;
        break;
      case 'Cool':
        type = 'Pure Tone';
        sub = 'Cool';
        break;
      case 'Warm':
        type = 'Pure Tone';
        sub = 'Warm';
        break;
      case 'Dev':
        type = 'Developer';
        sub = null;
        break;
      case 'Additive':
        type = 'Additive';
        sub = null;
        break;
      case 'Lightener':
      case 'Bleach':
        type = 'Lightener';
        sub = null;
        break;
    }

    // Produce fractional components
    let i: number;
    let desc: string;
    if (type == 'Base Tone') {
      result.push({name: '00N', description: 'Base Cream'});
      for (i = 2; i < 25; i++) {
        if (i == 0)
          desc = `Base Cream`;
        else
          desc = `${i * 0.5} Natural`;
        result.push({name: `${i * 0.5}N`, description: desc});
      }
    } else {
      if (type == 'Developer') {
        for (i = 0; i < 41; i++) {
          result.push({name: `D${i}`, description: `${i} Developer`});
        }
      } else {
        for (let prod of this.products) {
          if (prod.type == type && (sub == null || (!!prod.subtype && prod.subtype.includes(sub)))) {
            result.push({name: prod.name, description: prod.description});
          }
        }
      }
    }

    return result;
  }

  // Get a list of products by their type ("Base", "Cool", "Warm", "Dev"). Omit removes specific product names
  // from the result.
  productsByType(product_type: string, omit: string[] = []): CLiCSProduct[] {
    let result: CLiCSProduct[];
    let type = '???';
    let sub = null;
    this.assertProducts();

    switch (product_type) {
      case 'Base':
        type = 'Base Tone';
        sub = null;
        break;
      case 'Cool':
        type = 'Pure Tone';
        sub = 'Cool';
        break;
      case 'Warm':
        type = 'Pure Tone';
        sub = 'Warm';
        break;
      case 'Dev':
      case 'Developer':
        type = 'Developer';
        sub = null;
        break;
      case 'Additive':
        type = 'Additive';
        sub = null;
        break;
      case 'Lightener':
      case 'Bleach':
        type = 'Lightener';
        sub = null;
        break;
    }

    result = this.products.filter(prod => {
      return (prod.type == type && (sub == null || prod.subtype == sub) && !omit.includes(prod.name));
    });

    return result;
  }

  quickDispenseProducts(): Promise<CLiCSProduct[]> {
    return this.assertProducts().then((data) => {
      return this.products.filter(prod => {
        return (prod.quick == true);
      });
    });
  }

  // Since this is the keeper of mixing limits and rules... this method validates the formula and returns:
  // { success: false, mssg: 'Would you like to add developer?', mssgkey: 'mk_no_dev', severity: 'warn' }
  // Severity can be warn which may be ignored or error which should not permit use of the formula.
  // NOTE: "mode" is not part of a library Color Formula, expert / basic parameters assumed.
  validateFormula(formula: CLiCSColorFormula, action: string = null): any {
    let result = {success: false, mssg: 'Undefined formula', mssg_key: 'mk_formula_ng', severity: 'error'};
    if (formula.isEmpty()) {
      result.mssg = 'Please add one or more components to the formula';
      result.mssg_key = 'mk_formula_empty';
    } else {
      result = {success: true, mssg: 'Formula Ok', mssg_key: 'mk_formula_ok', severity: 'info'};
      if (formula.usesComponentIds()) {
        let index = formula.params.findIndex(element => (this.developerComponentIds.findIndex(el => element.value) >= 0));
        if (index < 0) {
          result = {
            success: false,
            mssg: 'Would you like to add developer?',
            mssg_key: 'mk_no_dev',
            severity: 'warn'
          };
        }
      } else {
        result = {success: true, mssg: 'Formula Ok', mssg_key: 'mk_formula_ok', severity: 'info'};
        let index = formula.params.findIndex(element => element.value.match(/g?D[0-9.]+/i) != null);
        if (index < 0) {
          result = {
            success: false,
            mssg: 'Would you like to add developer?',
            mssg_key: 'mk_no_dev',
            severity: 'warn'
          };
        }
      }
    }
    if (result.success == true && action) {
      if (action == 'save' && (formula.title == undefined || formula.title.trim() == '')) {
        result = {
          success: false,
          mssg: `Please add a title before saving the formula to your color ${this.modeCtrl.collectionStr(true)}`,
          mssg_key: 'mk_no_formula_title',
          severity: 'warn'
        };

      }
    }
    return result;
  }

  // Weights used in getDefaultCompWeight below
  listDefaultPureToneWeights(): number[] {
    return [4.0, 2.0, 6.0];
  }

  // Returns the default component weight to dispense for a product. TonalWeight refers to the combined weight of Base Tone
  // and Pure Tone components. baseLevel is the level of the base tone in the formula. If multiple baseTones are
  // included this returns the lowest level.
  getDefaultCompWeight(componentName: string, formula: CLiCSColorFormula, developerRatio: number = 1.0) {
    let result = 4.0;

    const tonalWeight = formula.tonalWeight();
    const isPureTone = componentName.match(/(\d\.?\d*)N/) == null && componentName.match(/g?D(\d\.?\d*)/) == null;

    // Pure tones default weight is based on the Base Tone level
    if (isPureTone) {
      const baseLevel = formula.baseLevel();
      if (!!baseLevel) {
        if (baseLevel > 11.5) {
          result = 2.0;
        } else {
          if (baseLevel < 6.0) {
            result = 6.0;
          } else {
            if (baseLevel < 9.0) {
              // if (componentName == "VR" || componentName == "R") {  NOTE: for reference only
              result = 4.0;
            } else {
              result = 2.0;
            }
          }
        }
      }
    }

    // Base tone?
    if (componentName.match(/(\d\.?\d*)N/) != null) {
      result = 20.0;
    } else {
      // Developer?
      if (componentName.match(/g?D(\d\.?\d*)/) != null) {
        if (tonalWeight > 0.0)
          result = tonalWeight * developerRatio;
        else
          result = 24.0;
      }
    }
    return result;
  }

  // Returns true if the component is a Base Tone or a Pure Tone
  static isTonalComponent(componentName: string): boolean {
    let result = true;
    const additives = ['Li', 'CoB', 'pH', 'CL'];
    if (componentName.match(/g?D(\d\.?\d*)/) != null)
      result = false;
    else {
      if (additives.indexOf(componentName) > -1)
        result = false;
    }
    return result;
  }

  static isBaseToneComponent(componentName: string): boolean {
    return (componentName.match(/(\d\.?\d*)N/) != null);
  }

  // Is neither a Base Tone or Developer
  static isPureToneComponent(componentName: string): boolean {
    return (componentName.match(/(\d\.?\d*)N/) == null && componentName.match(/g?D(\d\.?\d*)/) == null);
  }

  // Returns true if the component is a Developer
  static isDeveloperComponent(componentIdent: any): boolean {
    if (isNaN(componentIdent))
      return (componentIdent.match(/g?D(\d\.?\d*)/) != null);
    else
      return ([27, 26, 28, 98, 99].includes(parseInt(componentIdent)));  // NOTE: a priori knowledge of product IDs
  }

  // Returns a default total formula amount, given the specified hair length
  getDefaultAmount(hairLength: any = null): number {
    const defaults = {
      0: 20.0,
      'short': 20.0,
      'pixie': 20.0,
      10: 30.0,
      'ears': 30.0,
      'pixie_ears': 30.0,
      'pixie ears': 30.0,
      15: 40.0,
      'chin': 40.0,
      20: 50.0,
      'shoulder': 50.0,
      30: 60.0,
      'long': 60.0,
      'mid_back': 60.0,
      'mid-back': 60.0,
      40: 70.0,
      'waist': 70.0
    };
    if (hairLength) {
      if (typeof (hairLength) == 'string')
        hairLength = hairLength.toLowerCase();
      return defaults[hairLength];
    } else
      return (this.default_formula_amt);
  }

  // Takes a fractional product name and returns actual products with amounts as {weight:, product:}
  getRealFromFractional(fractionalProduct: string, weight: number = 100): any[] {
    let result = [];
    let type: string = 'D';  // "D" developer  "N" base tone
    let loProd: CLiCSProduct = null;
    let hiProd: CLiCSProduct = null;
    let loStrength: number = null;
    let hiStrength: number = null;

    // Is this base tone or developer?
    if (fractionalProduct.includes('N'))
      type = 'N';

    // Get fractional part
    let strength = this.calcStrength(fractionalProduct);

    // Get high and low products
    let available: CLiCSProduct[] = [];
    if (type == 'N')
      available = this.productsByType('Base');
    else {
      // available = this.productsByType('Developer');
      available = this.productsByType('Developer', ['D20']);  // To force D0 + D40 developer mix
    }

    // sort products by strength
    available.sort((a, b) => {
      const aa = parseInt(a.name.replace(/\D+/i, ''));
      const bb = parseInt(b.name.replace(/\D+/i, ''));
      if (aa > bb) {
        return 1;
      } else {
        if (aa < bb) {
          return -1;
        }
      }
      return 0;
    });

    // Find lower and higher products by strength
    for (let prod of available) {
      if (this.calcStrength(prod.name) >= strength) {
        hiProd = prod;
        hiStrength = this.calcStrength(prod.name);
        if (hiStrength == strength) {
          loProd = null;
          loStrength = null;
        }
        break;
      } else {
        loProd = prod;
        loStrength = this.calcStrength(prod.name);
      }
    }

    // Handle oddball case where lowest product is higher in strength
    if (hiProd == null) {
      hiProd = loProd;
      hiStrength = loStrength;
      loProd = null;
      loStrength = null;
    }

    // Calculate amounts
    if (loProd == null)
      result.push({weight: weight, product: hiProd});
    else {
      let ratio = (strength - loStrength) / (hiStrength - loStrength);
      let lo_weight = weight * (1.00 - ratio)
      let hi_weight = weight * ratio

      // For base tones use 2:1 high-to-low mix
      if (type == 'N') {
        const concentration = hiProd.concentration || 2.0;
        hi_weight *= concentration;
        const normalizeRatio = weight / (lo_weight + hi_weight)
        lo_weight *= normalizeRatio
        hi_weight *= normalizeRatio
      }

      result.push({weight: hi_weight, product: hiProd});
      result.push({weight: lo_weight, product: loProd});
    }

    return result;
  }

  // Takes actual components as objects {name: 'D0', weight: 10.0}, groups components, and combines the fractional
  // types and returns all as an array of component objects as {name: 'D15', weight: 18.2, text: '18.2g D15'}
  static getFractionalFromReal(components: any[], mixingBowlInstance: MixingBowlProvider): any[] {
    let result: any = [];
    let prod: CLiCSProduct = null;
    let dev: any = [];
    let base: any = [];
    let pure: any = [];

    const dev_pattern = /g?D(\d{1,2})/;
    const base_pattern = /(\d+)N/;

    // select components
    for (let comp of components) {
      let matches = comp.name.match(dev_pattern);
      if (matches)
        dev.push({comp: comp, val: parseInt(matches[1])});
      else {
        matches = comp.name.match(base_pattern);
        if (matches)
          base.push({comp: comp, val: parseInt(matches[1])});
        else
          pure.push({comp: comp, val: ''});
      }
    }

    // Sort components
    dev.sort((a, b) => {
      return (a.val - b.val);
    });
    base.sort((a, b) => {
      return (a.val - b.val);
    });
    pure.sort((a, b) => {
      return (a.comp.weight - b.comp.weight);
    });

    // Combine developer only if 2 dev components exist
    if (dev.length > 0) {
      let d0_weight: number = 0;
      let d20_weight: number = 0;
      let d40_weight: number = 0;

      for (let cfc of dev) {
        if (cfc.val == 0) {
          d0_weight += cfc.comp.weight;
        } else if (cfc.val == 20) {
          d20_weight += cfc.comp.weight;
        } else {
          d40_weight += cfc.comp_weight;
        }
      }

      const weight = d0_weight + d20_weight + d40_weight;
      const strength = ((d20_weight / weight) * 20) + ((d40_weight / weight) * 40)

      result.push({
        name: `D${strength.toFixed(0)}`,
        weight: weight,
        text: `${weight.toFixed(1)}g D${strength.toFixed(0)}`
      });
    }

    // Combine base tone ONLY if 2 base tone component
    while (base.length > 0) {
      if (base.length > 1) {
        if (base[1].val - base[0].val == 2) {
          // Calculate value of base tone's strength from components
          let concentration = 2.0;
          prod = mixingBowlInstance.findProduct(base[1].comp.name);
          if (!!prod) {
            concentration = prod.concentration || 2.0;
          }

          const lo_comp = base[0].val;
          const hi_comp = base[1].val;
          const hi_lo_diff = hi_comp - lo_comp;

          const weight = base[0].comp.weight + base[1].comp.weight;

          base[1].comp.weight /= concentration;
          const normalizeRatio = weight / (base[0].comp.weight + base[1].comp.weight);

          base[0].comp.weight *= normalizeRatio;
          base[1].comp.weight *= normalizeRatio;

          const strength = lo_comp + (hi_lo_diff - ((base[0].comp.weight / weight) * hi_lo_diff));

          if (strength % 1 == 0) {
            base[0].comp.name = `${strength.toFixed(0)}N`;
          } else {
            base[0].comp.name = `${strength.toFixed(2)}N`;
          }

          base[0].comp.weight = weight;
          base.splice(1, 1);  // remove '1' record
        }
      }
      if (base[0].comp.name == '0N')
        base[0].comp.name = '00N';
      result.push({
        name: base[0].comp.name,
        weight: base[0].comp.weight,
        text: `${base[0].comp.weight.toFixed(1)}g ${base[0].comp.name}`
      });
      base.splice(0, 1);
    }

    for (const pt of pure) {
      result.push({name: pt.comp.name, weight: pt.comp.weight, text: `${pt.comp.weight.toFixed(1)}g ${pt.comp.name}`});
    }

    return result;
  }

  // Takes an array of param objects {type:, value:, mod:} and coverts the preferred param types to component objects
  // {name:, weight:}
  static paramsToComponents(params: any[], preferredType: string = null) {
    let result: any[] = [];
    let compType: string = 'compName';

    if (preferredType)
      compType = preferredType;
    else {
      if (params.findIndex(element => element.type == 'compId') >= 0)
        compType = 'compId';
    }

    for (const param of params) {
      if (param.type == compType) {
        result.push({name: param.value, weight: parseFloat(param.mod)});
      }
    }
    return result;
  }

  // Takes an array of component objects {comp: {name: weight:}, text:} and coverts them to the preferred param type
  // representation {type: , value: , mod:}
  static componentsToParams(components: any[], paramType: string = 'compName'): any[] {
    let result = [];
    for (const comp of components) {
      if (comp.weight % 1 == 0)
        result.push({type: paramType, value: comp.name, mod: comp.weight.toFixed(0)});
      else
        result.push({type: paramType, value: comp.name, mod: comp.weight.toFixed(2)});
    }
    return result;
  }

  calcStrength(productName: string): number {
    return parseFloat(productName.replace('N', '').replace('D', ''));
  }

  getLocations(): any[] {
    return [
      {label: '(none)', value: ''},
      {label: 'All over color', value: 'All over color'},
      {label: 'Crown', value: 'Crown'},
      {label: 'Mohawk', value: 'Mohawk'},
      {label: 'New Growth', value: 'New Growth'},
      {label: 'Mid', value: 'Mid'},
      {label: 'Mid & Ends', value: 'Mid-Ends'},
      {label: 'Ends', value: 'Ends'},
      {label: 'Front Line', value: 'Front Line'},
      {label: 'Back Section', value: 'Back Section'},
      {label: 'Root Shadow', value: 'Root Shadow'},
      {label: 'Balayage', value: 'Balayage'},
      {label: 'Babylights', value: 'Babylights'},
      {label: 'Ombre', value: 'Ombre'},
      {label: 'Partial Highlight', value: 'Partial Highlight'},
      {label: 'Full Highlight', value: 'Full Highlight'},
      {label: 'Highlight 1', value: 'Highlight 1'},
      {label: 'Highlight 2', value: 'Highlight 2'},
      {label: 'Highlight 3', value: 'Highlight 3'},
      {label: 'Lowlight', value: 'Lowlight'}
    ]
  }

  // Generates an array of levels expressed as key (string) value (numerical)
  getStartingLevels(): any[] {
    let result: any[] = [];
    let lvl = Array.from({length: 24}, (value, key) => key);
    for (let l of lvl) {
      result.push({key: `${(l + 1) * 0.5}`, value: ((l + 1) * 0.5).toFixed(1)});
    }
    return result;
  }

  // Returns a single param type name for a given formula
  preferredParamType(formula: CLiCSFormula, preference: string = null) {
    let available: string[] = [];
    let result = preference || 'compOrig';
    for (const param of formula.params) {
      if (!available.includes(param.type)) {
        available.push(param.type);
      }
    }
    if (available.includes(preference)) {
      result = preference;
    } else {
      if (available.includes('compOrig')) {
        result = 'compOrig';
      } else {
        if (available.includes('compName')) {
          result = 'compName';
        } else {
          if (available.includes('compId')) {
            result = 'compId'
          }
        }
      }
    }
    return result;
  }

  // Returns the minimum allowable total formula weight that keeps the smallest component at a minimum weight
  minAllowableWeight(formula: CLiCSFormula, minDispensableWeight: number = 0.5): number {
    let minSingleCompWeight = 10000;
    let paramTotal = 0.0;
    let result = 20;  // "Safe" default amount

    if (!formula) {
      return result;
    }

    // Prefer "real" params over compOrig
    let preferredType = this.preferredParamType(formula, 'compName');
    if (preferredType == 'compOrig') {
      preferredType = this.preferredParamType(formula, 'compId');
    }

    // Get lowest param value
    for (const param of formula.params) {
      if (param.type == preferredType) {
        paramTotal += parseFloat(param.mod);
        if (parseFloat(param.mod) < minSingleCompWeight) {
          minSingleCompWeight = parseFloat(param.mod);
        }
      }
    }

    // If any matching params found, recalculate minimum weight to dispense
    if (minSingleCompWeight < 10000 && minSingleCompWeight > 0.0) {
      result = paramTotal * (minDispensableWeight / minSingleCompWeight);
    }

    // Protect against negative result
    if (result <= 0.0) {
      result = 20;
    }
    return Math.ceil(result);
  }

  // Calculates the minimum allowable total weight for a group of Formula Requests. Combines like params to see which
  // has the minimal weight, the determines how low the total group weight could be scaled to maintain a minimum
  // per-component weight. param: {type: value: mod:} all strings
  minAllowableGroupWeight(group: CLiCSFormula[], minDispensableWeight: number = 0.5): number {
    let includedParams: any[] = [];
    let preferredType = null;

    if (group.length == 1) {
      return this.minAllowableWeight(group[0], minDispensableWeight);
    } else {
      // Find the preferred param type across all group formulas
      for (const fr of group) {
        if (!preferredType) {
          preferredType = this.preferredParamType(fr, 'compName');
        } else {
          const type = this.preferredParamType(fr, 'compName');
          if (preferredType == 'compName' && ['compOrig', 'compId'].includes(type)) {
            preferredType = type;
          } else {
            if (preferredType == 'compOrig' && type == 'compId') {
              preferredType = type;
            }
          }
        }
      }

      // Create a combined array of formula parameters
      let presentTotal = 0.0; // The current total weight of the grouped FRs
      for (let fr of group) {
        presentTotal += fr.amount;
        for (const p of fr.params) {
          if (p.type == preferredType) {
            // If param already exist in includedParams sum this param amount (mod), else add the param to includedParams
            let existingParam = includedParams.find(el => el.value == p.value);
            if (!!existingParam) {
              let weight = parseFloat(existingParam.mod) + parseFloat(p.mod);
              existingParam.mod = weight.toFixed(3);
            } else {
              includedParams.push(p);
            }
          }
        }
      }

      // Find the lowest weight param, and the scale for the total grouped formula
      includedParams.sort((a, b) => {
        const a_weight = parseFloat(a.mod);
        const b_weight = parseFloat(b.mod);
        if (a_weight == b_weight) {
          return 0;
        } else {
          if (a_weight > b_weight)
            return 1;
          else
            return -1;
        }
      });
      const scale =  minDispensableWeight / parseFloat(includedParams[0].mod);
      return Math.ceil(presentTotal * scale);
    }
  }

  // Returns the maximum allowable total formula weight to dispense into any bowl
  maxAllowableBowlWeight(): number {
    return (300);
  }

  // Returns true if this is an additive-only quick dispense or CLAPP formula. Additives include CoBonder, lightener,
  // or pH reducer. Lightener may also include developer and still be considered an additive.
  isAdditiveFormula(formula, lightenerOnly: boolean = false): boolean {
    let result = true
    const additives = ['Li', 'CoB', 'pH']
    let hasLightener = false
    let developer = ['26', '27', '28', '98', '99']  // NOTE: a priori values used
    const range = Array.from(Array(41).keys())
    for (const i of range) {
      developer.push(`D${i}`);
    }

    const preferredType = this.preferredParamType(formula)
    const params = formula.params.filter((el) => {
      return el.type == preferredType
    });

    // Scan parameters for additives or developer
    for (let param of params) {
      if (additives.includes(param.value)) {
        if (param.value == 'Li')
          hasLightener = true;
      } else {
        if (!developer.includes(param.value)) {
          result = false
          break
        }
      }
    }

    return result && (hasLightener || !lightenerOnly)
  }

  // Returns true if the formula is an additive formula that includes lightener
  isLightenerFormula(formula): boolean {
    return this.isAdditiveFormula(formula, true)
  }

  // Returns an object with information about the developer in this formula as {present:, strength:, amount:}
  formulaDeveloperInfo(formula): any {
    let result = {present: false, strength: 20, amount: 0}
    let developer = ['26', '27', '28', '98', '99']  // NOTE: a priori values used
    const range = Array.from(Array(41).keys())
    for (const i of range) {
      developer.push(`D${i}`);
    }
    for (let param of formula.params) {
      if (developer.includes(param.value)) {
        result.present = true
        result.amount = parseFloat(param.mod)
        if (param.type == 'compOrig') {
          result.strength = parseInt(param.value.substr(1, 2))
          break
        }
      }
    }
    return result
  }

  // For quick dispense, limit the max amount for certain products
  maxQuickDispense(product: CLiCSProduct): number {
    let result = 300;
    switch (product.name.toLowerCase()) {
      case 'cob':
      case 'cobonder':
        result = 20;
        break;
      case 'ph':
      case 'ph reducer':
        result = 20;
        break;
    }
    return result;
  }

  // If the formula does not meet recommended criteria, return a warning string, else null
  // NOTE: assumes that compOrig parameters are present, which is consistent with Create Color page
  getFormulaWarningStr(formula: CLiCSFormula): string {
    let result = null;
    let tonalCount = 0;
    let pureToneCount = 0;

    const origParams = formula.params.filter((el) => {
      return el.type == 'compOrig';
    });

    // Scan components
    for (let param of origParams) {
      if (MixingBowlProvider.isTonalComponent(param.value)) {
        tonalCount += 1;
        if (!MixingBowlProvider.isBaseToneComponent(param.value)) {
          pureToneCount += 1;
        }
      }
    }

    if (tonalCount > 4) {
      result = 'CLICS recommended limiting formulas to a total of 4 color components or less.'
    } else {
      if (pureToneCount > 2) {
        result = 'CLICS recommends using no more than 2 Pure Tones in a formula.'
      }
    }
    return result;
  }

  // Returns a string associated with a starting level value from 1 through 11
  getLevelString(level: any, goldwell_levels: boolean = false): string {
    if (typeof(level) == 'string') {
      level = parseInt(level);
    }
    let levelStrings: string[] = [];
    if (goldwell_levels) {
      levelStrings = [
        '',
        '1N – Black',  // GW doesn't use 1N
        '2N – Black',
        '3N – Dark Brown',
        '4N – Medium Brown',
        '5N – Light Brown',
        '6N – Dark Blonde',
        '7N – Medium Blonde',
        '8N – Light Blonde',
        '9N – Very Light Blonde',
        '10N – Extra Light Blonde',
        '11N – Ultra Blonde'  // GW doesn't use 11N
      ]
    } else {
      levelStrings = [
        '',
        '1N – Black',
        '2N – Black/Brown',
        '3N – Darkest Brown',
        '4N – Dark Brown',
        '5N – Med Brown',
        '6N – Lt Brown',
        '7N – Dk Blonde',
        '8N – Med Blonde',
        '9N – Lt Blonde',
        '10N – Lightest Blonde',
        '11N – Ultra Blonde'
      ]
    }

    if (1 <= level && level <= 11) {
      return levelStrings[level];
    } else {
      return "";
    }
  }

  // Returns a 1 through 256 level from red, green, blue values
  luminanceFromRGB(R, G, B): number {
    return (0.2126*R + 0.7152*G + 0.0722*B);
  }

  // Takes R, G< and B (0-255) values and converts to a CLICS level number, 0-12
  levelFromRGB(R, G, B): number {
    let result = 0;
    const L = this.luminanceFromRGB(R, G, B) * 0.39;
    if (L >= 22.0)
      result = 1;
    if (L >= 26.0)
      result = 2;
    if (L >= 29.5)
      result = 3;
    if (L >= 33.0)
      result = 4;
    if (L >= 36.5)
      result = 5;
    if (L >= 40.0)
      result = 6;
    if (L >= 45.0)
      result = 7;
    if (L >= 50.0)
      result = 8;
    if (L >= 63.5)
      result = 9;
    if (L >= 77.0)
      result = 10;
    if (L >= 83.5)
      result = 11;
    if (L >= 90.0)
      result = 12;

    return result;
  }

  // Utility method converts a hex string like '#123f56' to rgb values
  // Returns object {r, g, b}
  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
}
