import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {ComponentsModule} from '../../components/components.module';
import {ApplicationInfoPage} from './application-info';
import {ApplicationInfoPageRoutingModule} from './application-info-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    ApplicationInfoPageRoutingModule,
  ],
  declarations: [ApplicationInfoPage],
  providers: [
    NavParams,
    Camera,
  ],
})
export class ApplicationInfoPageModule {}
