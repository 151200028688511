import {Component, AfterContentInit, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {PageControllerProvider} from '../../services/page-controller/page-controller'
import {SettingsProvider} from "../../services/settings/settings";

/**
 * Generated class for the NavigationSidebarComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'navigation-sidebar',
  templateUrl: 'navigation-sidebar.html',
  styleUrls: ['navigation-sidebar.scss'],
})
export class NavigationSidebarComponent implements AfterContentInit, OnInit, OnDestroy {
  // current_root: string = '';
  // current_page: string = 'clients';
  page_link_class = {};
  activePage: string = null;
  theme: string = 'light';

  currentRouteUrl: string = null;
  clientIsSelected: boolean = false;
  full_view: boolean = true;  // False = Limited View for Jensen & Co.
  owner_view: boolean = false;  // If true show owner-level reports
  manager_view: boolean = false;  // If true show manager-level reports
  renter_view: boolean = false;  // If true show chair-renter reports
  permissions: any = null;
  pageChangedSubscription: Subscription = null;
  clientSelectedSubscription: Subscription = null;
  dispenseModeChangedSubscription: Subscription = null;
  navigationEndSubscription: Subscription = null;

  enabled: boolean = true;
  enableTimer: any = null;
  pageParams: any = null;

  @Input() useConversionLibrary: boolean = false;
  @Input() current_root: string = ''; // top route name like 'clients' or 'lab' must be set on page
  @Output() changeLibrary = new EventEmitter();

  constructor(
    public events: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    public clicsService: CLiCSService,
    private pageCtrl: PageControllerProvider,
    public modeCtrl: ModeControllerProvider,
    private settingsCtrl: SettingsProvider
  ) {
    this.page_link_class = {
      "dispenser_app": "page-link",
      "queue": "page-link",
      "quick": "page-link",
      "lab_color": "page-link",
      "lab_app": "page-link",
      "collections_menu": "page-link",
      "convert": "page-link",
      "create": "page-link",
      "create_app": "page-link",
      "create_color": "page-link",
      "lookbook_salon": "page-link",
      "lookbook_apps": "page-link",
      "lookbook_clients": "page-link",
      "lookbook_cover": "page-link",
      "admin_dispenser": "page-link",
      "admin_equipment": "page-link",
      "admin_lookbook": "page-link",
      "admin_profile": "page-link",
      "admin_service": "page-link",
      "admin_inventory": "page-link",
      "admin_salon": "page-link",
      "admin_stylists": "page-link",
      "report_billing": "page-link",
      "report_clients": "page-link",
      "report_ecology": "page-link",
      "report_inventory": "page-link",
      "report_lookbook": "page-link",
      "report_register": "page-link",
      "clients": "page-link",
      "application": "page-link",
      "swatches": "page-link",
      "pics": "page-link",
      "profile": "page-link",
      "history": "page-link",
      "dispensed": "page-link"
    };


    this.permissions = this.modeCtrl.getPermissions();
    this.modeCtrl.init().then(response => {
      this.permissions = this.modeCtrl.getPermissions();
    });
  }

  ngOnInit() {
    const user = this.clicsService.current_user;
    this.owner_view = !!user && (!!user.is_admin || user.role >= 49);
    this.manager_view = !!user && (!!user.is_admin || user.role >= 40)
    this.renter_view = !!user && user.isChairRenter();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.activePage = event.url;

      // Remove forward slash and query params from URL
      if (this.activePage.match(/^\/.+/)) {
        this.activePage = this.activePage.substring(1);
        const queryStart = this.activePage.indexOf('?');
        if (queryStart >= 0) {
          this.activePage = this.activePage.substring(0, queryStart);
        }
      }
    });

    this.theme = this.clicsService.theme;
  }

  // Update the content after the page loads
  ngAfterContentInit() {
    this.clientIsSelected = this.clicsService.clientIsSelected();
    this.clientSelectedSubscription = this.events.subscribe('client:selected', (client) => {
      this.clientIsSelected = (client != null);
    });

    this.dispenseModeChangedSubscription = this.events.subscribe('dispense_mode:change', () => {
      this.permissions = this.modeCtrl.getPermissions();
      this.modeCtrl.init().then(response => {
        this.permissions = this.modeCtrl.getPermissions();
      });
    });

    this.route.queryParams.subscribe(params => {
      this.pageParams = params;
    });

    this.activePage = this.router.url;      // Remove forward slash and query params from URL
    if (this.activePage.match(/^\/.+/)) {
      this.activePage = this.activePage.substring(1);
      const queryStart = this.activePage.indexOf('?');
      if (queryStart >= 0) {
        this.activePage = this.activePage.substring(0, queryStart);
      }
    }
  }

  ngOnDestroy() {
    // this.pageChangedSubscription = this.events.unsubscribe(this.pageChangedSubscription);
    this.clientSelectedSubscription = this.events.unsubscribe(this.clientSelectedSubscription);
    this.dispenseModeChangedSubscription = this.events.unsubscribe(this.dispenseModeChangedSubscription);
  }

  // Push a page onto the page stack. Force makes the navigation push happen even if we think we're already
  // on the page.
  doPageNavigation(page_ref: string, force: boolean = false, copyParams = false, params: any = null) {
    if (!this.enabled) {
      return;
    }
    if (copyParams && this.pageParams) {
      this.pageCtrl.doPageNavigation(page_ref, this.pageParams, force);
    } else {
      this.pageCtrl.doPageNavigation(page_ref, params, force);
    }
  }

  // Choose conversion library (true) or CLICS colors (false). For CreateMenu page
  chooseConversion(active: boolean) {
    this.useConversionLibrary = active;
    this.changeLibrary.emit({conversion: this.useConversionLibrary});
  }

  // Disables navigation for no more than 30 seconds
  disableNavigation() {
    this.enableTimer = setTimeout(() => {
      this.enableNavigation();
    }, 30000);
    this.enabled = false;
  }

  // Re-enables navigation
  enableNavigation() {
    this.enabled = true;
    if (!!this.enableTimer) {
      clearTimeout(this.enableTimer);
      this.enableTimer = null;
    }
  }

}
