import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Contacts} from '@ionic-native/contacts';
import {StaffInvitationPage} from './staff-invitation';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [StaffInvitationPage],
  providers: [
    NavParams,
    Contacts,
  ],
})
export class StaffInvitationPageModule {}
