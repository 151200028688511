import {Component, ElementRef, ViewChild, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams, ToastController, Platform} from '@ionic/angular';
import {Validators, AbstractControl, ValidatorFn, FormGroup, FormControl} from '@angular/forms';
import {Contacts} from '@ionic-native/contacts';
import {ConfirmInvitationPage} from '../confirm-invitation/confirm-invitation';
import {CLiCSService} from '../../services/clics.service';
import {CLiCSClient} from '../../../lib/client';

/**
 * Generated class for the StaffInvitationPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-staff-invitation',
  templateUrl: 'staff-invitation.html',
  styleUrls: ['staff-invitation.scss'],
})
export class StaffInvitationPage implements OnInit {
  @ViewChild('roleRadio') roleEl: ElementRef;
  @ViewChild('lastNameInput') lastNameInput;
  @ViewChild('phoneInput') phoneInput;
  @ViewChild('nickNameInput') nickNameInput;

  staffForm: any = null;
  invitee: CLiCSClient = null; // Using client object for "person" fields
  role: string = 'stylist';

  constructor(private navCtrl: NavController,
              public navParams: NavParams,
              private contacts: Contacts,
              public clicsService: CLiCSService,
              private toastCtrl: ToastController,
              public plt: Platform,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.staffForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required)
    });
  }

  closeModal(paramObject: any = null) {
    if (paramObject)
      this.modalCtrl.dismiss(paramObject);
    else
      this.modalCtrl.dismiss();
  }

  // Allows blank fields OR one or more passed validators
  optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value ? Validators.compose(validators)(control) : null;
    };
  }

  async inviteStaffMember() {
    this.invitee = new CLiCSClient();
    this.invitee.first_name = this.staffForm.get('firstName').value;
    this.invitee.last_name = this.staffForm.get('lastName').value;
    this.invitee.phone = this.staffForm.get('phone').value;
    this.invitee.role = this.role;
    this.clicsService.apiInviteStaff(this.invitee).then(async (result) => {
      if (result.success) {
        if (!!result.message)
          this.confirmWithToast(result.message);
        else
          this.confirmWithToast(`${result.name} successfully invited`);
        this._clearForm();
      } else {
        if (!!result.existing) {
          const confirmModal = await this.modalCtrl.create({
            component: ConfirmInvitationPage,
            componentProps: {invite_data: result},
            backdropDismiss: true,
            cssClass: this.clicsService.theme,
          });

          confirmModal.onDidDismiss().then((overlayEvent) => {
            const data = overlayEvent.data;

            if (!!data && parseInt(data.choice) > 0) {
              this.clicsService.apiConfirmInvite(result.invite_code, parseInt(data.choice)).then((result) => {
                this.confirmWithToast(result.message);
                this._clearForm();
              });
            } else {
              this.confirmWithToast(`Sorry, could not process invitation`, true);
            }
          });

          await confirmModal.present();
        }
        else {
          if (!!result.message_key)
            this.confirmWithToast(`Invitation failed: ${result.message}`, true);
          else
            this.confirmWithToast(`Sorry, could not process invitation`, true);
        }
      }
    }, (reason) => {
      this.confirmWithToast(`Sorry, could not process invitation`, true);
    });
  }

  // If the keypress is Enter then go to the next input
  advanceOnEnter(event: any, nextInput: string) {
    if (event.key == 'Enter') {
      switch (nextInput) {
        case 'lastNameInput':
          setTimeout(() => {
            this.lastNameInput.setFocus();
          }, 150);
          break;
      }
    }
  }

  // Open a contacts dialog then load the contact information into the form fields
  loadFromContacts() {
    this.contacts.pickContact().then((contact: any) => {
      this._clearForm();
      this.staffForm.controls['firstName'].setValue(contact.name.givenName);
      this.staffForm.controls['lastName'].setValue(contact.name.familyName);
      this.staffForm.setValidators(Validators.compose([Validators.required]));
      this.staffForm.enable();
    });
  }

  _clearForm() {
    this.staffForm.controls['firstName'].setValue('');
    this.staffForm.controls['lastName'].setValue('');
    this.staffForm.controls['phone'].setValue('');
    this.staffForm.controls['firstName'].touched = false;
    this.staffForm.controls['lastName'].touched = false;
  }

  // Show a toast with a message
  async confirmWithToast(mssg: string, error = false) {
    let classStr = 'clics-toast';
    if (error) {
      classStr += ' error';
    }
    const duration = error ? 5000 : 4000;
    const toast = await this.toastCtrl.create({
      message: mssg,
      duration: duration,
      cssClass: classStr
    });
    await toast.present();
  }

  updateRole(event) {
    this.role = event.detail.value;
  }

}
