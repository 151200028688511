import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {ComponentsModule} from '../../components/components.module';
import {LabColorPage} from './lab-color';
import {LabColorPageRoutingModule} from './lab-color-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    LabColorPageRoutingModule,
  ],
  declarations: [
    LabColorPage,
  ],
  providers: [
    NavParams,
    TapticEngine,
  ],
})
export class LabColorPageModule {}
