import {Component} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {EventsService} from '../../services/events/events.service';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {CLiCSColorSession} from '../../../lib/session';
import {CLiCSAppType} from '../../../lib/app-type';

/**
 * Generated class for the StepsDisplayPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-steps-display',
  templateUrl: 'steps-display.html',
  styleUrls: ['steps-display.scss'],
})
export class StepsDisplayPage {
  cs: CLiCSColorSession = null;
  app_type: CLiCSAppType = new CLiCSAppType();
  modalDismissSubscription: Subscription = null;

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private modalCtrl: ModalController,
              private mixingBowl: MixingBowlProvider,
              private events: EventsService) {
  }

  ionViewWillEnter() {
    this.cs = this.navParams.get('cs');
    if (this.cs) {
      this.app_type = this.mixingBowl.findAppType(this.cs.app_type_ident);
    }
    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
