import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Generated class for the RinseTimeSetterComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'rinse-time-setter',
  templateUrl: 'rinse-time-setter.html',
  styleUrls: ['rinse-time-setter.scss'],
})
export class RinseTimeSetterComponent {
  @Input() title: string = 'Set Rinse Timer';
  @Input() value: number = 30;
  @Input() confirmText: string = 'Set';
  @Input() cancelText: string = 'Cancel';
  @Input() clearText: string = 'Clear Timer';
  @Input() suffix: string = ' min';
  @Input() processingTime: number = null;
  @Output() confirmation = new EventEmitter();
  @Output() cancel = new EventEmitter();

  min: number = 1;
  max: number = 90;
  // value: number;
  valStr: string = `${this.value} ${this.suffix}`;

  constructor() {
    this.valStr = `${this.value} minutes`;
  }

  ngOnInit() {
    this.valStr = `${this.value} minutes`;
  }

  initialize(minutesToRinse) {
    if (minutesToRinse && minutesToRinse > 0)
      this.value = minutesToRinse;
    else
      this.assertDefault();
  }

  assertDefault() {
    if (this.value == undefined || (!!this.value && this.value <= 0))
      this.value = 30;
  }

  updateValue(data) {
    this.value = data.amount
    this.assertDefault();
    this.valStr = `${this.value} minutes`;
  }

  doDispense() {
    this.confirmation.emit(this.value);
  }

  doClearTimer() {
    this.value = 0;
    this.confirmation.emit(0);
  }

  doCancel() {
    this.cancel.emit();
  }

}
