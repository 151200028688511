/*
    Defines a base class for a CLICS formula. This should not be used directly except where a list of mixed
    CLiCSColorFormulas and CLiCSFormulaRequests.
 */
// MIGRATION NOTE: removing import {ComponentFactoryName} from "@angular/compiler";
import {MixingBowlProvider} from "../app/services/mixing-bowl/mixing-bowl";

export class CLiCSFormula {
  token: string = '';
  title: string = '';
  mode: string = '';              // Used only to set downstream formula request mode
  rgb: string = '';
  level: number = null;
  thumb: string = '';
  formula_text: string = '';
  location: string = null;        // position on the head for applying this formula
  ordinal: number = 0;
  request_ident: string = null;   // used in Formula Request to identify an un-registered FR object to the server
  params: any[] = [];             // List of params ex: {type: 'compName', value: 'LOR', mod: '45.3'} {type: 'perm', value: 'demi'}
  product_line: any = null;

  // From Formula Request
  amount: number = null;          // amount of this formula to be dispensed
  coverage: string = 'p';         // sensible default (permanent)
  cobonded: boolean = false;
  info: string = '';
  owned: boolean = true;          // Assume this object owned by user

  library: string = null;         // Temporary to allow filtering of libraries
  selected: boolean = false;      // Set true if this is selected

  tag: boolean = false;           // set externally for local loop tracking

  // Eco-savings settings
  eco_type: string = 'custom';
  eco_amount: number = null;
  unused: number = null;

  group_id: number = 0;          // used to group formulas in an APP into a single dispense

  // Item can be constructed with either a data object or a string
  constructor(data: any = null, owned: boolean = true) {
    this.owned = owned;
    if (data != null) {
      if (typeof data == 'object')
        this.loadObj(data);
      else {
        if (typeof data == 'string')
          this.loadStr(data);
      }
    }
  }

  loadObj(data: any) {
    if (data !== undefined) {
      if ('token' in data)
        this.token = data.token;
      if ('request_ident' in data)
        this.request_ident = data.request_ident;
      if ('title' in data)
        this.title = data.title;
      if ('rgb' in data)
        this.rgb = data.rgb;
      if ('level' in data)
        this.level = data.level;
      if ('mode' in data)
        this.mode = data.mode;
      if ('thumb' in data)
        this.thumb = data.thumb;
      if ('formula_text' in data)
        this.formula_text = data.formula_text;
      if ('location' in data)
        this.location = data.location;
      if ('library' in data)
        this.library = data.library;
      if ('ordinal' in data)
        this.ordinal = data.ordinal;
      if ('coverage' in data)
        this.setCoverage(data.coverage);
      if ('cobonded' in data)
        this.setCobonded(data.cobonded);
      if ('owned' in data)
        this.owned = data.owned == true;
      if ('eco_type' in data)
        this.eco_type = data.eco_type;
      if ('eco_amount' in data)
        this.eco_amount = data.eco_amount;
      if ('unused' in data)
        this.unused = data.unused;
      if ('product_line' in data && !!data.product_line)
        this.product_line = data.product_line;
      if (data.params != undefined) {
        this.params.length = 0;
        for (let param of data.params) {
          let newParam = JSON.parse(JSON.stringify(param));
          this.params.push(newParam);
        }
      }
      if ('requested' in data)
        this.amount = parseFloat(data.requested);
      else {
        if ('requested_weight' in data) {
          this.amount = parseFloat(data.requested_weight);
        } else {
          if ('amount' in data)
            this.amount = parseFloat(data.amount);
          else {
            if (!this.amount)
              this.amount = 0.0;
          }
        }
      }
      if ('group_id' in data) {
        this.group_id = data.group_id;
      }
    }
  }

  loadStr(dataStr: string) {
    let data = JSON.parse(dataStr);
    this.loadObj(data);
  }

  // Sets the token for this formula but does not allow the token to be overwritten
  setToken(tokenStr: string, force :boolean = false): string {
    if (force || this.token == '' || this.token == null) {
      this.token = tokenStr;
    }
    return this.token;
  }

  // Sets or clears the amount of this formula to dispense
  setAmount(newAmount: number): boolean {
    let result: boolean = false;
    if (newAmount) {
      newAmount = parseFloat(newAmount.toFixed(1));  // prevent long decimal scale
      result = this.amount != newAmount;
      this.amount = newAmount;
    } else {
      result = this.amount != 0.0;
      this.amount = 0.0;
    }
    return result;
  }

  // Sets the amount to a default value if null or 0.0
  assertAmount(defaultAmount: number = null) {
    if (defaultAmount && defaultAmount >= 0) {
      if (!this.amount)
        this.amount = defaultAmount;
    } else {
      const preferredType = this.preferredCompType();
      let totalAmount: number = 0.0;
      for (let param of this.params) {
        if (param.type == preferredType)
          totalAmount += parseFloat(param.mod);
      }
      if (totalAmount == 0.0)
        totalAmount = 50.0;
      this.amount = parseFloat(totalAmount.toFixed(1));
    }
  }

  // Sets a local identifier. This is used to match server responses to the local CLiCSColorFormula object
  assertLocalIdent(regenerate: boolean = false) {
    if (this.request_ident == null || regenerate) {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      this.request_ident = text;
    }
    return this;
  }

  // Clears the token field and regenerates a local ident field
  clearToken(): void {
    this.token = '';
  }

  // Sets the formula mode, checking for valid formula string. Removes library formula reference for non-library FRs
  setMode(newMode: string) {
    const validModes = ['basic', 'library', 'expert', 'bogus'];
    if (validModes.indexOf(newMode) >= 0) {
      this.mode = newMode;
      if (this.mode != 'library')
        this.removeParamsByType('formTok');
    } else
      throw new Error(`Invalid mode string passed to CLiCSFormula::setMode (${newMode})`);
  }

  // If this is a library formula detect and remove formTok value in .token field while asserting a formTok parameter
  sanitizeLibraryFormula() {
    if (this.mode == 'library') {
      let pIndex = this.params.findIndex((el) => {
        return (el.type == 'formTok');
      });
      if (pIndex >= 0) {
        // Check for formTok value in token field, else leave it
        if (this.token == this.params[pIndex].value) {
          this.clearToken();
        }
      } else {
        // No formTok params suggest the token field is the formTok
        this.setParam('formTok', this.token, null, true);
        this.clearToken();
      }
    }
  }

  // Checks a parameter type against a list of known good param types
  static paramIsValid(param_type: string): boolean {
    const paramTypes = ['compOrig', 'compName', 'compId', 'formTok', 'formId', 'target', 'por', 'gray', 'hairClr'];
    let index = paramTypes.findIndex((el) => {
      return (el == param_type)
    });
    return (index >= 0);
  }

  // Sets the mod field on an existing param (typ. amount) or adds a new param if not found
  setParam(type: string, value: string, mod: string = null, distinctType: boolean = false): void {
    if (CLiCSFormula.paramIsValid(type)) {
      let index = this.params.findIndex((el) => {
        return (el.type == type && (distinctType || el.value == value));
      });
      if (mod) {
        // Find and update param or add new if first
        if (index >= 0)
          this.params[index].mod = mod;
        else
          this.addParam(type, value, mod);
      } else {
        // Add param if it doesnt already exist
        if (index < 0)
          this.addParam(type, value);
        else {
          // If distinct remove all params of this type and recreate a new one
          if (distinctType) {
            this.removeParamsByType(type);
            this.addParam(type, value, mod);
          }
        }
      }
    }
  }

  // Adds a new param
  addParam(type: string, value: string, mod: string = null) {
    if (CLiCSFormula.paramIsValid(type)) {
      // let validTypes = ['compName',];
      let param = {type: type, value: value, mod: mod};
      this.params.push(param);
    }
  }

  // Adds a param only if one matching type and value don't already exist
  instantiateParam(type: string, value: string, mod: string = null): void {
    if (CLiCSFormula.paramIsValid(type)) {
      let index = this.params.findIndex((el) => {
        return (el.type == type && el.value == value);
      });
      if (index < 0) {
        this.addParam(type, value, mod);
      }
    }
  }

  // Removes a param by type and value
  removeParam(type: string, value: string) {
    let index = this.params.findIndex((el) => {
      return (el.type == type && el.value == value);
    });
    if (index >= 0)
      this.params.splice(index, 1);
  }

  // Removes all params with the passed type
  removeParamsByType(type: string) {
    let index = null;
    while (index == null || index >= 0) {
      index = this.params.findIndex((el) => {
        return (el.type == type)
      });
      if (index >= 0)
        this.params.splice(index, 1);
    }
  }

  // Replace all local params with new ones, passed as array of param objects {type, value, mod}
  replaceParams(new_params: any[]) {
    this.params.length = 0;
    for (let param of new_params) {
      this.params.push(param);
    }
  }

  // Returns true if params use compId
  usesOriginal(): boolean {
    let index = this.params.findIndex(element => element.type == 'compOrig');
    return (index >= 0);
  }

  // Returns true if params use compId
  usesComponentIds(): boolean {
    let index = this.params.findIndex(element => element.type == 'compId');
    return (index >= 0);
  }

  // Returns true if params use compName (note: compId should be preferred)
  usesComponentNames(): boolean {
    let index = this.params.findIndex(element => element.type == 'compName');
    return (index >= 0);
  }

  // returns preferred parameter type depending on what exists: compOrig, then compId, then compName.
  // Reasoning: compOrig is mostly immutable, compId is definitive and more or less stable, compName is ephemeral.
  preferredCompType(): string {
    let result = 'compName';
    if (this.usesOriginal())
      result = 'compOrig';
    else {
      if (this.usesComponentIds())
        result = 'compId';
    }
    return result;
  }

  isEmpty(): boolean {
    return (this.params.length == 0);
  }

  // Checks component params "mod" field (amount) sums it and returns the sum
  totalAmount(): number {
    let result = 0.0;
    let useId = this.usesComponentIds();
    let compType = this.preferredCompType();

    for (let param of this.params) {
      if (param.type == compType)
        result += parseFloat(param.mod);
    }
    return result;
  }

  // when we change the amount of the formula we may want to update the parameters locally for editing purposes
  adjustParamsFromAmount(): any[] {
    for (let paramType of ['compOrig','compName','compId']) {
      let comps = this.params.filter((param) => {
        return param.type == paramType;
      });
      CLiCSFormula._normalizeParamsToAmount(this.amount, comps);
    }

    return this.params;
  }

  static _normalizeParamsToAmount(amount: number, params: any[]): any[] {
    let total: number = 0;
    let factor: number = 1;

    // Don't lose parameters by setting them all to zero
    if (amount <= 0.1) {
      return params;
    }

    for (let param of params) {
      total += parseFloat(param.mod);
    }
    if (total <= 0.0) {
      return params;
    } else {
      factor = amount / total;
      return params.map(el => {
        el.mod = (parseFloat(el.mod) * factor).toFixed(4);
        return el;
      });
    }
  }


  // If formula text is null looks for parameters and attempts to generate formula text from those parameters.
  // Does not check validity of formula text.
  assertFormulaText(force: boolean = false, overrideAmount: number = null): string {
    let paramType = 'compId';
    let amount = this.amount;

    if (force || this.formula_text == null || this.formula_text == "") {
      this.formula_text = '';

      // compOrig are preferred as they keep fractional formula intents intact
      if (this.usesOriginal())
        paramType = 'compOrig';
      else {
        if (this.usesComponentNames())
          paramType = 'compName';
      }

      // Add components to the formula text
      let total_weight: number = 0.0;

      if (overrideAmount) {
        amount = overrideAmount;
      }

      if (amount > 0.0) {
        for (let param of this.params) {
          if (param.type == paramType) {
            total_weight += parseFloat(param.mod);
          }
        }
      }
      for (let param of this.params) {
        if (param.type == paramType) {
          if (amount > 0.0) {
            const newWeight = (amount / total_weight) * parseFloat(param.mod);
            if (newWeight % 1 == 0)
              this.formula_text += `${newWeight.toFixed(0)}g ${param.value} + `;
            else
              this.formula_text += `${newWeight.toFixed(1)}g ${param.value} + `;
          } else
            this.formula_text += `${param.mod}g ${param.value} + `;
        }
      }
    }
    this.formula_text = this.formula_text.replace(/ \+ $/, '');
    return this.formula_text;
  }

  // Return some RGB value, white if none available
  getRGB() {
    if (this.rgb == null || this.rgb == "")
      return "#ffffff";
    else
      return this.rgb;
  }

  // If the formula has compName params uses this to generate an array of component strings (used for display)
  // If no compName components then the formula text is used.
  formulaStringArray(): string[] {
    let result = [];
    const paramType = this.nameParamType();

    let comps = this.params.filter((el) => {
      return (el.type == paramType);
    });

    // const useComps = !this.formula_text;  // Use this to prefer "correct" formula text
    const useComps = (paramType == 'compOrig');  // Use this to prefer compOrig in call
    // const useComps = this.mode != 'library' || this.formula_text == undefined || this.formula_text == '';  // Original logic
    if (comps.length > 0 && useComps) {   // Library formulas don't use compName params
      for (let comp of comps) {
        const newWeight = parseFloat(comp.mod);
        if (newWeight % 1 == 0)
          result.push(`${newWeight.toFixed(0)}g ${comp.value}`);
        else
          result.push(`${newWeight.toFixed(2)}g ${comp.value}`);
      }
    } else {
      if (this.formula_text != undefined && this.formula_text != '') {
        result = this.formula_text.split(' + ');
      }
    }
    return result;
  }

  // Removes the token in order to unlink this formula record from the remote
  unlink(): CLiCSFormula {
    this.token = null;
    this.assertFormulaText(true);
    this.assertLocalIdent(true);
    return this;
  }

  setOrdinal(ord: number) {
    this.ordinal = ord;
  }

  // Returns the title string if set else formula text
  titleString(max_length: number = 0, default_string: string = '(no title)'): string {
    let result: string = '';
    if (this.title != null && this.title != "")
      result = this.title;
    else {
      if (this.formula_text != null && this.formula_text != "")
        result = this.formula_text;
      else
        result = default_string;
    }
    if (max_length > 0 && result.length > max_length) {
      result = result.substring(0, max_length - 4) + '...';
    }
    return result;
  }

  // Returns the product line string, if any
  productLineStr(): string {
    if (!!this.product_line) {
      return this.product_line.name;
    } else {
      return '';
    }
  }

  // Clear this object's data
  clear(preserveToken: boolean = false): void {
    if (preserveToken == false)
      this.token = '';
    this.title = '';
    this.mode = '';
    this.rgb = '';
    this.level = null;
    this.thumb = '';
    this.formula_text = '';
    this.location = null;
    this.ordinal = 0;
    this.params.length = 0;
  }

  // Sets a position on the cranium. Returns true if the value was changed
  setLocation(newPosition: string): boolean {
    const result: boolean = newPosition != this.location;
    this.location = newPosition;
    return result;
  }

  locationName(): string {
    if (this.location == null || this.location == '')
      return ("");
    else
      return (this.location);
  }

  // Takes a coverage string and saves it as a single character. Returns true if the value was changed
  setCoverage(value: string): boolean {
    const c: string[] = ['p', 'd', 't', 'g'];
    let new_value = value.toLowerCase().substr(0, 1);
    if (c.indexOf(new_value) >= 0) {
      const result: boolean = this.coverage != new_value;
      this.coverage = new_value;
      this.updateInfo();
      return result;
    } else
      return false;
  }

  // Returns the full coverage string
  getCoverage(): string {
    const coverages: string[] = ['perm', 'demi', 'toner', 'gloss'];
    const c: string[] = ['p', 'd', 't', 'g'];
    let index = c.indexOf(this.coverage);
    if (index >= 0)
      return coverages[index];
    else
      return null;
  }

  // Returns true if cobonded value is changed
  setCobonded(isCobonded: boolean): boolean {
    const result: boolean = this.cobonded != isCobonded;
    this.cobonded = isCobonded;
    this.updateInfo();
    return result;
  }

  // Builds a string listing coverage and co-bonded
  updateInfo() {
    this.info = this.getCoverage();
    if (this.cobonded)
      this.info += ', CoB';
  }

  // Returns the sum of all of the component param amounts. Used to set a total formula amount from edited components
  sumOfParts(): number {
    let result: number = 0;
    const paramType = this.nameParamType();

    const comps = this.params.filter((el) => {
      return (el.type == paramType);
    });
    for (let comp of comps) {
      result += parseFloat(comp.mod);
    }
    return result;
  }

  // Returns the preferred component name param type (compOrig) if available in formula, else compName.
  nameParamType(): string {
    let paramType = 'compName';
    if (this.usesOriginal())
      paramType = 'compOrig';
    return paramType;
  }

  // Returns the total weight of the all the Base Tone and Pure Tone Components
  tonalWeight(): number {
    let result: number = 0.0;
    const paramType = this.nameParamType();

    const params = this.params.filter((el) => {
      return (el.type == paramType && MixingBowlProvider.isTonalComponent(el.value));
    });

    for (let param of params) {
      result += parseFloat(param.mod);
    }
    return result;
  }

  // Returns the APPROXIMATE level (lightness) of the base tone in this formula. If more than one base tones are
  // included this chooses the lowest value. Future implementations may sum up the base level by combining all base
  // tone components. Returns null if no base tones present in the formula.
  baseLevel(): number {
    let result: number = null;

    const paramType = this.nameParamType();
    const params = this.params.filter((el) => {
      return (el.type == paramType && MixingBowlProvider.isBaseToneComponent(el.value));
    });

    if (!!params && params.length > 0) {
      if (params.length == 1) {
        result = parseFloat(params[0].value.replace('N', ''));
      } else {
        if (paramType == 'compOrig') {
          result = 12;
          for (const param of params) {
            let level = parseFloat(param.value.replace('N', ''));
            if (level < result)
              result = level;
          }
        } else {
          // compName params
          // TODO: adjust for 2:1 higher-to-lower base tone ratio
          let level: number = 0.0;
          let count: number = 0.0;
          for (const param of params) {
            count += 1;
            level += parseFloat(param.value.replace('N', ''));
          }
          result = level / count;
        }
      }
    }
    return result;
  }

  // Calculates the ratio of developer to other components
  developerRatio(): number {
    let result: number = 1.0;
    let devWeight: number = 0.0;
    let tonalWeight: number = this.tonalWeight();

    const paramType = this.nameParamType();

    const params = this.params.filter((el) => {
      return (el.type == paramType && MixingBowlProvider.isDeveloperComponent(el.value));
    });

    for (let param of params) {
      devWeight += parseFloat(param.mod);
    }

    // Calculate dev ratio if both tonal and dev components
    if (devWeight > 0 && tonalWeight > 0.0)
      result = parseFloat((devWeight / tonalWeight).toFixed(1));

    return result;
  }

  // Returns true if this formula includes developer params
  hasDeveloper(): boolean {
    const paramType = this.nameParamType();
    const params = this.params.filter((el) => {
      return (el.type == paramType && MixingBowlProvider.isDeveloperComponent(el.value));
    });
    return (params.length > 0);
  }

  // Calculates the requested weight of color components in the formula
  colorAmount(): number {
    let compWeight: number = 0.0;
    let totalWeight: number = 0.0;

    // TODO: does not consider the presence of developer
    // Calculate the total amount of non-developer params
    const compType = this.preferredCompType();
    for (const param of this.params) {
      if (param.type == compType) {
        totalWeight += parseFloat(param.mod);
        if (MixingBowlProvider.isDeveloperComponent(param.value) == false) {
          compWeight += parseFloat(param.mod);
        }
      }
    }
    return (totalWeight / this.amount) * compWeight;
  }

  // Calculates the requested weight of color components in the formula
  // Adjusted is the default mode which returns a normalized amount based on this formula amount and the ratio
  // of components with respect to that amount. Setting adjusted to false will return the literal amount of developer
  // expressed in the formula params (used in formula-mix.ts)
  devAmount(adjusted: boolean = true): number {
    let devWeight: number = 0.0;
    let totalWeight: number = 0.0;

    // TODO: does not consider the presence of color components
    // Calculate the total amount of non-developer params
    const compType = this.preferredCompType();
    for (const param of this.params) {
      if (param.type == compType) {
        totalWeight += parseFloat(param.mod);
        if (MixingBowlProvider.isDeveloperComponent(param.value)) {
          devWeight += parseFloat(param.mod);
        }
      }
    }
    if (adjusted) {
      return (totalWeight / this.amount) * devWeight;
    } else {
      return devWeight;
    }
  }

  // Calculates the ratio of developer components to color components in this formula
  devRatio(decimalPlaces: number = 1): number {
    let compWeight: number = 0.0;
    let devWeight: number = 0.0;

    // Calculate the total amount of non-developer params
    const compType = this.preferredCompType();
    for (const param of this.params) {
      if (param.type == compType) {
        if (MixingBowlProvider.isDeveloperComponent(param.value)) {
          devWeight += parseFloat(param.mod);
        } else {
          compWeight += parseFloat(param.mod);
        }
      }
    }
    // Avoid div-by-zero and return default value
    if (compWeight == 0.0) {
      if (devWeight > 0) {
        compWeight = devWeight;
      } else {
        compWeight = 1.0;
        devWeight = 0.0;
      }
    }
    return parseFloat((devWeight / compWeight).toFixed(decimalPlaces));
  }

  // Adds or updates developer in this formula, using a ratio of the color components as the weight basis.
  // Does not modify the formula amount. If strength == 0, removes developer
  setDeveloper(strength: number, ratio: number = 1.0, mixingBowl: MixingBowlProvider): boolean {
    let changed = false;
    let compWeight = 0.0;
    let devWeight = 0.0;
    let nonPreferredParams: any[] = []; // additional params that may be removed
    let existingDevParams: any[] = [];
    let realDevComps: any[] = null;     // in case the formula uses compName params

    // Calculate the total amount of non-developer components
    const compType = this.preferredCompType();
    for (const param of this.params) {
      if (param.type == compType) {
        if (MixingBowlProvider.isDeveloperComponent(param.value) == false) {
          compWeight += parseFloat(param.mod);
        } else {
          existingDevParams.push(param);
        }
      } else {
        nonPreferredParams.push(param);
      }
    }

    // Calculate target total developer weight
    if (!ratio) {
      ratio = 1.0;
    }
    devWeight = compWeight * ratio;

    // Check for changed or non-existent developer params - set changed flag
    const targetDevCompOrig = `D${strength}`;
    if (compType == 'compOrig') {
      if (existingDevParams.length != 1) {
        changed = true;
      } else {
        for (const param of existingDevParams) {
          if (param.value != targetDevCompOrig || parseFloat(param.mod) != devWeight) {
            changed = true;
            break;
          }
        }
      }
    } else {
      if (compType == 'compName') {
        realDevComps = mixingBowl.getRealFromFractional(targetDevCompOrig, devWeight);
        if (existingDevParams.length != realDevComps.length) {
          changed = true;
        } else {
          for (const param of existingDevParams) {
            let index = realDevComps.findIndex((el) => el.product == param.value && el.weight == parseFloat(param.mod));
            if (index < 0) {
              changed = true;
              break;
            }
          }
        }
      } else {
        changed = true;  // catch-all
      }
    }

    if (changed) {
      this.removeDeveloper();
      if (ratio !== 0) {
        // Remove non-compOrig params
        for (const param of nonPreferredParams) {
          this.removeParam(param.type, param.value);
        }
        if (compType == 'compOrig') {
          this.params.push({type: 'compOrig', value: `D${strength}`, mod: `${devWeight}`});
        } else {
          if (compType == 'compName') {
            for (let param of realDevComps) {
              this.params.push({type: 'compName', value: param.product.name, mod: `${param.weight}`});
            }
          }
        }
      }
      if (this.mode == 'library') {
        this.mode = 'basic';  // We've modified the formula, so no longer link to library
      }
    }
    return changed;
  }

  // Removes all compName and compOrig developer params
  removeDeveloper() {
    let found: any[] = [];
    for (let param of this.params) {
      if (['compName', 'compOrig', 'compId'].includes(param.type) && MixingBowlProvider.isDeveloperComponent(param.value)) {
        found.push({type: param.type, value: param.value});
      }
    }
    for (const param of found) {
      this.removeParam(param.type, param.value);
    }
  }

  // Returns a numerical value for the developer strength
  developerStrength(): number {
    for (const param of this.params) {
      if (param.type == 'compOrig' && MixingBowlProvider.isDeveloperComponent(param.value)) {
        return parseFloat(param.value.replace('N', '').replace('gD', '').replace('D', ''));
      }
    }
  }

  // Recalculates the developer amount based on a passed ratio and the total weight of tonal components (base tone,
  // pure tone).
  // NOTE: developer ratio is controlled externally
  // NOTE: assumes one developer component. Multiples will work but changes ratio of one developer to the other
  adjustDeveloperByRatio(developerRatio: number = 1.0) {
    const paramType = this.nameParamType();
    let developerWeight: number = 0.0;

    const params = this.params.filter((el) => {
      return (el.type == paramType && MixingBowlProvider.isDeveloperComponent(el.value));
    });

    // Calculate developer weight
    if (params.length > 0) {
      developerWeight = this.tonalWeight() * developerRatio;
      if (params.length > 1) {
        developerWeight = parseFloat((developerWeight / params.length).toFixed(3));
      }
      // Assign new weight to developer params
      for (let param of params) {
        param.mod = developerWeight.toString();
      }
    }
  }

  // If this formula has compOrig params this will remove any compName params from the object
  cullCompNameParams() {
    if (this.usesOriginal()) {
      this.params = this.params.filter((el) => {
        return (el.type != 'compName');
      });
    }
  }

  // Converts all compName params to compOrig ONLY if there are no existing compOrig params.
  convertCompNameToCompOrig(mixingBowl: MixingBowlProvider) {
    if (this.usesOriginal() == false) {
      const nameParams = this.params.filter((el) => {
        return (el.type == 'compName');
      });

      const new_comps = MixingBowlProvider.getFractionalFromReal(MixingBowlProvider.paramsToComponents(nameParams), mixingBowl);
      const new_params = MixingBowlProvider.componentsToParams(new_comps);

      // Replace existing params with new params
      this.params = this.params.filter((el) => {
        return (el.type != 'compName');
      });
      for (const param of new_params)
        this.params.push(param);

      // finally, do the conversion
      for (let param of this.params) {
        if (param.type == 'compName')
          param.type = 'compOrig';
      }
    }
  }

  isOwned(): boolean {
    return (this.owned === true);
  }

  // Returns true if the formula is only developer
  isDeveloperOnly(): boolean {
    let hasOther = false;
    let hasDeveloper = false;
    let dev = false;

    for (const param of this.params) {
      dev = MixingBowlProvider.isDeveloperComponent(param.value)
      if (dev == true) {
        hasDeveloper = true;
      }
      if (dev == false) {
        hasOther = true;
        break;
      }
    }
    return hasDeveloper && !hasOther;
  }

  // Toggles the selected flag and returns the new value
  toggleSelected(): boolean {
    this.selected = !this.selected;
    return (this.selected);
  }

  // For identifying the class
  isFormulaRequest(): boolean {
    return false;
  }

  className(): string {
    return 'CLiCSFormula';
  }

  // Transforms developer params using CLICS designations (D20, D40) to Goldwell designations (gD20, gD40)
  useGoldwellDeveloper() {

  }

  // sets the developer for this FR from another formula. Set mixingBowl to the Mixing Bowl provider.
  setDeveloperFromFormula(formula: CLiCSFormula, mixingBowl: any) {
    if (!!this.amount && this.amount > 0.0) {
      this.adjustParamsFromAmount();  // Ensure parameters add up to the amount of the formula
    }
    if (!formula.hasDeveloper() && this.hasDeveloper()) {
      this.removeDeveloper();
    }
    if (formula.hasDeveloper() && !!formula.developerStrength()) {
      this.setDeveloper(formula.developerStrength(), formula.developerRatio(), mixingBowl);
    }
    // Only adjust the amount if this has a non-zero amount set
    if (this.amount > 0 && this.sumOfParts() > 0.0) {
      this.setAmount(this.sumOfParts());
    }
  }

}
