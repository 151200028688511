import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';

/**
 * Presents a color formula
 */

@Component({
  selector: 'app-formula-active',
  templateUrl: 'app-formula-active.html',
  styleUrls: ['app-formula-active.scss'],
})

export class AppFormulaActiveComponent implements OnInit {
  @Input() formula: CLiCSFormulaRequest;
  @Input() ordinal: string = '';
  @Input() showTimer: boolean = true;
  @Input() showMore: boolean = true;
  @Input() showLocation: boolean = true;
  @Input() showPedigree: boolean = true;
  @Input() showProductLine: boolean = false;
  @Input() theme: string = 'light';
  @Input() unit: string = "g";
  @Output() openFormulaChoices = new EventEmitter();
  @Output() openColorDetail = new EventEmitter();
  @Output() dispenseMore = new EventEmitter();
  @Output() applyNow = new EventEmitter();
  @Output() duplicate = new EventEmitter();

  defaultBowlColor = 'inherit';
  timerStr: string = '';

  permissions: any = null;
  settings: any = null;

    constructor(
      private modeCtrl: ModeControllerProvider,
      ) {
      // Load permissions from modeCtrl
      this.permissions = this.modeCtrl.getPermissions();

      this.modeCtrl.init().then(result => {
          this.permissions = this.modeCtrl.getPermissions();
      });

      if(!!this.permissions.show_milliliters){
          this.unit = "mL"
      }
    }

  ngOnInit() {
    if (this.formula == undefined) {
      this.formula = new CLiCSFormulaRequest();
    }
  }

  ionViewDidEnter() {
    if (this.theme == 'dark')
      this.defaultBowlColor = '#282828';  // $theme-dark-bg-color
    else
      this.defaultBowlColor = '#FFFFFF';  // $theme-light-bg-color
  }

  bowlColor() {
    if (this.formula && this.formula.bowl_color)
      return this.formula.bowl_color;
    else
      return this.defaultBowlColor;
  }

  // Instruct parent to open choice
  doFormulaChoices(token: any): void {
    this.openFormulaChoices.emit({formula_token: token});
  }

  // Instruct parent to open choice
  doColorDetail(token: any): void {
    this.openColorDetail.emit({formula_token: token});
  }

  // Emits an applNow event
  doApplyNow(token: any): void {
    this.applyNow.emit({formula_token: token});
  }

  updateTimerDisplay(): any {
    const timerData = this.formula.getApplicationTimer();
    if (timerData.started)
      this.timerStr = timerData.timerStr;
    else
      this.timerStr = '';
    return (timerData);
  }

  promptForMore(formula) {
    this.dispenseMore.emit({formula: formula})
  }

  // Emits and instruction to duplicate this formula
  duplicateFormula(token) {
    this.duplicate.emit({formula_token: token, ordinal: parseInt(this.ordinal)})
  }

  amountStr(): string {
    if (!!this.formula.amount) {
      if (this.formula.amount % 1 == 0) {
        return `${this.formula.amount.toFixed(0)}${this.unit}`
      } else {
        return `${this.formula.amount.toFixed(1)}${this.unit}`
      }
    }
  }

}
