import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform, ToastController} from '@ionic/angular';
import {BarcodeScanner, BarcodeScannerOptions} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryFullConfirmPage} from '../inventory-full-confirm/inventory-full-confirm';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';

/**
 * Generated class for the InventoryLoopPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-inventory-loop',
  templateUrl: 'inventory-loop.html',
  styleUrls: ['inventory-loop.scss'],
})
export class InventoryLoopPage {
  scannedData: any = null;
  encodedData: string = null;
  report: string = null;
  shipmentId: number = null;

  isFullScan: boolean = false
  incomingScan: boolean = false
  isFinished: boolean = false

  loopTimer: any = null;
  loopTimeout: number = 100;

  scanNextText: string = 'Scan First Canister';

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private barcodeCtrl: BarcodeScanner,
    private events: EventsService,
    private plt: Platform,
    private alertCtrl: AlertController,
    // private insomnia: Insomnia,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private clicsService: CLiCSService
    )
  {
    if (!!this.navParams.get('full_inventory')) {
      this.isFullScan = true;
    }
    if (!!this.navParams.get('incoming_scan')) {
      this.incomingScan = true;
    }
    this.shipmentId = this.navParams.get('shipment_id');
  }

  scanNext() {
    let _that = this;
    if (this.loopTimer) {
      this.loopTimer = null;
    }

    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Center CLICS barcode inside the scan area',
      resultDisplayDuration: 500,
      formats: 'CODE_128',
      orientation: 'portrait',
    };

    this.barcodeCtrl.scan(options).then(barcodeData => {
      _that.scannedData = barcodeData;
      _that.report = null;
      if (!_that.scannedData || _that.scannedData['text'] == '') {
        console.log("no barcode data")
        return
      } else {
        console.log('Barcode data', _that.scannedData['text']);
      }
      _that.clicsService.apiRegisterCanister(_that.scannedData['text'], _that.shipmentId).then((data) => {
        _that.scanNextText = 'Scan Next Canister';
        if (!!data.success) {
          _that.report = `Product: ${data.name}<br>Weight remaining: ${data.weight}<br>Marked empty: ${data.empty ? 'Yes' : 'No'}`
        } else {
          _that.report = ''
        }
        _that.loopTimer = setTimeout(() => {
          _that.scanNext();
        }, _that.loopTimeout);
      }, (reason) => {
        _that.report = 'Could not access barcode data';
      });
    }).catch(err => {
      console.log('Error', err);

      // MIGRATION NOTE: 'windows' is no longer a recognized platform, previous
      // logic specified !this.plt.is('windows') as well
      if (this.plt.is('desktop')) {
        this.report = 'Barcode scanning is not available on this platform'
      } else {
        this.report = 'Please enable camera access to scan barcodes'
      }
    });
  }

  cancelScan() {
    // this.insomnia.allowSleepAgain();
    if (this.loopTimer) {
      clearTimeout(this.loopTimer);
      this.loopTimer = null;
    }
    if (this.isFullScan) {
      this.clicsService.apiCancelValidateLocation();
    }
    this.modalCtrl.dismiss();
    this.events.publish('navrequest', {top: 'admin', page: 'admin_inventory'});
  }

  promptFinishScan() {
    this.alertCtrl.create({
      header: 'CONFIRM ALL SCANNED',
      message: 'Have you scanned ALL individual canisters that are not in dispensers?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Yes',
          handler: () => {
            this.doFinishScan();
          }
        }
      ],
      mode: 'ios'
    }).then(alert => alert.present());
  }

  //If response has success, scan is complete and database has been updated. 
  //If resopnse does not have success, check if their is a new_missing value and
  // if so display/warn how many will be set to missing. Yes -> set to missing and finish. No -> back to continue scanning.
  doFinishScan() {
    const vtok = localStorage.getItem('validation');
    this.clicsService.apiRecordMissingCanisters(vtok, true).then((data) => {
      if (data.success) {
        this.isFinished = true;
        localStorage.removeItem('validation');
        this.report = `<br><h2>Result</h2>Canisters on hand: ${data.all_active}<br>Installed canisters: ${data.in_machines}<br>Missing canisters: ${data.all_missing}`;
      } else {
        if (!!data.new_missing){
          this.alertCtrl.create({
            header: 'WARNING!',
            message: '<warn>This will log <number>' + data.new_missing + '</number> missing canisters since the last scan.</warn> <br>Are you sure you have scanned ALL individual canisters that are not in dispensers?',
            cssClass: "ion-alert warning",
            buttons: [
              {
                text: 'Yes, confirm missing',
                handler: () => {
                  this.clicsService.apiRecordMissingCanisters(vtok, false).then((data) => {
                    if (data.success) {
                      this.isFinished = true;
                      localStorage.removeItem('validation');
                      this.report = `<br><h2>Result</h2>Canisters on hand: ${data.all_active}<br>Installed canisters: ${data.in_machines}<br>Missing canisters: ${data.all_missing}`;
                    } else {
                      this.toastCtrl.create({
                        message: 'Sorry - something went wrong. Please try again.',
                        duration: 3000,
                        position: 'bottom'
                      }).then(toast => toast.present());
                    }
                  })
                }
              },
              {
                text: 'No, continue scanning',
                role: 'cancel',
              },  {
                text: 'Abandon scan',
                handler: () => {
                  this.cancelScan();
                }
              }
            ],
            mode: 'ios'
          }).then(alert => alert.present());
        } else {
           this.toastCtrl.create({
            message: 'Sorry - something went wrong. Please try again.',
            duration: 3000,
            position: 'bottom'
          }).then(toast => toast.present());
        }
      }
    });
  }

  finishPartialScan() {
    const vtok = localStorage.getItem('validation');
    this.clicsService.apiRecordPartialScan(vtok).then((data) => {
      if (data.success) {
        this.isFinished = true;
        localStorage.removeItem('validation');
        this.report = `<br><h2>Result</h2>Canisters on hand: ${data.all_active}<br>Installed canisters: ${data.in_machines}<br>Missing canisters: ${data.all_missing}`;
      } else {
        this.isFinished = true;
      }
    })
  }

  showInventoryReport() {
    // this.insomnia.allowSleepAgain();
    this.clicsService.apiGetInventoryReport(this.clicsService.current_salon).then((data) => {
      if (!!data.success) {
        this.isFinished = true;
        localStorage.removeItem('validation');
        this.report = `<br><h2>Result</h2>Canisters on hand: ${data.all_active}<br>Installed canisters: ${data.in_machines}<br>Missing canisters: ${data.all_missing}`;
      } else {
        this.isFinished = true;
      }
    });
  }

  // Close this modal and open the damaged scan modal
  scanDamaged() {
    let _that = this;
    this.modalCtrl.dismiss();
    this.clicsService.apiRequestValidateLocation().then(data => {
      if (data.success) {
        _that.modalCtrl.create({
          component: InventoryFullConfirmPage,
          componentProps: {fakeIt: true, fullInventory: false, trackingScan: false, damagedScan: true},
          cssClass: this.clicsService.theme,
        }).then(modal => modal.present());
      }
    });
  }

  closeWindow() {
    // this.insomnia.allowSleepAgain();
    this.modalCtrl.dismiss();
    this.events.publish('navrequest', {top: 'admin', page: 'admin_inventory'})
  }
}
