import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {CLiCSProduct} from "../../../lib/product";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Generated class for the DispenseProductComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'dispense-product',
  templateUrl: 'dispense-product.html',
  styleUrls: ['dispense-product.scss'],
})
export class DispenseProductComponent implements OnInit {
  @Input() product: CLiCSProduct = null;
  @Input() unit: string = "g";
  @Input() start: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() action = 'disp';
  @Input() showOz: boolean = true;

  @Output() requestDispense = new EventEmitter();

  valStr = "";  // used for display
  value: number = 0;
  ozVal: number = 0.0;
  ozValStr: string = "";
  productName: string = "";
  repeatTimer: any = null;
  waitInterval: number = 500;
  repeatInterval: number = 85;

  permissions: any = null;
  settings: any = null;

    constructor(
        private modeCtrl: ModeControllerProvider,
        ) {
        this.value = this.start;

        // Load permissions from modeCtrl
        this.permissions = this.modeCtrl.getPermissions();
    
        this.modeCtrl.init().then(result => {
            this.permissions = this.modeCtrl.getPermissions();
        });
    
        if(!!this.permissions.show_milliliters){
            this.unit = "mL"
        }
      }

  ngOnInit() {
    this.value = this.start;
    if (this.product) {
      this.productName = this.product.description;
    }
    this._update();
  }

  // Update display strings
  _update() {
    this.valStr = `${this.value}${this.unit}`;
    if (this.showOz) {
      this.ozVal = this.value * 0.035274;
      this.ozValStr = `(${this.ozVal.toFixed(2)}oz)`;
    }
  }

  startIncrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doIncrement();
      }, this.waitInterval);
    } else {
      this.endIncrement();
    }
  }

  doIncrement() {
    if (this.repeatTimer) {
      this.incrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doIncrement();
    }, this.repeatInterval);
  }

  endIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  startDecrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doDecrement();
      }, this.waitInterval);
    } else {
      this.endDecrement();
    }
  }

  doDecrement() {
    if (this.repeatTimer) {
      this.decrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doDecrement();
    }, this.repeatInterval);
  }

  endDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  incrVal() {
    this.value += 1;
    if (this.value > this.max)
      this.value = this.max;
    this._update();
  }

  decrVal() {
    this.value -= 1;
    if (this.value < this.min)
      this.value = this.min;
    this._update();
  }

  updateVal(data) {
    this.value = data.amount;
    this._update();
  }

  // Convert the requested product to a formula request and emit the request
  doRequestDispense() {
    if (this.product && this.value > 0) {
      let fr = new CLiCSFormulaRequest(
        {
          title: this.productName,
          amount: this.value,
          mode: 'basic',
          formula_text: `${this.valStr} ${this.product.name}`,
        });
      fr.addParam('compName', this.product.name, this.value.toFixed(2));
      fr.addParam('compId', this.product.token, this.value.toFixed(2));
      this.requestDispense.emit(fr);
    }
  }

}
