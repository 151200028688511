import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {StepsDisplayPage} from './steps-display';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [StepsDisplayPage],
  providers: [
    NavParams,
  ],
})
export class StepsDisplayPageModule {}
