import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {HairProfilePage} from './hair-profile';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [HairProfilePage],
})
export class HairProfilePageModule {}
