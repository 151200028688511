/*
    Defines color / application library search results. Search results are contained in three lists: Colors, Apps, and
    Conversions. Colors and Conversions are collections of CLiCSColorFormula objects while Apps is a collection of
    CLiCSApplication objects.
 */
import {CLiCSColorFormula} from "./color-formula";
import {CLiCSColorApplication} from "./color-application";
import {CLiCSFormula} from "./formula";

export class CLiCSLibrary {
  id: number = null;
  token: string = null;
  title: string = null;
  owned: boolean = false;  // Owned by this user?
  default: boolean = false; // Default library for this user / salon?
  color_filter: string = null;
  application_filter: string = null;
  conversion_filter: string = null;
  scope: string = null;  // indicates the scope of this library (user, salon, system, conversion, shared, purchased)
  active: boolean = true; // Used internally for repo castAs() - filter by target page

  // Flags that indicate whether the library filter spec has been modified and should be reloaded
  color_modified: boolean = true;
  application_modified: boolean = true;
  conversion_modified: boolean = true;

  formulas: Array<CLiCSColorFormula> = [];
  applications: Array<CLiCSColorApplication> = [];
  conversions: Array<CLiCSColorFormula> = [];  // TODO: remove this

  libraryTitles: string[] = [];
  filteredFormulas: Array<CLiCSColorFormula> = [];  // resulting from applying (or clearing) filter or search criteria
  selectedFormulas: Array<CLiCSColorFormula> = [];  // formulas who are have their selected bit set

  searchSpec: string = null;
  filterSpec: any = null;  // used to filter library

  filteredApps: CLiCSColorApplication[] = [];
  appSearchSpec: string = null;

  // manufacturer whose products are used in these library formulas
  manufacturer: string = null;
  manufacturer_id: number = null;

  // If conversion and the brand that this library is converting from
  conversion: boolean = false; // true if this is a conversion library
  conversion_manufacturer: string = null;
  conversion_manufacturer_id: number = null;

  // Presence indicates a CLICS Collection, value indicates ordering
  cc_ord: number = null;
  product_line: string = null;  // e.g. 'Topchic', 'Colorance', 'CLICS'

  constructor(data: any = null) {
    if (data) {
      if (typeof data == 'string')
        this.loadStr(data);
      else {
        if (typeof data == 'object')
          this.loadObj(data);
      }
    }
  }

  // Loads this from a data object
  loadObj(data: any) {
    this.formulas.length = 0;
    this.applications.length = 0;
    this.conversions.length = 0;
    if ('id' in data)
      this.id = data.id;
    if ('token' in data)
      this.token = data.token;
    if ('title' in data)
      this.title = data.title;
    if ('owned' in data)
      this.owned = data.owned == true;
    if ('scope' in data)
      this.scope = data.scope;
    if ('default' in data)
      this.default = data.default == true;
    if ('color_filter' in data)
      this.color_filter = data.color_filter;
    if ('application_filter' in data)
      this.application_filter = data.application_filter;
    if ('conversion_filter' in data)
      this.conversion_filter = data.conversion_filter;
    if ('formulas' in data) {
      for (let formula of data.formulas)
        this.formulas.push(new CLiCSColorFormula(formula, this.owned));
    }
    if ('applications' in data) {
      for (let application of data.applications)
        this.applications.push(new CLiCSColorApplication(application));
    }
    if ('conversions' in data) {
      for (let conversion of data.conversions)
        this.conversions.push(new CLiCSColorFormula(conversion));
    }
    if ('libraryTitles' in data) {
      for (let title of data.libraryTitles)
        this.libraryTitles.push(title);
      this.libraryTitles.sort();
    }
    if ('conversion' in data) {
      this.conversion = data.conversion == true;
    }
    if ('manufacturer' in data) {
      this.manufacturer = data.manufacturer;
    }
    if ('manufacturer_id' in data) {
      this.manufacturer_id = data.manufacturer_id;
    }
    if ('conversion_manufacturer' in data) {
      this.conversion_manufacturer = data.conversion_manufacturer;
    }
    if ('conversion_manufacturer_id' in data) {
      this.conversion_manufacturer_id = data.conversion_manufacturer_id;
    }
    if ('cc_ord' in data) {
      this.cc_ord = data.cc_ord;
    }
    if ('product_line' in data) {
      this.product_line = data.product_line;
    }
    this.applyFormulaFilter();
    this.applyApplicationSearch();
  }

  // Loads this object from a data string (JSON)
  loadStr(dataStr: string) {
    let data = JSON.parse(dataStr);
    this.loadObj(data);
  }

  // Clears all saved library items, erases filter values, and marks all lists as modified (to require reload)
  clear(): void {
    this.formulas.length = 0;
    this.applications.length = 0;
    this.filteredFormulas.length = 0;
    this.filteredApps.length = 0;
    this.conversions.length = 0;
    this.libraryTitles.length = 0;
    this.color_filter = null;
    this.application_filter = null;
    this.conversion_filter = null;
    this.color_modified = true;
    this.application_modified = true;
    this.conversion_modified = true;
    this.scope = null;
    this.owned = false;
    this.product_line = null;
  }

  empty(): boolean {
    return (this.formulas.length + this.applications.length + this.conversions.length == 0);
  }

  // Sets the scope string
  setScope(newScope: string) {
    // TODO: limit values to known scopes
    this.scope = newScope;
    if (this.scope == null)
      console.log(`Warning: NULL SCOPE set for library`);
  }

  // Sorts formulas by title
  sortFormulas() {
    this.formulas = this.formulas.sort((a, b) => {
      // Sort numerical names by their value
      let aMatch = a.title.match(/^(\d+\.?\d*)/);
      let bMatch = b.title.match(/^(\d+\.?\d*)/);
      if (aMatch && bMatch) {
        const aNum = parseFloat(aMatch[1]);
        const bNum = parseFloat(bMatch[1]);
        if (aNum < bNum)
          return -1;
        if (aNum > bNum)
          return 1;
      }

      if (a.title < b.title)
        return -1;
      if (a.title > b.title)
        return 1;
      return 0;
    });
  }

  sortApplications() {
    this.applications.sort((a, b) => {
      if (a.title < b.title)
        return -1;
      if (a.title > b.title)
        return 1;
      return 0;
    });
  }

  // Returns a copy of the formulas
  getLibraryFormulas(libraryTitle: string): CLiCSColorFormula[] {
    let result = [];

    for (const formula of this.formulas) {
      if (formula.library == libraryTitle) {
        result.push(new CLiCSColorFormula(formula));
      }
    }

    return result;
  }

  // Adds formulas to this library TAKES A DATA OBJECT
  addFormulas(library: any, clearFirst: boolean = false) {
    if (clearFirst)
      this.formulas.length = 0;
    this.addLibraryTitle(library.title);
    for (let formula of library.formulas) {
      this.addFormula(formula, library.title, false, this.owned);
    }
    this.sortFormulas();
    this.applyFormulaFilter();
    this.applyApplicationSearch();
    this.color_modified = false;
  }

  addFormula(formula: CLiCSFormula, libraryName: string = null, sortAfter: boolean = false, owned: boolean = false, selected: boolean = false) {
    // Is this CF already in the library?
    let index = this.formulas.findIndex((el) => {
      return (el.token == formula.token);
    });
    if (index < 0) {
      let newFormula = new CLiCSColorFormula(formula, owned);
      newFormula.library = libraryName;
      newFormula.mode = 'library';
      newFormula.selected = selected;
      this.formulas.push(newFormula);
      if (sortAfter) {
        this.sortFormulas();
        this.applyFormulaFilter();
        this.applyApplicationSearch();
      }
      this.color_modified = false;
    }
  }

  removeFormula(formula: CLiCSFormula, libraryName: string = null) {
    // Is this CF already in the library?
    let index = this.formulas.findIndex((el) => {
      return (el.token == formula.token);
    });
    if (index >= 0) {
      this.formulas.slice(index, 1);
      this.color_modified = false;
    }
    this.applyFormulaFilter();
    this.applyApplicationSearch();
  }

  // Adds a library title to a list of libraries if it doesn't yet exist, then sorts by title
  addLibraryTitle(libraryTitle: string) {
    let index = this.libraryTitles.indexOf(libraryTitle);
    if (index < 0) {
      this.libraryTitles.push(libraryTitle);
      this.libraryTitles.sort();
    }
  }

  // Adds applications to this library TAKES A DATA OBJECT
  addApplications(library: any, clearFirst: boolean = false) {
    if (clearFirst)
      this.applications.length = 0;
    this.addLibraryTitle(library.title);
    for (let app of library.apps) {
      if (app.token) {
        // Don't add app if already loaded into library
        let index = this.applications.findIndex((el) => {
          return (el.token === app.token);
        });
        if (index >= 0)
          continue;
      }
      this.applications.push(new CLiCSColorApplication(app, this.owned));
    }
    this.sortApplications();
    this.applyFormulaFilter();
    this.applyApplicationSearch();
    this.application_modified = false;
  }

  addApplication(app: any, sortAfter: boolean = false) {
    let index = -1;
    if (app.token) {
      // Don't add app if already loaded into library
      index = this.applications.findIndex((el) => {
        return (el.token === app.token);
      });
    }
    if (index < 0)
      this.applications.push(new CLiCSColorApplication(app, this.owned));
    else {
      this.applications[index].clear();
      this.applications[index].loadObj(app);
    }
    if (sortAfter) {
      this.sortApplications();
      this.applyFormulaFilter();
      this.applyApplicationSearch();
    }
    this.application_modified = false;
  }

  // Returns a formula either from color or
  findFormula(token: string): CLiCSColorFormula {
    let result = null;
    for (let formula of this.formulas) {
      if (formula.token == token) {
        result = formula;
        break;
      }
    }
    if (result == null) {
      for (let formula of this.conversions) {
        if (formula.token == token) {
          result = formula;
          break;
        }
      }
    }
    return result;
  }

  // Returns a formula either from color or
  findApplication(token: string): CLiCSColorApplication {
    let index = this.applications.findIndex((app) => {
      return (app.token == token);
    });
    if (index >= 0)
      return this.applications[index];
    else
      return null;
  }

  // Returns a subset of this.formulas based on a list of library name (e.g. 'My Colors' or 'CLICS Featured')
  findColorLibrary(libraryNames: string[]) {
    let result: CLiCSColorFormula[];
    result = this.formulas.filter((el) => {
      return (libraryNames.indexOf(el.library) >= 0);
    });
    return result;
  }

  // Uses a filter spec object like
  findByFilter(filterSpec: any) {
    let libraryName: string[] = [];
  }

  // Finds a formula in this library then either toggles or hard-sets the selection. If value is NULL then this
  // toggles the selection. Returns formula or null.
  selectFormula(token: string, newValue: any = null): any {
    let formula = this.findFormula(token);
    if (formula) {
      if (newValue == null)
        formula.selected = !formula.selected;
      else
        formula.selected = (newValue == true);
    }
    this.selectedFormulas.length = 0;
    this.selectedFormulas = this.formulas.filter((el) => {
      return (el.selected == true);
    });
    return formula;
  }

  // Finds a formula in this library then either toggles or hard-sets the selection. If value is NULL then this
  // toggles the selection. Returns formula or null.
  clearSelection() {
    this.selectedFormulas.length = 0;
    for (let formula of this.formulas)
      formula.selected = false;
  }

  anySelected() {
    const index = this.formulas.findIndex((el) => { return el.selected });
    return index >= 0;
  }

  // Updates the filteredFormulas array with matching results from a search string
  applyFormulaSearch(searchString: string) {
    this.searchSpec = searchString;
    if (this.searchSpec == null || this.searchSpec == "")
      this.applyFormulaFilter();
    else {
      // Search the current filtered formulas for matches
      this.filteredFormulas = this.applyFormulaFilter().filter((el) => {
        return el.title.toLowerCase().indexOf(this.searchSpec.toLowerCase()) >= 0;
      });
    }
  }

  // Remove search criteria
  cancelFormulaSearch() {
    this.applyFormulaSearch(null);
  }

  // Removes a formula from the filtered array
  _removeFromFiltered(formula: CLiCSColorFormula) {
    const index = this.filteredFormulas.findIndex(el => el.token == formula.token);
    if (index >= 0) {
      this.filteredFormulas.splice(index, 1);
    }
  }

  // Apply or clear a filter for the formulas in this repository
  applyFormulaFilter(filterSpec: any = null): CLiCSColorFormula[] {
    if (!!filterSpec) {
      this.searchSpec = null;
      this.filterSpec = filterSpec;
    }

    // Start with only the select library (or "all colors")
    if (!this.filterSpec || this.filterSpec.cleared == true) {
      this.filteredFormulas = this.formulas.filter(el => true);
    } else {
      let scopes: Array<string> = [];
      if (this.filterSpec.library.libCLiCS)
        scopes.push('system');
      if (this.filterSpec.library.libSalon)
        scopes.push('salon');
      if (this.filterSpec.library.libDesigned)
        scopes.push('user');
      if (this.filterSpec.library.libSaved)
        scopes.push('published');

      if (scopes.length > 0) {
        this.filteredFormulas = this.formulas.filter((el) => {
          return (scopes.includes(el.scope) && (el.level >= this.filterSpec.level.lower && el.level <= this.filterSpec.level.upper));
        });
      } else {
        this.filteredFormulas = this.formulas.filter(el => (el.level >= this.filterSpec.level.lower && el.level <= this.filterSpec.level.upper));
      }
    }

    return this.filteredFormulas;
  }

  // Clears filterSpec to the passed filterSpec object
  clearFormulaFilter(filterSpec: any) {
    this.searchSpec = null;
    this.filterSpec = filterSpec;
    this.filterSpec.tags = "";
    this.filterSpec.cleared = true;
    this.applyFormulaFilter();
  }

  // Searches for APPs with titles matching the string
  applyApplicationSearch(searchString: string = null) {
    this.appSearchSpec = searchString;
    if (this.appSearchSpec == null || this.appSearchSpec == "") {
      this.filteredApps.length = 0;
      for (let app of this.applications)
        this.filteredApps.push(new CLiCSColorApplication(app));
    } else
      this.filteredApps = this.applications.filter(el => el.title.toLowerCase().indexOf(this.searchSpec.toLowerCase()) >= 0);
  }

}
