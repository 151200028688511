import {Component} from '@angular/core';
import {ModalController, NavController, NavParams, ToastController} from '@ionic/angular';
import {BarcodeScanner, BarcodeScannerOptions} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryDamagedLoopPage} from '../inventory-damaged-loop/inventory-damaged-loop';
import {InventoryIncomingTrackingPage} from '../inventory-incoming-tracking/inventory-incoming-tracking';
import {InventoryLoopPage} from '../inventory-loop/inventory-loop';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {PageControllerProvider} from '../../services/page-controller/page-controller';

/**
 * Generated class for the InventoryFullConfirmPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-inventory-full-confirm',
  templateUrl: 'inventory-full-confirm.html',
  styleUrls: ['inventory-full-confirm.scss'],
})
export class InventoryFullConfirmPage {
  fakeIt: boolean = false;  // CRITICAL: remove this once machine barcode scan is available
  fullInventory: boolean = true;
  trackingScan: boolean = false;
  damagedScan: boolean = false;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private barcodeCtrl: BarcodeScanner,
    private events: EventsService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    // private insomnia: Insomnia,  // NOTE: prevents reaching this page
    public clicsService: CLiCSService) {
    if (!!this.navParams.get('fakeIt')) {
      this.fakeIt = true;
    }
    if (!!this.navParams.get('trackingScan')) {
      this.trackingScan = true;
    }
    if (!!this.navParams.get('damagedScan')) {
      this.damagedScan = true;
    }
    const isFull = this.navParams.get('fullInventory')
    if (isFull != undefined ) {
      this.fullInventory = isFull;
    }
  }

  scanValidationBarcode() {
    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Center barcode inside the scan area',
      resultDisplayDuration: 500,
      formats: 'EIN_8',
      orientation: 'landscape',
    };

    this.barcodeCtrl.scan(options).then(barcodeData => {
      // TODO: send code from barcode to confirm validation, store returned token or show error toast
    });
  }

  fakeConfirmation() {
    // TODO: remove this and do actual validation from machine screen barcode
    this.clicsService.apiFakeLocationValidation().then(data => {
        if (!!data.success) {
          localStorage.setItem('validation', data.token);

          if (!!this.trackingScan) {
            // this.insomnia.keepAwake();
            this.modalCtrl.create({
              component: InventoryIncomingTrackingPage,
              cssClass: this.clicsService.theme,
            }).then(modal => modal.present());

            this.modalCtrl.dismiss();
          } else {
            if (!!this.damagedScan) {
              // this.insomnia.keepAwake();
              this.modalCtrl.create({
                component: InventoryDamagedLoopPage,
                cssClass: this.clicsService.theme,
              }).then(modal => modal.present());

              this.modalCtrl.dismiss();
            } else {
              // this.insomnia.keepAwake();
              this.modalCtrl.create({
                component: InventoryLoopPage,
                componentProps: {full_inventory: this.fullInventory},
                cssClass: this.clicsService.theme,
              }).then(modal => modal.present());

              this.modalCtrl.dismiss();
            }
          }
        } else {
          this.toastCtrl.create({
            message: 'Sorry - something went wrong, please try again',
            duration: 3000,
            cssClass: 'error',
            position: 'bottom'
          }).then(toast => toast.present());
        }
      }
    );
  }

  chooseSalon() {
    this.modalCtrl.dismiss();
    this.events.publish('navreset');
  }

  cancelConfirmation() {
    this.modalCtrl.dismiss();
    this.events.publish('navrequest', {top: 'admin', page: 'admin_inventory'})
  }

}
