import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';

/**
 * Presents a color formula
 */

@Component({
  selector: 'app-formula',
  templateUrl: 'app-formula.html',
  styleUrls: ['app-formula.scss'],
})

export class AppFormulaComponent implements OnInit {
  @Input() formula: CLiCSFormulaRequest;
  @Input() hideAmount: boolean = false;
  @Input() showPedigree: boolean = true;
  @Input() showProductLine: boolean = false;
  @Input() useDispensedAmount: boolean = false;
  @Input() showDevRatio: boolean = false;
  @Input() unit: string = "g";
  @Output() openFormulaChoices = new EventEmitter();
  @Output() openColorDetail = new EventEmitter();

  permissions: any = null;
  settings: any = null;

  constructor(
    private modeCtrl: ModeControllerProvider,
  ) {
    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
      this.permissions = this.modeCtrl.getPermissions();
    });

    if (!!this.permissions.show_milliliters) {
      this.unit = "mL";
    }
  }

  ngOnInit() {
    if (this.formula == undefined) {
      this.formula = new CLiCSFormulaRequest();
    }
  }

  // Instruct parent to open choice
  doFormulaChoices(token: any): void {
    this.openFormulaChoices.emit({formula_token: token});
  }

  // Instruct parent to open choice
  doColorDetail(token: any): void {
    this.openColorDetail.emit({formula_token: token, amount: this.displayAmount()});
  }

  amountIsValid(): boolean {
    if (this.useDispensedAmount)
      return !this.hideAmount && !!this.formula.dispensed && this.formula.dispensed > 0.0
    else
      return !this.hideAmount && !!this.formula.amount && this.formula.amount > 0.0
  }

  displayAmount(): number {
    let formulaAmount = this.formula.amount;
    if (this.useDispensedAmount) {
      formulaAmount = this.formula.dispensed;
    }
    return formulaAmount;
  }

  amountStr(): string {
    let formulaAmount = this.formula.amount;
    if (this.useDispensedAmount) {
      formulaAmount = this.formula.dispensed;
    }
    if (!!formulaAmount) {
      if (formulaAmount % 1 == 0) {
        return `${formulaAmount.toFixed(0)}${this.unit}`
      } else {
        return `${formulaAmount.toFixed(1)}${this.unit}`
      }
    }
  }
}
