import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CLiCSColorSession} from "../../../lib/session";
import {CLiCSFormulaRequest} from "../../../lib/request";
import {CLiCSFormula} from "../../../lib/formula";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Displays an App (ColorSession) with a list of Formula Requests. The app information may be displayed or hidden
 * (use case: Pending Swatches is an app but we only show the formulas). Additionally, a list of formulas may be
 * passed in lieu of the app and these will be displayed.
 */
@Component({
    selector: 'queue-app',
    templateUrl: 'queue-app.html',
    styleUrls: ['queue-app.scss'],
})
export class QueueAppComponent implements OnInit {
    @Input() app: CLiCSColorSession;
    @Input() formulas: CLiCSFormulaRequest[];
    @Input() showApp: boolean = true;
    @Input() showCancel: boolean = true;
    @Input() unit: string = "g";
    @Output() cancelApplication = new EventEmitter();
    @Output() cancelFormulaRequest = new EventEmitter();
    @Output() cancelWeighing = new EventEmitter();
    @Output() appSelected = new EventEmitter();
    @Output() viewFormula = new EventEmitter();

    permissions: any = null;
    settings: any = null;

    constructor(
        private modeCtrl: ModeControllerProvider,
        ) {
        // Load permissions from modeCtrl
        this.permissions = this.modeCtrl.getPermissions();
    
        this.modeCtrl.init().then(result => {
            this.permissions = this.modeCtrl.getPermissions();
        });
    
        if(!!this.permissions.show_milliliters){
            this.unit = "mL"
        }
      }

    ngOnInit() {
        if (this.app) {
            this.formulas = this.app.formulas;
        }
    }

    cancelApp() {
        this.cancelApplication.emit({app: this.app});
    }

    cancelFormula(token: string) {
        this.cancelFormulaRequest.emit({app: this.app, formula_token: token});
        this.cancelWeighing.emit({app: this.app, formula_token: token});
    }

    selectApp() {
      this.appSelected.emit({app: this.app});
    }

    showFormulaDetails(formula: CLiCSFormula) {
        this.viewFormula.emit({formula_token: formula.token});
    }
}
