import {Component, OnInit, ViewChild} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClient} from '../../../lib/client';

/**
 * Generated class for the ClientSearchPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-client-search',
  templateUrl: 'client-search.html',
  styleUrls: ['client-search.scss'],
})
export class ClientSearchPage implements OnInit {
  @ViewChild('searchBar') searchBarElement;
  clients: CLiCSClient[] = [];
  matches: CLiCSClient[] = [];
  modalDismissSubscription: Subscription = null;

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private modalCtrl: ModalController,
              private clicsService: CLiCSService,
              private events: EventsService) {
  }

  ionViewWillEnter() {
    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.searchBarElement.setFocus();
    }, 500);
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  ngOnInit() {
    this.clients = this.navParams.data.clients;
    this.matches = this.clients;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  searchClients(event) {
    let val = event.target.value;
    if (val && val.trim() != '') {
      this.matches = this.clients.filter((item) => {
        return (item.name().toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    } else {
      this.matches = this.clients;
    }
  }

  selectClient(client_token) {
    this.modalCtrl.dismiss().then(() => {
      this.clicsService.selectClient(client_token);
      this.events.publish('navrequest', {top: 'clients', page: 'profile'})
    });
  }
}
