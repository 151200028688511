import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActionSheetController, AlertController, ModalController, ToastController, LoadingController, NavController, NavParams, Platform} from '@ionic/angular';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import * as Rollbar from 'rollbar';
import {RollbarService} from '../../../lib/rollbar';
import {Subscription} from 'rxjs';
import {ClientNoteAddPage} from '../client-note-add/client-note-add';
import {ColorDetailPage} from '../color-detail/color-detail';
import {PhotoZoomPage} from '../photo-zoom/photo-zoom';
import {StepsDisplayPage} from '../steps-display/steps-display';
import {PhotoGridComponent} from '../../components/photo-grid/photo-grid';
import {PhotoStoreProvider} from '../../services/photo-store/photo-store';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSColorSession} from '../../../lib/session';
import {CLiCSUser} from '../../../lib/user';
import {CLiCSClient} from '../../../lib/client';
import {CLiCSClientNote} from '../../../lib/client-note';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {SettingsProvider} from "../../services/settings/settings";
import {PageControllerProvider} from "../../services/page-controller/page-controller";
import {AdminHistoryPage} from "../admin-history/admin-history.page";
import {ClientNotesPage} from "../client-notes/client-notes";

/**
 * Generated class for the ApplicationInfoPage page.
 *
 */
@Component({
  selector: 'page-application-info',
  templateUrl: 'application-info.html',
  styleUrls: ['application-info.scss'],
})
export class ApplicationInfoPage implements OnInit {
  public static readonly DEFAULT_HEADSHOT: string = "assets/images/headshot-2.png";
  @ViewChild('photoGrid') photoGrid: PhotoGridComponent;

  context: string = 'any';
  source: string = null;
  cs: any = null;
  formulas: CLiCSFormulaRequest[] = [];
  editItem: EditQueueItem = null;
  isColorSession: boolean = false;
  theme: string = 'light';

  appType: string = null;
  startingLevel: string = null;
  sessionPhotos: any = {before: null, after: null, all: []};
  beforePhotoUrl: string = ApplicationInfoPage.DEFAULT_HEADSHOT;
  afterPhotoUrl: string = ApplicationInfoPage.DEFAULT_HEADSHOT;
  beforePhotoToken: string = 'before';
  afterPhotoToken: string = 'after';

  library_token: string = '';
  permissions: any = null;

  allowCamera: boolean = false;
  loading: any = null;  // loading spinner
  clientName: string = "";
  clientImgSrc: string;
  client: CLiCSClient = null;
  clientNotes: CLiCSClientNote[] = [];

  photoSource: string = 'camera';
  photoWhenTaken: string = 'unspecified';
  refresher: any = null;

  clientPhotoUploadedSubscription: Subscription = null;
  clientPhotoRemovedSubscription: Subscription = null;
  clientPhotoFailedSubscription: Subscription = null;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private clicsService: CLiCSService,
    private actionSheetCtrl: ActionSheetController,
    private events: EventsService,
    private editQueue: EditQueueProvider,
    private modalCtrl: ModalController,
    private photoStore: PhotoStoreProvider,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private modeCtrl: ModeControllerProvider,
    private mixingBowl: MixingBowlProvider,
    private platform: Platform,
    private camera: Camera,
    private loadingCtrl: LoadingController,
    public settingsCtrl: SettingsProvider,
    private pageCtrl: PageControllerProvider,
    @Inject(RollbarService) public rollbar: Rollbar
  ) {
    this.permissions = this.modeCtrl.getPermissions();
    this.modeCtrl.init().then(result => {
      this.permissions = this.modeCtrl.getPermissions();
    });
    this.clientImgSrc = '../../' + this.photoStore.getLoadingImgSrc();
    this.client = this.clicsService.activeClient()

    this.clientPhotoUploadedSubscription = this.events.subscribe('client_photo:uploaded', (data) => {
      this.reloadPhotos();
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    });

    this.clientPhotoRemovedSubscription = this.events.subscribe('client_photo:removed', (data) => {
      this.reloadPhotos();
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    });

    this.clientPhotoFailedSubscription = this.events.subscribe('client_photo:failed', (data) => {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
      // TODO: if we have a terminal count for attempts on any photo then show this...
      // TODO: if failed finally should link go to page to manage failed photos?
    });
  }

  ngOnInit() {
    this.allowCamera = (this.platform.is('cordova') || this.platform.is('ios') || this.platform.is('android'));
  }

  ionViewWillEnter() {
    this.context = this.navParams.get('context');
    this.source = this.navParams.get('source');  // source is where the CS/CA came from. Not always set. Passed forward to the stash.
    this.theme = this.clicsService.getTheme();

    // Try to get stashed object from clicsService
    this.cs = null;
    if (this.clicsService.hasStash()) {
      let stash = this.clicsService.getStash();
      if (!!stash.ca && stash.action == 'SHOW') {
        this.cs = stash.ca;
        this.clicsService.clearStash();
      }
    }

    // Look in editQueue or navParams
    if (!this.cs) {
      this.editItem = this.editQueue.getShowAppItem();
      if (this.editItem) {
        this.editQueue.clearAll();  // prevent schmutz
        this.cs = this.editItem.itemObject;
      } else
        this.cs = this.navParams.get('colorSession');
    }

    if (!!this.cs) {
      this.formulas = this.cs.formulas;
      this.isColorSession = this.cs.isColorSession();
      this.setBeforeAfterPhotos();
      this.appType = this.appTypeText();
      this.startingLevel = this.startingLevelText();
      this.loadNotes();
    } else
      this.formulas = [];
  }

  ionViewDidEnter() {
    this.photoGrid.setCSS(this.cs.token);
    this.photoGrid.setEmptyMssg('No additional photos found for this application')
  }

  ionViewWillLeave() {
    this.clientPhotoUploadedSubscription = this.events.unsubscribe(this.clientPhotoUploadedSubscription);
    this.clientPhotoRemovedSubscription = this.events.unsubscribe(this.clientPhotoRemovedSubscription);
    this.clientPhotoFailedSubscription = this.events.unsubscribe(this.clientPhotoFailedSubscription);
  }

  // Sets the before and after photos on this page
  setBeforeAfterPhotos() {
    const client = this.clicsService.activeClient();
    let beforePhotoUrl = ApplicationInfoPage.DEFAULT_HEADSHOT;
    let afterPhotoUrl = ApplicationInfoPage.DEFAULT_HEADSHOT;
    let beforePhotoToken: string = 'before';
    let afterPhotoToken: string = 'after';

    if (client && this.photoStore.clientHasPhotos(client)) {
      this.sessionPhotos = this.photoStore.sessionPhotos(this.cs.token);
      if (!!this.sessionPhotos.before) {
        beforePhotoUrl = this.sessionPhotos.before.thumb_url;
        beforePhotoToken = this.sessionPhotos.before.token;
      }
      if (!!this.sessionPhotos.after) {
        afterPhotoUrl = this.sessionPhotos.after.thumb_url;
        afterPhotoToken = this.sessionPhotos.after.token;
      }
    } else {
      this.sessionPhotos = {before: null, after: null, all: []};
    }

    this.beforePhotoUrl = beforePhotoUrl;
    this.afterPhotoUrl = afterPhotoUrl;
    this.beforePhotoToken = beforePhotoToken;
    this.afterPhotoToken = afterPhotoToken;
  }

  // Reloads all photos on the page
  reloadPhotos() {
    this.setBeforeAfterPhotos();
    this.photoGrid.setCSS(this.cs.token);  // reloads photo grid
    if (this.refresher) {
      this.refresher.complete();
    }
  }

  async openColorDetailModal(target_formula, data = null) {
    let formula = null;
    if (!!target_formula && !!target_formula.token) {
      formula = this.cs.findFormula(target_formula.token);
      if (!!formula) {
        if (!!data) {
          formula.setAmount(data.amount);
          formula.assertFormulaText(true);
        }
        this.clicsService.stashData({cf: formula, action: 'SHOW'}, this.source);
      } else {
        this.rollbar.error("application-info openColorDetailModel could not find the target formula", {
          target_formula: target_formula,
          color_session: this.cs
        });
        this.toastCtrl.create({
          message: 'Sorry - not able to open that formula',
          duration: 3000,
          position: 'bottom'
        }).then(toast => toast.present());
        return;
      }
    }

    // Open formula details modal
    const colorDetailModal = await this.modalCtrl.create({
      component: ColorDetailPage,
      componentProps: {noCustomize: true, source: this.source, view: 'modal'},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    colorDetailModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      // Define actions if the formula is modified in details | customize
      if (data.modified) {
        // When use is called "in the blind" then prompt for a client to use it for
        var buttons = [];

        if (this.clicsService.clientIsSelected()) {
          buttons.push({
            text: `Use this color for ${this.clicsService.clientName()}`,
            handler: () => {
              this.events.publish('navrequest', {top: 'clients', page: 'application'});
              this.modalCtrl.dismiss();
            }
          });
        }

        buttons.push({
          text: 'Select a client to use the chosen color for...',
          handler: () => {
            this.events.publish('navrequest', {top: 'clients', page: 'clients'});
            this.modalCtrl.dismiss();
          }
        });

        buttons.push({
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.clicsService.clearStash();
          }
        });

        this.actionSheetCtrl.create({
          buttons: buttons,
          mode: 'ios'
        }).then(actionSheet => actionSheet.present());
      }
    });

    await colorDetailModal.present();
  }

  async openAppDetail() {
    const stepsDisplayModal = await this.modalCtrl.create({
      component: StepsDisplayPage,
      componentProps: {cs: this.cs},
      cssClass: this.clicsService.theme,
    });
    await stepsDisplayModal.present();
  }

  closeModal(modified: boolean = false) {
    this.modalCtrl.dismiss({modified: modified});
  }

  async confirmUseApp() {
    var buttons = [
      {
        text: 'Select a client to use this app for...',
        handler: () => {
          this.doUseAppReplaceCS(true);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      },
    ];

    if (this.clicsService.clientIsSelected()) {
      buttons.unshift({
        text: `Use this app for ${this.clicsService.clientName()}`,
        handler: () => {
          this.doUseAppReplaceCS();
        }
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // If being chosen for use with a client application prompt for overwriting that application
  async promptForReplace() {
    // if (this.context == 'client') {
    const alert = await this.alertCtrl.create({
      header: 'Confirm App Replacement',
      message: `This will replace application for the chosen client with the formulas and preferences from this application. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.confirmUseApp();
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }


  // If being chosen for use with a client application prompt for overwriting that application
  async promptForRemoval() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm App Removal',
      message: `This color application will be permanently removed from your color ${this.modeCtrl.collectionStr(true)}. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.doRemoveApp();
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  // Remove this app, close this modal
  doRemoveApp() {
    this.clicsService.apiRemoveColorApplication(this.cs).then((data) => {
      if (data.success) {
        this.modalCtrl.dismiss({modified: true, deleted: true});
      } else {
        this.alertCtrl.create({
          header: "Problem Removing App",
          subHeader: `Something went wrong when trying to remove this app from the ${this.modeCtrl.collectionStr(true)}.`,
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());
      }
    });
  }

  // If a client is selected then use this app with that client
  async doUseAppReplaceCS(selectNewClient: boolean = false) {
    if (this.cs) {
      let client = this.clicsService.current_user.getClient();
      let new_cs = new CLiCSColorSession(this.cs);
      new_cs.unlink();
      if (!this.source || this.source != 'HISTORY') {
        new_cs.assertAmounts();
      }
      new_cs.reindex();

      // Set eco-source information
      for (let formula of new_cs.formulas) {
        formula.eco_amount = formula.amount;
        formula.eco_type = !!formula.unused ? 'smart_bowl' : 'history';
      }
      this.clicsService.stashData({modified: false, ca: new_cs, action: 'USE'}, this.source);
      if (selectNewClient || client == null) {
        this.events.publish('navrequest', {top: 'clients', page: 'clients'});
      } else {
        if (this.clicsService.activeClient().cs.isQueued()) {
          const alert = await this.alertCtrl.create({
            header: "Application in Process",
            message: "The client's application is actively queued and cannot be replaced. Please complete or cancel the client's application first.",
            buttons: ['Ok'],
            mode: 'ios'
          });
          await alert.present();
        }
        this.events.publish('navrequest', {top: 'clients', page: 'application'});
      }
    }
    this.closeModal(true);
  }

  appTypeText() {
    if (this.cs && this.cs.app_type_name)
      return (this.cs.app_type_name);
    else
      return (' ');
  }

  startingLevelText() {
    if (this.cs && this.cs.starting_level)
      return (this.mixingBowl.getLevelString(this.cs.starting_level, this.modeCtrl.getAppType() == 'goldwell'));
    else
      return (' ');
  }

  // Prompts to enter a name for the APP in the library
  async promptForTitle() {
    const alert = await this.alertCtrl.create({
      header: 'Application Name',
      message: `Enter a descriptive name for this application that will appear in your color ${this.modeCtrl.collectionStr(true)}`,
      inputs: [
        {
          name: 'title',
          placeholder: 'descriptive name for app',
          value: this.cs.title
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Continue',
          handler: data => {
            if (data.title != '') {
              this.cs.title = data.title;
              this.chooseLibrary();
            } else {
              this.promptForTitle();
            }
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  async chooseLibrary() {
    const current_user: CLiCSUser = this.clicsService.current_user;
    if (current_user) {
      var buttons = [];

      const libs = this.clicsService.repo.ownedLibraries();
      for (let library of libs) {
        buttons.push({
          text: library.title,
          handler: () => {
            this.setLibraryTokenAndSave(library.token);
          }
        });
      }

      if (this.modeCtrl.isPermitted('add_collection')) {
        buttons.push({
          text: `Add new ${this.modeCtrl.collectionStr(true)}...`,
          role: 'destructive',
          handler: () => {
            this.actionSheetCtrl.dismiss().then(() => {
                this.addLibrary();
            });
          }
        });
      }

      buttons.push({
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          this.toastCtrl.create({
            message: 'App save cancelled',
            duration: 3000,
            position: 'bottom'
          }).then(toast => toast.present());
        }
      });

      const actionSheet = await this.actionSheetCtrl.create({
          header: `Choose ${this.modeCtrl.collectionStr()} to Save To`,
          buttons: buttons,
        mode: 'ios'
      });

      await actionSheet.present();
    }
  }

  // Set the library token and continue with save operation
  setLibraryTokenAndSave(token: string) {
    this.library_token = token;
    this.doSaveApp();
  }

  async addLibrary() {
    const alert = await this.alertCtrl.create({
      header: `Create New ${this.modeCtrl.collectionStr()}`,
      inputs: [
        {
          name: 'title',
          type: 'text',
          label: `${this.modeCtrl.collectionStr()} Name`,
          placeholder: `Enter name for new ${this.modeCtrl.collectionStr(true)}`,
          value: ''
        }
      ],
      buttons: [
        {
          text: `${this.modeCtrl.collectionStr()} Collection`,
          handler: (data) => {
            this.alertCtrl.dismiss().then(() => {
              this.createLibraryAndSave(data.title);
            });
          }
        },
        {
          text: 'Cancel',
          role: "cancel",
          handler: () => {
            this.alertCtrl.dismiss().then(() => {
              this.chooseLibrary();
            });
          }
        }
      ],
      backdropDismiss: true,
      mode: 'ios'
    });
    await alert.present();
  }

  createLibraryAndSave(libraryTitle: string) {
    if (libraryTitle && libraryTitle !== "")
      this.clicsService.apiAddUserLibrary(libraryTitle)
        .then((result) => {
          if (result.success) {
            this.library_token = result.library.token;
            this.doSaveApp();
          } else {
            this.chooseLibrary();
          }
        });
  }

  doSaveApp() {
    this.clicsService.apiSaveColorApplication(this.cs, this.library_token).then((app) => {
      if (app) {
        let message: string = 'App Saved';
        this.cs.id = app.id;
        if (this.cs.token && this.cs.token === app.token)
          message = 'App Updated';
        else {
          this.cs.token = app.token;
          this.clicsService.repo.addApplication(app, this.library_token);
          this.clicsService.saveRepo();
        }
        this.alertCtrl.create({
          header: message,
          subHeader: `Your app was successfully saved to your color ${this.modeCtrl.collectionsStr(true)}`,
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());

        this.clicsService.clearCurrentColorApplication();
        if (this.context == 'library')
          this.alertCtrl.dismiss({modified: true});
        else
          this.events.publish('navrequest', {top: "lab", page: 'lab_app'});
      } else {
        this.alertCtrl.create({
          header: "Problem Saving App",
          subHeader: `Something went wrong when trying to save your app to the ${this.modeCtrl.collectionStr(true)}. Please check that the app has a valid name and try again.`,
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());
      }
    });
  }

  // Zooms to full size or opens a prompt to add a photo
  async zoomPhoto(token: string) {
    let _that = this;
    let title = 'Other Photo';
    const photo = this.sessionPhotos.all.find(el => el.token == token);

    if (!!photo) {
      if (photo.when_taken == 'before')
        title = 'Before Photo';
      if (photo.when_taken == 'after')
        title = 'After Photo';

      const photoZoomModal = await this.modalCtrl.create({
        component: PhotoZoomPage,
        componentProps: {photo: photo, title: title},
        backdropDismiss: true,
        cssClass: this.clicsService.theme,
      });

      photoZoomModal.onDidDismiss().then(async (overlayEvent) => {
        const data = overlayEvent.data;

        if (!!data.action && data.action == 'replace') {
          this.photoWhenTaken = data.photo.when_taken;

          this.promptForPhotoSource();
        } else {
          if (data.action == 'remove') {
            _that.loading = await _that.loadingCtrl.create({
              spinner: 'bubbles',
              message: 'removing photo...',
              duration: 5000
            });

            await _that.loading.present();

            this.photoStore.removePhoto(data.photo);
          } else {
            if (data.action == 'profile') {
              _that.loading = await _that.loadingCtrl.create({
                spinner: 'bubbles',
                message: 'setting profile photo...',
                duration: 5000
              });

              await _that.loading.present();

              this.photoStore.setProfilePhoto(data.photo.token, true).then((result) => {
                this.reloadPhotos();
                if (this.loading) {
                  this.loading.dismiss();
                  this.loading = null;
                }
              });
            }
          }
        }
      });

      await photoZoomModal.present();
    } else {
      if (this.allowCamera && (token == 'before' || token == 'after')) {
        this.photoWhenTaken = token;
        this.promptForPhotoSource();
      }
    }
  }

  takePicture() {
    let _that = this;
    if (this.allowCamera) {
      this.camera.getPicture(this.photoStore.cameraOptions).then(async (imageData) => {
        _that.clientImgSrc = 'data:image/jpeg;base64,' + imageData;
        _that.loading = await _that.loadingCtrl.create({
          spinner: 'bubbles',
          message: 'uploading photo...',
          duration: 10000
        });
        await _that.loading.present();

        // TODO: add when taken below
        _that.photoStore.addDevicePhoto(imageData, _that.client, _that.cs.token, this.photoWhenTaken, null, false);
        _that.photoGrid.setCSS(this.cs.token);  // reload photos
      }, err => {
        console.error(err);
      });
    }
  }

  getPictureFromLibrary() {
    let _that = this;
    this.camera.getPicture(this.photoStore.cameraLibraryOptions).then(async (imageData) => {
      _that.clientImgSrc = 'data:image/jpeg;base64,' + imageData;
      _that.loading = await _that.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'uploading photo...',
        duration: 10000
      });
      await _that.loading.present();

      _that.photoStore.addDevicePhoto(imageData, _that.client, _that.cs.token, this.photoWhenTaken, null, false);
      _that.photoGrid.setCSS(this.cs.token);  // reload photos
    }, err => {
      console.error(err);
    });
  }

  // Based on settings, get a photo from the camera or the
  doGetPhoto() {
    if (this.photoWhenTaken == 'before')
      this.beforePhotoUrl = ApplicationInfoPage.DEFAULT_HEADSHOT;
    if (this.photoWhenTaken == 'after')
      this.afterPhotoUrl = ApplicationInfoPage.DEFAULT_HEADSHOT;

    if (this.photoSource == 'camera') {
      this.takePicture();
    } else {
      if (this.photoSource == 'library') {
        this.getPictureFromLibrary();
      }
    }
  }

  // Only show headshots if either a before or an after photo is available
  showHeadshots(): boolean {
    return (this.beforePhotoUrl != ApplicationInfoPage.DEFAULT_HEADSHOT || this.afterPhotoUrl != ApplicationInfoPage.DEFAULT_HEADSHOT);
  }

  // Whether the photo will be used as the start photo, the finish photo, or just another photo
  async promptForPhotoTarget(source: string = null, finalize: boolean = false) {
    if (!!source) {
      this.photoSource = source;
    }

    // Customize prompting for before or after photos
    let beforePrompt = 'Replace Before Photo';
    if (this.beforePhotoUrl.includes('headshot'))
      beforePrompt = 'Make Before Photo';
    let afterPrompt = 'Replace After photo';
    if (this.afterPhotoUrl.includes('headshot'))
      afterPrompt = 'Make After Photo';

    const buttons = [
      {
        text: 'Add to Other Photos',
        handler: () => {
          this.photoWhenTaken = 'unspecified';
          if (!!finalize) {
            this.doGetPhoto();
          } else {
            this.promptForPhotoSource();
          }
        }
      },
      {
        text: beforePrompt,
        handler: () => {
          this.photoWhenTaken = 'before';
          if (!!finalize) {
            this.doGetPhoto();
          } else {
            this.promptForPhotoSource();
          }
        }
      },
      {
        text: afterPrompt,
        handler: () => {
          this.photoWhenTaken = 'after';
          if (!!finalize) {
            this.doGetPhoto();
          } else {
            this.promptForPhotoSource();
          }
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ];

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Is this a before, after or "other" photo?
  async promptForPhotoDestination() {
    if (!this.allowCamera) {
      return;
    }
    const buttons = [
      {
        text: `Set as Before Photo`,
        handler: () => {
          this.promptForPhotoSource('before');
        }
      },
      {
        text: `Set as After Photo`,
        handler: () => {
          this.promptForPhotoSource('after');
        }
      },
      {
        text: `Set as Additional Photo`,
        handler: () => {
          this.promptForPhotoSource('unspecified');
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ];

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  async promptForPhotoSource(when_taken: string = null) {
    if (['before','after','unspecified','during'].findIndex(el => el == when_taken ) > -1) {
      this.photoWhenTaken = when_taken;
    }

    const buttons = [
      {
        text: `Load from Photo Library`,
        handler: () => {
          this.photoSource = 'library';
          this.doGetPhoto();
        }
      },
      {
        text: `Take Photo with Camera`,
        handler: () => {
          this.photoSource = 'camera';
          this.doGetPhoto();
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ];

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Refresh the page on pull-down
  doRefresh(refresher: any = null) {
    this.refresher = refresher.detail;
    setTimeout(() => {
      if (!!this.refresher)
        this.refresher.complete();
    }, 3000);
    this.reloadPhotos();
  }


  doEditAction(data) {
    if (!!data && ('action' in data)) {
      if (data.action == 'save') {
        this.client.saveNote(data.note);
        this.clicsService.apiSaveClientNote(data.note).then((data) => {
          this.loadNotes();
        });
      }
      if (data.action == 'delete') {
        this.clicsService.apiDeleteClientNote(data.note.token).then((data) => {
          this.loadNotes();
        });
      }
    }
  }

  async openSettings() {
    const modal = await this.modalCtrl.create({
      component: AdminHistoryPage,
      componentProps: {view: 'modal'},
      cssClass: this.clicsService.theme
    });
    await modal.present();
  }

  async showConsultation() {
    // const editClientModal = await this.modalCtrl.create({
    //   component: ConsultationPage,
    //   backdropDismiss: true,
    //   cssClass: this.clicsService.theme,
    // });
    //
    // editClientModal.onDidDismiss().then((overlayEvent) => {
    //   this.s = this.events.subscribe('timer:server', () => {
    //     this.checkUserTimestamps()
    //   });
    // });
    // this.s = this.events.unsubscribe(this.s);
    //
    // await editClientModal.present();
  }

  // Open modal client notes page
  async showNotes() {
    const clientNotesModal = await this.modalCtrl.create({
      component: ClientNotesPage,
      componentProps: {client: this.client, css: this.cs.token},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    clientNotesModal.onDidDismiss().then(() => {
      this.loadNotes();
    });

    await clientNotesModal.present();
  }

  async showNoteEditor() {
    const clientNoteAddModal = await this.modalCtrl.create({
      component: ClientNoteAddPage,
      componentProps: {clientname: this.clientName, note: new CLiCSClientNote()},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    clientNoteAddModal.onDidDismiss().then((overlayEvent) => {
      this.doEditAction(overlayEvent.data);
    });

    await clientNoteAddModal.present();
  }

  async editNote(noteToken: string) {
    let note = this.clientNotes.find((el) => el.token == noteToken);
    const clientNoteAddModal = await this.modalCtrl.create({
      component: ClientNoteAddPage,
      componentProps: {clientname: this.clientName, note: note},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    clientNoteAddModal.onDidDismiss().then((overlayEvent) => {
      this.doEditAction(overlayEvent.data);
    });

    await clientNoteAddModal.present();
  }

  loadNotes() {
    this.clientNotes = this.client.user_notes.filter((el) => {return !!el.css && el.css === this.cs.token});
  }

  formattedDate(date: Date): string {
    if (date != null)
      return (`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
    else
      return '';
  }
}
