import {Component} from '@angular/core';
import {ModalController, NavController, NavParams, LoadingController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ClientNoteAddPage} from '../client-note-add/client-note-add';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClient} from '../../../lib/client';
import {CLiCSClientNote} from '../../../lib/client-note';
import {ApplicationInfoPage} from "../application-info/application-info";

/**
 * Generated class for the ClientNotesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-client-notes',
  templateUrl: 'client-notes.html',
  styleUrls: ['client-notes.scss'],
})
export class ClientNotesPage {
  clientName: string = "";
  client: CLiCSClient = null;
  css: string = null;  // Color Session token (optional)
  clientNotes: CLiCSClientNote[] = [];
  modalDismissSubscription: Subscription = null;
  noteUpdatedSubscription: Subscription = null;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private clicsService: CLiCSService
  ) {
  }

  ionViewWillEnter() {
    this.client = this.navParams.get('client');
    this.css = this.navParams.get('css');
    this.clientName = this.client.name();
    this.loadNotes();

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });

    this.noteUpdatedSubscription = this.events.subscribe('note:updated', () => {
      this.loadNotes();
    });
  }

  loadNotes() {
    this.clientNotes = [];
    if (this.client.user_notes.length > 0) {
      for (let note of this.client.user_notes) {
        this.clientNotes.push(new CLiCSClientNote(note));
      }
    } else {
      this.clicsService.apiGetClientNotes(this.client).then((data) => {
        for (let note of data.notes) {
          this.clientNotes.push(new CLiCSClientNote(note));
        }
      });
    }
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
    this.noteUpdatedSubscription = this.events.unsubscribe(this.noteUpdatedSubscription);
  }

  async showNoteEditor() {
    const editClientModal = await this.modalCtrl.create({
      component: ClientNoteAddPage,
      componentProps: {clientname: this.clientName},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    editClientModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;
      this.doEditAction(data);
      this.loadNotes();
    });

    await editClientModal.present();
  }

  async editNote(noteToken: string) {
    const editClientModal = await this.modalCtrl.create({
      component: ClientNoteAddPage,
      componentProps: {clientname: this.clientName, note: this.client.getNote(noteToken)},
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    editClientModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;
      this.doEditAction(data);
      this.loadNotes();
    });

    await editClientModal.present();
  }

  // saves the note
  doEditAction(data) {
    if (!!data && ('action' in data)) {
      if (data.action == 'save') {
        data.note.setCss(this.css);
        this.client.saveNote(data.note);
        this.loadNotes();
        this.clicsService.apiSaveClientNote(data.note);
      }
      if (data.action == 'delete') {
        this.clicsService.apiDeleteClientNote(data.note.token).then((data) => {
          this.loadNotes();
        });
      }
    }
  }

  formattedDateStr(dateStr: string): string {
    let match = dateStr.match(/(\d+)-(\d+)-(\d+)/);
    return (`${parseInt(match[2])}/${parseInt(match[3])}/${match[1]}`);
  }

  formattedDate(date: Date): string {
    if (date != null)
      return (`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
    else
      return '';
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  // Open an application details page
  async showApplicationDetails(sessionToken: string) {
    let loading: any = null;
    let loadTimer: any = null;

    if (!!this.css && sessionToken == this.css) {
      this.modalCtrl.dismiss();
    } else {
      if (this.client.history.length == 0) {
        loadTimer = setTimeout(async () => {
          loading = await this.loadingCtrl.create({
            spinner: 'bubbles',
            message: 'Loading History',
            duration: 10000
          });
          await loading.present();
        }, 500);

        await this.clicsService.apiGetClientHistory(this.client).then((data) => {
          clearTimeout(loadTimer);
          if (!!loading) {
            loading.dismiss();
          }
          this._openCSModal(sessionToken);
        });
      } else {
        await this._openCSModal(sessionToken);
      }
    }
  }

  // View the color session passed in a modal page
  async _openCSModal(sessionToken: string) {
    let cs = this.clicsService.activeClient().history.find((el) => {
      return (el.token == sessionToken);
    });
    if (cs) {
      this.clicsService.stashData({ca: cs, action: 'SHOW'}, 'HISTORY');

      const editClientModal = await this.modalCtrl.create({
        component: ApplicationInfoPage,
        componentProps: {source: 'HISTORY'},
        backdropDismiss: true,
        cssClass: this.clicsService.theme,
      });
      await editClientModal.present();

    } else
      console.log("No color session available to display");
  }
}
