import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FeedbackCategoriesPage} from './feedback-categories';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [FeedbackCategoriesPage],
  providers: [
    NavParams,
  ],
})
export class FeedbackCategoriesPageModule {}
