import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ClientNoteAddPage} from './client-note-add';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ClientNoteAddPage],
  providers: [
    NavParams,
  ],
})
export class ClientNoteAddPageModule {}
