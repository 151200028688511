import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FormulaIngredientComponent} from './formula-ingredient/formula-ingredient';
import {AppFormulaComponent} from './app-formula/app-formula';
import {AppFormulaActiveComponent} from './app-formula-active/app-formula-active';
import {ColorLibraryComponent} from './color-library/color-library';
import {ColorTileComponent} from './color-tile/color-tile';
import {DispenseConfirmComponent} from './dispense-confirm/dispense-confirm';
import {DispenseDeveloperComponent} from './dispense-developer/dispense-developer';
import {DispenseLightenerComponent} from './dispense-lightener/dispense-lightener';
import {DispenseProductComponent} from './dispense-product/dispense-product';
import {RecordMenuComponent} from './record-menu/record-menu';
import {PageFooterComponent} from './page-footer/page-footer';
import {NavigationBarComponent} from './navigation-bar/navigation-bar';
import {NavigationSidebarComponent} from './navigation-sidebar/navigation-sidebar';
import {QueueAppComponent} from './queue-app/queue-app';
import {AppCreateFormulaComponent} from './app-create-formula/app-create-formula';
import {RinseTimeSetterComponent} from './rinse-time-setter/rinse-time-setter';
import {ClientHeaderComponent} from './client-header/client-header';
import {FormulaItemComponent} from './formula-item/formula-item';
import {AppListingComponent} from './app-listing/app-listing';
import {ReportHeaderComponent} from './report-header/report-header';
import {ReportListComponent} from './report-list/report-list';
import {SessionReviewComponent} from './session-review/session-review';
import {ReportListWithDetailComponent} from './report-list-with-detail/report-list-with-detail';
import {AmountSliderComponent} from './amount-slider/amount-slider';
import {FormulaMixComponent} from './formula-mix/formula-mix';
import {ColorGridImageComponent} from './color-grid-image/color-grid-image';
import {ColorPillComponent} from './color-pill/color-pill';
import {ColorBarImageComponent} from './color-bar-image/color-bar-image';
import {ReportListExpandableComponent} from './report-list-expanable/report-list-expandable';
import {PhotoGridComponent} from './photo-grid/photo-grid';
import {ConfirmDeveloperComponent} from './confirm-developer/confirm-developer';
import {ClicsIconComponent} from "./clics-icon/clics-icon.component";
import {AppFormulaGroupComponent} from "./app-formula-group/app-formula-group.component";
import {AppFormulaGroupActiveComponent} from "./app-formula-group-active/app-formula-group-active.component";

@NgModule({
  declarations: [
    FormulaIngredientComponent,
    AppFormulaComponent,
    AppFormulaActiveComponent,
    AppFormulaGroupComponent,
    AppFormulaGroupActiveComponent,
    AppCreateFormulaComponent,
    ClicsIconComponent,
    ColorLibraryComponent,
    ColorTileComponent,
    ConfirmDeveloperComponent,
    DispenseConfirmComponent,
    DispenseDeveloperComponent,
    DispenseLightenerComponent,
    DispenseProductComponent,
    RecordMenuComponent,
    PageFooterComponent,
    NavigationBarComponent,
    NavigationSidebarComponent,
    QueueAppComponent,
    RinseTimeSetterComponent,
    ClientHeaderComponent,
    FormulaItemComponent,
    AppListingComponent,
    AppListingComponent,
    ReportHeaderComponent,
    ReportListComponent,
    SessionReviewComponent,
    ReportListWithDetailComponent,
    ReportListExpandableComponent,
    AmountSliderComponent,
    FormulaMixComponent,
    ColorGridImageComponent,
    ColorBarImageComponent,
    ColorPillComponent,
    PhotoGridComponent,
    ConfirmDeveloperComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    FormulaIngredientComponent,
    AppFormulaComponent,
    AppFormulaActiveComponent,
    AppFormulaGroupComponent,
    AppFormulaGroupActiveComponent,
    AppCreateFormulaComponent,
    ClicsIconComponent,
    ColorLibraryComponent,
    ColorTileComponent,
    ConfirmDeveloperComponent,
    DispenseConfirmComponent,
    DispenseDeveloperComponent,
    DispenseLightenerComponent,
    DispenseProductComponent,
    RecordMenuComponent,
    PageFooterComponent,
    NavigationBarComponent,
    NavigationSidebarComponent,
    QueueAppComponent,
    RinseTimeSetterComponent,
    ClientHeaderComponent,
    FormulaItemComponent,
    AppListingComponent,
    AppListingComponent,
    ReportHeaderComponent,
    ReportListComponent,
    SessionReviewComponent,
    ReportListWithDetailComponent,
    ReportListExpandableComponent,
    AmountSliderComponent,
    FormulaMixComponent,
    ColorGridImageComponent,
    ColorBarImageComponent,
    ColorPillComponent,
    PhotoGridComponent,
    ConfirmDeveloperComponent,
  ]
})
export class ComponentsModule {}
