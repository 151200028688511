import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActionSheetController, IonContent, LoadingController, ModalController, NavController, NavParams, ToastController} from '@ionic/angular';
import {ColorDetailPage} from '../color-detail/color-detail';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {SettingsProvider} from '../../services/settings/settings';
import {CLiCSLibrary} from '../../../lib/library';
import {CLiCSColorApplication} from '../../../lib/color-application';
import {CLiCSRepository} from '../../../lib/repository';
import {CLiCSColorSession} from '../../../lib/session';

/**
 * Shows formulas for comparisons of other manufacturer's colors
 * NOTE: this page is replaced with a unified LabColor page 2/2023
 */
@Component({
  selector: 'page-conversion-library',
  templateUrl: 'conversion-library.html',
  styleUrls: ['conversion-library.scss', '../lab-color/lab-color.scss'],
})
export class ConversionLibraryPage {
  @ViewChild(IonContent) content: IonContent;

  singleSelect: boolean = false;
  requestedView: string = 'default';
  context: string = 'any';
  theme: string = '';
  requestedLibrary: string = null;
  anySelected: boolean = false;
  loading: any = null;

  repo: CLiCSRepository = null;  // Repository object with all conversion libraries
  library: CLiCSLibrary = null;  // Library object for selected library

  libraryTitle: string = `Choose ${this.modeCtrl.collectionStr(true)}...`;
  libraryToken: string = null;
  manufacturerName: string = "Choose brand..."
  manufacturerId: number = null;

  ready = false;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private clicsService: CLiCSService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private settingsCtrl: SettingsProvider,
    private events: EventsService,
    private editQueue: EditQueueProvider,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    public modeCtrl: ModeControllerProvider,
    private route: ActivatedRoute
  ) {
  }

  ionViewWillEnter() {
    let _that = this;

    this.route.queryParams.subscribe(params => {
      this.context = params.context;
      this.requestedView = params.view;
      this.requestedLibrary = params.library;
      this.singleSelect = params.single === 'true';
    });

    this.context = this.navParams.get('context');
    this.requestedView = this.navParams.get('view');
    this.requestedLibrary = this.navParams.get('library');
    this.singleSelect = this.navParams.get('single') === 'true';

    this.theme = this.clicsService.theme;
    this.getConversionLibraries().then(result => {
      if (!!_that.requestedLibrary) {
        _that.library = _that.repo.getLibraryByTitle(_that.requestedLibrary);
        if (_that.library) {
          _that.manufacturerName = _that.library.conversion_manufacturer;
          _that.manufacturerId = _that.library.conversion_manufacturer_id;
          _that.libraryTitle = _that.library.title;
        }
      }
    });
  }

  // Returns HTML for color swatches matching the
  getConversionLibraries(): Promise<boolean> {
    let _that = this;
    let timeout = setTimeout(async () => {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Loading conversions',
        duration: 15000
      });
      await this.loading.present();
    }, 500);

    return this.clicsService.getConversionRepo().then((data) => {
      _that.repo = data;
      clearTimeout(timeout);
      this.ready = true;
      if (!this.requestedLibrary) {
        this.loadChoices();
      }
      if (_that.loading)
        _that.loading.dismiss();
      return true;
    }).catch((result) => {
      if (this.loading)
        this.loading.dismiss();

      this.toastCtrl.create({
        message: 'An error occurred loading conversions',
        duration: 3000,
        position: 'bottom'
      }).then(toast => toast.present());

      return false;
    });
  }

  // Reveals the color details page
  async showColorDetail(token) {
    const colorDetailModal = await this.modalCtrl.create({
      component: ColorDetailPage,
      componentProps: {formula_token: token, source: 'conversion', context: 'library', noCustomize: true},
      backdropDismiss: false,
      cssClass: this.theme,
    });

    colorDetailModal.onDidDismiss().then((overlayEvent) => {
      let index = this.library.formulas.findIndex((el) => {
        return (el.selected == true);
      });
    });

    await colorDetailModal.present();
  }

  clearSelection() {
    if (!!this.library) {
      this.library.clearSelection();
    }
    this.anySelected = false;
  }

  // When use is called "in the blind" then prompt for a client to use it for
  async confirmUseColor() {
    const buttons = [
      {
        text: 'Select a client to use the chosen color(s) for...',
        handler: () => {
          this.doUseColor2(true);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ];

    if (this.clicsService.clientIsSelected()) {
      buttons.unshift({
        text: `Use chosen colors for ${this.clicsService.clientName()}`,
        handler: () => {
          this.doUseColor2();
        }
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Stage app for use with current or next client
  doUseColor2(selectNewClient: boolean = false) {
    let ca = new CLiCSColorApplication();
    for (let formula of this.library.selectedFormulas) {
      ca.saveAppFormula(formula, null);
    }
    if (this.requestedView == 'modal') {
      this.modalCtrl.dismiss({ca: ca, action: 'PAYLOAD', modified: true});
    } else {
      this.clicsService.stashData({modified: true, ca: ca, action: 'PAYLOAD'});
      if (selectNewClient == true || this.clicsService.activeClient() == null)
        this.events.publish('navrequest', {top: 'clients', page: 'clients'});
      else
        this.events.publish('navrequest', {top: 'clients', page: 'application'});
    }
    this.clearSelection();
  }


  // Send formulas to the client's "app" (color session?)
  addColorsToTargetApp() {
    // Check for an active edit item
    let payload: CLiCSColorSession = new CLiCSColorSession();
    for (let formula of this.library.selectedFormulas) {
      formula.eco_type = 'default';
      formula.eco_amount = formula.amount;
      payload.saveAppFormula(formula, null);
    }
    this.modalCtrl.dismiss({modified: payload.isEmpty() == false, ca: payload, action: 'PAYLOAD'});
    this.clearSelection();
  }


  // if in the process of adding an app color, cancel "use_lab_app" and navigate back to app page
  cancelChooseColor() {
    if (!!this.library) {
      this.library.clearSelection();
    }
    if (this.requestedView == 'modal') {
      this.modalCtrl.dismiss({modified: false});
    }
  }


  async chooseConversionManufacturer() {
    var buttons = [];

    const manufacturers = this.repo.getConversionManufacturers();
    for (let man of manufacturers) {
      buttons.push({
        text: man.name.replace(/&\S+;/, ''),
        handler: () => {
          this.manufacturerName = man.name;
          this.manufacturerId = man.id;
          this.libraryTitle = `Choose ${this.modeCtrl.collectionStr(true)}...`;
          this.libraryToken = null;
          this.filterByManufacturer(man.id);
          this.saveChoices();
        }
      });
    }

    buttons.push({
      text: "Cancel",
      role: 'cancel'
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Conversion Brand',
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  filterByManufacturer(manufacturerId: number = null) {
    if (!!manufacturerId)
      this.manufacturerId = manufacturerId;
    this.library = null;
    const libNames = this.repo.getConversionLibraryTitles(this.manufacturerId);
    if (libNames.length == 1) {
      this.libraryTitle = libNames[0].title;
      this.selectLibrary(libNames[0].token);
    }
  }

  // Bring up action sheet to choose a collection
  async chooseLibrary() {
    if (!!this.manufacturerId) {
      var buttons = [];

      const libNames = this.repo.getConversionLibraryTitles(this.manufacturerId);
      for (let lib of libNames) {
        buttons.push({
          text: lib.title.replace(/&\S+;/, ''),
          handler: () => {
            this.libraryTitle = lib.title;
            this.libraryToken = lib.token;
            this.selectLibrary(lib.token);
            this.saveChoices();
          }
        });
      }

      buttons.push({
        text: "Cancel",
        role: 'cancel'
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: `Choose ${this.modeCtrl.collectionStr()}`,
        buttons: buttons,
        mode: 'ios'
      });

      await actionSheet.present();
    } else {
      this.toastCtrl.create({
        message: 'Please select a conversion brand',
        duration: 4000,
        position: 'bottom'
      }).then(toast => toast.present());
    }
  }

  selectLibrary(libraryToken) {
    this.library = this.repo.getLibraryByToken(libraryToken);
  }

  selectFormula(formulaToken) {
    if (!!this.library) {
      if (this.modeCtrl.isSimpleMode() || this.singleSelect) {
        this.library.clearSelection();
      }
      this.library.selectFormula(formulaToken);
      this.anySelected = this.library.anySelected();
    }
  }

  saveChoices() {
    if (!!this.manufacturerId) {
      localStorage.setItem('conv_man_id', `${this.manufacturerId}`);
    } else {
      localStorage.removeItem('conv_man_id');
    }
    if (!!this.libraryToken) {
      localStorage.setItem('conv_lib_tok', this.libraryToken);
    } else {
      localStorage.removeItem('conv_lib_tok');
    }
  }

  // Load manufacturer and library choice on load
  loadChoices() {
    let val = localStorage.getItem('conv_man_id');
    if (!!val && val != 'null') {
      this.manufacturerId = parseInt(val);
    }
    val = localStorage.getItem('conv_lib_tok');
    if (!!val && val != 'null') {
      this.libraryToken = val;
    }

    if (!!this.manufacturerId) {
      const manufacturer = this.repo.getConversionManufacturers().find((el) => { return el.id == this.manufacturerId });
      if (!!manufacturer)
        this.manufacturerName = manufacturer.name;
      this.filterByManufacturer(this.manufacturerId);

      const library = this.repo.getConversionLibraryTitles(this.manufacturerId).find((el) => {return el.token == this.libraryToken});
      if (!!library) {
        this.libraryTitle = library.title;
        this.selectLibrary(this.libraryToken);
      }
    }
  }

}
