import {Component, Input, OnInit} from '@angular/core';
import {ContentProvider} from "../../services/content/content";

/**
 * Generated class for the SessionReviewComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    selector: 'session-review',
    templateUrl: 'session-review.html',
    styleUrls: ['session-review.scss'],
})
export class SessionReviewComponent implements OnInit {
    @Input() review: any = null;

    constructor(
        public content: ContentProvider
    ) {}

    ngOnInit(): void {
    }

    ratingText(serviceRating: number): string {
        if (serviceRating == 0)
            return 'none';
        else
            return `${serviceRating}`;
    }

}
