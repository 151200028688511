import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CLiCSColorFormula} from "../../../lib/color-formula";
import {DomSanitizer} from "@angular/platform-browser";

/**
 * Displays a single color "chiclet" for use in comparison bar layout (e.g. Lab Colors page). If clicked the selected flag
 * toggles, showing or hiding a checkbox.
 */

@Component({
  selector: 'color-bar-image',
  templateUrl: 'color-bar-image.html',
  styleUrls: ['color-bar-image.scss'],
})
export class ColorBarImageComponent {
  @Input() formula: CLiCSColorFormula = null;
  @Output() selectFormula = new EventEmitter();
  @Output() inspectFormula = new EventEmitter();
  selected: boolean = true;
  tDown: number = 0;
  tUp: number = 0;
  lastUpTime: number = 0;
  longPressTimer: any = null;

  constructor(private sanitizer: DomSanitizer) {
  }

  tileBgStyle() {
    if (!!this.formula) {
      let html = 'background-color: ' + this.formula.rgb;
      return this.sanitizer.bypassSecurityTrustStyle(html);
    } else {
      return '';
    }
  }

  toggleSelected() {
    console.log("toggle formula");
    this.selectFormula.emit(this.formula.token);
  }

  openFormula(token: string = null) {
    console.log("long press");
    this.longPressTimer = null;
    this.tDown = 0;
    if (!!token) {
      this.inspectFormula.emit(token);
    } else {
      this.inspectFormula.emit(this.formula.token);
    }
  }

  mouseDown() {
    console.log("mouse down");
    this.tDown = new Date().getTime();
    this.longPressTimer = setTimeout(() => {this.openFormula(this.formula.token);}, 450);
  }

  mouseUp() {
    console.log("mouse up");

    // Clear inspection timeout (long-press)
    if (!!this.longPressTimer) {
      clearTimeout(this.longPressTimer);
      this.longPressTimer = null;
    }
    this.tUp = new Date().getTime();
    if (this.tDown > 0) {
      if (this.tDown < this.tUp) {
        const delay = this.tUp - this.tDown;
        if (delay < 400) {
          // Was this a double-tap?
          if (this.tUp - this.lastUpTime < 300) {
            this.openFormula();
          }
        } else {
          this.openFormula();
        }
      } else {
        this.toggleSelected();
      }
    }
    this.lastUpTime = this.tUp;
  }


}
