import {Component, Input} from '@angular/core';
import {CLiCSFormula} from "../../../lib/formula";

/**
 * Generated class for the ColorPillComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'color-pill',
  templateUrl: 'color-pill.html',
  styleUrls: ['color-pill.scss'],
})
export class ColorPillComponent {
  @Input() formula: CLiCSFormula = null;

  showTexture: boolean = true;

  constructor() {
  }

  toggleTexture() {
    this.showTexture = !this.showTexture;
  }

}
