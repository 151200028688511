import {Component} from '@angular/core';
import {LoadingController, ModalController, NavParams} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ApplicationInfoPage} from '../application-info/application-info';
import {CreateAppPage} from '../create-app/create-app';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSLibrary} from '../../../lib/library';
import {CLiCSRepository} from '../../../lib/repository';

/**
 * Generated class for the LabAppPage page.
 *
 */

@Component({
  selector: 'page-lab-app',
  templateUrl: 'lab-app.html',
  styleUrls: ['lab-app.scss'],
})
export class LabAppPage {
  context: string = 'any';
  requestedView: string = 'default';
  clientSelected: boolean = false;

  // 11/2019 now accesses APPs from a library object returned from the repo
  activeLibrary: CLiCSLibrary = null;
  repo: CLiCSRepository = null;

  checkingServerTimer: boolean = false;

  modalDismissSubscription: Subscription = null;
  timerSubscription: Subscription = null;

  constructor(
    private clicsService: CLiCSService,
    private events: EventsService,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    public modeCtrl: ModeControllerProvider) {
  }

  ionViewWillEnter() {
    if (this.modeCtrl.modeRedirect('lab_app') == false) {
      // TODO: use ActivateRoute.params observable?
      this.requestedView = this.navParams.get('view');
      this.context = this.navParams.get('context');
      this.clientSelected = this.clicsService.clientIsSelected();
      this.loadLibrary();

      this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
        if (this.context && (this.context != 'any' && this.context != ''))
          this.modalCtrl.dismiss();
      });

      this.timerSubscription = this.events.subscribe('timer:server', () => {
        this.checkUserTimestamps()
      });
    }
  }

  ionViewDidLeave() {
    this.timerSubscription = this.events.unsubscribe(this.timerSubscription);
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  // Load the library from the CLICS Service and clear the filter
  loadLibrary() {
    let that = this;

    let loading: any = null;
    let tout = setTimeout(async () => {
      loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: `Loading Color ${this.modeCtrl.collectionStr()}`,
        duration: 30000
      });
      await loading.present();
    }, 300);

    this.clicsService.getRepo().then((repo) => {
      that.repo = repo;
      that.activeLibrary = that.repo.getCombinedLibrary('ALL APPS');
      clearTimeout(tout);
      if (loading)
        loading.dismiss();
    });
  }

  // Take a search term and do dat search
  doSearch(searchSpec: string) {
    this.activeLibrary.applyApplicationSearch(searchSpec);
  }

  // Open the selected APP in the Lab Create App (editor) page
  doEditApp(app_token: string) {
    this.clicsService.getRepo().then((repo) => {
      let target = repo.findApplication(app_token);
      if (target) {
        this.clicsService.setCurrentColorApplication(target);
        this.events.publish('navrequest', {page: 'create_app'})
      }
    });
  }

  // Open the selected APP in an Application Info page
  async showAppDetail(app_token: string) {
    let target = this.activeLibrary.findApplication(app_token);
    if (target) {
      if (this.requestedView == 'modal') {
        const appDetailModal = await this.modalCtrl.create({
          component: ApplicationInfoPage,
          componentProps: {colorSession: target, context: this.context},
          backdropDismiss: false,
          cssClass: this.clicsService.theme,
        });

        appDetailModal.onDidDismiss().then((overlayEvent) => {
          const data = overlayEvent.data;

          if (data.deleted) {
            this.loadLibrary();
          }
          if (this.context == 'client' && data.modified) {
            this.closeModal(data.modified);
          }
        });

        await appDetailModal.present();
      } else {
        // this.navCtrl.navigateForward('/application_info', {colorSession: target});
        this.events.publish('navrequest', {page: 'application_info', params: {colorSession: target}})
      }
    }
  }

  // Redirect back to client app
  cancelChooseApp() {
    if (this.context == 'client')
      this.closeModal();
    else
      this.events.publish('navrequest', {top: 'clients', page: 'application'});
  }

  // Close the modal, notifying the sender whether an app was selected
  closeModal(modified: boolean = false) {
    this.modalCtrl.dismiss({modified: modified});
  }

  // Opens create app page as modal, adds app to library as selected
  async addNewLibraryApp() {
    const editAppModal = await this.modalCtrl.create({
      component: CreateAppPage,
      componentProps: {context: 'library'},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    editAppModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      if (data.modified) {
        this.loadLibrary();
      }
    });

    await editAppModal.present();
  }

  // Called by a server timer tick, checks whether the user's color library timestamp has advanced.
  checkUserTimestamps() {
    let _that = this;
    if (this.checkingServerTimer == false) {
      this.checkingServerTimer = true;
      this.clicsService.apiGetUserTimestamps().then((data) => {
        if (data.success) {
          if (data.lib_ts > _that.clicsService.current_user.lib_ts) {
            _that.clicsService.current_user.updateLibraryTimestamp(data.lib_ts);
            _that.clicsService.saveCurrentUser();
            _that.clicsService.repo.clearByScope('user');
            _that.clicsService.saveRepo();
            _that.loadLibrary();
          }
        }
        _that.checkingServerTimer = false;
      }, (data) => {
        _that.checkingServerTimer = false;
      });
    }
  }

}
