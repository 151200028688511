import {Platform} from '@ionic/angular';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {CLiCSService} from './services/clics.service';
import {EventsService} from './services/events/events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class CLiCSPanel {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    // private splashScreen: SplashScreen,
    public clicsService: CLiCSService,
    private events: EventsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    platform.ready().then(() => {
      console.log("Platform is ready");
      // splashScreen.hide();
      (navigator as any).splashscreen.hide();
      platform.resume.subscribe((result) => {
        this.clicsService.resume();
      });
      this.clicsService.resume(true);
    });

    // For platforms with a hardware back button, register the desired action
    let _that = this;
    platform.backButton.subscribeWithPriority(9999, () => {
      const curPageComponent = _that.route.component;
      const url = _that.router.url;

      // for consistency, only react to the hardware back button if a modal is not presently displayed
      document.addEventListener('backbutton', function (event) {
        event.preventDefault();
        event.stopPropagation();
      }, false);

      console.log(`HW back button request from ${url}, component: ${curPageComponent.name}`);

      if (
        curPageComponent.name != 'ModalCmp' &&  // TODO: does this actually work?
        !url.includes('signon') &&
        !url.includes('salonselect') &&
        !url.includes('login') &&
        !url.includes('signout')
      ) {
        _that.events.publish('navrequest', {page: 'back'});
      }
    });
  }

  // is the app authenticated and a user signed on?
  showChrome(): boolean {
    return this.clicsService.isSignedOn();
  }
}
