import {Injectable, Injector} from '@angular/core';
import {EventsService} from '../events/events.service';
declare let colorreader: any;

/*
  Generated class for the ColorScannerProvider provider.
*/
@Injectable({
  providedIn: 'root',
})
export class ColorScannerProvider {
  static connectionInfo: any = {connected: false, mustCalibrate: false}; // current connection status
  static availabiltyInfo: any = {ready: false, devices: []};  // lists devices available to connect or last connected
  static recentDevices: any = [];  // TODO: fill this in with each connection, most recent first
  static lastMeasurement: any = {success: false, L: 0.0, A: 0.0, B: 0.0};
  static colorData: any = {success: false, LAB: {L: 0.0, A: 0.0, B: 0.0}, RGB: {R: 0.0, G: 0.0, B: 0.0}, Level: 0};
  static foundScanners: any = {success: false, scanners: []};

  constructor(private events: EventsService) {
    console.log("ColorScanner: calling initPlugin");
    colorreader.initPlugin(this.handleSuccess, this.handleFailure);
    // TODO: load recent devices from local storage
  }

  // Immediately returns last known connection status but may generate a connect or disconnect event
  checkStatus(): void {
    colorreader.isDeviceConnected(this.handleCheckStatusSuccess, this.handleFailure);
  }

  // Attempts tp connect to a single available device or one that is known to this app instance.
  find(): void {
    colorreader.findScanners(ColorScannerProvider.recentDevices, this.handleFindScannersSuccess, this.handleFindScannersFailure);
  }

  // Attempts tp connect to a single available device or one that is known to this app instance.
  connect(): void {
    colorreader.connectScanner(ColorScannerProvider.recentDevices, this.handleConnectSuccess, this.handleConnectFailure);
  }

  // Signal the plugin to calibrate the scanner
  calibrate(): void {
    colorreader.calibrateScanner(this.handleCalibrateSuccess, this.handleCalibrateFailure);
  }

  // Signal the plugin to take a measurement
  measure(): void {
    colorreader.takeMeasurement(this.handleMeasureSuccess, this.handleMeasureFailure);
  }

  // status calls

  isConnected(): boolean {
    return(ColorScannerProvider.connectionInfo.connected);
  }

  mustCalibrate(): boolean {
    return(ColorScannerProvider.connectionInfo.mustCalibrate);
  }

  getConnectionInfo(): any {
    return(ColorScannerProvider.connectionInfo);
  }

  getColorData(): any {
    return(ColorScannerProvider.colorData);
  }

  // callback handlers

  handleSuccess = (data: any) => {
    let mssg = String(data);
    console.log(`ColorScanner Success (${mssg})`);
  };

  handleFailure = (data: any): void => {
    let mssg = String(data);
    console.log(`ColorScanner FAILURE: (${mssg})`);
  };

  handleFindScannersSuccess = (data: any): void => {
    console.log("find scanners: " + data);
    this.events.publish("scanner:find", data);
  };

  handleFindScannersFailure = (): void => {
    console.log("handleFindScannerFailure() - find scanner failed");
  };

  handleCheckStatusSuccess = (data: any): void => {
    if (data.connected != ColorScannerProvider.connectionInfo["connected"]) {
      if (data.connected) {
        this.events.publish("scanner:connect", data);
      }
      else {
        console.log("Scanner device disconnected");
        this.events.publish("scanner:disconnect");
      }
    }
    ColorScannerProvider.connectionInfo = JSON.parse(data);
    console.log("Current connection status is: " + ColorScannerProvider.connectionInfo.connected ? "Connected" : "Disconnected");
    this.events.publish("scanner:info", ColorScannerProvider.connectionInfo);
  };

  // After connection request. Ready = "connected", list of devices available
  // or 1 device if connected (always connects if 1 device)
  handleConnectSuccess = (data: any): void => {
    console.log(`ColoScannerProvider.handleConnectSuccess reached, connected device: ${data.name}`);
    // TODO: update recentDevices list and save to local storage
    this.checkStatus(); // generate a connected event and check calibration status
  };

  handleConnectFailure = (data: any): void => {
    console.log("Connect attempt to scanner failed");
    ColorScannerProvider.connectionInfo = {connected: false, mustCalibrate: false};
    this.events.publish('scanner:disconnect');
  };

  handleCalibrateSuccess = () => {
    ColorScannerProvider.connectionInfo.mustCalibrate = false;
    this.events.publish("scanner:calibration", {success: true});
  };

  handleCalibrateFailure = () => {
    ColorScannerProvider.connectionInfo.mustCalibrate = true;
    this.events.publish("scanner:calibration", {success: false});
  };

  handleMeasureSuccess = (data: any) => {
    let levelVal = 0;
    let colorData = JSON.parse(data[0]);

    // generate 1/2-level results from measured level threshold values (for levels 0.5, 1, 1.5, 2 etc.) by finding closest match
    const thresholds = [4, 8, 14.5, 21, 24, 27, 28.5, 30, 33.5, 37, 40, 43, 50.5, 59, 64, 69, 71.5, 74, 79.5, 85, 88, 91, 94, 97];
    const closest = thresholds.reduce(function(prev, curr) {
      return (Math.abs(curr - colorData.L) < Math.abs(prev - colorData.L) ? curr : prev);
    });
    const threshIndex = thresholds.indexOf(closest);
    console.log(`Scanned L value: ${colorData.L}, closest match: ${closest}, index of closest: ${threshIndex}, returned value: ${(threshIndex / 2) + 0.5}`);
    levelVal = (threshIndex / 2) + 0.5;

    // TODO: convert LAB to RGB!!
    ColorScannerProvider.colorData = {success: colorData.success, LAB: {L: colorData.L, A: colorData.A, B: colorData.B}, RGB: {R: 0.0, G: 0.0, B: 0.0}, Level: levelVal};
    this.events.publish("scanner:measurement", ColorScannerProvider.colorData);
  };

  handleMeasureFailure = () => {
    console.log("handleMeasureFailure reached");
    ColorScannerProvider.lastMeasurement = {success: false, L: 0.0, A: 0.0, B: 0.0};
    ColorScannerProvider.colorData = {success: false, LAB: {L: 0.0, A: 0.0, B: 0.0}, RGB: {R: 0.0, G: 0.0, B: 0.0}, Level: 0};
    this.events.publish("scanner:measurement", ColorScannerProvider.colorData);
  };


}
