import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FeedbackPage} from './feedback';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [FeedbackPage],
  providers: [
    NavParams,
  ],
})
export class FeedbackPageModule {}
