import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentProvider} from "../../services/content/content";

/**
 * Generated class for the ReportListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
*/
@Component({
    selector: 'report-list',
    templateUrl: 'report-list.html',
    styleUrls: ['report-list.scss'],
})
export class ReportListComponent implements OnInit {

    @Input() data: any = null;
    @Input() use_table: boolean = false;
    @Input() small_headers: boolean = false;
    @Output() listClick = new EventEmitter();

    cols: number = 2;
    title: string = null;
    headings: string[] = [];
    hasHeadings: boolean = false;

    constructor(
        public content: ContentProvider
    ) {}

    ngOnInit() {
        if (this.data) {
            this.cols = this.data.cols.length;
            this.title = this.data.title;
            for (let col of this.data.cols) {
                if (col.title && col.title != '') {
                    this.hasHeadings = true;
                    this.headings.push(col.title)
                }
                else
                    this.headings.push('')
            }
        }
    }

    isLinkColumn(column_index: number) {
        return (this.data.cols[column_index].link_type != null);
    }

    isIconColumn(column_index: number) {
        return (this.data.cols[column_index].var_type == 'icon');
    }

    alignRight(index: number): boolean {
        return(this.data.cols[index].var_type == 'number');
    }

    handleAttrClick(column_index: number, token: string) {
        this.listClick.emit({type: this.data.cols[column_index].link_type, token: token});
    }
}
