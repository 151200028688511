import {Observable, of, throwError, empty} from 'rxjs';
import {catchError, delay, mergeMap, retryWhen, shareReplay} from 'rxjs/operators';

const getErrorMessage = (maxRetry: number) =>
  `Tried to load Resource over XHR for ${maxRetry} times without success.`;

const DEFAULT_MAX_RETRIES = 5
const DEFAULT_BACKOFF = 1000

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(delayMs),
        mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry)))
      ))
    );
}

export function retryWithBackoff(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
            if (retries-- > 0) {
              const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
              return of(error).pipe(delay(backoffTime));
            }
            return throwError(getErrorMessage(maxRetry));
          }
        ))
      )
    );
}

// Performs an HTTP GET with up to 2 retries
export function getWithRetry(http, url, options = {}): Observable<any> {
  return http.get(url, options).pipe(
    retryWithBackoff(750, 2, 2000),
    catchError(error => {
      console.error(error);
      return empty();
    }),
    shareReplay()
  );
}

// Performs an HTTP POST with up to 2 retries. CAREFUL! repeated POSTS may have unpredictable behavior
export function postWithRetry(http, url, body = {}): Observable<any> {
  return http.post(url, body).pipe(
    retryWithBackoff(750, 2, 2000),
    catchError(error => {
      console.error(error);
      return empty();
    }),
    shareReplay()
  );
}
