import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InventoryLoopPage} from './inventory-loop';

const routes: Routes = [
  { path: '', component: InventoryLoopPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InventoryLoopPageRoutingModule {}
