/*********************************************************
 * The page controller handles navigation as directed by links on the navigation-bar or navigation-sidebar
 * components. Part of this navigation is maintaining left-right stacks for the pages in a particular top-level
 * tab (so, doing a page navigation pushes or pops from the navigation stack, as needed). This also maintains
 * some state for the navigation components, mainly the active page for each top-level tab.
 *
 * The page controller also starts and stops a server timer that generates a periodic event (timer:server) that can
 * be used to trigger on-page server queries and updates.
 *********************************************************/

import {Injectable, Injector} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {timer} from 'rxjs';
import {CLiCSService} from '../clics.service';
import {EventsService} from '../events/events.service';
import {ModeControllerProvider} from '../mode-controller/mode-controller';

/*
    This class handles managing the stack, loading and unloading pages as required.
 */
@Injectable({
  providedIn: 'root',
})
export class PageControllerProvider {
  // Lists the pages, in order, for each top level navigation route
  route_matrix = [
    {
      route: 'clients', page_index: 0, pages: [
        {pageClass: 'ClientsPage', ref: 'clients', title: 'List'},
        {pageClass: 'ProfilePage', ref: 'profile', title: 'Profile'},
        {pageClass: 'ApplicationPage', ref: 'application', title: 'App'},
        {pageClass: 'HistoryPage', ref: 'history', title: 'History'},
        {pageClass: 'PicsPage', ref: 'pics', title: 'Pics'},
      ]
    },
    {
      route: 'lab', page_index: 0, pages: [
        // {pageClass: 'CollectionsMenuPage', ref: 'collections_menu', title: 'CLICS'},
        {pageClass: 'LabColorsPage', ref: 'lab_clics', title: 'CLICS'},
        {pageClass: 'LabColorsPage', ref: 'lab_topchic', title: 'TopChic'},
        {pageClass: 'LabColorsPage', ref: 'lab_colorance', title: 'Colorance'},
        {pageClass: 'LabColorsPage', ref: 'lab_convert', title: 'Conversions'},
        {pageClass: 'LabColorPage', ref: 'lab_color', title: 'Colors'},
        {pageClass: 'LabAppPage', ref: 'lab_app', title: 'Apps'}
        // {pageClass: CreatePage, ref: 'create', title: 'Create'},
        // {pageClass: CreateColorPage, ref: 'create_color', title: 'Create Color'},
        // {pageClass: CreateAppPage, ref: 'create_app', title: 'Create App'},
      ]
    },
    {
      route: 'dispenser', page_index: 0, pages: [
        {pageClass: 'QueuePage', ref: 'queue', title: 'Queue'},
        {pageClass: 'QuickPage', ref: 'quick', title: 'Quick'},
        {pageClass: 'DispenseHistoryPage', ref: 'dispensed', title: 'Dispensed'}
      ]
    },
    {
      route: 'lookbook', page_index: 0, pages: [
        {pageClass: 'LookbookSalonPage', ref: 'lookbook_salon', title: 'Salon'},
        {pageClass: 'LookbookAppsPage', ref: 'lookbook_apps', title: 'Apps'},
        {pageClass: 'LookbookClientsPage', ref: 'lookbook_clients', title: 'Clients'},
        {pageClass: 'LookbookColorsPage', ref: 'lookbook_colors', title: 'Colors'},
        {pageClass: 'LookbookCoverPage', ref: 'lookbook_cover', title: 'Cover'},
        {pageClass: 'LookbookFeaturedPage', ref: 'lookbook_featured', title: 'Featured'},
        {pageClass: 'LookbookFollowingPage', ref: 'lookbook_following', title: 'Following'},
        {pageClass: 'LookbookTrendingPage', ref: 'lookbook_trending', title: 'Trending'},
      ]
    },
    {
      route: 'admin', page_index: 0, pages: [
        {pageClass: 'AdminProfilePage', ref: 'admin_profile', title: 'Profile'},
        {pageClass: 'AdminServicePage', ref: 'admin_service', title: 'Service'},
        {pageClass: 'AdminInventoryPage', ref: 'admin_inventory', title: 'Inventory'},
        {pageClass: 'AdminDispenserPage', ref: 'admin_dispenser', title: 'Dispenser'},
        {pageClass: 'AdminEquipmentPage', ref: 'admin_equipment', title: 'Equipment'},
        {pageClass: 'AdminLookbookPage', ref: 'admin_lookbook', title: 'Lookbook'},
        {pageClass: 'AdminSalonPage', ref: 'admin_salon', title: 'Salon'},
        {pageClass: 'AdminStylistsPage', ref: 'admin_stylists', title: 'Stylists'},
        {pageClass: 'InventoryFullIntroPage', ref: 'inventory_full', title: 'Full Inventory'},
        {pageClass: 'InventoryLoopPage', ref: 'inventory_loop', title: 'Scan Inventory'},
        {pageClass: 'InventoryFullConfirmPage', ref: 'inventory_confirm', title: 'Confirm Location'},
        {pageClass: 'AdminHistoryPage', ref: 'admin_history', title: 'Client History Settings'},
      ]
    },
    {
      route: 'reports', page_index: 0, pages: [
        {pageClass: 'ReportClientsPage', ref: 'report_clients', title: 'Clients Report'},
        {pageClass: 'ReportInventoryPage', ref: 'report_inventory', title: 'Inventory Report'},
        {pageClass: 'ReportRegisterPage', ref: 'report_register', title: 'Register Report'},
        {pageClass: 'ReportClientHistoryPage', ref: 'report_client_history', title: 'Client History Report'},
        {pageClass: 'ReportStylistHistoryPage', ref: 'report_stylist_history', title: 'Stylist History Report'},
        {pageClass: 'ReportClientReviewHistoryPage', ref: 'report_client_review_history', title: 'Client Review History Report'},
        {pageClass: 'ReportDateRegisterPage', ref: 'report_date_register', title: 'Daily Register Report'},
        {pageClass: 'ReportBillingPage', ref: 'report_billing', title: 'Billing Report'},
        {pageClass: 'ReportInvoicePage', ref: 'report_invoice', title: 'Invoice Detail'},
        {pageClass: 'ReportSessionPage', ref: 'report_session', title: 'Session Detail'},
        {pageClass: 'ReportsPage', ref: 'reports', title: 'Reports'},
      ]
    },
    {
      route: 'signout', page_index: 0, pages: [
        {pageClass: 'SignonPage', ref: 'signout', title: 'Sign Out'}
      ]
    },
    {
      route: 'logout', page_index: 0, pages: [
        {pageClass: 'LoginPage', ref: 'LOGOUT', title: 'Log Out'}
      ]
    },
    {
      route: 'salonselect', page_index: 0, pages: [
        {pageClass: 'SalonSelectPage', ref: 'salonselect', title: 'Salon Selection'}
      ]
    },
    {
      route: 'createmenu', page_index: 0, pages: [
        {pageClass: 'CreateMenuPage', ref: 'createmenu', title: 'Create Menu'}
      ]
    }
  ];

  // root_nav: NavController = null;  // TODO: REMOVE THIS amd include
  default_route_name: string = 'clients';
  default_page_name: string = 'clients';
  current_route: any = null;
  page_index: number = 0;
  history: string[] = [];

  serverTimer = null;  // timer to produce timer:server event
  serverPeriod = 10000;  // default period for how often to check server timestamps, in milliseconds

  modeCtrl: ModeControllerProvider = null;
  events: EventsService = null;
  clicsService: CLiCSService = null;

  pageParams: any[] = [];

  constructor(
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.modeCtrl = this.injector.get(ModeControllerProvider);
    this.events = this.injector.get(EventsService);
    this.clicsService = this.injector.get(CLiCSService);

    // Default the current root to "clients"
    this._setTopNavigation(this.default_route_name);

    // Listen for navigation requests from pages {top: 'clients', page: 'application'}
    let that = this;
    console.log("++++ INSTANTIATING PAGE CONTROLLER ++++");
    this.events.subscribe('navrequest', (request) => {
      let force = false;
      if ('force' in request) {
        force = request.force;
      }
      this._doNavRequest(request, force);
    });
    // Set page index to 0 for all routes
    this.events.subscribe('navreset', (request) => {
      // this.reset_route();
      this.goStartPage();
    });

    // Test for being online after an offline event
    timer(0, 10000).subscribe(() => {
      this.clicsService.checkConnection();
    });

    // Track router events to prevent back navigation past the initial page
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        if (!url.includes('/login') && !url.includes('/signon') && !url.includes('/salonselect')) {
          this.history.push(url);
        } else {
          this.history = [];
        }
      }
    })

    this._setTimerByPage();
  }

  // In response to a navrequest event this saves the params into this provider and
  // navigates to the requested page.
  _doNavRequest(request: any, force = false) {
    const that = this;
    const params: any = ('params' in request) ? request.params : null;

    if (request.page != undefined && request.top != undefined) {
      if (request.page == 'back')
        this.doBackNavigation();
      else {
        that._forcePageIndexOnRoot(request.top, request.page);
        that.doTopNavigation(request.top, params);
      }
    } else {
      if (request.top != undefined) {
        if (request.top == 'autoclient') {
          if ('forceAppPage' in request)
            this.autoNavigateToClient(request.forceAppPage);
          else
            this.autoNavigateToClient();
        } else {
          that.doTopNavigation(request.top, params);
        }
      }
      if (request.page != undefined) {
        if (request.page == 'back')
          that.doBackNavigation();
        else
          that.doPageNavigation(request.page, params, force);
      }
    }
  }

  // Sets the top level navigation (route) without actually navigating. Primarily for initializing.
  _setTopNavigation(route_name: string): void {
    this.current_route = this.route_matrix.find((element) => {
      return (element.route == route_name);
    });
  }

  // Stores the page name and
  storePageParams(page: string, params: any) {
    let pageObj = this.pageParams.find((el) => {
      return (el.page == page);
    });
    if (!!pageObj) {
      pageObj.params = params;
    } else {
      this.pageParams.push({page: page, params: params});
    }
  }

  // get all of the page params as an object
  getPageParams(page: string): any {
    let pageObj = this.pageParams.find((el) => {
      return (el.page == page);
    });
    if (!!pageObj) {
      return pageObj.params;
    } else {
      return null;
    }
  }

  // retrieve a single param for a page
  getParam(page: string, paramKey: string): any {
    let pageObj = this.pageParams.find((el) => {
      return (el.page == page);
    });
    if (!!pageObj) {
      return pageObj.params[paramKey];
    } else {
      return null;
    }
  }

  // Start running timers, updated every N seconds. ifNotStarted should be set true on the initial call to prevent
  // multiple timers from running.
  _startServerTimer(periodInMs: number = null) {
    this._stopServerTimer();
    if (periodInMs == null)
      periodInMs = this.serverPeriod;
    if (this.serverTimer == null) {
      this.serverTimer = timer(0, periodInMs).subscribe(() => {
        this.events.publish('timer:server');
      });
    }
  }

  // Stop any running server timers
  _stopServerTimer() {
    if (this.serverTimer != null) {
      this.serverTimer.unsubscribe();
      this.serverTimer = null;
    }
  }

  // Sets the top level navigation and, if necessary, clears and initializes the page stack in the NavController
  doTopNavigation(route_name: string, params: any = null): void {
    // search for this route name in the above list
    if (route_name == 'home')
      route_name = this.default_route_name;

    let route = this.route_matrix.find((element) => {
      return (element.route == route_name);
    });

    if (route) {
      if (route_name == 'signout' && (!params || !params.update_only)) {
        this.clicsService.apiSignOut();
        this.storePageParams('signon', params);
        this.router.navigate(['/signon'], {queryParams: params}).then(() => this._performPostNavRules());
      } else {
        if (route_name == 'logout') {
          this.clicsService.apiLogOut();
          this.storePageParams('login', params);
          this.router.navigate(['/login'], {queryParams: params}).then(() => this._performPostNavRules());
        } else {
          // Only push page if it's not the current top-level route or page
          // let pageInfo = this._recallLastPageForRoot(route);
          if (this.clicsService.isLoggedIn() == false) {
            this.storePageParams('login', params);
            this.router.navigate(['/login'], {queryParams: params}).then(() => this._performPostNavRules());
          } else {
            if (this.clicsService.isSignedOn() == false) {
              this.storePageParams('signon', params);
              this.router.navigate(['/signon'], {queryParams: params}).then(() => this._performPostNavRules());
            } else {
              this.current_route = route;
              let pageInfo = this._recallLastPageForRoot();

              if (pageInfo.ref) {
                this.storePageParams(pageInfo.ref, params);
                console.log('PageCtrl navigating to page: ' + pageInfo.ref)
                this.router.navigate(['/' + pageInfo.ref], {queryParams: params}).then(() => {  // NOTE: previously specified animate: false
                  this._rememberRootPage(pageInfo.page_index);
                  this._performPostNavRules();
                });
              } else {
                this.storePageParams(pageInfo.page.ref, params);
                console.log('PageCtrl navigate to page: ' + pageInfo.page.ref)
                this.router.navigate(['/' + pageInfo.page.ref], {queryParams: params}).then(() => {
                  this._rememberRootPage(pageInfo.page_index);
                  this._performPostNavRules();
                });
              }
            }
          }
        }
      }
    } else {
      console.error("Route " + route_name + " not found in doTopNavigation()");
    }
  }

  // Changes the page loaded for the current route (top-level nav). page_index is 0-based. Force makes the
  // navigation push happen even if we think we're already on the page.
  // NOTE: Added 1/2023: force will also force top-level navigation so we don't get "page not found in route"
  doPageNavigation(page_ref: string, params: any = null, force: boolean = false): void {
    let page_index = -1;
    if (!!this.current_route) {
      page_index = this.current_route.pages.findIndex((element) => {
        return (element.ref == page_ref);
      });
      if (page_index < 0 && force) {
        this.doTopNavigation(this.getRouteFromPage(page_ref));
        page_index = this.current_route.pages.findIndex((element) => {
          return (element.ref == page_ref);
        });
      }
      if (page_index >= 0) {
        const page = this.current_route.pages[page_index];
        // Check that the user is logged in / signed on
        if (page.ref != 'LOGOUT' && this.clicsService.isLoggedIn() == false) {
          this.storePageParams('login', params);
          this.router.navigate(['/login'], {queryParams: params}).then(() => this._performPostNavRules());
        } else {
          if (page.ref != 'signout' && this.clicsService.isSignedOn() == false) {
            this.storePageParams('signon', params);
            this.router.navigate(['/signon'], {queryParams: params}).then(() => this._performPostNavRules());
          } else {
            if (this._isCurrentPage(page) == false || force) {
              this.storePageParams(page.ref, params);
              this.router.navigate(['/' + page.ref], {queryParams: params}).then(() => {  // NOTE: #30compile previously specified animate: false
                this._rememberRootPage(page_index);
                this._performPostNavRules();
              });
            } else {
              // Special case for Application page
              if (page.ref == "application")
                this.events.publish('color_session:reload');
            }
          }
        }
      } else {
        console.error("PageControllerProvider::doPageNavigation() - Page " + page_ref + " not found in route " + this.current_route.route);
      }
    } else {
      console.error("Route not initialized in doPageNavigation(). Page request ignored.");
    }
  }

  // Depending on the state of the current client and their CS select the appropriate page
  autoNavigateToClient(forceAppPage: boolean = false): void {
    this.doTopNavigation('clients');
    if (this.clicsService.clientIsSelected())
      if (forceAppPage == false && this.clicsService.activeClient().cs.isEmpty() && (this.modeCtrl.isPermitted('access_profile')))
        this.doPageNavigation('profile', {auto: true});
      else
        this.doPageNavigation('application');
    else {
      this.doPageNavigation('clients')
    }
  }

  // Returns true if the page object passed is the same one currently on the screen
  _isCurrentPage(page: any): boolean {
    return (this.current_route.pages.indexOf(page) == this.current_route.page_index);
  }

  // Sets page_index on the current_root object so that it's remembered.
  _rememberRootPage(pageIndex: number, rootRoute: any = null): void {
    if (rootRoute) {
      this.current_route = this.current_route = rootRoute;
    }
    this.current_route.page_index = pageIndex;
    this._protectRoutePageIndex(this.current_route);
  }

  // Get the last Page object visited for this root route
  _recallLastPageForRoot(route: any = null): any {
    if (route) {
      this._protectRoutePageIndex(route);
      return ({
        page: route.pages[route.page_index], // now returns entire page object
        page_index: route.page_index
      });
    } else {
      if (this.current_route) {
        // Don't default to lab_clics page if GW app
        if (this.current_route.route == 'lab' && this.current_route.page_index == 0 && this.modeCtrl.getAppType() == 'goldwell') {
          this.current_route.page_index = this.current_route.pages.findIndex(el => el.ref == 'lab_topchic');
        }
        this._protectRoutePageIndex(this.current_route);
        return ({
          page: this.current_route.pages[this.current_route.page_index], // now returns entire page object
          page_index: this.current_route.page_index
        });
      } else {
        return {page: null, page_index: 0};
      }
    }
  }

  // Performs rules that should occur post-navigation
  _performPostNavRules(): void {
    if (this.current_route.route != 'lab')
      this.clicsService.setUseLabApp(false);
    this._setTimerByPage();
    this.events.publish('navigation:changed', this.current_route.route);
    this.events.publish('page:changed', this.getCurrentPageRef());
  }

  // Server timer is started or stopped depending on the page we're on
  _setTimerByPage(page: string = null): void {
    if (!page) {
      page = this.getCurrentPageRef();
    } else {
      if (page.match(/^\/.+/)) {
        page = page.substring(1)
      }
    }
    switch (page) {
      case 'application':
        this._startServerTimer();
        break;
      case 'queue':
        this._startServerTimer();
        break;
      case 'clients':
      case 'lab_color':
      case 'lab_app':
        // TODO: increase this timeout for production
        this._startServerTimer(600000);
        break;
      case 'profile':
      case 'history':
        // TODO: increase this timeout for production
        this._startServerTimer(20000);
        break;
      default:
        this._stopServerTimer();
        break;
    }
  }

  // Clamp route page index between 0 and pages.length - 1
  _protectRoutePageIndex(route: any) {
    if (route) {
      if (route.page_index >= route.pages.length) {
        route.page_index = 0;
        console.log(`_protectRoutePageIndex clamped page index on route ${route.route}`);
      } else {
        if (route.page_index < 0) {
          route.page_index = 0;
          console.log(`_protectRoutePageIndex fixed negative page index on route ${route.route}`);
        }
      }
    }
  }

  // Keeps track of navigation history
  doBackNavigation(): void {
    if (this.history.length > 1) {
      this.history.pop();
      this.location.back();  // Navigate back using browser history
      const prior_page = this.history.pop();
      if (!!prior_page) {
        const activePageInfo = this._routeUrlIndex(prior_page);
        if (activePageInfo.current_route) {
          if (this.current_route.route != activePageInfo.route_ref) {
            this.doTopNavigation(activePageInfo.route_ref);
          }
          this.current_route.page_index = activePageInfo.page_index;
          this._setTimerByPage(prior_page);
          this.events.publish('navigation:changed', activePageInfo.route_ref);
          this.events.publish('page:changed', activePageInfo.page_ref);
        } else {
          this.doBackNavigation();  // Re-entrant until end of stack or indexed page found
        }
      }
    }
  }

  // Returns the top-level route string; returns the default route name if no route set
  getCurrentRoot(): string {
    if (this.current_route != null)
      return this.current_route.route;
    else
      return this.default_route_name;
  }

  setCurrentPageFromUrl() {
    let cur_page = this.router.url;
    if (!!cur_page) {
      cur_page = cur_page.replace(/\//, '');
    }
    for (let top_route of this.route_matrix) {
      let idx = top_route.pages.findIndex((el) => {
        return el.ref == cur_page;
      });
      if (idx >= 0) {
        this.current_route = top_route;
        this.current_route.page_index = idx;
        break;
      }
    }
  }

  // Returns the string identifier of the page currently selected
  getCurrentPageRef(): string {
    // Set current page by url
    this.setCurrentPageFromUrl();

    if (!!this.current_route) {
      // Protect against bad index (modified navigation)
      if (this.current_route.page_index >= this.current_route.pages.length)
        this.current_route.page_index = 0;
      return this.current_route.pages[this.current_route.page_index].ref;
    } else {
      return this.default_page_name;
    }
  }

  // When navigating to a new top-level route & subordinate page, set the page_index in route_matrix in order
  // to navigate to that page.
  _forcePageIndexOnRoot(top: string, page: string) {
    let route_index = this.route_matrix.findIndex((element) => {
      return (element.route == top);
    });
    if (route_index >= 0) {
      let pages: any[] = this.route_matrix[route_index].pages;
      let page_index = pages.findIndex((element) => {
        return (element.ref == page);
      });
      if (this.page_index >= 0) {
        this.route_matrix[route_index].page_index = page_index;
      } else {
        console.log(`Warning: could not find page index for ${top}|${page}`);
      }
    }
  }

  // Looks up a page object (e.g. HistoryPage) in the matrix and, if found, returns the route index and page index
  _pageObjectIndex(pageObject: any): any {
    let result = {current_route: null, route_index: null, route_ref: null, page_index: null, page_ref: null};
    let route_index = -1;

    for (let route of this.route_matrix) {
      route_index += 1;
      let pages: any[] = route.pages;
      let index = pages.findIndex((pageEl) => {
        // return (pageEl.page.name == pageObject.component.name);
        return (pageEl.pageClass == pageObject.component.name);  // TODO: #30compile does this work since stringifying the page object? Should.
      });
      if (index >= 0) {
        result.current_route = route;
        result.route_index = route_index;
        result.route_ref = route.route;
        result.page_index = index;
        result.page_ref = route.pages[index].ref;
        break;
      }
    }
    return (result);
  }

  // Looks up a page object (e.g. HistoryPage) in the matrix and, if found, returns the route index and page index
  _routeUrlIndex(routeUrl: string): any {
    let result = {current_route: null, route_index: null, route_ref: null, page_index: null, page_ref: null};
    let route_index = -1;

    if (routeUrl.match(/^\/.+/)) {
      routeUrl = routeUrl.substring(1)
    }

    for (let route of this.route_matrix) {
      route_index += 1;
      let pages: any[] = route.pages;
      let index = pages.findIndex((pageEl) => {
        return (pageEl.ref == routeUrl);
      });
      if (index >= 0) {
        result.current_route = route;
        result.route_index = route_index;
        result.route_ref = route.route;
        result.page_index = index;
        result.page_ref = route.pages[index].ref;
        break;
      }
    }
    return (result);
  }

  // Find the route name from a page ref
  getRouteFromPage(pageRef: string): string {
    let result: string = null;

    if (pageRef.match(/^\/.+/)) {
      pageRef = pageRef.substring(1)
    }

    for (let route of this.route_matrix) {
      for (let page of route.pages) {
        if (page.ref == pageRef) {
          result = route.route;
          break
        }
      }
      if (!!result) {
        break;
      }
    }
    return result;
  }

  // Find the name of the page class from the page ref
  getClassFromPage(pageRef: string): string {
    let result: string = null;

    if (pageRef.match(/^\/.+/)) {
      pageRef = pageRef.substring(1)
    }

    for (let route of this.route_matrix) {
      for (let page of route.pages) {
        if (page.ref == pageRef) {
          result = page.pageClass;
          break
        }
      }
      if (!!result) {
        break;
      }
    }
    return result;
  }

  // Set all routes back to defaults - useful when signing out
  reset_route(new_root_name: string = null): void {
    for (let route of this.route_matrix) {
      route.page_index = 0;
    }
    if (new_root_name) {
      this._setTopNavigation(new_root_name);
    } else {
      this._setTopNavigation(this.default_route_name);
    }
  }

  // Routes to the Sign On page or the Salon Select page depending on the app mode. Reverts to Login or Salon
  // Select Page if salon not chosen or user not logged in.
  goStartPage(): void {
    if (this.clicsService.isLoggedIn() == false) {
      this.reset_route('logout');
      this.router.navigate(['/login']);
    } else {
      if (this.clicsService.current_salon == null) {
        this.reset_route('salonselect');
        this.router.navigate(['/salonselect'], {queryParams: {f: 1}}); // TODO: use a routerLink on header and footer links? Has prompt option? Removed: , {prompt: true}
      } else {
        if (this.clicsService.app_mode == 'personal') {
          this.reset_route('salonselect');
          this.router.navigate(['/salonselect'], {queryParams: {f: 1}}); // TODO: use a routerLink on header and footer links? Has prompt options? Removed: , {prompt: true}
        } else {
          this.reset_route('signout');
          this.router.navigate(['/signon']);
        }
      }
    }
  }

  // Navigate to the appropriate settings page based on the current page when this method is called
  // Auto return will return to the original page if we're in an admin page
  doSettingsNavigation(): string {
    const curPageRef = this.getCurrentPageRef();
    let dest: string = 'admin_profile';
    switch (curPageRef) {
      case 'application': {
        dest ='admin_service';
        break;
      }
      case 'history': {
        dest ='admin_profile';
        break;
      }
      case 'report_inventory': {
        dest = 'admin_inventory';
        break;
      }
      default: {
        break;
      }
    }
    if (!!curPageRef) {
      this.doPageNavigation(dest, {rtn: curPageRef}, true);
    } else {
      this.doPageNavigation(dest, null, true);
    }
    return(dest);
  }

  // Only allow a certain length of page navigation, with 5 count hysteresis
  // _limitStackLength(maxLength: number = 25) {
  //   const numPages: number = this.root_nav.length();
  //   if ((numPages - maxLength - 5) > 1) {
  //     this.root_nav.remove(0, numPages - maxLength);
  //   }
  // }
}
