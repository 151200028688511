import {CLiCSColorSession} from "./session";
import {CLiCSClient} from "./client";

// Object describing a single timer, typically a rinse timer. This merely holds the timer information.

export class sessionTimer {
  css: string = null;  // token for the color session this timer is based on
  type: string = null;  // FUTURE: use to parse CS timers by timer type
  timerExpiresAt: Date = null;
  clientName: string = null;
  clientToken: string = null;
  fired: boolean = true;

  // NOTE: future implementations may parse timers from the CS based on "type"
  constructor(cs: CLiCSColorSession = null, client: CLiCSClient = null, type: string = 'rinse') {
    this.fired = false;
    if (!!cs) {
      this.css = cs.token;
      this.update(cs, client, type);
    }
  }

  // Update this timer from a color session. Returns false if the color session token does not match
  update(cs: CLiCSColorSession, client: CLiCSClient = null, type: string = 'rinse') {
    if (!cs || cs.token != this.css) {
      return false;
    }
    this.type = type;
    this.timerExpiresAt = new Date();
    this.timerExpiresAt.setTime(cs.processing_at.getTime() + cs.processing_time * 60000);  // minutes to ms
    if (!!client) {
      this.clientName = client.name();
      this.clientToken = client.token;
    } else {
      this.clientName = cs.client_name;
    }
    return true;
  }

  // Update this timer from a color session. Returns false if the color session token does not match
  // Timer object: { css: , type: 'rinse', expires_at:, client_name:, cli: }
  updateFromObj(obj: any) {
    if (!obj) {
      return false;
    }
    this.css = obj.css;
    this.type = obj.type;
    this.timerExpiresAt = new Date(obj.expires_at);
    this.clientName = obj.client_name;
    this.clientToken = obj.cli;
    return true;
  }

  clear() {
    this.css = null;
    this.timerExpiresAt = null;
    this.type = null;
    this.fired = true;  // avoid false "firing" for null timer record
  }

  // Is this timer expired (or unset)?
  isExpired(): boolean {
    if (!this.css || !this.timerExpiresAt) {
      return true;
    } else {
      return this.timerExpiresAt.getTime() < new Date().getTime();
    }
  }

  // Is this timer set and not yet expired?
  isActive(): boolean {
    if (!!this.css && !!this.timerExpiresAt) {
      return (this.timerExpiresAt.getTime() >= new Date().getTime());
    } else {
      return false;
    }
  }

  // Indicates that the timer has expired and was fired
  isTriggered(): boolean {
    if (!this.css || !this.timerExpiresAt) {
      return false;
    } else {
      return this.fired;
    }
  }

  // Returns true if the timer trigger has not yet been fired then clears the fired flag
  shouldTriggerTimer(): boolean {
    if (!this.fired && this.isExpired()) {
      this.fired = true;
      return true;
    } else {
      return false;
    }
  }
}
