// import {CLiCSColorApplication} from "./app";
import {CLiCSColorSession} from "./session";
import {CLiCSClientPhoto} from "./photo";
import {CLiCSClientNote} from "./client-note";
import {CLiCSApplication} from "./application-base";

export class CLiCSClient {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  nickname: string;
  photo_url: string;
  gender: string;
  birth_date: Date;
  cs: CLiCSColorSession = null;
  history: CLiCSColorSession[] = [];
  client_hair_profile: any = null;
  last_access: number = 0;
  token: string = null;  // This is the user-specific access token for this client record
  user_notes: CLiCSClientNote[] = [];
  photos: CLiCSClientPhoto[] = [];
  shared: boolean = false;  // If true this is not an owned client, but shared from another source
  default: boolean = false;
  role: string = null;

  constructor(dataObj = null) {
    this.loadObj(dataObj);
  }

  loadObj(dataObj: any): void {
    if (!!dataObj) {
      this.token = dataObj.token;
      this.first_name = dataObj.first_name;
      this.last_name = dataObj.last_name;
      this.nickname = dataObj.nickname;
      this.phone = dataObj.phone;
      this.email = dataObj.email;
      this.photo_url = dataObj.photo_url;
      this.client_hair_profile = dataObj.client_hair_profile;
      this.last_access = dataObj.last_access;
      this.cs = new CLiCSColorSession(dataObj.cs);
      if ('shared' in dataObj) {
        this.shared = dataObj.shared == true;
      }
      if ('default' in dataObj) {
        this.default = (dataObj.default == true);
      }
    }
  }

  // Loads this object from a JSON string
  load(dataStr: string): void {
    let dataObj = JSON.parse(dataStr);
    this.loadObj(dataObj);
  }

  loadHairProfile(new_profile: any) {
    if (new_profile.precolor == "" || new_profile.precolor == null)
      new_profile.precolor = false;
    this.client_hair_profile = new_profile;
  }

  setToken(token: string = null): void {
    this.token = token;
  }

  // Adds a single photo to this client object. photo is a data object as:
  // {token: string, full_url: string, thumb_url: string, when_taken: number, css: string, date_time: string}
  addPhoto(photo: any) {
    const index: number = this.photos.findIndex((el) => {
      return el.token == photo.token;
    });
    if (index < 0)
      this.photos.push(new CLiCSClientPhoto(photo));
  }

  // Adds an array of photos to this client
  addPhotos(photos: CLiCSClientPhoto[]) {
    for (let photo of photos) {
      this.addPhoto(photo);
    }
    this.sortPhotos();
  }

  // Sorts photos by date because, really, there's no other meaningful criteria. Oldest first.
  sortPhotos() {
    this.photos.sort((a, b) => {
      return (a.taken_at.getTime() - b.taken_at.getTime());
    });
  }

  // Returns true if the client has photos
  hasPhotos(): boolean {
    return this.photos.length > 0;
  }

  // Get the most recent photo for this client.
  lastPhoto(): CLiCSClientPhoto {
    if (this.hasPhotos()) {
      return (this.photos[this.photos.length - 1]);
    } else
      return null;
  }

  // Returns the photos for a given photo session as: {before, after, all []}
  sessionPhotos(colorSessionToken: string): any {
    let result = {before: null, after: null, all: []};
    const filtered: CLiCSClientPhoto[] = this.photos.filter((el) => el.css == colorSessionToken);
    for (let photo of filtered) {
      result.all.push(photo);
      if (photo.when_taken == 'before')
        result.before = photo;
      if (photo.when_taken == 'after')
        result.after = photo;
    }
    return result;
  }

  // Loads a color session for this client. Optionally ignore (reset) the token.
  loadColorSession(data: object, preserveToken: boolean = false): void {
    if (this.cs) {
      this.cs.clear(preserveToken);
      this.cs.loadObj(data, preserveToken);
    } else
      this.cs = new CLiCSColorSession(data, false);
  }

  // Load user notes as an array of { token: string, note: string }
  loadUserClientNotes(noteData: any) {
    this.user_notes.length = 0;
    for (let note of noteData) {
      this.user_notes.push(new CLiCSClientNote(note));
    }
  }

  // Load user notes as an array of { token: string, note: string, date: string }
  insertNote(newNote: any) {
    this.user_notes.unshift(new CLiCSClientNote(newNote));
  }

  // Load user notes as an array of { token: string, note: string, date: string }
  deleteNote(token: string) {
    let noteIndex = this.user_notes.findIndex((el) => {
      return (el.token == token);
    });
    if (noteIndex >= 0)
      this.user_notes.splice(noteIndex, 1);
  }

  // Returns note content by token
  getNote(token: string = null, requestIdent: string = ''): CLiCSClientNote {
    let foundNote: CLiCSClientNote = null;
    if (token != null) {
      foundNote = this.user_notes.find((el) => {
        return (el.token == token);
      });
    }
    if (foundNote == null) {
      foundNote = this.user_notes.find((el) => {
        return (el.requestIdent == requestIdent);
      });
    }
    return foundNote;
  }

  // Insert or update a note identified by token or request ident
  saveNote(modifiedNote: CLiCSClientNote): CLiCSClientNote {
    let localNote = this.getNote(modifiedNote.token, modifiedNote.requestIdent);
    if (localNote == null) {
      localNote = new CLiCSClientNote(modifiedNote);
      this.user_notes.unshift(localNote);
    } else {
      localNote.update(modifiedNote);
    }
    return localNote;
  }

  name(): string {
    if (this.nickname !== null && this.nickname != undefined && this.nickname.trim() != '')
      return `${this.first_name} \'${this.nickname}\' ${this.last_name}`;
    else
      return `${this.first_name} ${this.last_name}`;
  }

  // Adds an array of Color Sessions to the history array
  // {token: string, full_url: string, thumb_url: string, when_taken: number, css: string, date_time: string}
  addHistory(history: CLiCSColorSession[], clearFirst: boolean = false) {
    if (clearFirst) {
      this.history.length = 0;
      for (let cs of history)
        this.history.push(new CLiCSColorSession(cs));
    } else {
      // Check existing history and add if not present
      for (let cs of history) {
        let index: number = this.history.findIndex((el) => {
          return el.token == cs.token;
        });
        if (index < 0)
          this.history.push(new CLiCSColorSession(cs));
      }
    }

    // Sort by date, newest first
    this.history.sort((a, b) => {
      if (a.finished_at && b.finished_at)
        return (b.finished_at.getTime() - a.finished_at.getTime());
      else
        return (b.started_at.getTime() - a.started_at.getTime());
    });
  }

  // Are there history records recorded for this client?
  hasHistory(): boolean {
    return (this.history.length > 0);
  }

  // Returns a color session object matching the session token string, or null. Searches the current session (this.cs)
  // and the history array.
  findHistorySession(session_token: string) {
    let result = null;
    if (this.cs != null) {
      if (this.cs.token == session_token)
        result = this.cs;
      else {
        result = this.history.find((el) => {
          return (el.token == session_token);
        });
        if (result == undefined)
          result = null;
      }
    }
    return result;
  }

  // If a client hair profile exists, return the length value (string) from the profile
  hairLength(): any {
    if (this.client_hair_profile) {
      if ('length' in this.client_hair_profile)
        return (this.client_hair_profile['length']);
    }
    return null;
  }

  // Returns the first note assigned to this color session
  applicationNote(cs: CLiCSApplication): CLiCSClientNote {
    let result = this.user_notes.find((el) => { return el.css === cs.token });
    if (result == undefined) {
      result = null;
    }
    return result;
  }
}
