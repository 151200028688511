import { Injectable, Injector } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {CLiCSService} from "../clics.service";
import {PageControllerProvider} from "../page-controller/page-controller";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  clicsService: CLiCSService = null;
  pageController: PageControllerProvider = null;

  constructor(
    private router: Router,
    private injector: Injector
  ) {
    this.clicsService = this.injector.get(CLiCSService);
    this.pageController = this.injector.get(PageControllerProvider);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.clicsService.isLoggedIn() == false) {
      this.router.navigate((['/login']));
      return false;
    }
    if (this.clicsService.isSignedOn() == false) {
      this.router.navigate((['/signon']));
      return false;
    }
    if (!this.clicsService.current_salon) {
      this.router.navigate((['/salonselect']));
      return false;
    }
    return true;
  }
}
