/*
    The color session is publicly referred to as an "App" in this program. Apps refer to a library collection of
    formulas with a title, photo, etc. The color session, while also a collection of formulas (actually, formula
    requests on the back end) is intended for imminent dispensing and tracking. It is a LIVE application. It is what
    gets staged to the machine. On the back-end this maps directly to a ColorSession record (not a ColorApplication
    record) which is what gets staged to the machines.
 */
import {IonDatetime} from '@ionic/angular';
import {CLiCSColorFormula} from "./color-formula";
import {CLiCSFormulaRequest} from "./request";
import {CLiCSApplication} from "./application-base";
import {CLiCSFormula} from "./formula";

export class CLiCSColorSession extends CLiCSApplication {
  processing_time: number = 0;  // processing time (rinse time) in minutes
  processing_at: Date = null;   // Date time when rinse timer was set
  queued_at: Date = null;
  started_at: Date = null;
  finished_at: Date = null;
  updated_at: Date = null;
  client_name: string = null;
  formulas: Array<CLiCSFormulaRequest> = [];
  rinse_at: Date = null;
  before_photo_taken: boolean = false;
  after_photo_taken: boolean = false;
  event_timestamp: number = Date.now() / 1000;  // For retrieving session events

  constructor(data: any = null, preserveToken: boolean = false) {
    super(data, preserveToken);
    if (data != null) {
      if (typeof data == 'object')
        this.loadObj(data, preserveToken);
      else {
        if (typeof data == 'string') {
          this.loadStr(data, preserveToken)
        }
      }
    }
  }

  // Load data from another object. Optionally ignore token
  loadObj(data: any, preserveToken: boolean = false) {
    if (data !== undefined) {
      // this.clear(preserveToken);
      super.loadObj(data, preserveToken);
      if ('processing_time' in data)
        this.processing_time = data.processing_time;
      if ('processing_at' in data)
        this.processing_at = new Date(data.processing_at);
      this.setTimestamps(data);
      if ('client_name' in data)
        this.client_name = data.client_name;
      this.formulas.length = 0; // ### don't add to existing formulas
      if ('formulas' in data) {
        for (let fr of data.formulas) {
          let new_formula = (new CLiCSFormulaRequest(fr));
          new_formula.sanitizeLibraryFormula();  // detect and remove formTok value in .token field
          this.formulas.push(new_formula);
        }
      }
      if ('before_photo_taken' in data)
        this.before_photo_taken = data.before_photo_taken == true;
      if ('after_photo_taken' in data)
        this.after_photo_taken = data.after_photo_taken == true;
    }
  }

  // Either an edited version of this CS is passed or another CS/CA which is to be added to this session. Preserves
  // the token and any formulas that have been or are being dispensed.
  updateFromObject(data: any) {
    if (!!data) {
      if ('title' in data)
        this.title = data.title;
      if ('app_type_ident' in data)
        this.app_type_ident = data.app_type_ident;
      if ('app_type_name' in data)
        this.app_type_name = data.app_type_name;
      if ('processing_time' in data)
        this.processing_time = data.processing_time;
      if ('processing_at' in data)
        this.processing_at = new Date(data.processing_at);

      this.setTimestamps(data);

      if ('formulas' in data) {
        this.tagAllFormulas(); // ### tag for removal later
        for (let fr of data.formulas) {
          let matched_formula: CLiCSFormulaRequest = this.findFormula(fr.token, fr.request_ident);
          if (matched_formula) {
            matched_formula.tag = false;
            if (matched_formula.isDispensed() == false) {
              matched_formula.setAmount(fr.amount);
              matched_formula.setCobonded(fr.cobonded);
              matched_formula.setCoverage(fr.coverage);
              matched_formula.location = fr.location;
              matched_formula.ordinal = fr.ordinal;
              matched_formula.pedigree = fr.pedigree;
            }
          } else {
            let new_formula = new CLiCSFormulaRequest(fr);
            new_formula.sanitizeLibraryFormula();  // detect and remove formTok value in .token field
            this.formulas.push(new_formula);
          }
        }
      }
    }
  }

  setTimestamps(data: any) {
    if (data) {
      if ('queued_at' in data) {
        if (data.queued_at)
          this.queued_at = new Date(data.queued_at);
        else
          this.queued_at = null;
      }
      if ('started_at' in data) {
        if (data.started_at)
          this.started_at = new Date(data.started_at);
        else
          this.started_at = null;
      }
      if ('finished_at' in data) {
        if (data.finished_at)
          this.finished_at = new Date(data.finished_at);
        else
          this.finished_at = null;
      }
      if ('updated_at' in data) {
        if (data.updated_at) {
          this.updated_at = new Date(data.updated_at);
          this.setEventTimestamp(Math.ceil(this.updated_at.getTime() / 1000));
        } else
          this.updated_at = null;
      }
    }
  }

  // Adds or updates an formula in the CS
  saveAppFormula(formula: CLiCSColorFormula, amount: number = null, use_cobonder: boolean = false): CLiCSFormulaRequest {
    let new_formula: CLiCSFormulaRequest = null;
    if (formula.token != null && formula.token.length > 0) {
      new_formula = this.formulas.find((element) => {
        return (element.token == formula.token);
      });
    } else {
      if (formula.request_ident != null && formula.request_ident.length > 0) {
        new_formula = this.formulas.find((element) => {
          return (element.request_ident == formula.request_ident);
        });
      }
    }
    if (new_formula) {
      new_formula.loadFormula(formula);
    } else {
      new_formula = new CLiCSFormulaRequest(formula);
      if (formula.mode != 'library') {
        new_formula.setAmount(formula.totalAmount());  // TODO: lookup default location and amounts
      }
      // If a new formula then add co-bonder if option is set
      if (use_cobonder)
        new_formula.setCobonded(true);
      this.formulas.push(new_formula);
    }
    if (amount)
      new_formula.setAmount(amount);
    if (new_formula.mode == 'library') {
      new_formula.sanitizeLibraryFormula();
    }
    return new_formula;
  }

  // Push a new formula to this Color Session
  addFormula(formula: CLiCSFormula, pedigree: string = null) {
    let fr = new CLiCSFormulaRequest(formula);
    if (!!pedigree && !fr.pedigree) {
      fr.pedigree = pedigree;
    }
    this.formulas.push(fr);
  }

  // Clears the data from this app / color session
  clear(preserveToken: boolean = false) {
    super.clear(preserveToken);
    this.processing_time = 0.0;
    this.queued_at = null;
    this.started_at = null;
    this.finished_at = null;
    this.updated_at = null;
    this.formulas.length = 0;
  }

  // Disassociates this CS from a server instance - use when copying this object as new from another CS
  unlink() {
    this.token = '';
    this.processing_time = 0.0;
    this.queued_at = null;
    this.started_at = null;
    this.finished_at = null;
    for (let formula of this.formulas) {
      formula.unlink();
    }
  }

  // Return true if this is queued
  isQueued(): boolean {
    return this.queued_at !== null && this.formulas.length > 0;
  }

  // Has dispensing started for this Color Session?
  isStarted(): boolean {
    return this.isQueued() && this.started_at != null;
  }

  // Has dispensing completed for this Color Session?
  isFinished(): boolean {
    return this.finished_at != null;
  }

  // Returns true if no token or formulas
  isBlank(): boolean {
    return (this.token == null && this.formulas.length == 0);
  }

  // Returns true if no token
  isAnonymous(): boolean {
    return (this.token == null);
  }

  isEmpty(): boolean {
    return (this.formulas.length == 0);
  }

  // Compares a timestamp from the API to this updated_at timestamp
  isCurrentAsOf(timestamp: Date): boolean {
    if (this.updated_at == null)
      return false;  // reload this CS
    const local = +this.updated_at;
    const remote = +new Date(timestamp);
    if (isNaN(local) || isNaN(remote))
      return false;  // reload this CS
    else
      return local >= remote;
  }

  sessionDateStr(): string {
    let result = "";
    if (this.finished_at != null) {
      result = `${this.finished_at.getMonth() + 1}/${this.finished_at.getDate()}/${this.finished_at.getFullYear() - 2000}`;
    }
    return result;
  }

  // Sets updated at to Now (time zone aware)
  touch() {
    this.updated_at = new Date(new Date().getTime());
  }

  // Sets updated at to Now ONLY in the absence of a set value
  softTouch() {
    if (this.updated_at == null)
      this.updated_at = new Date(new Date().getTime());
  }

  // Searches this CS for a formula matching the passed token or request_ident (FRs only)
  findFormula(token: string, request_ident: string = null): CLiCSFormulaRequest {
    const index = this.findFormulaIndex(token, request_ident);
    if (index >= 0)
      return this.formulas[index];
    else
      return null;
  }

  // Return an ionicon name based on the status of this session
  statusIcon(): string {
    if (this.finished_at)
      return "remove";
    else
      return "warning";
  }

  // Sets the processing time
  setProcessingTime(processingMinutes: number, processingStart: Date = null) {
    if (!!processingMinutes && processingMinutes > 0) {
      this.processing_time = Math.floor(processingMinutes);
      if (!!processingStart)
        this.processing_at = new Date(processingStart.getTime());
      else
        this.processing_at = new Date(Date.now());
    } else {
      this.processing_time = null;
      this.processing_at = null;
    }
  }

  rinseTimerSet(): boolean {
    return (this.processing_time && this.processing_time > 0 && !!this.processing_at);
  }

  // How many minutes until it's time to rinse? Based on first dispensing for now
  minutesUntilRinse(): number {
    if (this.rinseTimerSet()) {
      const now = new Date().getTime();
      const rinseAt = this.processing_at.getTime() + (this.processing_time * 60000);
      let result = (rinseAt - now) / 60000;
      if (result < 0)
        result = 0;
      return Math.round(result);
    } else {
      return 0;
    }
  }

  // Checks the local formula (does not reload from server) and sets or clears the finished_at field accordingly
  checkFinished(): boolean {
    let finished: boolean = true;
    for (let formula of this.formulas) {
      if (formula.isDispensed() == false) {
        finished = false;
        break;
      }
    }
    if (finished && this.finished_at == null)
      this.finished_at = new Date();
    else {
      if (!finished && this.finished_at)
        this.finished_at = null;
    }
    return this.isFinished();
  }

  // Updates the ordinals in order of the formulas in the array
  // UPDATE: respects grouped formulas (group_id > 0)
  reindex() {
    let ord = 1;
    let groups: any[] = [];  // track ordinals used in grouped formulas {groupID: ordinal:}
    // Automatically add next groupID to specially-tagged formulas
    if (this.hasGroups(true)) {
      this.groupUngroupedFormulas(this.nextGroup());
    }
    for (let formula of this.formulas) {
      if (!!formula.group_id && formula.group_id > 0) {
        let matched = groups.find(el => el.group_id == formula.group_id);
        if (!!matched) {
          formula.ordinal = matched.ordinal;
        } else {
          formula.ordinal = ord;
          groups.push({group_id: formula.group_id, ordinal: ord});
          ord += 1;
        }
      } else {
        formula.ordinal = ord;
        ord += 1;
      }
    }
  }

  tagAllFormulas() {
    for (let formula of this.formulas) {
      formula.tag = true;
    }
  }

  // Sets the event Timestamp with the passed Unix timestamp or "now"
  setEventTimestamp(externalTimestamp: number = null) {
    let time: any = null;
    if (this.event_timestamp == undefined)
      this.event_timestamp = 0;
    if (externalTimestamp == null)
      this.event_timestamp = Math.ceil(Date.now() / 1000);
    else if (externalTimestamp > this.event_timestamp)
      this.event_timestamp = externalTimestamp;
  }

  isColorSession() {
    return true;
  }

  // For use on History page, the amount (requested) should be the actual amount dispensed. When it's used it will
  // use the dispensed amount.
  makeHistorical() {
    for (let fr of this.formulas) {
      if (!!fr.dispensed && fr.dispensed > 0) {
        fr.amount = fr.dispensed;
      }
    }
  }

  // Returns an array of Formula Requests that includes only the first of any grouped formulas
  groupedFormulas() {
    let usedGroups: number[] = [];
    let groupedFormulas = [];
    for (let fr of this.formulas) {
      if (fr.group_id > 0) {
        if (!usedGroups.includes(fr.group_id)) {
          usedGroups.push(fr.group_id);
          groupedFormulas.push(fr);
        }
      } else {
        groupedFormulas.push(fr);
      }
    }
    groupedFormulas.sort((a, b) => {
      if (a.ordinal > b.ordinal)
        return 1;
      else {
        if (a.ordinal < b.ordinal)
          return -1;
      }
      return 0;
    } );
    return groupedFormulas;
  }

  // Returns other formulas in the group that formula is in. Inclusive also includes the passed formula.
  groupMates(formula: CLiCSFormulaRequest, inclusive = false): CLiCSFormulaRequest[] {
    let result = [];
    if (formula.group_id > 0) {
      result = this.formulas.filter(el => { return el.group_id == formula.group_id && el.token != formula.token });
    }
    if (inclusive) {
      result.push(formula);
    }
    return result;
  }

  // Change total amount of formulas in a group with formula, or for all groups if formula is null
  limitGroupTotal(formula: CLiCSFormula = null, limit: number = 300.0) {
    let groupedFormulas: CLiCSFormula[] = [];
    if (!!formula) {
      groupedFormulas = [formula];
    } else {
      groupedFormulas = this.groupedFormulas();
    }

    // Find the total amount for the grouped formulas
    for (let rootFormula of groupedFormulas) {
      if (rootFormula.group_id > 0) {
        let group = this.formulas.filter(el => el.group_id == rootFormula.group_id);
        let totalAmount = 0.0;
        for (const fr of group) {
          totalAmount += fr.amount;
        }
        if (totalAmount > limit) {
          const adjustment: number = limit / totalAmount;
          for (let fr of group) {
            fr.setAmount(fr.amount * adjustment);
            fr.adjustParamsFromAmount();
          }
        }
      }
    }
  }

  // Sets the total amount for a group, defined by any single group formula, adjusting each formula
  setGroupTotal(rootFormula: CLiCSFormula, newAmount: number) {
    let groupedFormulas: CLiCSFormula[] = [];
    if (!rootFormula) {
      return groupedFormulas;
    }
    if (rootFormula.group_id == 0) {
      groupedFormulas = [rootFormula];
      rootFormula.setAmount(newAmount);
      rootFormula.adjustParamsFromAmount();
    } else {
      // Find the total amount for the grouped formulas
      groupedFormulas = this.formulas.filter(el => el.group_id == rootFormula.group_id);
      let totalAmount = 0.0;
      for (const fr of groupedFormulas) {
        totalAmount += fr.amount;
      }
      const adjustment: number = newAmount / totalAmount;
      for (let fr of groupedFormulas) {
        fr.setAmount(fr.amount * adjustment);
        fr.adjustParamsFromAmount();
      }
    }
    return groupedFormulas;
  }

  // Returns true if one or more grouped formulas totals more than the amount that will fill in teh bowl
  hasOverweightGroup(allowableAmount: number = 300.0): boolean {
    let result = false;
    const grouped = this.groupedFormulas();
    for (const formula of grouped) {
      let totalAmount = 0.0;
      if (formula.group_id > 0) {
        for (const fr of this.formulas) {
          if (fr.group_id == formula.group_id) {
            totalAmount += fr.amount;
          }
        }
      }
      if (totalAmount > allowableAmount) {
        result = true;
        break;
      }
    }
    return result;
  }

  className(): string {
    return 'CLiCSColorSession'
  }
}
