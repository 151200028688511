
export class CLiCSClientNote {
    token: string = null;
    noteText: string = '';
    noteDate: Date = null;
    requestIdent: string = null;
    css: string = null;  // Color Session token

    // Handle existing server date or another ClientNote object
    constructor(dataObj: any = null, preserveToken: boolean = false) {
        if (dataObj)
            this.load(dataObj, preserveToken);
        this.assertLocalIdent();
    }

    load(dataObj: any, preserveToken: boolean = false) {
        if (dataObj != null) {
            if (preserveToken == false && 'token' in dataObj)
                this.token = dataObj.token;
            if ('note' in dataObj)
                this.noteText = dataObj.note;
            if ('noteText' in dataObj)
                this.noteText = dataObj.noteText;
            if ('date' in dataObj)
                this.noteDate = new Date(dataObj.date);
            if ('noteDate' in dataObj)
                this.noteDate = new Date(dataObj.noteDate);
            if ('requestIdent' in dataObj)
                this.requestIdent = dataObj.requestIdent;
            if ('css' in dataObj)
                this.css = dataObj.css;
        }
        this.assertLocalIdent();
    }

    // Loads this object from a JSON string
    update(dataObj: any, preserveToken: boolean = false): void {
        if (dataObj != null) {
            if (preserveToken == false && 'token' in dataObj)
                this.token = dataObj.token;
            if ('note' in dataObj)
                this.noteText = dataObj.note;
            if ('noteText' in dataObj)
                this.noteText = dataObj.noteText;
            if ('date' in dataObj)
                this.noteDate = new Date(dataObj.date);
            if ('noteDate' in dataObj)
                this.noteDate = new Date(dataObj.noteDate);
            if ('css' in dataObj)
                this.css = dataObj.css;
        }
    }

    // Sets a local identifier. This is used to match server responses to the local CLiCSColorFormula object
    assertLocalIdent(regenerate: boolean = false) {
        if (this.requestIdent == null || regenerate) {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            this.requestIdent = text;
        }
        return this;
    }

    // Set the token but avoid setting a token value back to null
    setToken(newToken: string) {
        if (this.token == null || this.token == '' || newToken != null)
            this.token = newToken;
    }

    setText(newText: string) {
        this.noteText = newText.trim();
    }

    setDate(newDate: Date = null) {
        if (newDate)
            this.noteDate = newDate;
        else {
            if (this.noteDate == null)
                this.noteDate = new Date();
        }
    }

    // Sets a Color Session token to this client note
    setCss(css: string) {
      this.css = css;
    }
}
