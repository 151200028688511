import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {ColorFormulaToRGB} from 'colorformulatorgb/ColorFormulaToRGB';
import {ComponentsModule} from '../../components/components.module';
import {CreateColorPage} from './create-color';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [CreateColorPage],
  providers: [
    NavParams,
    Keyboard,
    TapticEngine,
    ColorFormulaToRGB,
  ],
})
export class CreateColorPageModule {}
