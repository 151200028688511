import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IonContent} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {CLiCSColorFormula} from '../../../lib/color-formula';

/**
 * Displays a single color "chiclet" for use in grid layout (e.g. Lab Colors page). If clicked the selected flag
 * toggles, showing or hiding a border.
 */

@Component({
  selector: 'color-grid-image',
  templateUrl: 'color-grid-image.html',
  styleUrls: ['color-grid-image.scss'],
})
export class ColorGridImageComponent {
  @Input() formula: CLiCSColorFormula = null;
  @Input() content: IonContent = null;
  @Output() selectFormula = new EventEmitter();
  @Output() inspectFormula = new EventEmitter();

  selected: boolean = true;
  tDown: number = 0;
  tUp: number = 0;
  lastUpTime: number = 0;
  longPressTimer: any = null;
  scrollTop: number = null;

  constructor(private sanitizer: DomSanitizer, private taptic: TapticEngine) {
  }

  tileBgStyle() {
    if (!!this.formula) {
      let html = 'background-color: ' + this.formula.rgb;
      return this.sanitizer.bypassSecurityTrustStyle(html);
    } else {
      return '';
    }
  }

  toggleSelected() {
    this.selectFormula.emit(this.formula.token);
  }

  openFormula(token: string = null) {
    this.longPressTimer = null;
    this.tDown = 0;
    if (!!token) {
      this.inspectFormula.emit(token);
    } else {
      this.inspectFormula.emit(this.formula.token);
    }
  }

  async mouseDown() {
    this.tDown = new Date().getTime();
    this.longPressTimer = setTimeout(() => {this.handleLongPress();}, 450);
    this.scrollTop = await this.getScrollTop();
  }

  mouseUp() {
    // Clear inspection timeout (long-press)
    if (!!this.longPressTimer) {
      clearTimeout(this.longPressTimer);
      this.longPressTimer = null;
    }
    this.tUp = new Date().getTime();
    if (this.tDown > 0) {
      if (this.tDown < this.tUp) {
        const delay = this.tUp - this.tDown;
        if (delay < 400) {
          this.toggleSelected();
          // Was this a double-tap?
          if (this.tUp - this.lastUpTime < 300) {
            this.openFormula();
          }
        } else {
          this.openFormula();
        }
      } else {
        this.toggleSelected();
      }
    }
    this.lastUpTime = this.tUp;
  }

  async getScrollTop(): Promise<number> {
    if (!!this.content) {
      const scrollElement = await this.content.getScrollElement();
      return scrollElement.scrollTop;
    } else {
      return 0;
    }
  }

  // calls openFormula if a long press but scrolling has not happened
  async handleLongPress() {
    this.longPressTimer = null;
    this.tDown = 0;
    const newTop = await this.getScrollTop();
    if (Math.abs(this.scrollTop - newTop) < 10) {
      this.taptic.selection();
      this.openFormula(this.formula.token);
    }
  }

//Changing font size for cgi label depending on the length of the name
  dynamicFont(formulaName: String = "") { 
    if (formulaName.length <= 5) {
      return 'cgi-title short';
    } else if (formulaName.length <= 10 ) {
      return 'cgi-title med';
    } else if (formulaName.length <= 16) {
      return 'cgi-title long';
    } else {
      return 'cgi-title ';
    }
  }  

}
