import {Component, ViewChild} from '@angular/core';
import {NavController, NavParams, Platform, ModalController, IonInput, AlertController} from '@ionic/angular';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {CLiCSService} from '../../services/clics.service';
import {PageControllerProvider} from '../../services/page-controller/page-controller';
import {FlashMessage} from '../../../lib/flash';
import {CLiCSUser} from '../../../lib/user';

/**
 * Generated class for the LoginPasswordPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-login-password',
  templateUrl: 'login-password.html',
  styleUrls: ['login-password.scss'],
})
export class LoginPasswordPage {
  @ViewChild('loginPwd') loginPwd: IonInput;

  flash: FlashMessage = null;
  user: CLiCSUser = null;
  in_recovery: boolean = false;
  recovery_done: boolean = false;

  email: string = "";
  password: string = null;
  show_password: boolean = false;

  app_mode: string = '';

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private pageCtrl: PageControllerProvider,
    private platform: Platform,
    private keyboard: Keyboard,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private clicsService: CLiCSService)
  {
    this.flash = this.clicsService.flash;
  }

  ionViewWillEnter() {
    this.user = this.clicsService.current_user;
    this.app_mode = this.clicsService.app_mode;
    if (this.app_mode == null)
      this.app_mode = 'personal';
  }

  doLogin(): void {
    this.modalCtrl.dismiss({cancelled: false, email: this.email, password: this.password});
  }

  isLoggedIn(): boolean {
    return this.clicsService.isLoggedIn();
  }

  doLogOut(): void {
    if (confirm('Are you sure you want to log out?')) {
      this.clicsService.apiLogOut();
      this.clicsService.isLoggedIn();
    }
  }

  showRecovery() {
    this.flash.clear();
    this.in_recovery = true;
  }

  // Request recovery email
  doRecover(email: any) {
    // TODO: add email validation here
    this.email = email;  // Used in recovery confirm page
    this.clicsService.apiRecoverLogin(email).then(async (result) => {
      if (!!result) {
        this.recovery_done = true;
      } else {
        const alert = await this.alertCtrl.create({
          header: 'Unable to Reset Password',
          message: `Sorry, we were unable to find that email address. Please check the email and try again.`,
          buttons: [
            {
              text: 'Ok',
              role: 'cancel'
            }
          ],
          mode: 'ios'
        });
        await alert.present();
      }
    });
  }

  closeKbdIfDone(event, nextField = false, submit = false) {
    if (event.key == 'Enter') {
      if (this.platform.is('cordova') || this.platform.is('ios') || this.platform.is('android')) {
        this.keyboard.hide();
      }
      if (nextField) {
        this.loginPwd.setFocus();
      } else {
        if (submit) {
          this.doLogin();
        }
      }
      return false;
    }
    return true;
  }

  // Password manager form-fill does not trigger [(ngModel)] change event reliably.
  recordPwd(event: any) {
    this.password = event.target.value;
  }

  // Sets the email string from the form input
  recordEmail(event: any) {
    this.email = event.target.value;
  }

  // Returns the correct logo image file for the currently selected theme
  logoImage() {
    if (this.clicsService.theme == 'dark')
      return 'large_logo_white.png';
    else
      return 'large_logo.png';
  }

  cancel() {
    this.modalCtrl.dismiss({cancelled: true, email: '', password: ''})
  }

  togglePasswordVisible() {
    this.show_password = !this.show_password;
  }

}
