import {CLiCSUser} from "./user";
import {CLiCSColorApplication} from "./color-application";
import {CLiCSColorFormula} from "./color-formula";
import {CLiCSColorSession} from "./session";
import {CLiCSFormulaRequest} from "./request";
import {CLiCSFormula} from "./formula";

/*
 Describes a data object (Color Session/"APP" or Formula) that is queued for editing in the app. This allows
 items to be passed between contexts, grouped by association, and navigated between.
 */

export class EditQueueItem {
    token: string = null;       // random string used to identify this item
    itemObject: any;
    userToken: string = null;
    clientToken: string = null;
    objectType: string;
    action: string = null;      // Intended: customize (edit & back), new, edit
    library: boolean = false;   // is this a referenced library item?
    owned: boolean = false;     // does the user own this item? Useful for library items.
    edited: boolean = false;    // has this been imported into an editor?
    canceled: boolean = false;  // if an edit was cancelled (typically has no effect)
    changed: boolean = false;   // has the value changed since last used?
    saved: boolean = false;     // has this been saved to a library since edited?
    used: boolean = false;      // has this been "used" since edited?
    belongs_to: string = null;  // token of a container object, typically an APP
    topNav: string = null;
    pageNav: string = null;

    // Create an instance with data object, referenced user, intended action
    constructor(itemObj: any, action: string = null, user: CLiCSUser = null, topNav: string = null, pageNav: string = null, clientToken: string = null) {
        this.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.itemObject = EditQueueItem.copyObject(itemObj);  // create a copy of the object
        this.setAction(action, 'NEW');
        if (user)
            this.userToken = user.api_token;
        this.topNav = topNav;
        this.pageNav = pageNav;
        this.clientToken = clientToken;
        this.setTypeFromObject();
    }

    // Set the objectType field from the object itself
    setTypeFromObject() {
        let type: string = "???";
        if (this.itemObject != null) {
            type = "" + this.itemObject.className();
        }

        switch (type) {
            case "CLiCSColorApplication":
                this.objectType = "APP";
                break;
            case "CLiCSColorSession":
                this.objectType = "APP";
                break;
            case "CLiCSFormulaRequest":
                this.objectType = "FORMULA";
                this.library = this.itemObject.mode == 'library';
                break;
            case "CLiCSFormula":
                this.objectType = "FORMULA";
                break;
            case "CLiCSColorFormula":
                this.objectType = "FORMULA";
                this.library = true;
                break;
            default:
                this.objectType = "UNKNOWN";
                break;
        }
    }


    // Returns the token of the itemObject
    objectToken(): string {
        return this.itemObject.token;
    }

    // Update the action parameter if a non-null string is passed. Use a default if newAction is blank and default
    // action is provided.
    setAction(newAction: string, defaultAction: string = null): EditQueueItem {
        if (!!newAction) {
            this.action = newAction.toUpperCase();
        }
        else {
            if (defaultAction != null && newAction.length != 0)
                this.action = defaultAction.toUpperCase();
        }
        return this;
    }

    // Update the token for the container object (belongs_to)
    setOwnerObject(containerToken: string): EditQueueItem {
        if (containerToken != null && containerToken.length != 0) {
            this.belongs_to = containerToken;
        }
        return this;
    }

    // Update the token for the container object (belongs_to)
    setClientToken(clientToken: string): EditQueueItem {
        if (clientToken != null && clientToken.length != 0) {
            this.clientToken = clientToken;
        }
        return this;
    }

    // Call this whenever a change is made to the item object
    touch(): void {
        this.changed = true;
        this.saved = false;
        this.used = false;
    }

    // When pulled into an editor, reset the state in order to track changes and usage. Returns itself
    startEdit(): EditQueueItem {
        this.edited = true;
        this.used = false;
        this.changed = false;
        return(this);
    }

    // The update method creates a copy of th
    finishEdit(): EditQueueItem {
        this.edited = true;
        this.changed = true;
        return(this);
    }

    cancelEdit(): EditQueueItem {
        this.edited = true;
        this.changed = false;
        this.canceled = true;
        return(this);
    }

    // When pulled back into a USE context (e.g. client APP), reset the state to track changes. Returns itself
    startUse(): EditQueueItem {
        this.used = true;
        return(this);
    }

    // Update the underlying data object with a COPY of the item
    update(itemObj: any, topNav: string = null, pageNav: string = null, action: string = null): EditQueueItem {
        this.itemObject = EditQueueItem.copyObject(itemObj, this.itemObject);
        this.setTypeFromObject();
        this.changed = false;
        this.setAction(action);
        if (topNav != null)
            this.topNav = topNav;
        if (pageNav != null)
            this.pageNav = pageNav;
        return this;
    }

    // Copies data and status from passed edit item, leaving the token and navigation intact
    updateFromItem(editItem: EditQueueItem) {
        if (this.token == editItem.token) {
            this.itemObject = editItem.itemObject;
            this.objectType = editItem.objectType;
            this.edited = editItem.edited;
            this.canceled = editItem.canceled;
            this.changed = editItem.changed;
            this.saved = editItem.changed;
            this.used = editItem.used;
        }
        else {
            throw(`EditQueue::updateFromItem() - attempt to update from a foreign Edit Queue Item`);
        }
    }

    // Returns a copy of a passed object, using constructor for known classes or generic JSON method otherwise. An
    // optional referenceObject may be passed to assert the type that the itemObject should be converted to.
    static copyObject(itemObject: any, referenceObject: any = null, targetClassName: string = null): any {
        let result: any = null;
        let type: string = "" + itemObject.className();

        // Determine class of new object
        if (type != 'EditQueueItem') {
            if (referenceObject == null)
                type = targetClassName == null ? type : targetClassName;
            else
                type = "" + referenceObject.className();
        }

        switch (type) {
            case "CLiCSColorApplication":
                result = new CLiCSColorApplication(itemObject);
                break;
            case "CLiCSColorSession":
                result = new CLiCSColorSession(itemObject);
                break;
            case "CLiCSFormulaRequest":
                result = new CLiCSFormulaRequest(itemObject);
                break;
            case "CLiCSFormula":
                result = new CLiCSFormula(itemObject);
                break;
            case "CLiCSColorFormula":
                result = new CLiCSColorFormula(itemObject);
                break;
            case "EditQueueItem":
                result = new EditQueueItem(
                    EditQueueItem.copyObject(itemObject.itemObject, null, targetClassName),
                    itemObject.action, null,
                    itemObject.topNav,
                    itemObject.pageNav,
                    itemObject.clientToken
                );
                result.token = itemObject.token;
                result.userToken = itemObject.userToken;
                break;
            // default:
            //     throw 'EditQueueItem::copyObject() - unfamiliar object class: ' + type;
        }
        return(result);
    }

    // Return navigation object or NULL
    getNavigation(): any {
        if (this.topNav && this.pageNav)
            return {top: this.topNav, page: this.pageNav};
        else
            return null;
    }

    // Returns last 5 of the token
    shortToken(): string {
        return this.token.substr(this.token.length - 5);
    }

    // Returns a string representation of this object
    toString(): string {
        let result: string = `${this.objectType} (${this.token.substr(this.token.length - 5)}): ${this.action} [${this.topNav}:${this.pageNav}] `;
        if (this.objectType == 'APP')
            result += `- ${this.itemObject.formulas.length} formulas`;
        return (result);
    }

}

