/* Keeps content for this app. Content categorized as page or help content and indexed by page identifier from
 * page controller. */

export class CLiCSContent {
    content: any = []; // array of { contentType: string, pageName: string, content: string }

    constructor(contentData: any = null) {
        if (contentData) {
            if (contentData.content != undefined)
                this.content = contentData.content;
            else {
                this.loadContent(contentData);
            }
        }
    }

    // Loads a specific content type from a data array, taking an array of { contentType: string, pageName: string,
    // content: string }
    loadContentByType(contentArray: any[], contentType: string) {
        for (let content of contentArray) {
            if (content.contentType == contentType) {
                if (this._updateExisting(content) == false)
                    this.content.push(
                        {
                            contentType: content.contentType,
                            pageName: content.pageName,
                            content: content.content
                        });
            }
        }
    }

    // Loads this from a data object, taking an array of { pageName: string, content: string }
    loadContent(contentArray: any[]) {
        for (let content of contentArray) {
            if (this._updateExisting(content) == false)
                this.content.push(
                    {
                        contentType: content.contentType,
                        pageName: content.pageName,
                        content: content.content
                    });
        }
    }

    // Retrieve page content by type and page name
    pageContent(pageName: string, contentType: string = 'help'): string {
        let index = this.content.findIndex((el) => {
            return (el.contentType == contentType && el.pageName == pageName);
        });
        if (index >= 0)
            return(this.content[index].content);
        else
            return('');
    }i

    // Is this object empty?
    empty(): boolean {
        return(this.content.length == 0);
    }

    // If an existing record matching contentType and pageName is found then update it and return true
    _updateExisting(contentData: any): boolean {
        let index = this.content.findIndex((el) => {
            return (el.contentType == contentData.contentType && el.pageName == contentData.pageName);
        });
        if (index >= 0) {
            this.content[index] =
                {
                    contentType: contentData.contentType,
                    pageName: contentData.pageName,
                    content: contentData.content
                };
            return true;
        }
        else
            return false;
    }
}
