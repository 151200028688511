import {NgModule} from '@angular/core';
import {Routes, RouterModule, RouterLink, PreloadAllModules} from '@angular/router';
import {AuthGuardService} from "./services/auth-guard/auth-guard.service";
import {ClientGuardService} from "./services/client-guard/client-guard.service";

export const routes: Routes = [
  { path: '', redirectTo: 'clients', pathMatch: 'full' },
  { path: 'home', redirectTo: 'clients', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'signon', loadChildren: () => import('./pages/signon/signon.module').then(m => m.SignonPageModule) },
  { path: 'signout', loadChildren: () => import('./pages/signon/signon.module').then(m => m.SignonPageModule) },
  { path: 'salonselect', loadChildren: () => import('./pages/salon-select/salon-select.module').then(m => m.SalonSelectPageModule) },
  { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsPageModule), canActivate: [AuthGuardService] },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule), canActivate: [ClientGuardService] }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'application', loadChildren: () => import('./pages/application/application.module').then(m => m.ApplicationPageModule), canActivate: [ClientGuardService]  },  // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'application_info', loadChildren: () => import('./pages/application-info/application-info.module').then(m => m.ApplicationInfoPageModule) },
  { path: 'history', loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule), canActivate: [ClientGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'pics', loadChildren: () => import('./pages/pics/pics.module').then(m => m.PicsPageModule), canActivate: [ClientGuardService] }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  // { path: 'createmenu', loadChildren: () => import('./pages/create-menu/create-menu.module').then(m => m.CreateMenuPageModule), canActivate: [AuthGuardService]  },
  { path: 'collections_menu', loadChildren: () => import('./pages/collections-menu/collections-menu.module').then(m => m.CollectionsMenuPageModule), canActivate: [AuthGuardService] }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  // { path: 'convert', loadChildren: () => import('./pages/conversion-library/conversion-library.module').then(m => m.ConversionLibraryPageModule), canActivate: [AuthGuardService] }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_clics', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_colorance', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_topchic', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_goldwell', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_color', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_convert', loadChildren: () => import('./pages/lab-color/lab-color.module').then(m => m.LabColorPageModule), canActivate: [AuthGuardService]  }, // MIGRATION NOTE: may need adjustment, check after reconciling page-controller
  { path: 'lab_app', loadChildren: () => import('./pages/lab-app/lab-app.module').then(m => m.LabAppPageModule), canActivate: [AuthGuardService]  },
  { path: 'queue', loadChildren: () => import('./pages/queue/queue.module').then(m => m.QueuePageModule), canActivate: [AuthGuardService]  },
  { path: 'quick', loadChildren: () => import('./pages/quick/quick.module').then(m => m.QuickPageModule), canActivate: [AuthGuardService]  },
  { path: 'dispensed', loadChildren: () => import('./pages/dispense-history/dispense-history.module').then(m => m.DispenseHistoryPageModule)  },

  { path: 'lookbook_salon', loadChildren: () => import('./pages/lookbook-salon/lookbook-salon.module').then(m => m.LookbookSalonPageModule) },
  { path: 'lookbook_apps', loadChildren: () => import('./pages/lookbook-apps/lookbook-apps.module').then(m => m.LookbookAppsPageModule) },
  { path: 'lookbook_clients', loadChildren: () => import('./pages/lookbook-clients/lookbook-clients.module').then(m => m.LookbookClientsPageModule) },
  { path: 'lookbook_colors', loadChildren: () => import('./pages/lookbook-colors/lookbook-colors.module').then(m => m.LookbookColorsPageModule) },
  { path: 'lookbook_cover', loadChildren: () => import('./pages/lookbook-cover/lookbook-cover.module').then(m => m.LookbookCoverPageModule) },
  { path: 'lookbook_featured', loadChildren: () => import('./pages/lookbook-featured/lookbook-featured.module').then(m => m.LookbookFeaturedPageModule) },
  { path: 'lookbook_following', loadChildren: () => import('./pages/lookbook-following/lookbook-following.module').then(m => m.LookbookFollowingPageModule) },
  { path: 'lookbook_trending', loadChildren: () => import('./pages/lookbook-trending/lookbook-trending.module').then(m => m.LookbookTrendingPageModule) },

  { path: 'admin_profile', loadChildren: () => import('./pages/admin-profile/admin-profile.module').then(m => m.AdminProfilePageModule) },
  { path: 'admin_service', loadChildren: () => import('./pages/admin-service/admin-service.module').then(m => m.AdminServicePageModule) },
  { path: 'admin_inventory', loadChildren: () => import('./pages/admin-inventory/admin-inventory.module').then(m => m.AdminInventoryPageModule) },
  { path: 'admin_dispenser', loadChildren: () => import('./pages/admin-dispenser/admin-dispenser.module').then(m => m.AdminDispenserPageModule) },
  { path: 'admin_equipment', loadChildren: () => import('./pages/admin-equipment/admin-equipment.module').then(m => m.AdminEquipmentPageModule) },
  { path: 'admin_lookbook', loadChildren: () => import('./pages/admin-lookbook/admin-lookbook.module').then(m => m.AdminLookbookPageModule) },
  { path: 'admin_salon', loadChildren: () => import('./pages/admin-salon/admin-salon.module').then(m => m.AdminSalonPageModule) },
  { path: 'admin_stylists', loadChildren: () => import('./pages/admin-stylists/admin-stylists.module').then(m => m.AdminStylistsPageModule) },
  { path: 'admin_history', loadChildren: () => import('./pages/admin-history/admin-history.module').then(m => m.AdminHistoryPageModule) },

  { path: 'inventory_full', loadChildren: () => import('./pages/inventory-full-intro/inventory-full-intro.module').then(m => m.InventoryFullIntroPageModule) },
  { path: 'inventory_loop', loadChildren: () => import('./pages/inventory-loop/inventory-loop.module').then(m => m.InventoryLoopPageModule) },
  { path: 'inventory_confirm', loadChildren: () => import('./pages/inventory-full-confirm/inventory-full-confirm.module').then(m => m.InventoryFullConfirmPageModule) },

  { path: 'report_clients', loadChildren: () => import('./pages/report-clients/report-clients.module').then(m => m.ReportClientsPageModule), canActivate: [AuthGuardService] },
  { path: 'report_inventory', loadChildren: () => import('./pages/report-inventory/report-inventory.module').then(m => m.ReportInventoryPageModule), canActivate: [AuthGuardService] },
  { path: 'report_register', loadChildren: () => import('./pages/report-register/report-register.module').then(m => m.ReportRegisterPageModule), canActivate: [AuthGuardService] },
  { path: 'report_client_history', loadChildren: () => import('./pages/report-client-history/report-client-history.module').then(m => m.ReportClientHistoryPageModule), canActivate: [AuthGuardService] },
  { path: 'report_stylist_history', loadChildren: () => import('./pages/report-stylist-history/report-stylist-history.module').then(m => m.ReportStylistHistoryPageModule), canActivate: [AuthGuardService] },
  { path: 'report_client_review_history', loadChildren: () => import('./pages/report-client-review-history/report-client-review-history.module').then(m => m.ReportClientReviewHistoryPageModule), canActivate: [AuthGuardService] },
  { path: 'report_date_register', loadChildren: () => import('./pages/report-date-register/report-date-register.module').then(m => m.ReportDateRegisterPageModule), canActivate: [AuthGuardService] },
  { path: 'report_billing', loadChildren: () => import('./pages/report-billing/report-billing.module').then(m => m.ReportBillingPageModule), canActivate: [AuthGuardService] },
  { path: 'report_invoice', loadChildren: () => import('./pages/report-invoice/report-invoice.module').then(m => m.ReportInvoicePageModule), canActivate: [AuthGuardService] },
  { path: 'report_session', loadChildren: () => import('./pages/report-session/report-session.module').then(m => m.ReportSessionPageModule), canActivate: [AuthGuardService] },
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule), canActivate: [AuthGuardService] },

  { path: 'page_not_found', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule) },
  { path: '**', redirectTo: 'page_not_found', pathMatch: 'full' },   {
    path: 'admin-history',
    loadChildren: () => import('./pages/admin-history/admin-history.module').then( m => m.AdminHistoryPageModule)
  },
// TODO: replace with sensible redirect
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
