import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryLoopPage} from './inventory-loop';
import {InventoryLoopPageRoutingModule} from './inventory-loop-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    InventoryLoopPageRoutingModule,
  ],
  declarations: [InventoryLoopPage],
  providers: [
    NavParams,
    BarcodeScanner,
  ],
})
export class InventoryLoopPageModule {}
