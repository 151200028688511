import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {ApplicationPage} from "../../pages/application/application";
import {DispenseConfirmComponent} from "../dispense-confirm/dispense-confirm";
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";

@Component({
  selector: 'app-app-formula-group-active',
  templateUrl: './app-formula-group-active.component.html',
  styleUrls: ['./app-formula-group-active.component.scss'],
})
export class AppFormulaGroupActiveComponent implements OnInit {
  @Input() firstFormula: CLiCSFormulaRequest = null;
  @Input() parent: ApplicationPage = null;
  @Input() showTimer: boolean = true;
  @Input() showMl: boolean = false;
  @Input() showProductLine: boolean = false;
  @Input() mixingBowl: MixingBowlProvider = null;
  @Output() applyNow = new EventEmitter();
  @Output() dispenseMore = new EventEmitter();
  @Output() onChange = new EventEmitter;

  @ViewChild('groupAmountConfirm') amountConfirm: DispenseConfirmComponent;

  group_id: number = 0;
  formulas: CLiCSFormulaRequest[] = [];
  ready: boolean = false;
  timerStr: string = 'Your Timer Here';
  showTotal: boolean = false;
  minAllowableWeight: number = 10;

  constructor() {
  }

  ngOnInit() {
    // this.ready = false;
    this.reload();
    this.ready = true;
  }

  // Reload formulas
  reload() {
    this.formulas = [];
    if (!!this.firstFormula) {
      if (this.firstFormula.group_id > 0) {
        this.group_id = this.firstFormula.group_id;
        for (let formula of this.parent.cs.formulas) {
          if (formula.group_id == this.firstFormula.group_id) {
            this.formulas.push(formula);
          }
        }
      } else {
        this.formulas.push(this.firstFormula);
      }
    }
  }

  // Calls parent formula choice method then reloads this object
  openFormulaChoices(formula) {
    this.parent.openFormulaChoices(formula).then(result => {
      this.reload();
    });
  }

  // Calls parent remove formula method then reloads this object
  removeFormula(formula) {
    this.parent.removeFormula(formula).then(result => {
      this.reload();
    });
  }

  ungroupFormula(event, formula) {
    this.parent.ungroupFormulas(formula, 2, true).then(result => {
      this.reload();
    });
  }

  hasFormula(formula) {
    let result = false;
    for (let fr of this.formulas) {
      if (fr.token == formula.token) {
        result = true;
        break;
      }
    }
    return result;
  }

  combinedTitle() {
    let result = "";
    for (const fr of this.formulas) {
      if (result == "") {
        result = result + fr.title;
      } else {
        result = result + ' + ' + fr.title
      }
    }
    return result;
  }

  // Returns the first formula request for this group, else null
  first_fr(): CLiCSFormulaRequest {
    if (!!this.formulas[0])
      return this.formulas[0];
    else
      return null;
  }

  // Emits an applNow event
  doApplyNow(token: any): void {
    this.applyNow.emit({formula_token: token});
  }

  updateTimerDisplay(): any {
    const timerData = this.first_fr().getApplicationTimer();
    if (timerData.started)
      this.timerStr = timerData.timerStr;
    else
      this.timerStr = '';
    return (timerData);
  }

  promptForMore(formula) {
    this.dispenseMore.emit({formula: formula})
  }

  // Returns a string representing the total weight of the grouped formulas
  groupTotal(): number {
    let amount = 0.0;
    for (const fr of this.formulas) {
      amount += fr.amount;
    }
    return amount;
  }

  groupTotalString(): string {
    const amount = this.groupTotal();
    if (amount % 1 == 0) {
      if (this.showMl) {
        return `${amount}mL`
      } else {
        return `${amount}g`
      }
    } else {
      if (this.showMl) {
        return `${amount.toFixed(1)}mL`
      } else {
        return `${amount.toFixed(1)}g`
      }
    }
  }

  // Displays the total amount choice dialog
  showTotalAmountChoice() {
    const preferredAmount = this.groupTotal() > 300.0 ? 300 : this.groupTotal();
    if (!!this.mixingBowl) {
      this.minAllowableWeight = this.mixingBowl.minAllowableGroupWeight(this.formulas);
    }
    this.amountConfirm.updateValue({amount: parseFloat(preferredAmount.toFixed(1))});
    this.showTotal = true;
  }

  // Adjust the total amount for the formulas
  setTotalAmount(event: any) {
    let totalAmount = 0.0;
    for (const fr of this.formulas) {
      totalAmount += fr.amount;
    }
    if (totalAmount != event.amount) {
      const adjustment: number = event.amount / totalAmount;
      for (let fr of this.formulas) {
        fr.setAmount(parseFloat((fr.amount * adjustment).toFixed(0)));
        fr.adjustParamsFromAmount();
      }
      this.signalChange(event.amount);
    }
    this.showTotal = false;
  }

  // Indicate that a change has happened
  signalChange(totalAmount: number) {
    this.onChange.emit({total_amount: totalAmount});
  }

}
