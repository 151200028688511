import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CLiCSFormula} from "../../../lib/formula";
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Used to prompt for amounts
 */
@Component({
  selector: 'dispense-lightener',
  templateUrl: 'dispense-lightener.html',
  styleUrls: ['dispense-lightener.scss'],
})
export class DispenseLightenerComponent {
  @Input() formula: CLiCSFormula = null;
  @Input() unit: string = "g";
  @Input() minQty: number = 10;
  @Input() maxQty: number = 300;
  @Input() initialDevQty: number = null;
  @Input() defaultStrength: number = 20;
  @Input() default: number = 40;
  @Input() option: string = 'Include Developer'; // Title for option switch (displays if set)
  @Input() includeDevChoice: boolean = true;
  @Input() minWeightText: string = 'minimum weight for this formula';
  @Input() showMinWeightText: boolean = true;
  @Input() location: string = '';
  @Input() confirmText = 'Save';
  @Input() showLocation = true;
  @Output() confirmation = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('dispense_lightener_modal') dispenseModal: ElementRef;

  totalQty: number = 20;
  qtyString: string = `${this.totalQty}${this.unit}`;
  devStrength = 20;  // Developer strength, etc.d
  devStrengthStr = 'D20'; // Developer strength, etc.
  devPercent = 1.0;  // Developer fraction of total
  devPercentSaved = 1.0;  // Developer fraction of total
  devMinQty = 10;
  devMaxQty = 160;
  devQty = 40;  // Developer strength, etc.
  devPctStr = '40 (1:1)'; // Developer strength, etc.
  liQty = 40;
  localTitle = 'Li + D20';

  locations: any[] = null;

  permissions: any = null;
  settings: any = null;

  constructor(
    private mixingBowl: MixingBowlProvider,
    private modeCtrl: ModeControllerProvider,
    ) {
      // Load permissions from modeCtrl
      this.permissions = this.modeCtrl.getPermissions();
  
      this.modeCtrl.init().then(result => {
          this.permissions = this.modeCtrl.getPermissions();
      });
  
      if(!!this.permissions.show_milliliters){
          this.unit = "mL"
      }
  }

  ngOnInit() {
    this.locations = this.mixingBowl.getLocations();
    this.totalQty = this.default;
    this.devStrength = this.defaultStrength;
    if (!!this.initialDevQty && this.initialDevQty <= (this.totalQty * 4 / 5) && this.initialDevQty > 0.0) {
      this.updateDevQty({amount: this.initialDevQty, min: 5, max: 240})
    }
    this.updateAll();
  }

  updateDevChoice() {
    if (this.includeDevChoice) {
      if (this.devPercent == 0.0 && this.devPercentSaved > this.devPercent) {
        this.devPercent = parseFloat(this.devPercentSaved.toFixed(1));
      }
    } else {
      if (this.devPercent > 0.0) {
        this.devPercentSaved = this.devPercent;
        this.devPercent = 0.0;
      }
    }
  }

  updateTotalQty(data = null) {
    if (!!data) {
      this.totalQty = data.amount;
    }
    this.qtyString = `${this.totalQty}${this.unit}`;
    this.updateDevQty();
    this.updateDevQtyRange();
    this.updateLocalTitle();
  }

  updateDevStrength(data: any = null) {
    if (!!data) {
      this.devStrength = data.amount;
    }
    this.devStrengthStr = `D${this.devStrength}`;
    this.updateLocalTitle();
  }

  updateDevQty(data: any = null) {
    let timer: any = null;
    if (!!data) {
      if (this.includeDevChoice) {
        this.devQty = data.amount;
        this.devPercent = this.devQty / (this.totalQty - this.devQty);
      } else {
        this.devQty = 0.0;
      }
      this.liQty = this.totalQty - this.devQty;
    } else {
      const liPercent = 1 / (this.devPercent + 1.0);
      this.liQty = this.totalQty * liPercent;
      this.devQty = this.totalQty - this.liQty;
    }

    // Prepare variables for dev quantity display
    let dev = this.devQty.toFixed(1);
    if (this.devQty % 1 == 0) {
      dev = this.devQty.toFixed(0);
    }

    const ratio = this.devPercent;
    let ratioNum = ratio.toFixed(1);
    if (parseFloat(ratioNum) % 1 == 0) {
      ratioNum = ratio.toFixed(0);
    }

    this.devPctStr = `${dev}${this.unit} (${ratioNum}:1)`;
    this.updateLocalTitle();
  }

  // Limit the developer amount to enforce 1:4 up to 4:1 dev range
  updateDevQtyRange() {
    this.devMinQty = Math.round(this.totalQty / 2);
    this.devMaxQty = Math.round(this.totalQty * 4 / 5);
  }

  updateLocalTitle() {
    let dev = this.devQty.toFixed(1);
    if (this.devQty % 1 == 0) {
      dev = this.devQty.toFixed(0);
    }

    let li = this.liQty.toFixed(1);
    if (this.liQty % 1 == 0) {
      li = this.liQty.toFixed(0);
    }

    if (this.includeDevChoice)
      this.localTitle = `${li}${this.unit} Li + ${dev}${this.unit} D${this.devStrength}`;
    else
      this.localTitle = `${li}${this.unit} Li`
  }

  updateAll() {
    this.updateDevChoice();
    this.updateDevStrength();
    this.updateTotalQty();
    this.updateDevQty();
    this.updateLocalTitle();
  }

  doDispense() {
    this.confirmation.emit({
      amount: this.totalQty,
      liAmount: this.liQty,
      devAmount: this.devQty,
      inclDev: this.includeDevChoice,
      option: (this.includeDevChoice && !!this.option),
      optionMod: this.devStrengthStr,
      formulaStr: this.localTitle,
      location: this.location
    });
  }

  doCancel() {
    this.cancel.emit(null);
  }

  // True if at min weight but not original default
  atMinWeight() {
    // @ts-ignore
    return(this.totalQty == this.minQty && this.showMinWeightText == true && this.minQty != 10 );
  }
}
