/*
    Defines a CLICS Color Formula. For imported library Color Formulas the actual formula is kept in a string format.
    When creating a new formula parameters are added to the formula and the RGB color value is updated.
 */
import {CLiCSFormula} from "./formula";

export class CLiCSColorFormula extends CLiCSFormula {
    id: number = null;
    level: number = null;
    color_angle: number = null;
    library: string = '';
    published: boolean = false;
    tags: string = '';  // CRITICAL: a temporary measure - these will remain in the containing library attributes
    scope: string = '?';  // Set externally for filtering purposes (user,salon,system,shared,purchased)

    // Formula records that have been deleted from the library will still be loaded to the app
    archived_at: Date = null;
    deleted: boolean = false;
    active: boolean = true;  // Used internally for repo castAs() (filter by target page)

    // Item can be constructed with either a data object or a string.
    constructor(data: any = null, owned: boolean = true) {
        super(data, owned);
        if (data) {
            if (data instanceof CLiCSColorFormula) {
                this.mode = 'library';  // sensible default
                this.load(data);
            }
            else {
                if (data.components != undefined) {
                    for (let comp of data.components) {
                        this.params.push({type: 'compName', value: comp.component_name, mod: comp.weight})
                    }
                }
                if ('id' in data)
                    this.id = data.id;
                if ('archived_at' in data) {
                    if (data.archived_at == null)
                        this.archived_at = null;
                    else
                        this.archived_at = new Date(data.archived_at);
                }
                if ('deleted' in data)
                    this.deleted = data.deleted;
                if ('level' in data)
                    this.level = data.level;
                if ('tags' in data)
                    this.tags = data.tags;
                if ('scope' in data)
                    this.scope = data.scope;
                if ('library' in data)
                    this.library = data.library;
                this.mode = 'basic';  // sensible default
            }
        }
    }

    // Clear the data in this object
    clear(preserveToken: boolean = false) {
        super.clear(preserveToken);
        this.id = null;
        this.mode = 'library';
        this.color_angle = null;
        this.published = false;
        this.scope = '';
    }

    // Load this object from another ColorFormula object
    // UPDATE: added generic formula fields like mode, coverage, request_ident, etc.
    load(formula: CLiCSColorFormula, preserveToken: boolean = false) {
        if (formula) {
            this.clear(preserveToken);
            if ('token' in formula && preserveToken == false)
                this.token = formula.token;
            if ('request_ident' in formula)
                this.request_ident = formula.request_ident;
            if ('id' in formula)
                this.id = formula.id;
            if ('title' in formula)
                this.title = formula.title;
            if ('scope' in formula)
                this.scope = formula.scope;
            if ('mode' in formula)
                this.mode = formula.mode;
            if ('rgb' in formula)
                this.rgb = formula.rgb;
            if ('level' in formula)
                this.level = formula.level;
            if ('thumb' in formula)
                this.thumb = formula.thumb;
            if ('formula_text' in formula)
                this.formula_text = formula.formula_text;
            if ('ordinal' in formula)
                this.ordinal = formula.ordinal;
            if ('published' in formula)
                this.published = formula.published;
            if ('library' in formula)
                this.library = formula.library;
            if ('location' in formula)
                this.location = formula.location;
            if ('coverage' in formula)
                this.setCoverage(formula.coverage);
            if ('cobonded' in formula)
                this.setCobonded(formula.cobonded);
            if ('owned' in formula)
                this.owned = formula.owned == true;
            if ('product_line' in formula)
                this.product_line = formula.product_line;
            if ('params' in formula) {
                const allowable = ['compName', 'formTok', 'compOrig'];
                this.params.length = 0;
                for (let param of formula.params) {
                    let newParam = JSON.parse(JSON.stringify(param));
                    if (allowable.indexOf(newParam.type) >= 0)
                        this.params.push(newParam);
                }
            }
        }
    }

  // For identifying the class
  isFormulaRequest(): boolean {
    return false;
  }

  className(): string {
    return 'CLiCSColorFormula'
  }

}
