import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {Contacts} from '@ionic-native/contacts';
import {ClientAddPage} from './client-add';
import {ComponentsModule} from "../../components/components.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  declarations: [ClientAddPage],
  providers: [
    NavParams,
    Contacts,
  ],
})
export class ClientAddPageModule {}
