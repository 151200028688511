import {Injectable, Injector} from '@angular/core';
import {ToastController} from '@ionic/angular'
import {timer} from 'rxjs';
import {CLiCSService} from '../clics.service';
import {EventsService} from '../events/events.service';
import {SettingsProvider} from '../settings/settings';
import {CLiCSClient} from '../../../lib/client';
import {CLiCSColorSession} from '../../../lib/session';
import {sessionTimer} from '../../../lib/session-timer';
// import {BackgroundMode} from "@awesome-cordova-plugins/background-mode/ngx";

/*
  This provider keeps a list of [rinse] timer records that are active (not yet triggered). It subscribes to a system
  clock and checks for any timers that have expired, playing a sound and displaying a toast when triggered.
*/
@Injectable({
  providedIn: 'root',
})
export class TimerAlertProvider {
  audioPlayer: HTMLAudioElement = new Audio();
  private timers: sessionTimer[];
  private alertTimer: any = null;
  private alertTimerPeriod: number = 2000;
  private lastDownloadAt: Date = null;
  private clicsService: CLiCSService;
  private settingsCtrl: SettingsProvider;

  constructor(
    private injector: Injector,
    private events: EventsService,
    private toastCtrl: ToastController,
    // private backgroundMode: BackgroundMode
  ) {
    this.audioPlayer.src = 'assets/audio/attention.mp3';
    this.audioPlayer.load();
    if (!this.timers) {
      this.timers = [];
    }
    this.clicsService = this.injector.get(CLiCSService);
    this.settingsCtrl = this.injector.get(SettingsProvider);
    this._startAlertTimer();
  }

  numTimers(): number {
    if (!this.timers) {
      this.timers = [];
    }
    return this.timers.length;
  }

  // Adds a new timer record or updates an existing one
  addTimerFromCS(cs: CLiCSColorSession, client: CLiCSClient = null, type: string = 'rinse'): sessionTimer {
    let result = null;
    const existing = this.timers.find((el) => el.css == cs.token );
    const newTimer = new sessionTimer(cs, client, type);

    if (!!existing) {
      result = existing;
      if (!newTimer.isExpired()) {
        existing.update(cs, client, type);
      } else {
        console.log("Timer expired, update ignored");
      }
    } else {
      // Only add if the timer expires in the future
      if (!newTimer.isExpired()) {
        this.timers.push(newTimer);
        result = newTimer;
      } else {
        console.log(`Ignoring rinse timer for ${newTimer.clientName}... expired`);
      }
    }
    return result;
  }

  // Adds a new timer record or updates an existing one
  // Timer object: { css: , type: 'rinse', expires_at:, client_name:, cli: }
  addTimerFromObj(obj: any): sessionTimer {
    let result = null;
    if (!obj) {
      return null;
    }
    const existing = this.timers.find((el) => el.css == obj.css );
    const newTimer = new sessionTimer();  // NOTE: not initialized
    newTimer.updateFromObj(obj);

    if (!!existing) {
      result = existing;
      if (!newTimer.isExpired()) {
        existing.updateFromObj(obj);
      } else {
        console.log("Timer expired, update ignored");
      }
    } else {
      // Only add if the timer expires in the future
      if (!newTimer.isExpired()) {
        this.timers.push(newTimer);
        result = newTimer;
      } else {
        console.log(`Ignoring rinse timer for ${newTimer.clientName}... expired`);
      }
    }
    return result;
  }

  // Checks all timers for ones that are expired but not yet fired.
  checkAndFireTimers() {
    // Sound alert for all expired timers
    if (!!this.timers) {
      this.timers.sort((a,b) => {
        return a.timerExpiresAt.getTime() - b.timerExpiresAt.getTime()
      });
      for (let timer of this.timers) {
        if (timer.shouldTriggerTimer()) {
          this.doAlert(timer);
        }
      }

      // Remove all triggered timers (relies on unique timer.css values withing timers[]
      const triggered = this.timers.filter(e => e.isTriggered() == true);
      for (let timer of triggered) {
        let index = this.timers.findIndex(e => e.css == timer.css)
        if (index > -1) {
          this.timers.splice(index, 1);
        }
      }
    }
    // this._setBackgroundMode();
  }

  getNextFireableTimer(): sessionTimer {
    let result = null;
    if (!!this.timers) {
      result = this.timers.find(e => e.shouldTriggerTimer());

      // Remove all triggered timers (relies on unique timer.css values withing timers[]
      const triggered = this.timers.filter(e => e.isTriggered() == true);
      for (let tmr of triggered) {
        let index = this.timers.findIndex(e => e.css == tmr.css)
        if (index > -1) {
          this.timers.splice(index, 1);
        }
      }
    }
    return result;
  }

  // Play a sound and show a toast
  async doAlert(timer: sessionTimer, mssg: string = 'Time to rinse', appendClientName: boolean = true) {
    let classStr = 'clics-toast rinse';
    const duration = 8000;
    if (!timer) {
      return;
    }
    if (appendClientName && !!timer.clientName) {
      mssg = mssg + ' ' + timer.clientName;
    }
    const toast = await this.toastCtrl.create({
      message: mssg,
      duration: duration,
      cssClass: classStr,
      position: 'top'
    });
    await toast.present();

    // Play sound if enabled
    if (this.settingsCtrl.settings.rinse_alarm) {
      this.audioPlayer.play();
    }
  }

  // Make sure the alert timer is running
  _assertAlertTimer() {
    if (!this.alertTimer) {
      this._startAlertTimer();
    }
  }

  // Start running timers, updated every N seconds.
  _startAlertTimer(periodInMs: number = null) {
    const _that = this;
    this._stopAlertTimer();
    if (periodInMs == null)
      periodInMs = this.alertTimerPeriod;
    if (this.alertTimer == null) {
      this.alertTimer = timer(0, periodInMs).subscribe(() => {
        _that.events.publish('timer:alert');
        _that.checkAndFireTimers();
        _that.checkForDownloads();  // checks once every N minutes
      });
    }
  }

  // Stop any running server timers
  _stopAlertTimer() {
    if (this.alertTimer != null) {
      this.alertTimer.unsubscribe();
      this.alertTimer = null;
    }
  }

  // Enables background mode if there is one or more active timers, else disables background mode
  _setBackgroundMode() {
    let enable = false;
    if (this.timers.length > 0) {
      for (let timer of this.timers) {
        if (timer.isActive()) {
          enable = true;
          break;
        }
      }
    }
    // if (!!enable) {
    //   this.backgroundMode.enable();
    // } else {
    //   this.backgroundMode.disable();
    // }
  }

  // pings the server every 10 minutes to get updated timers
  checkForDownloads(force: boolean = false): Promise<boolean> {
    let _that = this;
    if (!this.clicsService.current_user || !this.clicsService.session_token) {
      return Promise.resolve(false);
    }
    // CRITICAL: 45 seconds for demo only - change the retry time to 5 minutes (300000) or 10 minutes (600000)
    if (force || !this.lastDownloadAt || this.lastDownloadAt.getTime() < new Date().getTime() - 300000) {
      this.lastDownloadAt = new Date();
      this.clicsService.apiGetRinseTimers().then(data => {
        if (data.success) {
          for (let timer of data.timers) {
            _that.addTimerFromObj(timer);
          }
        }
        // _that._setBackgroundMode();
        return data.success;
      });
    } else {
      // this._setBackgroundMode();
      return Promise.resolve(false);
    }
  }

}
