import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {LoginTextPage} from './login-text';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [LoginTextPage],
  providers: [
    Keyboard,
  ],
})
export class LoginTextPageModule {}
