import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {ConversionLibraryPage} from './conversion-library';
import {ConversionLibraryPageRoutingModule} from './conversion-library-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    ConversionLibraryPageRoutingModule
  ],
  declarations: [ConversionLibraryPage],
  providers: [
    NavParams,
  ],
})
export class ConversionLibraryPageModule {}
