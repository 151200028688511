import {Component} from '@angular/core';
import {NavController, NavParams, AlertController, Platform, ModalController} from '@ionic/angular';
import {CLiCSClientPhoto} from '../../../lib/photo';

/**
 * Generated class for the PhotoZoomPage page.
 *
 */
@Component({
  selector: 'page-photo-zoom',
  templateUrl: 'photo-zoom.html',
  styleUrls: ['photo-zoom.scss'],
})
export class PhotoZoomPage {
  photoTitle = "Photo";
  photo: CLiCSClientPhoto = null;
  url: string = null;
  replaceable: boolean = false;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private platform: Platform,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController
  ) {
    this.photoTitle = this.navParams.get('title');
    this.photo = this.navParams.get('photo');
    this.url = this.photo.full_url;
    this.replaceable = this.photo.when_taken == 'before' || this.photo.when_taken == 'after';
    if (!this.platform.is('cordova') && !this.platform.is('ios') && !this.platform.is('android'))
      this.replaceable = false;
  }

  async confirmReplace() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Photo Replacement',
      message: `This will replace the ${this.photo.when_taken} photo with a new photo. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.alertCtrl.dismiss({action: 'replace', photo: this.photo});
            this.modalCtrl.dismiss({action: 'replace', photo: this.photo});
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  async confirmRemove() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Photo Removal',
      message: `This will delete this photo permanently. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.alertCtrl.dismiss({action: 'remove', photo: this.photo});
            this.modalCtrl.dismiss({action: 'remove', photo: this.photo});
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  async confirmProfile() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Profile Photo',
      message: `This will make this photo the client's profile photo. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.alertCtrl.dismiss({action: 'profile', photo: this.photo});
            this.modalCtrl.dismiss({action: 'profile', photo: this.photo});
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  closeModal() {
    this.modalCtrl.dismiss({action: 'none', photo: this.photo});
  }
}
