import {Component} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {CLiCSService} from "../../services/clics.service";

/**
 * Generated class for the ConfirmInvitationPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-confirm-invitation',
  templateUrl: 'confirm-invitation.html',
  styleUrls: ['confirm-invitation.scss'],
})
export class ConfirmInvitationPage {
  invite_data: any = null;
  user_choice: number = null;

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private modalCtrl: ModalController,
              private clicsService: CLiCSService) {
    this.invite_data = this.navParams.get('invite_data');
  }

  confirmInvitation() {
    this.closeModal({choice: this.user_choice});
  }

  closeModal(paramObject: any = null) {
    if (paramObject)
      this.modalCtrl.dismiss(paramObject);
    else
      this.modalCtrl.dismiss();
  }

  setUserChoice(event: any) {
    this.user_choice = event.detail.value;
  }

}
