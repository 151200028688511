import { Component } from '@angular/core';
import {NavParams, ModalController} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {SettingsProvider} from "../../services/settings/settings";
import {CLiCSService} from "../../services/clics.service";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";
import {EventsService} from "../../services/events/events.service";

@Component({
  selector: 'app-admin-history',
  templateUrl: './admin-history.page.html',
  styleUrls: ['./admin-history.page.scss'],
})
export class AdminHistoryPage {
  currentCategory: string = '';
  requestedView: string = 'default';
  settings: any[] = [];
  returnTo: string = null;

  constructor(
    public settingsCtrl: SettingsProvider,
    public clicsService: CLiCSService,
    private navParams: NavParams,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    public modeCtrl: ModeControllerProvider,
    private events: EventsService
  ) {
    this.currentCategory = '';
  }

  ionViewWillEnter() {
    this.requestedView = this.navParams.get('view');
    this.modeCtrl.modeRedirect('admin_service');
    this.settings = this.settingsCtrl.getSettings(['Display']);
  }

  ionViewDidEnter() {
    this.route.queryParams.subscribe(params => {
      this.returnTo = params.rtn;
    });
  }

  saveSettings(event: any) {
    console.log('save settings: ' + JSON.stringify(event.detail));
    this.settingsCtrl.setValue(event.detail.value, event.detail.checked ? true : false);
    this.settingsCtrl.saveToLocal();
    this.settings = this.settingsCtrl.getSettings(['Display']);
  }

  // Returns true if the string passed is a new category
  isNewCategory(itemCategory: string): boolean {
    if (this.currentCategory != itemCategory) {
      this.currentCategory = itemCategory;
      return true;
    }
    else
      return false;
  }

  closeModal() {
    if (this.requestedView == 'modal') {
      this.modalCtrl.dismiss();
    } else {
      if (!!this.returnTo) {
        this.events.publish('navrequest', {page: this.returnTo, force: true});
      }
    }
  }

  showNonModalElement(): boolean {
    return (!this.requestedView && !this.returnTo);
  }

  showModalElement(): boolean {
    return (this.requestedView == 'modal' || !!this.returnTo);
  }
}
