import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, LoadingController, NavParams, ActionSheetController, ToastController, IonContent} from '@ionic/angular';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {Subscription} from 'rxjs';
import {ColorDetailPage} from '../color-detail/color-detail';
import {CreateColorPage} from '../create-color/create-color';
import {ColorFilterPage} from '../color-filter/color-filter';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {SettingsProvider} from '../../services/settings/settings';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSColorFormula} from '../../../lib/color-formula';
import {CLiCSColorApplication} from '../../../lib/color-application';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSUser} from '../../../lib/user';
import {CLiCSColorSession} from '../../../lib/session';
import {CLiCSRepository} from '../../../lib/repository';
import {CLiCSLibrary} from '../../../lib/library';
import {CLiCSFormulaRequest} from '../../../lib/request';
import {CLiCSClient} from "../../../lib/client";

/**
 * Page shows a list of library colors for all collections "ALL COLORS" or for a chosen collection.
 * Updated 11/2019 to use the system Repository object which contains all libraries and formulas (and APPs).
 * activeLibrary is the selected library object, including "ALL COLORS" and is the primary touch point for display
 * on this page. Selections are maintained both in the activeLibrary object and in the master repo object, but filters
 * and search criteria are only applied to the library object, so get reset when a new library is chosen.
 */
@Component({
  selector: 'page-lab-color',
  templateUrl: 'lab-color.html',
  styleUrls: ['lab-color.scss'],
})
export class LabColorPage implements OnInit {
  @ViewChild('searchBar') searchBarElement;
  @ViewChild(IonContent) content: IonContent;

  requestedView: string = 'default';
  context: string = 'any';
  theme: string = 'light';

  // as of 11/2019 uses repo library
  repo: CLiCSRepository = null;

  target: string = 'all';  // clics, my_colors, conversion, all (null)
  libraryIdent: string = null;
  source: string = null;  // Indicate and alternate source like client (CLAPP)
  collection_str: string = null;  // the high-level collection string
  filter_by_product_line: boolean = false;  // some collection strings indicate a product line filter

  activeLibrary: CLiCSLibrary = new CLiCSLibrary();  // Legacy, recall last library

  showSearch = false;
  showDisplay = false;  // Finish loading before displaying results
  multipleSelected: boolean = false;
  activeColorToken: string = null;  // If this page called with an active color token passed

  editItem: EditQueueItem = null; // if this page was reached with an editable APP load it for USE
  promptForSwatch: boolean = false;
  compareMode: boolean = false; // When comparing selected colors

  selectedLibraryTitle: string = "";
  libraryDisplay: string = `Choose ${this.modeCtrl.collectionStr()}...`;

  checkingServerTimer: boolean = false;
  showingColorDetails: boolean = false;

  // Swatch weight limits
  defaultWeight: number = 20;
  minFormulaWeight: number = 10;
  maxFormulaWeight: number = 30;

  permissions: any = null;
  singleSelect: boolean = false;

  libraryAddedSubscription: Subscription = null;
  libraryModifiedSubscription: Subscription = null;
  clientSelectedSubscription: Subscription = null;
  filterSetSubscription: Subscription = null;
  filterClearSubscription: Subscription = null;
  modalDismissSubscription: Subscription = null;
  timerSubscription: Subscription = null;
  libraryRenameFailureSubscription: Subscription = null;
  csUpdatedSubscription: Subscription = null;
  csUpdateFailSubscription: Subscription = null;
  csUpdateSubscription: Subscription = null;

  // Support for fast dispense out of color detail modal
  tout: any = null; // loading timeout objects
  loading: any = null;  // loading spinner graphic
  origClient: CLiCSClient = null;
  fastDispense: boolean = false;

  manufacturerName: string = "Choose brand...";
  manufacturerId: number = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navParams: NavParams,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    private clicsService: CLiCSService,
    private editQueue: EditQueueProvider,
    private events: EventsService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private settingsCtrl: SettingsProvider,
    private actionSheetCtrl: ActionSheetController,
    private toastCtrl: ToastController,
    private mixingBowl: MixingBowlProvider,
    public modeCtrl: ModeControllerProvider,
    private taptic: TapticEngine
  ) {
    this.permissions = this.modeCtrl.getPermissions();
    this.modeCtrl.init().then(response => {
      this.permissions = this.modeCtrl.getPermissions();
    });
  }

  ngOnInit() {
    this.theme = this.clicsService.theme;
  }

  ionViewWillEnter() {
    this.singleSelect = this.navParams.get('single') === 'true';
    this.source = this.navParams.get('source');
    this.libraryIdent = null;

    if (this.clicsService.isLoggedIn() == false) {
      this.router.navigate(['/login']);
      return;
    } else {
      if (this.clicsService.isSignedOn() == false) {
        this.router.navigate(['/signon']);
        return;
      }
    }

    if (this.modeCtrl.modeRedirect('lab_color') == false) {
      // Determine how to display this page: my color, clics collections, or conversions
      this.target = 'colors';
      this.filter_by_product_line = false;
      if (this.router.url.includes('lab_clics')) {
        this.target = 'clics';
        this.collection_str = 'CLICS';
      }
      if (this.router.url.includes('lab_goldwell')) {
        this.target = 'goldwell';
        this.collection_str = 'Goldwell';
      }
      if (this.router.url.includes('lab_topchic')) {
        this.target = 'topchic';
        this.collection_str = 'Topchic';
        this.filter_by_product_line = true;
      }
      if (this.router.url.includes('lab_colorance')) {
        this.target = 'colorance';
        this.collection_str = 'Colorance';
        this.filter_by_product_line = true;
      }
      if (this.router.url.includes('lab_conver')) {
        this.target = 'conversions';
        this.collection_str = 'Conversions';
      }

      if (!!this.navParams.get('target')) {
        this.target = this.navParams.get('target');
      }

      if (!this.collection_str) {
        switch (this.target) {
          case 'clics':
            this.collection_str = 'CLICS';
            break;
          case 'topchic':
            this.collection_str = 'Topchic';
            this.filter_by_product_line = true;
            break;
          case 'colorance':
            this.collection_str = 'Colorance';
            this.filter_by_product_line = true;
            break;
          case 'goldwell':
            this.collection_str = 'Goldwell';
            break;
          case 'colors':
            this.collection_str = 'My Colors';
            break;
          case 'conversions':
            this.collection_str = 'Conversions';
            break;
        }
      }

      this.context = this.navParams.get('context');
      this.requestedView = this.navParams.get('view'); // 'modal' or [not modal]
      this.editQueue.logReport();

      this.editItem = this.editQueue.getEditAppItem('CLiCSColorApplication'); // May return null
      if (!!this.editItem) {
        console.log(`Lab Color page ${this.editItem.action} queue item ${this.editItem.shortToken()}`);
        this.editQueue.logReport();
      }

      this.activeColorToken = this.navParams.get('active_color');

      // Load all colors, get library object, list library titles
      this._loadRepository().then((result) => {
        this.resetColors();

        // Fill page selects for conversion manufacturers and product lines
        if (this.target == 'conversions' && (this.manufacturerId == undefined || this.manufacturerId == 0)) {
          const manufacturers = this.repo.getConversionManufacturers();
          if (!!manufacturers && manufacturers.length > 0) {
            this.chooseConversionManufacturer();
          }
        }

        this.libraryModifiedSubscription = this.events.subscribe('library:modified', () => this._loadRepository()); // @@@ SD'A needed?

        this.clientSelectedSubscription = this.events.subscribe('client:selected', () => {
          this.resetColors()
        });

        this.filterSetSubscription = this.events.subscribe('filter:set', (data) => {
          this.setFilter(data)
        });

        this.filterClearSubscription = this.events.subscribe('filter:clear', (data) => {
          this.clearFilter(data)
        });

        this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
          if (this.context && (this.context != 'any' && this.context != ''))
            this.modalCtrl.dismiss();
        });

        this.libraryRenameFailureSubscription = this.events.subscribe("library:rename:failure", (data) => {
          this.alertCtrl.create({
            header: data.message,
            buttons: [{text: 'Ok'}],
            mode: 'ios'
          }).then(alert => alert.present());
        });

        this.timerSubscription = this.events.subscribe('timer:server', () => {
          this.checkUserTimestamps()
        });

        // subscribe to CS update request, for fast dispense
        this.csUpdateSubscription = this.events.subscribe('color_session:update', async () => {
          if (this.fastDispense) {
            this.loading = await this.loadingCtrl.create({
              spinner: 'bubbles',
              message: 'adding...',
              duration: 6000
            });
            await this.loading.present();
          }
        });

        // Subscribe to completion of updating the CS (fast dispense). Queue CS, select original client, dismiss loading
        this.csUpdatedSubscription = this.events.subscribe('color_session:updated', (data) => {
          if (this.fastDispense) {
            this.fastDispense = false;
            // Select original client and navigate to queue page
            this.clicsService.apiQueueColorSession(null).then((data) => {
              this.clicsService.selectClient(this.origClient.token).then(result => {
                if (!!this.loading) {
                  this.loading.dismiss();
                }
                this.events.publish('navrequest', {top: 'dispenser', page: 'queue'});
              });
            });
          }
        });

        // Subscribe to CS update failed event. Still select original client but should show an error alert.
        this.csUpdateFailSubscription = this.events.subscribe('color_session:update:failure', (data) => {
          if (this.fastDispense) {
            this.fastDispense = false;
            this.clicsService.selectClient(this.origClient.token).then(result => {
              this._clearLoading();
              this.toastCtrl.create({
                message: 'An error occurred queueing your formula. Please try again.',
                duration: 5000,
                position: 'bottom'
              }).then(toast => toast.present());
              console.error("CS update failed");
            });
          }
        });

        // Load the conversions collections upon first visit to conversions page
        if (this.target == 'conversions') {
          this.getConversionLibraries().then(result => {
            this.resetColors();
            console.log('conversions loaded');
          });
        }
      });
    }
    this.theme = this.clicsService.theme;
  }

  ionViewDidEnter() {
    // Get identifier for selected collection (library), if any
    this.libraryIdent = this.navParams.get('library_token');
    this.origClient = this.clicsService.activeClient();
    if (!this.libraryIdent) {
      this.libraryIdent = this.navParams.get('library');  // library name
    }
    if (!this.libraryIdent) {
      this.route.queryParams.subscribe((params) => {
        if ('c' in params) {
          this.libraryIdent = params.c;
        }
      });
    }

    if (!this.libraryIdent) {
      this._recallLibraryChoice();
    }

    if (!!this.libraryIdent) {
      delete this.navParams.data.library;
      if (!!this.repo) {
        this._findAndSelectLibrary(this.libraryIdent);
      }
    }
    if (!this.manufacturerName || this.manufacturerName == '' || this.manufacturerName == 'null') {
      this.manufacturerName = "Choose Brand..."
    }

    this.showDisplay = true;
  }

  ionViewWillLeave() {
    this.showDisplay = false;
    this._saveLibraryChoice();
  }

  ionViewDidLeave() {
    this.libraryAddedSubscription = this.events.unsubscribe(this.libraryAddedSubscription);
    this.libraryModifiedSubscription = this.events.unsubscribe(this.libraryModifiedSubscription);
    this.clientSelectedSubscription = this.events.unsubscribe(this.clientSelectedSubscription);
    this.filterSetSubscription = this.events.unsubscribe(this.filterSetSubscription);
    this.filterClearSubscription = this.events.unsubscribe(this.filterClearSubscription);
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
    this.libraryRenameFailureSubscription = this.events.unsubscribe(this.libraryRenameFailureSubscription);
    this.timerSubscription = this.events.unsubscribe(this.timerSubscription);
  }

  // TODO: reCAST the main repo
  _rebaseRepo(that: any = null) {
    if (!that) {
      that = this;
    }
    switch (that.target.toLowerCase()) {
      case 'clics':
      case 'system':
        that.repo.castAs('system');
        break;
      case 'colors':
      case 'my_colors':
      case 'user':
        that.repo.castAs('user');
        break;
      case 'conversions':
      case 'conv':
        that.repo.castAs('conversions');
        break;
      default:
        break;
    }
  }

  // Loads the local repo object from the central repository
  _loadRepository(): Promise<any> {
    let _that = this;
    // Load local list of library names
    this.tout = setTimeout(async () => {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: `Loading Color ${this.modeCtrl.collectionsStr()}`,
        duration: 30000
      });
      await this.loading.present();
    }, 500);

    return this.clicsService.getRepo(true).then((repo) => {
      _that.repo = repo;
      _that._rebaseRepo(_that);
      if (!!this.libraryIdent) {
        _that._findAndSelectLibrary(this.libraryIdent);
      } else {
        _that._loadSelectedLibrary();
      }

      let index = _that.repo.formulas.findIndex((el) => {
        return (el.selected == true);
      });
      // If calling page indicates an active formula (by request ident field) select that formula
      _that.activeLibrary.selectFormula(_that.activeColorToken, true);

      _that._clearLoading(_that);
    });
  }

  // Based on a scope string are we on the right LAB page or should we redirect?
  _shouldRedirectByScope(scope: string): boolean {
    switch (this.target) {
      case 'colors':
        if (scope != 'user' && scope != 'salon')
          return true;
        break;
      case 'clics':
        if (scope != 'system')
          return true;
        break;
      case 'conversions':
        if (scope != 'conversions')
          return true;
        break;
      default:
        break;
    }
    return false;
  }

  // Perform a redirect to another page based on the scope (typ. of a selected library)
  _redirectByScope(scope: string, params: any = null) {
    switch (scope) {
      case 'user':
      case 'salon':
        this.events.publish('navrequest', {top: 'lab', page: 'lab_color', params: params});
        break;
      case 'system':
        this.events.publish('navrequest', {top: 'lab', page: 'lab_clics', params: params});
        break;
      case 'conversions':
        this.events.publish('navrequest', {top: 'lab', page: 'lab_convert', params: params});
        break;
      default:
        break;
    }
  }

  // Does a reload to freshen local display data
  _reloadFromRepo(libraryIdent: string = null) {
    let _that = this;

    // Check if the library is represented on this target page. If not, redirect to an appropriate page
    const scope = this.repo.scopeFromLibrary(libraryIdent);
    if (!!scope && this._shouldRedirectByScope(scope)) {
       this._redirectByScope(scope, {c: libraryIdent});
       return;
    }

    this.clicsService.getRepo(true).then((repo) => {
      _that.repo = repo;
      _that._rebaseRepo();
      _that._loadSelectedLibrary();
      _that.activeLibrary.applyFormulaFilter();
      if (libraryIdent) {
        _that._findAndSelectLibrary(libraryIdent);
      }
    });
  }

  // Force a reload of color libraries, then navigate to a source page
  reloadColorLibrary() {
    this.tout = setTimeout(async () => {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Loading Color Collection',
        duration: 30000
      });
      await this.loading.present();
    }, 50);

    this.clicsService.reloadLibraries(false).then((result) => {
      this._clearLoading();
      this._reloadFromRepo(this.libraryIdent);
    });
  }

  doSelectColor(formula: CLiCSColorFormula, isSelected: boolean = null) {
    // Toggle selection in both repo and active library
    if (this.modeCtrl.getDispenseMode() == 'simple') {
      this.resetColors();
    }
    if (isSelected == null) {
      this.repo.selectFormula(formula.token, null);
      this.activeLibrary.selectFormula(formula.token, this.repo.findFormula(formula.token).selected);
    } else {
      this.repo.selectFormula(formula.token, !isSelected);
      this.activeLibrary.selectFormula(formula.token, this.repo.findFormula(formula.token).selected);
    }
    this.multipleSelected = this.repo.selectedFormulas.length > 1;
    if (this.anySelected() == false)
      this.compareMode = false;
  }

  // Returns true if one or more formula is selected
  anySelected() {
    // return(this.activeLibrary.selectedFormulas.length > 0);
    return((this.repo && this.repo.selectedFormulas.length > 0) || this.activeLibrary.selectedFormulas.length > 0);
  }

  tileBgStyle(formula: CLiCSColorFormula) {
    let html = 'background-color: ' + formula.rgb;
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  // Marks colors as selected = false
  resetColors() {
    this.activeLibrary.clearSelection();
    this.repo.clearFormulaSelection(this.activeLibrary.title);
    this.compareMode = false;
    this.multipleSelected = false;
    this.cancelSearch();
  }


  handleColorClick(token, formula: CLiCSColorFormula = null) {
    if (this.modeCtrl.getDispenseMode() == 'simple' || this.singleSelect) {
      this.useSingleColor(token);
    } else {
      if (!!formula)
        this.doSelectColor(formula);
    }
  }

  // Reveals the color details page
  async showColorDetail(token) {
    let _that = this;
    if (!this.showingColorDetails) {
      this.showingColorDetails = true;
      this.taptic.selection();

      const colorDetailModal = await this.modalCtrl.create({
        component: ColorDetailPage,
        componentProps: {
          formula_token: token,
          source: !!this.source ? this.source : 'library',
          context: this.context,
          fromModal: this.requestedView == 'modal'
        },
        backdropDismiss: false
      });

      colorDetailModal.onDidDismiss().then(async (overlayEvent) => {
        _that.fastDispense = !!overlayEvent.data.fastDispense;

        let index = _that.activeLibrary.filteredFormulas.findIndex((el) => {
          return (el.selected == true);
        });
        _that.showingColorDetails = false;
        _that._loadSelectedLibrary(false);

        if (_that.fastDispense) {
          _that.loading = await _that.loadingCtrl.create({
            spinner: 'bubbles',
            message: 'Adding Formula to Queue...',
            duration: 10000
          });
          await _that.loading.present();
        }

        if (overlayEvent.data.useFormula === true) {
          this.resetColors();
          this.doSelectColor(overlayEvent.data.formula);
          this.confirmUseColor();
        }
        this.multipleSelected = this.activeLibrary.selectedFormulas.length > 1;
      });
      await colorDetailModal.present();
    }
  }

  // Creates copies of the selected colors, adds developer if they don't have it already, determines the min amount
  // that can be dispensed and dispenses either 20g or the min amount.
  async doSwatchColors(data: any = null) {
    let request: CLiCSFormulaRequest = null;
    let swatchFormulas: CLiCSFormulaRequest[] = [];
    let amount = 20.0;  // default amount
    if (!!data) {
      amount = data.amount;
    }
    // Find formulas that have been selected
    // let selected = this.activeLibrary.filteredFormulas.filter((el) => {
    //   return (el.selected == true)
    // });

    this.tout = setTimeout(async () => {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Queuing Swatches...',
        duration: 4000
      });
      await this.loading.present();
    }, 50);

    // Convert color formulas to formula requests and add developer if required
    // for (const formula of selected) {
    for (const formula of this.repo.selectedFormulas) {
      request = new CLiCSFormulaRequest(formula);
      if (!request.hasDeveloper()) {
        request.setDeveloper(20, 1.0, this.mixingBowl);
        // request.includeDevInTitle();
      }
      // Set to 20g or min allowable weight
      request.setAmount(Math.max(this.mixingBowl.minAllowableWeight(request), amount));
      swatchFormulas.push(request);
    }
    this.clicsService.apiQueueSwatches(swatchFormulas, null)
      .then((data) => {
        this._clearLoading();

        let alertSpec = {
          header: null,
          subHeader: null,
          buttons: ['Ok'],
        }
        if (data.success) {
          alertSpec.header = 'Ready to Dispense';
          alertSpec.subHeader = 'Your swatches are ready to dispense from any available CLICS Dispenser machine';
        } else {
          let errorMessage = data.message_key;
          if (data.message_key == 'mk_please_add_payment_method') {
            errorMessage = 'As a chair renter at this salon you must have a valid payment method set up in order to dispense formulas.';
            errorMessage += ' Please select Help and then My Account below, or log in at account.clics.com';
          } else {
            if (data.message_key == 'mk_validate_payment_method') {
              errorMessage = 'Before continuing you must validate the payment method you entered.';
              errorMessage += ' Please select Help and then My Account below, or log in at account.clics.com';
            }
          }
          alertSpec.header = 'Oops...';
          alertSpec.subHeader = `Sorry! Something went wrong and your swatches did not get queued properly. Please check the Queue page and try again. Message: ${errorMessage}`;
        }
        this.alertCtrl.create(alertSpec).then(alert => alert.present());
        if (data.success) {
          this.resetColors();
        }
      }, (result) => {
        this._clearLoading();
        this.alertCtrl.create({
          header: 'Dang!',
          subHeader: 'Something went wrong and your swatches did not get queued properly. Please check the Queue page and try again.',
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());
      });
    this.promptForSwatch = false;
  }

  // Send formulas to the client's "app" (color session?)
  // GroupFormulas adds a special group_id to included formulas to be included in a group (group_id > 0)
  addColorsToTargetApp(groupFormulas: boolean = false) {
    // Check for an active edit item
    if (this.editItem) {
      // for (let formula of this.activeLibrary.filteredFormulas) {
      for (let formula of this.repo.selectedFormulas) {
        if (formula.selected) {
          this.editItem.itemObject.saveAppFormula(formula, null);
        }
      }
      if (groupFormulas) {
        this.editItem.itemObject.groupUngroupedFormulas(999, this.mixingBowl);  // Pass special group ID to receiver. These will be replaced with next group ID
      }
      if (this.requestedView == 'modal') {
        if (this.context == 'client')
          this.editQueue.payload(this.editItem);
        else
          this.editQueue.edit(this.editItem);

        this.modalCtrl.dismiss({modified: true, target: this.target});
      } else {
        this.editQueue.editAndReturn(this.editItem);
      }
    } else {
      if (this.context == 'client') {
        let payload: CLiCSColorSession = new CLiCSColorSession();
        // for (let formula of this.activeLibrary.filteredFormulas) {
        for (let formula of this.repo.selectedFormulas) {
          if (formula.selected) {
            payload.saveAppFormula(formula, null);
          }
        }
        if (groupFormulas) {
          payload.groupUngroupedFormulas(999, this.mixingBowl);  // Pass special group ID to receiver. These will be replaced with next group ID
        }

        this.modalCtrl.dismiss({modified: payload.isEmpty() == false, ca: payload, action: 'PAYLOAD', target: this.target});
      } else {
        // no passed APP, create one, add the colors and send to the Create APP page
        this.editItem = this.editQueue.createNew('CLiCSColorApplication');
        if (this.editItem) {
          // for (let formula of this.activeLibrary.filteredFormulas) {
          for (let formula of this.repo.selectedFormulas) {
            if (formula.selected) {
              this.editItem.itemObject.saveAppFormula(formula, null);
            }
          }
          // Group formulas with special group_id so downstream code knows to assign a group_id
          if (groupFormulas) {
            this.editItem.itemObject.groupUngroupedFormulas(999, this.mixingBowl);  // Pass special group ID to receiver. These will be replaced with next group ID
          }
          this.editQueue.send('lab', 'create_app');
        }
      }
    }
    this.resetColors();
  }

  // For simple mode color click, gets passed a single color and returns it in a payload. Assume client context
  // and modal view.
  useSingleColor(formula_token) {
    let payload: CLiCSColorSession = new CLiCSColorSession();
    const formula = this.activeLibrary.filteredFormulas.find(el => el.token == formula_token);
    if (!!formula) {
      payload.saveAppFormula(formula, null);
    }
    this.modalCtrl.dismiss({modified: payload.isEmpty() == false, ca: payload, action: 'PAYLOAD', target: this.target});
  }

  applySearch(event) {
    this.activeLibrary.applyFormulaSearch(event.target.value);
  }

  cancelSearch() {
    if (!!this.activeLibrary) {
      this.activeLibrary.cancelFormulaSearch();
    }
    this.showSearch = false;
  }

  toggleShowSearch() {
    if (!!this.showSearch)
      this.cancelSearch();
    else {
      this.showSearch = true;
      setTimeout(() => {
        this.searchBarElement.setFocus();
      }, 150);
    }
  }

  async showFilterPage() {
    const addClientModal = await this.modalCtrl.create({
      component: ColorFilterPage,
      componentProps: {filterSpec: this.activeLibrary.filterSpec},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });
    await addClientModal.present();
  }

  setFilter(filterSpec: any) {
    this.activeLibrary.applyFormulaFilter(filterSpec);
    // this.applyFilter(filterSpec);
  }

  // Clears filterSpec to the passed filterSpec object
  clearFilter(filterSpec: any) {
    this.activeLibrary.clearFormulaFilter(filterSpec);
  }

  // if in the process of adding an app color, cancel "use_lab_app" and navigate back to app page
  cancelChooseColor() {
    this.resetColors();
    if (this.requestedView == 'modal') {
      if (this.editItem)
        this.editQueue.cancel(this.editItem);
      this.modalCtrl.dismiss({modified: false});
    } else {
      if (this.editItem) {
        this.editQueue.cancelAndReturn(this.editItem);
      } else
        alert('can\'t cancel');
    }
  }

  // Cancel link is only when we are choosing a color from another workflow (e.g. add to Client APP)
  showCancel(): boolean {
    return (this.requestedView == 'modal' || this.editItem != null);
  }

  // Add To... link appears when one or more colors are selected in ALL COLORS view
  showAddTo(): boolean {
    return (this.selectedLibraryTitle == '' || this.target != 'colors');
  }

  // Show the settings icon only if the active library is owned by me
  showSettings(): boolean {
    return (this.activeLibrary.owned);
  }

  // Add To... link appears when one or more colors are selected in ALL COLORS view
  showRemove(): boolean {
    return (this.selectedLibraryTitle != '' && this.target == 'colors');
  }

  async promptForRemove() {
    let that = this;
    let buttons = [];
    buttons.push({
      text: `Remove selected from this ${this.modeCtrl.collectionStr(true)}`,
      handler: () => {
        const result = that.repo.removeSelectedFormulasFromLibrary(that.activeLibrary.token);
        this.clicsService.saveRepo();
        this.clicsService.apiRemoveFormulasFromLibrary(result.libraryToken, result.formulaTokens).then((data) => {
          this.clicsService.apiAssignFormulasToLibrary(result.defaultLibToken, result.orphanedFormulas);
        });
        this.clicsService.repo.clearFormulaSelection();

        // Notify user that some formulas could not be
        if (this.activeLibrary.token == result.defaultLibToken) {
          if (result.orphanedFormulas.length > 0) {
            let mssg = 'Could not remove formula from the default collection';
            if (result.orphanedFormulas.length > 1)
              mssg = `Could not remove some formulas from the default ${this.modeCtrl.collectionStr(true)}`;
            this.toastCtrl.create({
              message: mssg,
              duration: 3000,
              position: 'bottom'
            }).then(toast => toast.present());
          }
        }
        this._loadSelectedLibrary();
      }
    });

    buttons.push({
      text: 'Permanently delete selected formulas',
      handler: () => {
        that.doDeleteSelected();
      }
    });

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Prompts with a warning then deletes formulas from all folders
  async doDeleteSelected() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Deletion',
      message: 'This will permanently delete the selected formula(s) from all of your color collection. Continue with delete?',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            let _that = this;
            let result = this.clicsService.repo.archiveSelectedFormulas();
            this.clicsService.saveRepo();
            this.clicsService.apiRemoveColorFormulas(result.formulaTokens).then(data => {
              _that.clicsService.repo.clearFormulaSelection();
              _that._loadSelectedLibrary();
            });
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  // Open the Create Color page to build a custom color for the library
  async createNewLibraryColor() {
    if (this.target != 'colors') {
      return;
    }
    const createColorModal = await this.modalCtrl.create({
      component: CreateColorPage,
      componentProps: {context: 'new', clear: true},
      backdropDismiss: false,
    });

    createColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      if (data.modified) {
        if ('library' in data)
          this._reloadFromRepo(data.library);
        else
          this._reloadFromRepo(this.libraryIdent);
      }
    });

    await createColorModal.present();
  }

  // Reload this.activeLibrary.filteredFormulas with all formulas from the selected collection (or all colors)
  _loadSelectedLibrary(cancelSearch: boolean = true) {
    const hideAllColors: boolean = this.target == 'conversions';
    let searchTerm: string = null;
    if (this.repo) {
      if (cancelSearch) {
        this.cancelSearch();  // Close search bar
      } else {
        if (!!this.searchBarElement) {
          searchTerm = this.searchBarElement.value;
        }
      }

      this.activeLibrary.filteredFormulas = [];  // clear the display
      this.activeLibrary = null;

      // Attempt to load library by token
      if (!!this.libraryIdent) {
        const foundLib = this.repo.libraries.findIndex(el => el.token == this.libraryIdent);
        if (foundLib >= 0) {
          this.activeLibrary = this.repo.getLibraryByToken(this.libraryIdent);
        }
      }

      // Attempt to load library by title
      if (!this.activeLibrary && this.selectedLibraryTitle.length > 0) {
        this.activeLibrary = this.repo.getLibraryByTitle(this.selectedLibraryTitle);
      }

      // Load all colors if permitted
      if (!this.activeLibrary && !hideAllColors) {
        this.activeLibrary = this.repo.getCombinedLibrary('ALL COLORS', this.filter_by_product_line ? this.collection_str : null);
      }

      // Load empty library
      if (!this.activeLibrary) {
        this.activeLibrary = new CLiCSLibrary();
      }

      this.activeLibrary.sortFormulas();
      this.activeLibrary.applyFormulaFilter();
      if (!cancelSearch && !!searchTerm && searchTerm.length > 0) {
        this.applySearch({target: {value: searchTerm}});
      }
      this.multipleSelected = this.activeLibrary.selectedFormulas.length > 1;
    }
  }

  // Attempts to find the library by TOKEN or TITLE and to select it if found
  _findAndSelectLibrary(libraryIdent: string) {
    if (!this.repo) {
      return;
    }
    let foundTitle = this.repo.getLibraryTitles().find(el => (el == libraryIdent));
    if (foundTitle == undefined) {
      const matched = this.repo.libraries.find(el => (el.token == libraryIdent));
      if (matched)
        foundTitle = matched.title;
    }
    if (foundTitle != undefined) {
      this.cancelSearch();
      this.selectedLibraryTitle = foundTitle;
      this.libraryDisplay = this.selectedLibraryTitle;
      this._loadSelectedLibrary();
    }
  }

  // Save the identifier
  _saveLibraryChoice() {
    if (!!this.libraryIdent && this.libraryIdent.length > 0) {
      localStorage.setItem(this.target + 'LibIdent', this.libraryIdent);
    } else {
      localStorage.removeItem(this.target + 'LibIdent');
    }
    if (!!this.selectedLibraryTitle) {
      localStorage.setItem(this.target + 'LibTitle', this.selectedLibraryTitle);
    } else {
      localStorage.removeItem(this.target + 'LibTitle');
    }

    if (this.target == 'conversions') {
      localStorage.setItem('convManufacturer', this.manufacturerName);
      localStorage.setItem('conManId', String(this.manufacturerId));
    }
  }

  // Restore chosen library when entering the page
  _recallLibraryChoice() {
    this.libraryIdent = localStorage.getItem(this.target + 'LibIdent');
    this.selectedLibraryTitle = localStorage.getItem(this.target + 'LibTitle');
    if (!!this.selectedLibraryTitle && this.selectedLibraryTitle.length > 0) {
      this.libraryDisplay = this.selectedLibraryTitle;
    }
    if (this.selectedLibraryTitle == undefined) {
      this.selectedLibraryTitle = '';
    }
    if (this.target == 'conversions') {
      this.manufacturerName = localStorage.getItem('convManufacturer');
      this.manufacturerId = Number(localStorage.getItem('conManId'));
      if (isNaN(this.manufacturerId)) {
        this.manufacturerId = null;
      }
    }
  }

  // Remove any library identifiers saved for this page
  _forgetLibraryChoice() {
    localStorage.removeItem(this.target + 'LibIdent');
    localStorage.removeItem(this.target + 'LibTitle');

    if (this.target == 'conversions') {
      localStorage.removeItem('convManufacturer');
      localStorage.removeItem('conManId');
    }
  }

  // When use is called "in the blind" then prompt for a client to use it for
  // Group passes a group flag which indicates to set a non-zero group_id to all included FRs (if more than one)
  async confirmUseColor(group: boolean = false) {
    let buttons = [];
    if (this.clicsService.clientIsSelected()) {
      buttons.push({
        text: this.repo.multipleSelection() ? `Use chosen colors for ${this.clicsService.clientName()}` : `Use chosen color for ${this.clicsService.clientName()}`,
        handler: () => {
          this.doUseColor(false, group);
        }
      });
    }

    buttons.push({
      text: this.repo.multipleSelection() ? 'Select a client to use the chosen colors for...' : 'Select a client to use the chosen color for...',
      handler: () => {
        this.doUseColor(true, group);
      }
    });

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Stage app for use with current or next client
  doUseColor(selectNewClient: boolean = false, groupFormulas = false) {
    let ca = new CLiCSColorApplication();
    // for (let formula of this.activeLibrary.filteredFormulas) {
    for (let formula of this.repo.selectedFormulas) {
      formula.eco_type = 'default';
      formula.eco_amount = formula.amount;
      if (formula.selected) {
        ca.saveAppFormula(formula, null);
      }
    }
    if (groupFormulas) {
      ca.groupUngroupedFormulas(999, this.mixingBowl);  // Pass special group ID to receiver. These will be replaced with next group ID
    }

    // Close modal and return CA
    if (this.requestedView == 'modal') {
      this.modalCtrl.dismiss({ca: ca, action: 'PAYLOAD', modified: true, target: this.target, group: groupFormulas});
    } else {
      this.clicsService.stashData({modified: true, ca: ca, action: 'PAYLOAD', target: this.target});
      this.resetColors();
      if (selectNewClient == true || this.clicsService.activeClient() == null)
        this.events.publish('navrequest', {top: 'clients', page: 'clients'});
      else
        this.events.publish('navrequest', {top: 'clients', page: 'application'});
    }
  }

  // Show library selection action sheet
  async chooseLibrary() {
    const current_user: CLiCSUser = this.clicsService.current_user;
    if (current_user) {
      let buttons = [];
      for (let title of this.repo.getLibraryTitles(this.filter_by_product_line ? this.collection_str : null)) {
        buttons.push({
          text: title.toUpperCase(),
          handler: () => {
            this.selectedLibraryTitle = title;
            this.libraryDisplay = title;
            this._loadSelectedLibrary();
          }
        });
      }

      buttons.push({
        text: "Cancel",
        role: 'cancel'
      });

      const action = await this.actionSheetCtrl.create({
        header: `Choose ${this.modeCtrl.collectionStr()}`,
        buttons: buttons,
        mode: 'ios'
      });

      await action.present();
    }
  }

  clearBrandSelection() {
    if (this.target == 'conversions') {
      this.manufacturerName = 'Choose Brand...';
      this.manufacturerId = null;
      this.clearLibrarySelection();
    }
  }

  clearLibrarySelection() {
    this.libraryIdent = null;
    this.selectedLibraryTitle = '';
    if (this.target == 'conversions') {
      this.libraryDisplay = `${this.modeCtrl.collectionStr()}...`;
    }
    else {
      this.libraryDisplay = `${this.modeCtrl.collectionStr()}...`;
    }

    switch (this.target) {
      case 'clics':
        this.collection_str = 'CLICS';
        break;
      case 'topchic':
      case 'Topchic':
        this.collection_str = 'Topchic';
        this.filter_by_product_line = true;
        break;
      case 'colorance':
      case 'Colorance':
        this.collection_str = 'Colorance';
        this.filter_by_product_line = true;
        break;
      case 'goldwell':
        this.collection_str = 'Goldwell';
        break;
      case 'colors':
        this.collection_str = 'My Colors';
        break;
      case 'conversions':
        this.collection_str = 'Conversions';
        break;
    }

    this._loadSelectedLibrary();
    this._forgetLibraryChoice();
  }

  checkElegibleFormulas() {
    const selected: CLiCSColorFormula[] = this.activeLibrary.selectedFormulas;
    const selectedAndOwned: CLiCSColorFormula[] = selected.filter(el => (el.owned == true));

    if(selected.length === selectedAndOwned.length) return this.addSelectedToLibrary()

    const nonElegibleFormulas = selected.filter( formula => !selectedAndOwned.includes(formula))
    this.displayNonElegibleFormulasAlert(nonElegibleFormulas, selected)
  }

  async displayNonElegibleFormulasAlert(nonElegibleFormulas: CLiCSColorFormula[], selectedFormulas: CLiCSColorFormula[]) {
    let title = "Some formulas ineligible for saving"
    const cancelButton = { text: 'Cancel', role: 'cancel' }
    let buttons = [
      {
        text: 'Deselect ineligible',
        handler: () => this.deselectFormulas(nonElegibleFormulas)
      },
      cancelButton
    ]

    if (selectedFormulas.length == nonElegibleFormulas.length) {
      title = "Formulas ineligible for saving"
      buttons = [ cancelButton ]

      if (selectedFormulas.length == 1) title = "Formula ineligible for saving"
    }

    const alert = await this.alertCtrl.create({
      header: title,
      message: `You cannot save formulas from published collections to your private color ${this.modeCtrl.collectionsStr(true)}.`,
      buttons: buttons,
      mode: 'ios'
    });
    await alert.present();
  }

  deselectFormulas(formulas: CLiCSColorFormula[]){
    formulas.forEach(formula => this.doSelectColor(formula) )
  }

  // Selected formulas are added to a library, chosen by title
  async addSelectedToLibrary() {
    let buttons = [];
    const libs = this.repo.ownedLibraries();
    for (let library of libs) {
      buttons.push({
        text: library.title,
        handler: () => {
          this.selectedLibraryTitle = library.title;
          this.libraryDisplay = library.title;
          // let selectedFormulas = this.activeLibrary.selectedFormulas;
          let selectedFormulas = this.repo.selectedFormulas;
          const result = this.repo.includeSelectedFormulasInLibrary(library.token, selectedFormulas);
          this.clicsService.apiAssignFormulasToLibrary(result.libraryToken, result.formulaTokens).then((data) => {
            this.clicsService.repo.clearFormulaSelection();

            // If we've assigned to a library that's presented on a different page, redirect to that page, else reload library
            if (this._shouldRedirectByScope(library.scope)) {
              this._redirectByScope(library.scope, {c: library.token});
            } else {
              this._loadSelectedLibrary();
            }
          });
        }
      });

      buttons.push({
        text: 'Cancel',
        role: 'cancel',
      });
    }

    const action = await this.actionSheetCtrl.create({
      header: 'Add Selected Formulas To...',
      buttons: buttons,
      mode: 'ios'
    });

    await action.present();
  }

  async promptForLibrarySettings() {
    let buttons = [];
    buttons.push({
      text: 'Rename Collection',
      handler: () => {
        this.promptForLibraryName();
      }
    });

    buttons.push({
      text: `Remove ${this.modeCtrl.collectionStr()}`,
      handler: () => {
        const defaultLibrary = this.clicsService.repo.getDefaultLibrary();
        this.promptForRemoveCollection(this.activeLibrary.title, false, defaultLibrary.title);
      }
    });

    buttons.push({
      text: 'Remove and Delete Formulas',
      handler: () => {
        this.promptForRemoveCollection(this.activeLibrary.title, true);
      }
    });

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: `${this.modeCtrl.collectionsStr()} Settings`,
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Rename the current library unless a library with that name already exists
  doRenameLibrary(libraryName: string) {
    let that = this;
    let error: boolean = false;
    let message: string = 'Sorry, that collection name is already in use';

    // TODO: spinner here?
    // change name on the remote
    this.clicsService.apiRenameUserLibrary(this.activeLibrary, libraryName)
      .then((repoData) => {
        if (repoData.success) {
          this.clicsService.getRepo().then((repo) => {
            that.repo = repo;
            that._rebaseRepo(that);
            that.selectedLibraryTitle = libraryName;
            that.libraryDisplay = this.selectedLibraryTitle;
          });
        } else {
          error = true;
          if (repoData.message_key != 'mk_library_collection_name_already_exists')
            message = `Sorry... a problem occurred trying to rename this ${this.modeCtrl.collectionStr(true)}`;
          this.events.publish('library:rename:failure', {message: message})
        }
      });

  }

  async promptForLibraryName() {
    const alert = await this.alertCtrl.create({
      header: 'Change Collection Name',
      inputs: [
        {
          name: 'libraryName',
          placeholder: 'New Name'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Save',
          handler: (data) => {
            // Check if there is already a library with this name...
            if (this.repo.getLibraryByTitle(data.libraryName) == null) {
              this.doRenameLibrary(data.libraryName);
            } else {
              this.alertCtrl.create({
                header: `Sorry, that ${this.modeCtrl.collectionStr(true)} name is already in use`,
                buttons: [{text: 'Ok'}]
              }).then(warning => warning.present());
            }
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  // Deletes the active collection (library) then moves the formulas from that collection to the default collection
  async removeCollectionNoDelete() {
    if (this.activeLibrary.owned && !this.activeLibrary.default) {
      const removedTitle = this.activeLibrary.title;

      this.tout = setTimeout(async () => {
        this.loading = await this.loadingCtrl.create({
          spinner: 'bubbles',
          message: `Removing ${this.modeCtrl.collectionStr()}...`,
          duration: 10000
        });
        await this.loading.present();
      }, 50);

      let defaultLib = this.clicsService.repo.getDefaultLibrary();
      this.clicsService.apiRemoveUserLibrary(this.activeLibrary, defaultLib).then((result) => {
        // NOTE: main repo library removed in API method
        if (result.success) {
          this.clicsService.getRepo().then((repo) => {
            this.repo = repo;
            this._rebaseRepo();
            this._findAndSelectLibrary(result.default_library);
            this._clearLoading();
            this.toastCtrl.create({
              message: `The ${removedTitle} collection was successfully removed.`,
              duration: 3000,
              position: 'bottom'
            }).then(toast => toast.present());

          });
        } else {
          this._clearLoading();
        }
      });
    } else {
      let message: string = '';
      if (!this.activeLibrary.owned)
        message = `Sorry - unable to remove this ${this.modeCtrl.collectionStr(true)} since it's not owned by you`;
      else
        message = `This is your default ${this.modeCtrl.collectionStr(true)} and cannot be removed`;
      const alert = await this.alertCtrl.create({
        header: message,
        buttons: [{text: 'Ok'}],
        mode: 'ios'
      });
      await alert.present();
    }
  }

  // Deletes the active collection (library) then moves the formulas from that collection to the default collection
  async removeCollectionAndDelete() {
    if (this.activeLibrary.owned && !this.activeLibrary.default) {
      const removedTitle = this.activeLibrary.title;

      this.tout = setTimeout(async () => {
        this.loading = await this.loadingCtrl.create({
          spinner: 'bubbles',
          message: `Deleting ${this.modeCtrl.collectionStr()}...`,
          duration: 10000
        });
        await this.loading.present();
      }, 50);

      let formulasToRemove: string[] = [];
      for (let formula of this.activeLibrary.formulas)
        formulasToRemove.push(formula.token);

      // First, remove the formulas from the back end and library
      this.clicsService.apiRemoveColorFormulas(formulasToRemove).then((data) => {
        if (data.success == true) {
          for (let token of data.tokens) {
            this.clicsService.repo.removeFormula(token);
          }
          this.clicsService.saveRepo();

          let defaultLib = this.clicsService.repo.getDefaultLibrary();
          this.clicsService.apiRemoveUserLibrary(this.activeLibrary, defaultLib).then((result) => {
            // NOTE: main repo library removed in API method
            if (result.success) {
              this.clicsService.getRepo().then((repo) => {
                this.repo = repo;
                this._rebaseRepo();
                this._findAndSelectLibrary(defaultLib.token);
                this._clearLoading();
                this.toastCtrl.create({
                  message: `The ${removedTitle} collection was successfully removed.`,
                  duration: 3000,
                  position: 'bottom'
                }).then(toast => toast.present());

              });
            } else {
              this._clearLoading();
            }
          });
        } else {
          this._clearLoading();
          this.toastCtrl.create({
            message: `There was a problem removing this ${this.modeCtrl.collectionStr(true)}.`,
            duration: 3000,
            position: 'bottom'
          }).then(toast => toast.present());
        }
      });
    } else {
      let message: string = '';
      if (!this.activeLibrary.owned)
        message = `Sorry - unable to remove this ${this.modeCtrl.collectionStr(true)} since it's not owned by you`;
      else
        message = "This is your default collection and cannot be removed";

      const alert = await this.alertCtrl.create({
        header: message,
        buttons: [{text: 'Ok'}],
        mode: 'ios'
      });
      await alert.present();
    }
  }

  // Alert confirmation for removing a collection
  async promptForRemoveCollection(collectionName: string, withDelete: boolean = false, defaultCollection: string = 'default') {
    let message = `This will remove the ${collectionName} ${this.modeCtrl.collectionStr(true)} but keep the contents. Formulas from this ${this.modeCtrl.collectionStr(true)} will be available in the ${defaultCollection} ${this.modeCtrl.collectionStr(true)}. Continue?`;
    let title = `Remove ${this.modeCtrl.collectionStr()}`;
    if (withDelete) {
      message = `This will remove the ${collectionName} ${this.modeCtrl.collectionStr(true)} and will DELETE all of the formulas included in it. Continue?`;
      title = `Delete ${this.modeCtrl.collectionStr()}`
    }

    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Continue',
          handler: () => {
            if (withDelete == true)
              this.removeCollectionAndDelete();
            else
              this.removeCollectionNoDelete();
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  // Called by a server timer tick, checks whether the user's color library timestamp has advanced.
  checkUserTimestamps() {
    let _that = this;
    if (this.checkingServerTimer == false) {
      this.checkingServerTimer = true;
      this.clicsService.apiGetUserTimestamps().then((data) => {
        if (data.success) {
          if (data.lib_ts > _that.clicsService.current_user.lib_ts) {
            _that.clicsService.current_user.updateLibraryTimestamp(data.lib_ts);
            _that.clicsService.saveCurrentUser();
            _that.clicsService.repo.clearByScope('user');
            _that.clicsService.saveRepo();
            _that._loadRepository();
          }
        }
        _that.checkingServerTimer = false;
      }, (data) => {
        _that.checkingServerTimer = false;
      });
    }
  }

  // Establish minimums then show swatch amount prompt
  promptSwatch() {
    this.setSwatchLimits();
    this.promptForSwatch = true;
  }

  // Iterate through chosen formulas and find lowest acceptable swatch amount
  setSwatchLimits() {
    this.minFormulaWeight = 10;
    this.defaultWeight = 20;
    this.maxFormulaWeight = 30;

    let selected = this.activeLibrary.filteredFormulas.filter((el) => {
      return (el.selected == true)
    });

    // Find lowest limit for all selected formulas
    for (let formula of selected) {
      let minAllow = this.mixingBowl.minAllowableWeight(formula);
      if (minAllow > this.minFormulaWeight) {
        this.minFormulaWeight = minAllow;
      }
    }

    // Establish 10g min and adjust default as needed
    if (this.minFormulaWeight < 10)
      this.minFormulaWeight = 10;
    else {
      if (this.minFormulaWeight > 10) {
        this.maxFormulaWeight = this.minFormulaWeight + 20;
      }
      if (this.minFormulaWeight > this.defaultWeight)
        this.defaultWeight = this.minFormulaWeight;
    }
  }

  async chooseConversionManufacturer() {
    var buttons = [];
    const manufacturers = this.repo.getConversionManufacturers();
    for (let man of manufacturers) {
      buttons.push({
        text: man.name.replace(/&\S+;/, ''),
        handler: () => {
          this.manufacturerName = man.name;
          this.manufacturerId = man.id;
          this.clearLibrarySelection();

          // Auto-select the collection if only 1 exists
          const conversionLibs = this.repo.getConversionLibraryTitles(this.manufacturerId);
          if (conversionLibs.length == 1) {
            this.selectedLibraryTitle = conversionLibs[0].title;
            this.libraryIdent = conversionLibs[0].token;
            this.libraryDisplay = conversionLibs[0].title;
            this._loadSelectedLibrary();
          } else {
            this.chooseConversionLibrary();
          }
        }
      });
    }

    buttons.push({
      text: "Cancel",
      role: 'cancel',
      handler: () => {
        this.clearBrandSelection();
      }
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Conversion Brand',
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Show library selection action sheet
  async chooseConversionLibrary() {
    let buttons = [];
    const conversionLibs = this.repo.getConversionLibraryTitles(this.manufacturerId);
    for (let lib of conversionLibs) {
      buttons.push({
        text: lib.title.replace(/&.+;/, '').toUpperCase(),
        handler: () => {
          this.selectedLibraryTitle = lib.title;
          this.libraryIdent = lib.token;
          this.libraryDisplay = lib.title;
          this._loadSelectedLibrary();
        }
      });
    }

    buttons.push({
      text: "Cancel",
      role: 'cancel',
      handler: () => {
        this.clearBrandSelection();
      }
    });

    const action = await this.actionSheetCtrl.create({
      header: `Choose ${this.modeCtrl.collectionStr()}`,
      buttons: buttons,
      mode: 'ios'
    });

    await action.present();
  }


  // Returns HTML for color swatches matching the
  getConversionLibraries(): Promise<boolean> {
    let _that = this;
    this.tout = setTimeout(async () => {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Loading conversions',
        duration: 15000
      });
      await this.loading.present();
    }, 500);

    return this.clicsService.getConversionRepo().then((repo) => {
      _that.repo = repo;
      _that._clearLoading();

      // TODO: if library selected, load that library

      return true;
    }).catch((result) => {
      this._clearLoading();
      this.toastCtrl.create({
        message: 'An error occurred loading conversions',
        duration: 3000,
        position: 'bottom'
      }).then(toast => toast.present());

      return false;
    });
  }

  _clearLoading(that: any = null) {
    if (!that) {
      that = this;
    }
    if (!!that.tout) {
      clearTimeout(that.tout);
      that.tout = null;
    }
    if (!!that.loading) {
      that.loading.dismiss();
      that.loading = null;
    }
  }

  closeModal() {
    if (this.requestedView == 'modal') {
      this.modalCtrl.dismiss();
    }
  }
}
