import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentProvider} from "../../services/content/content";

/**
 * Similar to report-list-with-detail but has per-row drill-down for detail data that is normally hidden but opens on icon click
 */

@Component({
  selector: 'report-list-expandable',
  templateUrl: 'report-list-expandable.html',
  styleUrls: ['report-list-expandable.scss'],
})
export class ReportListExpandableComponent {
  @Input() data: any = null;
  @Input() small_header: boolean = false;
  @Output() listClick = new EventEmitter();

  cols: number = 2;
  title: string = null;
  headings: string[] = [];
  hasHeadings: boolean = false;

  list_items: any[] = [];
  list_item_details: any[] = [];

  constructor(
    public content: ContentProvider
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.cols = this.data.cols.length;
      this.title = this.data.title;
      for (const col of this.data.cols) {
        if (col.title && col.title != '') {
          this.hasHeadings = true;
          this.headings.push(col.title)
        } else
          this.headings.push('')
      }

      for (const item of this.data.items) {
        this.list_items.push(item);
      }
      for (const item of this.data.details) {
        this.list_item_details.push(item);
      }

    }
  }

  isLinkColumn(column_index: number) {
    return (this.data.cols[column_index].link_type != null);
  }

  isIconColumn(column_index: number) {
    return (this.data.cols[column_index].var_type == 'icon');
  }

  alignRight(index: number): boolean {
    return (this.data.cols[index].var_type == 'number');
  }

  detailRight(index: number): boolean {
    return (this.data.detail_cols[index].var_type == 'number');
  }

  handleAttrClick(column_index: number, token: string) {
    this.listClick.emit({type: this.data.cols[column_index].link_type, token: token});
  }

  openDetail(item: any) {
    item.visible = true;
  }

  closeDetail(item: any) {
    item.visible = false;
  }
}
