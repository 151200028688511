import {Injectable, Injector, Inject} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {CLiCSService} from '../clics.service';
import {EventsService} from '../events/events.service';
import {CLiCSClientPhoto} from '../../../lib/photo';
import {CLiCSClient} from '../../../lib/client';
import { Network } from '@ionic-native/network/ngx';
import * as Rollbar from 'rollbar';
import {RollbarService} from '../../../lib/rollbar';

/*
  A provider used to store image objects and upload them to the back-end server. As photos are added they are stored
  locally (method TBD) and are uploaded to the back-end server.
*/
@Injectable({
  providedIn: 'root',
})
export class PhotoStoreProvider {
  private photos: CLiCSClientPhoto[] = [];
  private clicsService: CLiCSService;
  private defaultClientImage = 'assets/images/headshot-no-photo.png';
  private loadingImage = 'assets/images/headshot-loading.png';
  private uploading: boolean = false;    // indicated upload in progress

  private currentUpload: string = null;  // Set to request_ident of image being uploaded to the server

  readonly cameraOptions: Object = {
    quality: 80,
    targetWidth: 1000,
    targetHeight: 1000,
    sourceType: this.camera.PictureSourceType.CAMERA,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediatype: this.camera.MediaType.PICTURE,
    correctOrientation: true
  };

  readonly cameraLibraryOptions: Object = {
    quality: 80,
    targetWidth: 1000,
    targetHeight: 1000,
    sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediatype: this.camera.MediaType.PICTURE,
    correctOrientation: true
  };

  constructor(private events: EventsService,
              private injector: Injector,
              private camera: Camera,
              private plt: Platform,
              private network: Network,
              @Inject(RollbarService) public rollbar: Rollbar) {
    this.clicsService = this.injector.get(CLiCSService);
    this.events.subscribe('client_photo:check', () => {this.saveNewPhotos()});
  }

  clear() {
    this.photos.length = 0;
  }

  // All program elements using photos should call this function, NOT apiGetClientPhotos. This avoids circular
  // references between this provider and CLiCSService.
  getClientPhotos(client: CLiCSClient, latestPhoto: CLiCSClientPhoto = null): Promise<boolean> {
    return this.clicsService.apiGetClientPhotos(client, latestPhoto ? latestPhoto.token : null)
        .then(photos => {
          this.addPhotos(photos);
          return true;
        }, () => {return false;});
  }

  // Returns a photo
  clientHasPhotos(client: CLiCSClient): boolean {
    const index = this.photos.findIndex((el) => {
      return (el.cli == client.token);
    });
    return index >= 0;
  }

  // Takes an array of photo data object and adds them to the local photo store, updating any existing photos that match
  addPhotos(photos: any[]) {
    for (let photo of photos)
      this.addPhoto(photo);
    // Sort photos, oldest to newest
    this.photos.sort((a, b) => {
      if (a.taken_at && b.taken_at)
        return(a.taken_at.getTime() - b.taken_at.getTime());
      else
        return(0);
    });
  }

  // Adds one photo to this image store. If the photo already exists then update it with this informaiton
  addPhoto(photoData: any) {
    let existing = this.photos.find((el) => {
      return((el.token && el.token == photoData.token) || (photoData.request_ident))
    });
    if (existing) {
      // TODO: return true/false is the existing object was changed or not. Return a bool or count of changes.
      // TODO: set modified flag so upload to server will happen
      existing.loadObj(photoData);
    }
    else
      this.photos.push(new CLiCSClientPhoto(photoData));

    // Establish profile photo as new profile photo is added
    if ('is_profile' in photoData) {
      if (photoData.is_profile == true) {
        this.setProfilePhoto(photoData.token);
      }
    }
  }

  // Adds one photo to this image store. If the photo already exists then update it with this informaiton
  removePhoto(photo: CLiCSClientPhoto): Promise<boolean> {
    if (!!photo) {
      let _that = this;
      const index = this.photos.findIndex(el => el.token == photo.token);
      if (index >= 0) {
        this.clicsService.apiRemoveClientPhoto(photo).then(result => {
          if (result.success) {
            _that.photos.splice(index, 1);
            this.events.publish('client_photo:removed');
          }
          return(Promise.resolve(result.success));
        }, reason => {
          return(Promise.resolve(false));
        });
      } else {
        return(Promise.resolve(false));
      }
    }
  }

  // Sets is_profile for photo matched by token, clears is_profile for all other photos assigned to this client.
  // If not matched then existing profile photo flag is not cleared.
  setProfilePhoto(photoToken, updateRemote: boolean = false): Promise<boolean> {
    let existing = this.photos.find((el) => {
      return( !!el.token && el.token == photoToken )
    });
    if (!!existing) {
      for (let photo of this.photos) {
        if (photo.token != existing.token && photo.cli == existing.cli) {
          photo.is_profile = false;
        }
      }
      existing.is_profile = true;
      if (!!updateRemote) {
        this.clicsService.apiSetProfilePhoto(existing).then((result) => {
          return result.success;
        });
      } else {
        return Promise.resolve(true);
      }
    } else {
      return Promise.resolve(false);
    }
  }

  // Uploads any new photos to the remote server. If an upload is already in progress this passes through.
  saveNewPhotos(photo_request_ident: string = null) {
    if (!this.currentUpload && !(this.network.type == 'none')) {
      let newPhotoIndex: number = -1;
      let selected: any = null;
      const newPhotos = this.photos.filter((el) => {
        return (el.isNew());
      });

      if (newPhotos.length == 0) {
        return;
      }

      // Look for a specifically requested photo, typically to retry the upload
      if (!!photo_request_ident) {
        selected = newPhotos.find((el) => {
          return (el.request_ident = photo_request_ident);
        });

        // If 2+ attempts already then fall through to random photo choice
        if (!!selected && selected.attempts < 2) {
          newPhotoIndex = this.photos.findIndex((el) => {
            return (el.request_ident == selected.request_ident);
          });
        }
      }

      // Choose a random new photo to upload (avoids hanging up on one bad file)
      if (!newPhotoIndex || newPhotoIndex < 0) {
        selected = newPhotos[Math.floor(Math.random() * newPhotos.length)];

        // Convert the selected element back into a photo index
        if (!!selected && !!selected.request_ident) {
          newPhotoIndex = this.photos.findIndex((el) => {
            return (el.request_ident == selected.request_ident);
          });
        }
      }

      // Failsafe...
      if (!newPhotoIndex || newPhotoIndex < 0) {
        newPhotoIndex = this.photos.findIndex((el) => {
          return (el.isNew());
        });
      }

      if (newPhotoIndex >= 0) {
        let newPhoto = this.photos[newPhotoIndex];
        if (newPhoto.readyForAttempt()) {
          newPhoto.touch();
          this.currentUpload = newPhoto.request_ident || 'uploading';
          this.clicsService.apiAddClientPhoto(newPhoto)
            .then((data) => {
              if (!!data.success) {
                newPhoto.loadObj(data.photo);
                newPhoto.touch();
                this.currentUpload = null;
                this.events.publish('client_photo:uploaded', {css: data.photo.css, when_taken: data.photo.when_taken});
                this.events.publish('client_photo:check');
              } else {
                this.currentUpload = null;
                newPhoto.addAttempt();
                this.logFailedUpload(newPhoto, 'API success false')
                this.events.publish('client_photo:failed', {request_ident: newPhoto.request_ident});
                this.events.publish('client_photo:check');
              }
            }, (reason) => {
              this.currentUpload = null;
              newPhoto.addAttempt();
              this.logFailedUpload(newPhoto, 'apiAddClientPhoto rejected')
              this.events.publish('client_photo:failed', {request_ident: newPhoto.request_ident});
              this.events.publish('client_photo:check');
            });
        }
      }
    }
  }

  // Logs a failed attempt to Rollbar
  logFailedUpload(photo: CLiCSClientPhoto, details: string = '') {
    let mssg = "PhotoStore failed to upload image";
    if (photo.attempts == 3) {
      mssg = mssg + ' 3 times';
    } else {
      if (photo.attempts % 10 == 0) {
        mssg = mssg + ` ${photo.attempts} times`;
      } else {
        return; // Don't report every failure
      }
    }

    this.rollbar.warn(mssg,
      {
        image_request_ident: photo.request_ident,
        user_token: this.clicsService.current_user.api_token,
        user_name: this.clicsService.current_user.full_name,
        platform: this.plt.platforms().join(),
        details: details
      }
    );
  }

  // Adds one photo to this image store. If the photo already exists then update it with this information
  // Is profile flags this image as the profile photo
  addDevicePhoto(imgData: string, client: CLiCSClient, css: string = null, when_taken: string = null, timestamp: Date = null, is_profile = false) {
    const photoObj = {
      cli: client.token,
      css: css,
      when_taken: when_taken,
      timestamp: timestamp ? timestamp : new Date(),
      is_profile: is_profile
    };
    let newPhoto = new CLiCSClientPhoto(photoObj);
    newPhoto.setImageData(imgData);

    // de-assign before / after for existing session photos
    if (!!css && newPhoto.when_taken == 'before' || newPhoto.when_taken == 'after') {
      for (let photo of this.photos) {
        if (photo.css == css && photo.when_taken == newPhoto.when_taken) {
          photo.when_taken = 'unspecified';
        }
      }
    }

    // de-assign existing profile photo for this client if this is a new profile photo
    if (!!client && !!is_profile) {
      for (let photo of this.photos) {
        if (photo.cli == client.token && photo.is_profile) {
          photo.is_profile = false;
        }
      }
    }

    this.photos.push(newPhoto);
    console.log(`${this.photos.length} photos now in the local photo store`)
    this.saveNewPhotos();
    this.events.publish('client:updated', client);
  }

  // Returns all photos associated with a Color Session (css = CS.token)
  sessionPhotos(css: string, omitBeforeAfter: boolean = false): any {
    let result = {before: null, after: null, all: []};
    if (!!css && css.trim().length > 0) {
      let filtered = [];
      if (!!omitBeforeAfter)
        filtered = this.photos.filter(el => (el.css == css && el.when_taken != 'before' && el.when_taken != 'after'));
      else
        filtered = this.photos.filter(el => el.css == css);

      for (let photo of filtered) {
        result.all.push(photo);
        if (photo.when_taken == 'before')
          result.before = photo;
        if (photo.when_taken == 'after')
          result.after = photo;
      }
    }

    // Sort photos, oldest to newest
    result.all.sort((a, b) => {
      if (a.taken_at && b.taken_at)
        return(a.taken_at.getTime() - b.taken_at.getTime());
      else
        return(0);
    });

    return result;
  }

  // Returns all photos associated with a client token (cli)
  clientPhotos(cli: string): CLiCSClientPhoto[] {
    let photos = [];
    if (!!cli && cli.trim().length > 0) {
      const filtered = this.photos.filter(el => el.cli == cli);
      for (let photo of filtered) {
        photos.push(photo);
      }
    }

    // Sort photos, oldest to newest
    photos.sort((a, b) => {
      if (a.taken_at && b.taken_at)
        return(a.taken_at.getTime() - b.taken_at.getTime());
      else
        return(0);
    });

    return photos;
  }

  // Returns all after photos for a client
  afterPhotos(client: CLiCSClient) {
    return this.photos.filter(el => el.cli == client.token && el.when_taken == 'after');
  }

  // Returns the src string (URL or base64 image data) for the "photo-of-record" for this client.
  clientAppPhoto(client: CLiCSClient): CLiCSClientPhoto {
    let matches = this.photos.filter((el) => {
      return (el.cli == client.token && el.when_taken == 'after');
    });
    if (matches.length > 0)
      return (matches[matches.length - 1]);
    else {
      // If no AFTER shots available, return the most recent photo of any type
      let matches = this.photos.filter((el) => {
        return (el.cli == client.token);
      });
      if (matches.length > 0)
        return (matches[matches.length - 1]);
      else
        return null;
    }
  }

  // Returns the last (newest) photo for this client
  clientRecentPhoto(client: CLiCSClient): CLiCSClientPhoto {
    let matches = this.photos.filter((el) => {
      return (el.cli == client.token && (el.when_taken == 'after' || el.when_taken == 'unspecified'));
    });
    if (matches.length > 0)
      return (matches[matches.length - 1]);
    else
      return null;
  }

  // Returns the last photo marked as Profile for this client
  clientProfilePhoto(client: CLiCSClient): CLiCSClientPhoto {
    let match = this.photos.find((el) => {
      return (el.cli == client.token && el.is_profile == true);
    });
    if (!match) {
      match = this.clientRecentPhoto(client);
    }
    return (match);
  }

  // Where external filtering logic is used, this returns the path to the local "No Image" img
  getDefaultImgSrc(): string {
    return this.defaultClientImage;
  }

  // Loading Photos image
  getLoadingImgSrc(): string {
    return this.loadingImage;
  }

}
