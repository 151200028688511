import {Component} from '@angular/core';
import {AlertController, LoadingController, NavController, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ColorScannerProvider} from '../../services/color-scanner/color-scanner';
import {EventsService} from '../../services/events/events.service';

/**
 * Generated class for the ScanPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-scan',
  templateUrl: 'scan.html',
  styleUrls: ['scan.scss'],
})
export class ScanPage {
  level: number = 0;
  levelStr: string = "";
  timer: any = null;  // connection timer
  connected: boolean = false;
  noDevices: boolean = false; // no devices found
  loading: any = null;
  connectRetryPeriod: number = 5000;

  scannerInfoSubscription: Subscription = null;
  scannerConnectSubscription: Subscription = null;
  scannerFindSubscription: Subscription = null;
  scannerDisconnectSubscription: Subscription = null;
  scannerCalibrateSubscription: Subscription = null;
  scannerMeasurementSubscription: Subscription = null;
  modalDismissSubsription: Subscription = null;

  constructor(private navCtrl: NavController,
              private colorScanner: ColorScannerProvider,
              private events: EventsService,
              private modalCtrl: ModalController,
              private loadingCtrl: LoadingController,
              private alertCtrl: AlertController) {
  }

  ionViewDidEnter() {
    this.scannerInfoSubscription = this.events.subscribe("scanner:info", this.handleScannerInfo);
    this.scannerConnectSubscription = this.events.subscribe("scanner:connect", this.handleConnect);
    this.scannerFindSubscription = this.events.subscribe("scanner:find", this.handleFindScanners);
    this.scannerDisconnectSubscription = this.events.subscribe("scanner:disconnect", this.handleDisconnect);
    this.scannerCalibrateSubscription = this.events.subscribe("scanner:calibrate", this.handleCalibrate);
    this.scannerMeasurementSubscription = this.events.subscribe("scanner:measurement", this.handleMeasurement);
    this.colorScanner.checkStatus();
    this.modalDismissSubsription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidLeave() {
    this.scannerInfoSubscription = this.events.unsubscribe(this.scannerInfoSubscription);
    this.scannerConnectSubscription = this.events.unsubscribe(this.scannerConnectSubscription);
    this.scannerFindSubscription = this.events.unsubscribe(this.scannerFindSubscription);
    this.scannerDisconnectSubscription = this.events.unsubscribe(this.scannerDisconnectSubscription);
    this.scannerCalibrateSubscription = this.events.unsubscribe(this.scannerCalibrateSubscription);
    this.scannerMeasurementSubscription = this.events.unsubscribe(this.scannerMeasurementSubscription);
    this.modalDismissSubsription = this.events.unsubscribe(this.modalDismissSubsription);
  }

  setLevel(newLevel: number) {
    this.level = newLevel;
    if (newLevel > 0 && newLevel < 13) {
      this.levelStr = `${newLevel}`;
    }
    else {
      this.levelStr = "";
    }
  }

  async requestMeasurement() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'measuring...',
      duration: 4000,
    });
    await this.loading.present();
    this.colorScanner.measure();
  }

  handleCalibrate = (data: any) => {
    console.log("ScanPage: handleCalibrate reached with data" + data);
  };

  handleMeasurement = (data: any) => {
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }

    console.log(`ScanPage: handleMeasurement reached with level ${data.Level}`);
    this.setLevel(data.Level);
  };

  handleFindScanners = (data: any) => {
    let dataObj: any = JSON.parse(data);
    console.log("ScanPage: handleFindScanners reached with data " + data);
    if (this.colorScanner.isConnected() == false && dataObj.ready == true) {
      this.noDevices = false;
      this.colorScanner.connect();
    }
    else {
      if (this.colorScanner.isConnected()) {
        console.log("handleFindScanners: device already connected.");
        this.connected = true;
        this.noDevices = false;
        if (this.colorScanner.mustCalibrate())
          this.promptForCalibration();
      }
      else {
        if (dataObj.ready) {
          console.log("handleFindScanners: ready to connect."); // TODO: logic just above should capture this. FIX!
          this.noDevices = false;
          this.colorScanner.connect();
        }
        else {
          console.log("handleFindScanners: no available devices found.");
          this.noDevices = true;
          this.colorScanner.find(); // recurse on finding scanners
        }
      }
    }
  };

  async promptForCalibration() {
    const alert = await this.alertCtrl.create({
      header: 'Calibrate Scanner',
      message: "For best accuracy the color scanner must be calibrated. Please place the cover on the scanner and press Calibrate to continue.",
      buttons: [
        {
          text: 'Calibrate',
          handler: () => {
            this.colorScanner.calibrate();
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  handleScannerInfo = (data: any) => {
    console.log("ScanPage: handleScannerInfo reached");
    console.log("Callback connection status " + this.colorScanner.isConnected());

    // this.connectionInfo = data;
    if (this.colorScanner.isConnected() == false) {  // TODO: parse out boolean strings
      console.log("ScanPage: NO DEVICE CONNECTED");
      console.log("ScanPage: calling colorScanner.find() SINCE NOT CONNECTED");
      this.colorScanner.find();
    }
    else {
      console.log("ScanPage: device appears to be connected");
      this.connected = true;
      if (this.colorScanner.mustCalibrate())
        this.promptForCalibration();
    }

  };

  handleConnect = (data: any) => {
    this.connected = true;
    console.log("ScanPage: handleConnect reached");
  };

  handleDisconnect = () => {
    this.connected = false;
    console.log("ScanPage: handleDisconnect reached");
  };

  closeModal(shouldUse: boolean = false) {
    this.modalCtrl.dismiss({use: shouldUse, level: this.level});
  }

}
