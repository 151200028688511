/*
    Defines a CLICS Color "Application" (collection of color formulas with amounts and positions).
    The actual formula is kept in a string format. Individual component handling will come later.

 */

import {CLiCSColorFormula} from "./color-formula";
import {CLiCSApplication} from "./application-base";

export class CLiCSColorApplication extends CLiCSApplication {
  id: number = null;
  formulas: Array<CLiCSColorFormula> = [];
  thumb: string = '';
  owned: boolean = true;
  archived_at: Date = null;
  deleted: boolean = false;
  scope: string = 'all';   // Set externally, used for filtering
  active: boolean = true;  // Used internally for repo castAs() - filter by target page

  constructor(data: any = null, owned: boolean = true) {
    super(data);
    this.owned = owned;
    this.formulas = [];
    this.thumb = '';
    if (data != null) {
      if (typeof data === 'object')
        this.loadObj(data);
      else {
        if (typeof data === 'string') {
          this.loadStr(data)
        }
      }
    }
  }

  loadObj(data: any) {
    if (data !== undefined) {
      super.loadObj(data);
      if ('id' in data)
        this.id = data.id;
      if ('thumb' in data)
        this.thumb = data.thumb;
      if ('app_type_ident' in data)
        this.app_type_ident = data.app_type_ident;
      if ('app_type_name' in data)
        this.app_type_name = data.app_type_name;
      if ('archived_at' in data) {
        if (data.archived_at == null)
          this.archived_at = null;
        else
          this.archived_at = new Date(data.archived_at);
      }
      if ('deleted' in data)
        this.deleted = data.deleted;
      if ('owned' in data)
        this.owned = data.owned == true;
      if (data.starting_level)
        this.setStartingLevel(data.starting_level);
      if ('formulas' in data) {
        this.formulas.length = 0;
        for (let formula of data.formulas) {
          this.formulas.push(new CLiCSColorFormula(formula));
        }
      }
    }
  }

  // Clears the data from this app / color session
  clear() {
    super.clear();
    this.id = null;
    this.owned = true;
    this.formulas.length = 0;
  }

  // Adds or updates an formula in the CA
  saveAppFormula(source_formula: CLiCSColorFormula, amount: number = null, use_cobonder: boolean = false): CLiCSColorFormula {
    let formula = this.formulas.find((element) => {
      return (element.token == source_formula.token);
    });
    if (formula != undefined) {
      formula.load(source_formula, true);
    } else {
      formula = new CLiCSColorFormula(source_formula);
      if (use_cobonder)
        formula.setCobonded(true);
      // if (source_formula.mode == 'library')
      //     formula.setAmount(40.0);  // TODO: lookup default location and amounts
      // else
      //     formula.setAmount(source_formula.totalAmount());  // TODO: lookup default location and amounts
      this.formulas.push(formula);
    }
    return formula;
  }

  // Searches this CS for a formula matching the passed token or request_ident (FRs only)
  findFormula(token: string, request_ident: string = null): CLiCSColorFormula {
    const index = this.findFormulaIndex(token, request_ident);
    if (index >= 0)
      return this.formulas[index];
    else
      return null;
  }

  className(): string {
    return 'CLiCSColorApplication'
  }
}

