import { Component } from '@angular/core';

/**
 * Generated class for the ColorLibraryComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'color-library',
  templateUrl: 'color-library.html',
  styleUrls: ['color-library.scss'],
})
export class ColorLibraryComponent {

  text: string;

  constructor() {
    console.log('Hello ColorLibraryComponent Component');
    this.text = 'Hello World';
  }

}
