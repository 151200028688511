/*
 CLICS Service class

 This class acts as both the interface to the CLICS Server - online or local - and maintains the state of the app.
 It is shared by most components in the app. It handles both retrieval and updating of settings and the creation of
 clients sessions and colors.

 The app_token is a generate-once (per session) code that's used to coordinate logins and logouts.
 */
import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {DateTime} from 'luxon';
import {v1 as uuidv1} from 'uuid';
import * as Rollbar from 'rollbar';
import {RollbarService} from '../../lib/rollbar';

import {EventsService} from './events/events.service';
import {MessagingProvider} from './messaging/messaging';
import {ModeControllerProvider} from './mode-controller/mode-controller';
import {CLiCSClient} from '../../lib/client';
import {CLiCSUser} from '../../lib/user';
import {CLiCSSalon} from '../../lib/salon';
import {FlashMessage} from '../../lib/flash';
import {CLiCSLibrary} from '../../lib/library'
import {CLiCSColorFormula} from '../../lib/color-formula';
import {CLiCSColorSession} from '../../lib/session';
import {CLiCSFormulaRequest} from '../../lib/request';
import {CLiCSFormula} from '../../lib/formula';
import {CLiCSProduct} from '../../lib/product';
import {CLiCSManifest} from '../../lib/manifest';
import {CLiCSAppType} from '../../lib/app-type';
import {CLiCSColorApplication} from '../../lib/color-application';
import {CLiCSContent} from '../../lib/content';
import {CLiCSClientPhoto} from '../../lib/photo';
import {CLiCSRepository} from '../../lib/repository';
import {CLiCSClientNote} from '../../lib/client-note';
import {getWithRetry, postWithRetry} from '../../lib/delayed-retry';
import {promise} from "protractor";

const STAGING_ENDPOINT = 'https://staging.clics.com';
// const STAGING_ENDPOINT = 'http://192.168.86.187:3000';  // home

const PRODUCTION_ENDPOINT = 'https://account.clics.com';
// const PRODUCTION_ENDPOINT = 'http://192.168.86.244:3000';  // home
// const PRODUCTION_ENDPOINT = 'http://192.168.1.170:3000';  // office

// const LOCAL_ENDPOINT = 'http://local.clicscolors.com:3000';
const LOCAL_ENDPOINT = 'http://localhost:3000';
// const LOCAL_ENDPOINT = 'http://192.168.1.231:3000';  // office
// const LOCAL_ENDPOINT = 'http://192.168.86.244:3000';  // home
// const LOCAL_ENDPOINT = 'https://account.clics.com';
// const LOCAL_ENDPOINT = 'https://staging.clics.com';

@Injectable({
  providedIn: 'root'
})
export class CLiCSService {
  public static DEVELOPMENT_MODE = false;  // CRITICAL: set this FALSE for Production releases!

  app_ident = null;  // Unique app identifier
  app_mode = null;
  manifest: CLiCSManifest = null;
  login_token: string = null;
  session_token: string = null;
  current_salon: CLiCSSalon = null;
  current_user: CLiCSUser = null;
  content: CLiCSContent = null;
  theme: string;
  autoTheme = true;
  themeListener: any = null;

  // Injected providers
  modeCtrl: ModeControllerProvider = null;
  messagingCtrl: MessagingProvider = null;

  repo: CLiCSRepository = null;  // This is a new library management container as or 11.2019

  // Machine queues - not saved in local storage
  dispenser_queue: CLiCSColorSession[] = [];
  swatch_queue: CLiCSColorSession[] = [];
  quick_queue: CLiCSColorSession[] = [];
  unweighed_queue: CLiCSColorSession[] = [];

  current_formula: CLiCSColorFormula = null;  // current lab formula for editing
  current_color_application: CLiCSColorApplication = null;  // current lab color session for editing
  use_lab_app: boolean = false;  // add chosen/created colors to Lab App (current color session)

  flash = null;
  last_modified = null;
  online: boolean = true;
  possibly_offline = false; // if a single offline is detected then set this true. If ping fails set this.online = false
  prior_client_token: string = null; // Previously selected client for this user

  // Staged objects to be used for the next selected client
  stagedColorApplication: any = null;  // May be a ColorApplication or a ColorSession
  stagedColorFormula: CLiCSColorFormula = null;

  swatchHistory: any[] = [];
  dispenseHistory: any[] = [];
  lastWakeTime: Date = null;
  lastUpdateTime: Date = null;

  private dataStash = {modified: false, ca: null, cf: null, data: null, action: 'NONE', source: null, updated_at: null, reload: false, target: null};  // Place to stash object and ACTION fof

  private endpoint_url = PRODUCTION_ENDPOINT;
  public endpoint_description = 'production';

  private salons: CLiCSSalon[] = [];   // List of salons accessible to this user
  private clients: CLiCSClient[] = []; // List of clients accessible to this user

  private dispenseMode: string = null;  // 'simple' (no clients) 'advanced' (normal, client list)

  constructor(private http: HttpClient,
              private events: EventsService,
              private plt: Platform,
              private statusBar: StatusBar,
              private injector: Injector,
              @Inject(RollbarService) public rollbar: Rollbar) {
    this.modeCtrl = this.injector.get(ModeControllerProvider);
    this.messagingCtrl = this.injector.get(MessagingProvider);

    this.loadFromLocal();
    this.assertAppIdent();

    this.flash = new FlashMessage('en');

    // NOTE: CLiCSService.DEVELOPMENT_MODE (above) MUST be off for production releases
    if (CLiCSService.DEVELOPMENT_MODE) {
      // MIGRATION NOTE: 'windows' is no longer a recognized platform, previous
      // logic specified !this.plt.is('windows') as well
      if (this.plt.is('desktop')) {
        this.endpoint_url = LOCAL_ENDPOINT;
        console.log('Using local testing server URL');
      } else {
        console.log(`Using ${this.endpoint_description} server URL ${this.endpoint_url}`);
      }
    }

    if (this.activeClient()) {
      this.apiGetColorSession(this.activeClient());
    }

    if (!this.app_mode)
      this.app_mode = 'personal';

    // If autoTheme is set true then follow the device theme
    setTimeout(() => {
      this.getTheme();
      this.setTheme('', false);
      this.followDeviceTheme();
    }, 2000);

    // When a color session has been replaced by another color session object
    console.log("++++ INSTANTIATING CLICS SERVICE ++++");
    events.subscribe('color_session:replace', (cs: CLiCSColorSession) => {
      this.replaceColorSession(cs);
    });
    events.subscribe('color_session:stage', (cs: CLiCSColorSession) => {
      this.stageColorSession(cs);
    });
    events.subscribe('color_session:update', (cs: CLiCSColorSession) => {
      // this.recordStartingLevel();
      this.apiUpdateColorSession(cs);
    });
    // Same as above but only changes the ordinal value on the FRs
    events.subscribe('color_session:reorder', (cs: CLiCSColorSession) => {
      this.apiReorderColorSession(cs);
    });
    // Queue a CS for dispensing
    events.subscribe('color_session:queue', (cs: CLiCSColorSession) => {
      this.recordStartingLevel();
      this.apiQueueColorSession(cs);
    });
    // Queue a CS for dispensing
    events.subscribe('color_session:unqueue', (cs: CLiCSColorSession) => {
      this.apiUnqueueColorSession(cs);
    });
    // Update starting level in the current client's color session
    events.subscribe('color_session:level', (newLevel) => {
      this.recordStartingLevel(newLevel);
    });
    // When a color session is updated, update it on the server
    events.subscribe('formula:update', (data: any) => {
      this.apiSaveFormulaRequest(data.cs, data.formula);
    });
    // When a formula timer is updated record it on the server
    events.subscribe('formula:timer', (data: any) => {
      this.apiSetFormulaTimers(data.cs, data.formula);
    });
    events.subscribe('formula:remove', (data: any) => {
      this.apiRemoveFormulaRequest(data.cs, data.formula_token);
    });
    // If navigating out of client selection workflow, forget staged colors
    events.subscribe('page:changed', (page: string) => {
      if (this.stagedColorApplication || this.stagedColorFormula) {
        if (page != 'clients' && page != 'profile' && page != 'application' && page != 'history')
          this.forgetStagedColors();
      }
    });
    // If a color or app is staged for the next client, use it
    events.subscribe('client:updated', (data: any) => {
      if (this.stagedColorApplication) {
        this.replaceColorSession(this.stagedColorApplication);
        this.stagedColorApplication = null;
      }
    });

    this.saveToLocal();

    // Load APP TYPE from mode controller, then validate login
    this.modeCtrl.init(true).then(result => {
      // Confirm we're logged in and get to work
      this.validateLogin().then((success) => {
        if (success == false)
          this.events.publish('navrequest', {top: 'logout', page: 'LOGOUT'});
        else {
          this.validateSalon();  // TODO: more here
        }
      });

      // Finally, ensure that the dispense mode is set
      if (!this.dispenseMode) {
        this.modeCtrl.setDispenseMode('expert');
        this.handleDispenseModeChange();
      }
    });
  }

  // Sets the mode for this app to a valid value
  setAppMode(new_mode: string): string {
    const mode = new_mode.toLowerCase();
    switch (mode) {
      case 'salon': {
        this.app_mode = 'salon';
        break;
      }
      default: {
        this.app_mode = 'personal';
        break;
      }
    }
    localStorage.setItem('app_mode', this.app_mode);
    return this.app_mode;
  }

  // Load settings from local storage
  loadFromLocal(): Promise<any> {
    let dispenseModeChanged: boolean = false;
    let val = localStorage.getItem('app_ident');
    if (val != null && val != 'null')
      this.app_ident = val;
    val = localStorage.getItem('app_mode');
    if (val != null && val != 'null')
      this.app_mode = val;
    val = localStorage.getItem('theme');
    if (val != null && val != 'null') {
      this.setTheme(val, false);
    }
    val = localStorage.getItem('dispense_mode');
    if (val != null && val != 'null') {
      this.modeCtrl.setDispenseMode(val);
      dispenseModeChanged = true;
    }
    val = localStorage.getItem('auto_theme');
    if (val != null && val != 'null') {
      console.log(`loadFromLocal loading auto_theme ${val}`);
      this.autoTheme = (val == 'true');
    }
    val = localStorage.getItem('manifest');
    if (val != null && val != 'null')
      this.manifest = new CLiCSManifest(JSON.parse(val));
    val = localStorage.getItem('login_token');
    if (val != null && val != 'null') {
      this.login_token = val;
      console.log('Login token loaded from local');
    }
    val = localStorage.getItem('salons');
    if (val != null && val != 'null') {
      let items = JSON.parse(val);
      this.salons = [];
      for (let item of items) {
        this.salons.push(new CLiCSSalon(item));
      }
    }
    val = localStorage.getItem('current_salon');
    if (!!val && val != 'undefined' && val != 'null')
      this.current_salon = <CLiCSSalon>JSON.parse(val);
    val = localStorage.getItem('current_formula');
    if (!!val && val != 'null')
      this.current_formula = new CLiCSColorFormula(JSON.parse(val));
    val = localStorage.getItem('current_color_session');
    if (!!val && val != 'null')
      this.current_color_application = new CLiCSColorApplication(JSON.parse(val));
    val = localStorage.getItem('clients');
    if (!!val && val != 'null') {
      let items = JSON.parse(val);
      this.clients = [];
      for (let item of items) {
        this.clients.push(new CLiCSClient(item));
      }
    }
    val = localStorage.getItem('repo');
    if (!!val && val != 'null')
      this.repo = new CLiCSRepository(JSON.parse(val));
    val = localStorage.getItem('content');
    if (!!val && val != 'null')
      this.content = new CLiCSContent(JSON.parse(val));
    if (this.app_mode == 'personal') {
      val = localStorage.getItem('session_token');
      if (!!val && val != 'null') {
        this.session_token = val;
        val = localStorage.getItem('current_user');
        if (!!val && val != 'null') {
          this.current_user = new CLiCSUser(JSON.parse(val));
        }
      }
    } else {
      val = sessionStorage.getItem('session_token');
      if (!!val && val != 'null')
        this.session_token = val;
      val = sessionStorage.getItem('current_user');
      if (!!val && val != 'null')
        this.current_user = new CLiCSUser(JSON.parse(val));
    }
    this.restoreEndpoint();  // restore the endpoint URL to the last used value (might be staging)
    // Avoid null string
    if (this.login_token == 'null')
      this.login_token = null;
    if (dispenseModeChanged) {
      this.handleDispenseModeChange().then((result) => {
        return(result);
      });
    } else {
      return Promise.resolve(true);
    }
  }

  // Returns the mobile app type as maintained by the Mode Controller
  mobileAppType(): string {
    return this.modeCtrl.getAppType();
  }

  // Save current settings to local storage
  saveToLocal(): void {
    try {
      localStorage.setItem('app_ident', this.app_ident);
      localStorage.setItem('app_mode', this.app_mode);
      localStorage.setItem('theme', this.theme);
      localStorage.setItem('dispense_mode', this.dispenseMode);
      localStorage.setItem('auto_theme', this.autoTheme ? 'true' : 'false');
      localStorage.setItem('manifest', JSON.stringify(this.manifest));
      localStorage.setItem('login_token', this.login_token);
      localStorage.setItem('current_salon', JSON.stringify(this.current_salon));
      localStorage.setItem('current_formula', JSON.stringify(this.current_formula));
      localStorage.setItem('current_color_session', JSON.stringify(this.current_color_application));
      localStorage.setItem('content', JSON.stringify(this.content));
      if (this.app_mode == 'personal') {
        localStorage.setItem('session_token', this.session_token);
        if (this.current_user)
          localStorage.setItem('current_user', JSON.stringify(this.current_user));
        else
          localStorage.removeItem('current_user');
      } else {
        sessionStorage.setItem('session_token', this.session_token);
        if (this.current_user)
          localStorage.setItem('current_user', JSON.stringify(this.current_user));
        else
          localStorage.removeItem('current_user');
      }
      this.saveRepo();
    } catch (error) {
      console.log(error.message);
    }
    this.last_modified = Date.now();
  }


  // Clears any stored library objects from local storage
  clearLibrary(): void {
    if (!!this.repo) {
      this.repo.clear();
    }
    localStorage.removeItem('repo');
  }

  // Clears conversion collections from the repo
  clearConversionLibrary(): void {
    this.repo.clearByScope('conversion');
  }

  // Saves list of products to local storage (not done in saveToLocal)
  saveProducts(products: CLiCSProduct[]): void {
    localStorage.setItem('products', JSON.stringify(products));
  }

  // Saves list of products to local storage (not done in saveToLocal)
  clearProducts(): void {
    localStorage.removeItem('products');
  }

  // Saves list of app types to local storage (not done in saveToLocal)
  saveAppTypes(app_types: CLiCSAppType[]): void {
    localStorage.setItem('app_types', JSON.stringify(app_types));
  }

  // Saves list of salons for the logged in user
  saveSalons(salons: CLiCSSalon[]): void {
    if (!!salons)
      localStorage.setItem('salons', JSON.stringify(salons));
    else
      localStorage.removeItem('salons');
  }

  // Saves list of app types to local storage (not done in saveToLocal)
  saveContent(content: CLiCSContent): void {
    if (!content || content.empty())
      localStorage.removeItem('content');
    else
      localStorage.setItem('content', JSON.stringify(content));
  }

  // Save the current user only
  saveCurrentUser(): void {
    if (this.current_user)
      localStorage.setItem('current_user', JSON.stringify(this.current_user));
    else
      localStorage.removeItem('current_user');
  }

  // Save theme preference
  saveTheme(): void {
    localStorage.setItem('theme', this.theme);
    localStorage.setItem('auto_theme', !!this.autoTheme ? 'true' : 'false');
    console.log(`Save theme recorded theme ${localStorage.getItem('theme')} and auto_theme ${localStorage.getItem('auto_theme')}`);
  }

  // Save the dispense mode for the app (basic (no client list), advance (normal, client list), expert (future)
  saveDispenseMode(): void {
    localStorage.setItem('dispense_mode', this.dispenseMode);
  }

  // Reads products from local storage (not done in readFromLocal) and returns the array to the caller
  getProducts(): CLiCSProduct[] {
    let result: CLiCSProduct[] = [];
    let val = localStorage.getItem('products');
    if (!!val && val != 'null') {
      let items = JSON.parse(val);
      for (let item of items) {
        result.push(new CLiCSProduct(item));
      }
    }
    return result;
  }

  // Reads app types from local storage and returns them.
  getAppTypes(): CLiCSAppType[] {
    let result: CLiCSAppType[] = [];
    let val = localStorage.getItem('app_types');
    if (!!val && val != 'null') {
      let items = JSON.parse(val);
      for (let item of items) {
        result.push(new CLiCSAppType(item));
      }
    }
    return result;
  }

  // Reads content from local storage and returns the result
  getContent(): CLiCSContent {
    if (this.content == null) {
      let val = localStorage.getItem('content');
      if (!!val && val != 'null')
        this.content = new CLiCSContent(JSON.parse(val));
    }
    return this.content;
  }

  // Saves combined color library object to local storage
  saveRepo(repo: CLiCSRepository = null): void {
    if (repo == null)
      repo = this.repo;
    if (repo == undefined || repo.empty())
      localStorage.removeItem('repo');
    else
      localStorage.setItem('repo', JSON.stringify(repo));
  }

  // Reads repository (color library) from local storage and returns the result
  loadRepo(): CLiCSRepository {
    if (!this.repo) {
      let val = localStorage.getItem('repo');
      if (!!val && val != 'null')
        this.repo = new CLiCSRepository(JSON.parse(val));
      else
        this.repo = new CLiCSRepository();
    }
    return this.repo;
  }

  // Forget sign-on (session) specific data. Call on sign out or before doing sign on.
  unloadSessionData(clearSalons: boolean = false, clearSystemRepo: boolean = false): void {
    if (this.clients != null) {
      this.clearAllClients();
    }
    if (clearSalons) {
      localStorage.removeItem('salons');
      this.salons = null;
    }
    localStorage.removeItem('welcomed');
    this.loadRepo();
    if (this.repo) {
      if (clearSystemRepo) {
        console.log("unloadSessionData clearing all repo scopes");
        this.repo.clear();
      } else {
        console.log("unloadSessionData clearing repo user and salon scopes");
        this.repo.clearByScope('user');
        this.repo.clearByScope('salon');
      }
      this.saveRepo();
    }
  }

  // This does a number of things. It gets the latest manifest from the server and compares it to the stored manifest
  // object in local storage. If any category version numbers have changed that data is discarded and the server is
  // called to update data in the app.
  checkManifest(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_manifest', {params: {sess: this.session_token}})
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          if (!this.manifest)
            this.manifest = new CLiCSManifest();
          for (let fest of data.manifests) {
            // If manifest has newer version then clear the local data so it can be loaded on use
            if (this.manifest.isCategoryUpdated(fest.category, fest.major, fest.minor)) {
              switch (fest.category) {
                case 'products':
                  localStorage.removeItem('products');
                  this.apiGetProducts().then((result) => {
                    this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  });
                  break;
                case 'app_types':
                  localStorage.removeItem('app_types');
                  this.apiGetAppTypes();
                  this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  break;
                case 'library':
                  this.repo.clear();
                  this.saveRepo();
                  this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  break;
                // NOTE: confirmed to clear out the list of salons on user login. Salon list not really a manifest item.
                // case 'salons':
                //   this.salons = null;
                //   localStorage.removeItem('salons');
                //   this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                //   break;
                case 'settings':
                  // Update global app settings and defaults, not user setting
                  this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  break;
                case 'content':
                  localStorage.removeItem('content');
                  this.content = null;
                  this.apiGetContent();
                  this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  break;
                case 'conversions':
                  this.clearConversionLibrary();
                  this.manifest.updateCategory(fest.category, fest.major, fest.minor);
                  break;
              }
            }
          }
          if (this.manifest.isModified())
            localStorage.setItem('manifest', JSON.stringify(this.manifest));
        }
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Make sure that there's an App identifier before any login attempts
  assertAppIdent() {
    if (this.app_ident == null) {
      this.app_ident = uuidv1();
      localStorage.setItem('app_ident', this.app_ident);
    }
  }

  // Attempts to log in a user (stylist / owner / manager), sets login_token and current user, returns true/false
  apiLogIn(email: string, password: string): Promise<boolean> {
    let _that = this;
    this.assumeOnline();
    this.clearAllClients(); // always start with no clients
    this.assertAppIdent();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/log_in',
      {
        params: {
          email: email,
          password: password,
          appid: this.app_ident,
          apptype: this.mobileAppType()
        }
      })
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          return this._finishLogin(_that, data);
        } else {
          // Login failed
          _that.flash.setError(data.message_key);
          _that.login_token = null;
          _that.current_user = null;
          _that.saveToLocal();
          return false;
        }
      })
      .catch(error => {
        this.handleOffline();
        return false;
      })
  }

  // Attempts to log in using a one-time code
  apiCodeLogIn(code: string): Promise<boolean> {
    let _that = this;
    this.assumeOnline();
    this.clearAllClients(); // always start with no clients
    this.assertAppIdent();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/log_in_with_code',
      {
        params: {
          code: code,
          appid: this.app_ident,
          apptype: this.mobileAppType()
        }
      })
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          return this._finishLogin(_that, data);
        } else {
          // Login failed
          _that.flash.setError(data.message_key);
          _that.login_token = null;
          _that.current_user = null;
          _that.saveToLocal();
          return false;
        }
      })
      .catch(error => {
        this.handleOffline();
        return false;
      })
  }

  // Used by login code below to complete the login process
  _finishLogin(_that: any, data: any): boolean {
    _that.login_token = data.login_token;
    _that.session_token = data.session_token;
    _that.current_user = new CLiCSUser(data.user);
    _that.saveToLocal();

    // Set the dispense mode based on the user's training level
    _that._assertMaxDispenseMode(true);

    _that.salons = [];
    _that.current_salon = null;
    for (let salon of data.info.salons) {
      _that.salons.push(new CLiCSSalon(salon));
    }
    _that.saveSalons(_that.salons);

    // Load clients list from API
    _that.prior_client_token = null;
    _that.apiGetClients().then((result) => {
      _that.checkManifest().then((result) => {
        if (_that.dispenseMode == 'simple') {
          _that.selectDefaultClient().then((client) => {
            _that.handleDispenseModeChange().then(() => {
              return true;
            });
          });
        } else {
          _that.handleDispenseModeChange().then(() => {
            return true;
          });
        }
      }, (reason) => {
        console.error(`finishLogin -> checkManifest failed with reason ${reason}`)
      });  // Make sure all data is up to snuff
    }, (reason) => {
      console.error(`finishLogin -> apiGetClients failed with reason ${reason}`)
    });
    return true;
  }

  // Logs out the user from this app (app locks) and instructs the back-end to log out the user
  apiLogOut(): Promise<boolean> {
    this.assumeOnline();
    this.clearAllClients();
    this.assertAppIdent();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/log_out', {
      params: {
        ltok: this.login_token,
        appid: this.app_ident
      }
    })
      .toPromise()
      .then((data: any) => {
        console.log('apiLogOut unloading login session data');
        this.login_token = null;
        this.session_token = null;
        this.current_user = null;
        this.current_salon = null;
        this.saveToLocal();
        this.unloadSessionData(true, true);
        return (data.success === true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Checks the login credentials and returns true/false to indicate if correct. Makes no changes to the app state,
  // does not generate a login_token on the back-end.
  apiPingServer(): Promise<boolean> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/ping')
      .toPromise()
      .then((data: any) => {
        return (data.success === true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Passes a chosen user and pin to the API for validation
  apiSignOn(chosen_user: CLiCSUser, pin: string): Promise<boolean> {
    let _that = this;
    this.assumeOnline();
    console.log('apiSignOn unloading session data');
    this.unloadSessionData();
    let params = {user: chosen_user.api_token, pin: pin, apptype: this.mobileAppType()};
    if (this.session_token != null)
      params['sess'] = this.session_token;
    if (!!this.current_salon)
      params['saln'] = this.current_salon.api_token;
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/sign_on', {params: params})
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          _that.current_user = new CLiCSUser(chosen_user);
          if ('num_dispenses' in data) {
            if (!!data.num_dispenses) {
              _that.current_user.num_dispenses_on_login = Number(data.num_dispenses);
            } else {
              _that.current_user.num_dispenses_on_login = null;
            }
          }
          if ('user_role' in data) {
            _that.current_user.role = data.user_role || _that.current_user.role
          }
          _that.session_token = data.token;
          _that.prior_client_token = null;
          _that.current_user.loadLibraries(data.info.libraries);

          // Set the dispense mode based on the user's training level
          _that._assertMaxDispenseMode(true);

          _that.saveToLocal();
          _that.apiGetClients().then((result) => {
            _that.checkManifest().then((result) => {
              _that.getRepo(false).then((data) => {
                if (_that.dispenseMode == 'simple') {
                  _that.selectDefaultClient().then((client) => {
                    _that.handleDispenseModeChange().then(() => {
                      return true;
                    });
                  });
                } else {
                  _that.handleDispenseModeChange().then(() => {
                    return true;
                  });
                }
              });
            });  // Make sure all data is up to snuff
          });
        } else {
          return false;
        }
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Signs out the current user while leaving the app authenticated (logged in)
  apiSignOut(): Promise<boolean> {
    let _that = this;
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/sign_off', {params: {sess: this.session_token}})
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          console.log('apiSignOut unloading login session data');
          _that.current_user = null;
          _that.session_token = null;
          _that.prior_client_token = null;
          _that.dispenseMode = null;
          _that.clearQueues();
          _that.clearLibrary();

          _that.saveToLocal();
          _that.unloadSessionData();
          _that.events.publish('navreset');
        }
        return (data.success === true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Sets a new pin on the account. This requires full authentication of the CLICS account
  apiSetPin(new_pin: string, email: string, password: string): Promise<boolean> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/set_pin', {
      params: {
        pin: new_pin,
        email: email,
        password: password
      }
    })
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          this.current_user = null;
          this.session_token = null;
          this.saveToLocal();
        } else {
          this.flash.setError(data.message_key);
        }
        return (data.success === true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Request a password recovery email
  apiRecoverLogin(email: string): Promise<boolean> {
    this.assumeOnline();
    return this.http.get(this.endpoint_url + '/api/control_panel/reset_login', {params: {email: email}})
      .toPromise()
      .then((data: any) => {
        this.flash.setMessage(data.message_key, '', !data.success)
        return (data.success === true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Returns a list of users (stylists) having permission for this salon
  apiGetUsers(): Promise<CLiCSUser[]> {
    this.assumeOnline();
    if (this.current_salon == null) {
      return Promise.resolve([]);
    } else {
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_users', {
        params: {saln: this.current_salon.api_token}
      })
        .toPromise()
        .then((data: any) => {
          return (data.users as CLiCSUser[]);
        })
        .catch(error => {
          this.handleOffline();
          return ([]);
        });
    }
  }

  // Only used for refresh since user's salons are passed in Login response
  apiGetSalons(): Promise<CLiCSSalon[]> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_salons', {params: {sess: this.session_token}})
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.salons = [];
          for (const salon of data.salons) {
            this.salons.push(new CLiCSSalon(salon));
          }
          this.saveSalons(this.salons);
        }
        return (this.salons);
      })
      .catch(error => {
        this.handleOffline();
        return ([]);
      });
  }

  apiGetClientPhotos(client: CLiCSClient, latest_token: string = null): Promise<CLiCSClientPhoto[]> {
    this.assumeOnline();
    const params: any = {params: {cli: client.token, sess: this.session_token}};
    if (latest_token)  // get results since the last photo
      params.params.latest_token = latest_token;
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_client_photos', params)
      .toPromise()
      .then((data: any) => {
        return data.photos;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // apiAddClientPhoto(client: CLiCSClient, photo: string, when_taken: string, color_session?: CLiCSColorSession): Promise<boolean> {
  apiAddClientPhoto(newPhoto: CLiCSClientPhoto): Promise<any> {
    this.assumeOnline();
    const params = {
      cli: newPhoto.cli,
      sess: this.session_token,
      photo: newPhoto.getImageData(),
      when_taken: newPhoto.when_taken,
      css: newPhoto.css,
      taken_at: newPhoto.taken_at,
      request_ident: newPhoto.request_ident,
      is_profile: newPhoto.is_profile
    };
    return this.http.post(this.endpoint_url + '/api/control_panel/add_client_photo', params)
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // apiAddClientPhoto(client: CLiCSClient, photo: string, when_taken: string, color_session?: CLiCSColorSession): Promise<boolean> {
  apiRemoveClientPhoto(photo: CLiCSClientPhoto): Promise<any> {
    this.assumeOnline();
    const params = {
      sess: this.session_token,
      cli: photo.cli,
      pho: photo.token
    };
    return this.http.post(this.endpoint_url + '/api/control_panel/remove_client_photo', params)
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // apiAddClientPhoto(client: CLiCSClient, photo: string, when_taken: string, color_session?: CLiCSColorSession): Promise<boolean> {
  apiSetProfilePhoto(photo: CLiCSClientPhoto): Promise<any> {
    this.assumeOnline();
    const params = {
      sess: this.session_token,
      cli: photo.cli,
      pho: photo.token
    };
    return this.http.post(this.endpoint_url + '/api/control_panel/set_profile_photo', params)
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Add a note for this client
  apiSaveClientNote(note: CLiCSClientNote): Promise<any> {
    this.assumeOnline();
    const cs_token = note.css;
    if (this.clientIsSelected()) {
      const params = {
        cli: this.current_user.getClient().token,
        sess: this.session_token,
        note: note.noteText,
        token: note.token,
        ident: note.requestIdent
      };
      if (!!cs_token) {
        params['css'] = cs_token;
      }
      return this.http.post(this.endpoint_url + '/api/control_panel/save_client_note', params)
        .toPromise()
        .then((data: any) => {
          let client = this.current_user.getClient();
          if (data.success) {
            let localNote: CLiCSClientNote = client.getNote(data.note.token, data.request_ident);
            if (localNote == null)
              client.saveNote(data.note);
            else
              localNote.update(data.note);
          }
          this.events.publish('note:updated', {note: data.note});
          return (data);
        })
        .catch(error => {
          this.handleOffline();
          return false;
        });
    } else {
      return (Promise.resolve(false));
    }
  }

  // Add a note for this client
  apiDeleteClientNote(noteToken: string = null): Promise<boolean> {
    this.assumeOnline();
    if (this.clientIsSelected()) {
      const params = {cli: this.current_user.getClient().token, sess: this.session_token, token: noteToken};
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/delete_client_note', params)
        .toPromise()
        .then((data: any) => {
          if (data.success)
            this.current_user.getClient().deleteNote(noteToken);
          return (data.success === true);
        })
        .catch(error => {
          this.handleOffline();
          return false;
        });
    } else {
      return (Promise.resolve(false));
    }
  }

  // Retrieve notes for this client
  apiGetClientNotes(client: CLiCSClient): Promise<any> {
    this.assumeOnline();
    let params = {params: {sess: this.session_token, cli: client.token}};
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_client_notes', params)
      .toPromise()
      .then((data: any) => {
        if (data.success === true) {
          client.loadUserClientNotes(data.notes);
          return data;
        }
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  apiUpdateClientHairProfile(client: CLiCSClient, clientHairProfile: any): Promise<boolean> {
    this.assumeOnline();
    const params = {cli: client.token, sess: this.session_token, client_hair_profile: clientHairProfile};

    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/update_client_hair_profile', params)
      .toPromise()
      .then((data: any) => {
        return (data.success == true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  apiGetConsultations(client: CLiCSClient): Promise<any> {
    this.assumeOnline();
    const params = {params: {cli: client.token, sess: this.session_token}}
    return getWithRetry(this.http, this.endpoint_url + '/api/consultations/get_consultations', params)
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return null;
      });
  }

  apiGetConsultationQuestions(): Promise<any> {
    this.assumeOnline();
    const params = {params: {app_type: this.mobileAppType()}};
    return getWithRetry(this.http, this.endpoint_url + '/api/consultations/get_questions', params)
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return null;
      });
  }

  apiPostConsultation(client: CLiCSClient, client_responses: string[], custom_responses: any): Promise<any> {
    this.assumeOnline();
    const params = {
      cli: client.token,
      sess: this.session_token,
      client_responses,
      custom_responses,
    };
    return this.http.post(this.endpoint_url + '/api/consultations/save_consultation', params)
      .toPromise()
      .then((data: any) => {
        return(data);
      })
      .catch(error => {
        this.handleOffline();
        return null;
      })
  }

  apiGetClientHairProfile(client: CLiCSClient): Promise<any> {
    this.assumeOnline();
    const params = {params: {cli: client.token, sess: this.session_token}};
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_client_hair_profile', params)
      .toPromise()
      .then((data: any) => {
        this.current_user.getClient().loadHairProfile(data.client_hair_profile);

        return data.client_hair_profile;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Returns a list of clients associated with this user (stylist), if client_ts
  // is passed, retrieves only those clients modified since client_ts.
  apiGetClients(force: boolean = false, client_ts: number = null): Promise<CLiCSClient[]> {
    this.assumeOnline();
    if (!force && (this.clients != null && this.clients.length > 0))
      return Promise.resolve(this.clients as CLiCSClient[]);
    else {
      // Send salon if a salon is currently chosen
      let options: any = {
        params: {sess: this.session_token, saln: null}
      }
      if (!!this.current_salon) {
        options.params.saln = this.current_salon.api_token;
      }
      if (!!client_ts) {
        options.params.client_ts = client_ts;
      }

      return getWithRetry(this.http,this.endpoint_url + '/api/control_panel/get_clients', options)
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            let clientObjs = data.clients;
            if (!client_ts) {
              // default: no timestamp, replace all clients
              console.log("Reloading all clients");
              this.clients = [];
              for (let cl of clientObjs) {
                let new_client = new CLiCSClient(cl);
                this.clients.push(new_client);
              }
            } else {
              // timestamp presented, update or add client
              console.log("Reloading clients using timestamp");
              for (let cl of clientObjs) {
                const index = this.clients.findIndex((el) => el.token == cl.token );
                if (index >= 0) {
                  this.clients[index].loadObj(cl);
                } else {
                  let new_client = new CLiCSClient(cl);
                  this.clients.push(new_client);
                }
              }
              // Sort by name, since we may add new clients at the end,
              this.clients.sort((a, b) => {
                let comp = a.first_name.localeCompare(b.first_name);
                if (comp == 0) {
                  comp = a.last_name.localeCompare(b.last_name);
                }
                return(comp);
              });
            }
            localStorage.setItem('clients', JSON.stringify(this.clients));
            this.current_user.updateClientTimestamp(data.timestamp);

            // If the current client no longer exists, clear the current client
            if (!!this.current_user && !!this.current_user.current_client) {
              const index = this.clients.findIndex(el => el.token == this.current_user.current_client.token);
              if (index < 0)
                this.clearClient();
              else
                this.current_user.setClient(this.clients[index]);  // update user client record
            }
            this.saveCurrentUser();
            this.events.publish('client:updated');
          }
          return this.clients;
        })
        .catch(error => {
          this.handleOffline();
          return null;
        });
    }
  }

  // Get client history record - a lot of data!
  apiGetClientHistory(client: CLiCSClient = null): Promise<CLiCSColorSession[]> {
    // Use current client if none specified
    if (client == null && this.current_user !== null) {
      client = this.current_user.current_client;
    }

    this.assumeOnline();
    if (client == null)
      return Promise.reject('No client selected');
    else {
      // CRITICAL: improve this logic to use last update of CS or other server timestamp
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_client_history',
        {params: {sess: this.session_token, cli: client.token, saln: this.current_salon.api_token}})
        .toPromise()
        .then((data: any) => {
          client.addHistory(data.color_sessions, true);
          return client.history;
        })
        .catch(error => {
          this.handleOffline();
          return null;
        });
    }
  }

  // Get swatch history record - a lot of data!
  apiGetSwatchHistory(): Promise<any> {
    // Use current client if none specified
    this.assumeOnline();
    let sln = null;
    if (this.current_salon) {
      sln = this.current_salon.api_token;
    }

    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_swatch_history',
      {params: {sess: this.session_token}})
      .toPromise()
      .then((data: any) => {
        // Load swatch history for external use
        this.swatchHistory.length = 0;
        for (let swatch of data.formula_requests) {
          this.swatchHistory.push(new CLiCSFormulaRequest(swatch));
        }
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return null;
      });
  }

  // Get swatch history record - a lot of data!
  apiGetDispenseHistory(page: number = 0, per_page: number = 100): Promise<any> {
    // Use current client if none specified
    this.assumeOnline();

    let params = {params: {sess: this.session_token, page: `${page}`, per_page: `${per_page}`}};
    if (!!this.current_salon) {
      params['params']['saln'] = this.current_salon.api_token;
    }

    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_dispense_history', params)
      .toPromise()
      .then((data: any) => {
        // Load swatch history for external use
        if (page == 0) {
          this.dispenseHistory.length = 0;
        }
        for (let fr of data.formula_requests) {
          this.dispenseHistory.push(new CLiCSFormulaRequest(fr));
        }
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return null;
      });
  }

  // Requests the color session for a client and loads it up. Publishes a client:updated event.
  // discardRecent tells the server to return a new Color Session if the current color session has been recently dispensed.
  // ADDED 11/2020 we assert the salon by default. This requires server support but prevents cross-salon CLAPP additions
  apiGetColorSession(client: CLiCSClient, discardRecent: boolean = false, assert_salon: boolean = true): Promise<CLiCSColorSession> {
    let target_client = undefined;
    const _that = this;
    if (!!client) {
      let params: any = {
        sess: this.session_token,
        cli: client.token,
        discard_recent: discardRecent
      }
      if (assert_salon && !!this.current_salon) {
        params.saln = this.current_salon.api_token
      }
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_color_session', {params: params})
        .toPromise()
        .then((data: any) => {
            if (data.success) {
              if (!!_that.clients) {
                target_client = _that.clients.find((element, index) => {
                  return element.token == data.cs.cli;
                });
              }

              // NOTE: belts-and-suspenders code here - make sure some client is listed here
              if (target_client == undefined) {
                const new_client = new CLiCSClient(client);
                _that.clients.push(new_client);
                localStorage.setItem('clients', JSON.stringify(this.clients));
                target_client = new_client;
              }

              // Only load over the local client CS if the timestamp is newer.
              if (!target_client) {
                console.log("Could not establish target_client in apiGetColorSession");
              }
              if (!!target_client.cs && !!target_client.cs.token) {
                if (target_client.cs.isCurrentAsOf(data.cs.updated_at) == false) {
                  target_client.loadColorSession(data.cs);
                  _that.events.publish('client:updated', target_client);
                }
              } else {
                if (!target_client.cs || !target_client.cs.token) {
                  target_client.loadColorSession(data.cs);
                  _that.updateClient(target_client);
                  _that.apiGetClientNotes(target_client).then(result => {
                    _that.events.publish('client:updated', target_client);
                  });
                }
              }
              return (target_client.cs);
            } else {
              return null;
            }
          }, response => {
            this.handleOffline();
            return null;
          }
        )
    } else {
      return Promise.reject(null);
    }
  }

  // Lookup an arbitrary color session by token. Used for drill-down on report pages
  apiRetrieveColorSession(cs_token: string): Promise<CLiCSColorSession> {
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/color_session', {
      params: {
        sess: this.session_token,
        css: cs_token
      }
    })
      .toPromise()
      .then((data: any) => {
          if (data.success)
            return (new CLiCSColorSession(data.cs));
          else
            return null;
        }, response => {
          this.handleOffline();
          return null;
        }
      )
  }

  // This is similar to apiUpdateColorSession, but for one only CLiCSFormulaRequest.
  apiSaveFormulaRequest(color_session: CLiCSColorSession, fr: CLiCSFormulaRequest): Promise<boolean> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    fr.assertToken();  // To prevent duplicate set token here rather than in server
    if (fr.group_id > 0 && color_session.isQueued()) {
      // Automagically resize grouped formulas if we are queued for dispensing
      color_session.limitGroupTotal(null, 300.0); // NOTE: a priori value normally retrieved from Mixing Bowl provider
    }
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/save_formula_request',
      {sess: this.session_token, css: color_session.token, formula: JSON.stringify(fr)})
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          // Set the token if local formula is new
          let found_formula = this.findClientFormula(null, data.formula.request_ident);
          if (found_formula != null) {
            found_formula.setToken(data.formula.token, true);  // Still required. Server may change token!
            found_formula.formula_text = data.formula.formula_text;
            found_formula.replaceParams(data.formula.params);
          }
        }
        this.events.publish('formula:updated');
        return (data.success);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Sets a P (personal) pedigree for the passed Formula Request and assigns the passed Color Formula as the base
  // formula to the FR.
  apiSetPedigree(fr: CLiCSFormulaRequest, cf: CLiCSColorFormula): Promise<any> {
    this.assumeOnline();
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/set_pedigree',
      {sess: this.session_token, fr: fr.token, cf: cf.token})
      .toPromise()
      .then((data: any) => {
        this.events.publish('formula:updated');
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // This is similar to apiUpdateColorSession, but for one only CLiCSFormulaRequest.
  apiSetFormulaTimers(color_session: CLiCSColorSession, fr: CLiCSFormulaRequest): Promise<boolean> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/set_formula_timers',
      {sess: this.session_token, css: color_session.token, formula: JSON.stringify(fr)})
      .toPromise()
      .then((data: any) => {
        return (data.success);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // This is similar to apiUpdateColorSession, but for only one CLiCSFormulaRequest.
  apiRemoveFormulaRequest(color_session: CLiCSColorSession, formula_token: string): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/remove_formula_request',
      {sess: this.session_token, css: color_session.token, frq: formula_token})
      .toPromise()
      .then((data: any) => {
        // if (data.success)
        //     color_session.setEventTimestamp(data.timestamp);
        this.events.publish('formula_request:removed');
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // This is similar to apiRemoveFormulaRequest will cancel / archive an in-process FR or delete it if not dispensed.
  apiCancelFormulaRequest(color_session: CLiCSColorSession, formula_token: string): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/cancel_formula_request',
      {sess: this.session_token, css: color_session.token, frq: formula_token})
      .toPromise()
      .then((data: any) => {
        // if (data.success)
        //     color_session.setEventTimestamp(data.timestamp);
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // This is similar to apiUpdateColorSession, but for one only CLiCSFormulaRequest.
  apiDispenseMore(color_session: CLiCSColorSession, formula_token: string, weight: number): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return this.http.post(this.endpoint_url + '/api/common/dispense_more',
      {sess: this.session_token, frq: formula_token, weight: weight})
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Internally a "client application" or "APP" is referred to as a "color session". Takes the passed color session
  // and its formulas and send it to the server where it is updated. If the CS is not validated or dispensing has
  // started the update fails. Returns response object as a Promise
  apiUpdateColorSession(color_session: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;

    if (color_session.isQueued() && color_session.hasGroups()) {
      // Automagically resize grouped formulas if we are queued for dispensing
      color_session.limitGroupTotal(null, 300.0);  // NOTE: a priori amount, normal retrieved from MixingBowl provider
    }

    // To prevent duplicates, set tokens here rather than on the server
    for (let fr of color_session.formulas) {
      fr.assertToken();
    }

    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/update_color_session',
      {sess: this.session_token, css: color_session.token, color_session: JSON.stringify(color_session)})
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          for (let f_data of data.formulas) {
            color_session.updated_at = new Date(data.timestamp * 1000);
            // color_session.setEventTimestamp(data.timestamp);

            // Set the token in new local formulas
            let found_formula = this.findClientFormula(null, f_data.request_ident);
            if (found_formula) {
              found_formula.setToken(f_data.token, true);  // Still required. Server may change token!
              found_formula.replaceParams(f_data.params);
            } else {
              console.log(`Formula ${f_data.request_ident} not previously present in Clapp (probably added)`);
            }
          }
          this.events.publish('color_session:updated', {data: data})
        } else {
          this.events.publish('color_session:update:failure');
        }
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Updates remote Color Session with the starting level in the passed color_session
  apiRecordStartingLevel(color_session: CLiCSColorSession): Promise<boolean> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/record_starting_level',
      {sess: this.session_token, css: color_session.token, starting_level: color_session.starting_level})
      .toPromise()
      .then((data: any) => {
        color_session.updated_at = data.timestamp;
        return (data.success);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Updates remote Color Session with the value of processing_time in the passed CS object
  apiSetProcessingTime(color_session: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/set_processing_time',
      {sess: this.session_token, css: color_session.token, processing_time: color_session.processing_time})
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Similar to apiUpdateColorSession but only changes the ordinal value
  apiReorderColorSession(color_session: CLiCSColorSession): Promise<boolean> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/reorder_formulas',
      {sess: this.session_token, css: color_session.token, color_session: JSON.stringify(color_session)})
      .toPromise()
      .then((data: any) => {
        // if (data.success)
        //     color_session.setEventTimestamp(data.timestamp);
        return (data.success);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // "Send color session to the machine queue" (acutally, just set queued_at)
  apiQueueColorSession(color_session: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/queue',
      {sess: this.session_token, css: color_session.token, saln: this.current_salon.api_token})
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.events.publish('color_session:queue:success', {message_key: data.message_key, cs: data.cs});
        } else
          this.events.publish('color_session:queue:failure', {message_key: data.message_key});
        return ({success: data.success, message_key: data.message_key, cs: data.cs});
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // "Remove" a color session from the queue
  apiUnqueueColorSession(color_session: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/unqueue',
      {sess: this.session_token, css: color_session.token})
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          // color_session.setEventTimestamp(data.timestamp);
          this.events.publish('color_session:unqueue:success', {cs: data.cs});
        } else
          this.events.publish('color_session:unqueue:failure');
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }


  // Get timers for CS dispensing
  apiGetSessionTimers(color_session: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    if (color_session == null)
      color_session = this.activeClient().cs;
    return getWithRetry(this.http, this.endpoint_url + '/api/common/get_session_timers',
      {
        params: {
          sess: this.session_token,
          css: color_session.token
        }
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Get the latest timestamps for this user's library and client list
  apiGetUserTimestamps(): Promise<any> {
    this.assumeOnline();
    let params = {sess: this.session_token};
    return getWithRetry(this.http, this.endpoint_url + '/api/common/get_user_timestamps', {params: params})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Get the latest timestamp of any color session for this user to tell if the queue or CS may have changed
  apiGetSessionTimestamp(cs: CLiCSColorSession = null): Promise<any> {
    this.assumeOnline();

    let params = {sess: this.session_token};
    if (cs)
      params['css'] = cs.token;

    return getWithRetry(this.http, this.endpoint_url + '/api/common/get_session_timestamp',
      {
        params: params
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Get the latest timestamp of any color session for this user to tell if the queue or CS may have changed
  apiGetSessionEvents(cs: CLiCSColorSession): Promise<any> {
    this.assumeOnline();
    let timestamp = cs.event_timestamp
    if (timestamp == null)
      timestamp = 0;
    let params: any = {sess: this.session_token, css: cs.token, timestamp: timestamp};
    return getWithRetry(this.http, this.endpoint_url + '/api/common/get_session_events', {params: params})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Request the queued Color Sessions for display on the Queue page
  apiGetQueue(): Promise<any> {
    const _that = this;
    this.assumeOnline();
    if (!!this.current_salon) {
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_queue',
        {
          params: {sess: this.session_token, saln: this.current_salon.api_token}
        })
        .toPromise()
        .then((data: any) => {
          _that.clearQueues();
          for (let cs of data.css) {
            if (cs.client_name.trim() == 'Swatches')
              _that.swatch_queue.push(new CLiCSColorSession(cs));
            else {
              if (cs.client_name.trim() == 'Quick Dispense')
                _that.quick_queue.push(new CLiCSColorSession(cs));
              else
                _that.dispenser_queue.push(new CLiCSColorSession(cs));
            }
          }
          return data;
        })
        .catch(error => {
          this.handleOffline(null, error);
          return false;
        });
    } else {
      // Cannot get queue if no salon selected
      return Promise.reject(false);
    }
  }

  // Swatch a single formula
  apiQueueSwatch(formula: CLiCSFormula, weight: number = 16.0): Promise<any> {
    this.assumeOnline();
    return this.http.post(this.endpoint_url + '/api/control_panel/swatch',
      {sess: this.session_token, formula: formula, weight: weight, saln: this.current_salon.api_token})
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Swatch multiple formulas. If amount is passed all swatches will have the same amount. If no amount provided
  // then the amount set in the formulas will be used.
  apiQueueSwatches(formulas: CLiCSFormula[], weight: number = null): Promise<any> {
    this.assumeOnline();
    return this.http.post(this.endpoint_url + '/api/control_panel/swatches',
      {sess: this.session_token, formulas: formulas, weight: weight, saln: this.current_salon.api_token})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Same as Queue Swatch but queues as "Quick Dispense" instead of "Swatches"
  apiQuickDispense(formula: CLiCSFormula, weight: number = 30.0): Promise<any> {
    this.assumeOnline();
    return this.http.post(this.endpoint_url + '/api/control_panel/quick_dispense',
      {sess: this.session_token, formula: formula, weight: weight, saln: this.current_salon.api_token})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Adds a library for the user with the name provided
  apiAddUserLibrary(libraryName: string): Promise<any> {
    this.assumeOnline();
    const params = {sess: this.session_token, library_name: libraryName};
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/add_user_library', params)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
          this.current_user.libraries.push(
            {
              title: data.library.title,
              token: data.library.token,
              sharing_mode: data.library.sharing_mode
            }
          );
        }
        data.library.owned = true;
        this.repo.addLibrary(data.library);
        this.events.publish('library:added', {title: data.library.title, token: data.library.token});
        return (data);
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Renames a user's library for the user with the name provided
  apiRenameUserLibrary(library: CLiCSLibrary, newName: string): Promise<any> {
    this.assumeOnline();
    const params = {sess: this.session_token, libTok: library.token, library_name: newName};
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/rename_library', params)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.repo.renameLibrary(library.token, newName);
          this.saveRepo();
          this.events.publish('library:renamed', {title: newName, token: library.token});
        }
        return (data);
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Removes a user library, optionally defining a new default library
  apiRemoveUserLibrary(library: CLiCSLibrary, defaultLib: CLiCSLibrary = null): Promise<any> {
    this.assumeOnline();
    let params = {sess: this.session_token, libTok: library.token, default_libTok: null};
    if (defaultLib) {
      params.default_libTok = defaultLib.token;
    }
    return this.http.post(this.endpoint_url + '/api/control_panel/remove_library', params)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.repo.removeLibrary(library.token, data.default_library);
          this.saveRepo();
          this.events.publish('library:removed', {title: library.title, token: library.token});
        }
        return (data);
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Saves a color to the user's default library.
  // TODO: If saln is passed along with sess then follow salon ownership rules for user
  apiSaveColorFormula(formula: CLiCSFormula, libraryToken: string = null): Promise<any> {
    this.assumeOnline();
    const params = {sess: this.session_token, formula: JSON.stringify(formula), library_token: libraryToken};
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/save_color_formula', params)
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
          return data;
        } else
          return null;
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Saves a color to the user's default library.
  // TODO: If saln is passed along with sess then follow salon ownership rules for user
  apiRemoveColorFormulas(formulaTokens: string[]): Promise<any> {
    this.assumeOnline();
    const params = {
      sess: this.session_token,
      saln: null,
      formula_tokens: formulaTokens
    };
    if (this.current_salon)
      params.saln = this.current_salon.api_token;

    return this.http.post(this.endpoint_url + '/api/control_panel/remove_color_formulas', params)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
        }
        return data  // {success , tokens[]}
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Saves an Color Session ("application") to the user's default library
  // CONSIDER: this is using Color Session instead of LibraryApp since session is less distinct than ColorFormula is from FormulaRequest
  apiSaveColorApplication(app: CLiCSColorApplication, libraryToken: string = null): Promise<CLiCSColorApplication> {
    this.assumeOnline();
    const params = {sess: this.session_token, app: JSON.stringify(app), library_token: libraryToken};
    return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/save_color_application', params)
      .toPromise()
      .then((data: any) => {
        if (data.success == true) {
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
          return data.ca;
        } else
          return null;
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Removes a Color Application from the color library (if owned)
  apiRemoveColorApplication(app: CLiCSColorApplication): Promise<any> {
    this.assumeOnline();
    const params = {sess: this.session_token, token: app.token};
    return this.http.post(this.endpoint_url + '/api/control_panel/remove_color_application', params)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
          this.repo.removeApp(data.token);
        }
        return data;
      })
      .catch((error) => {
        this.handleOffline(null, error);
        return null;
      });
  }

  // Takes a list of one or more ColorFormula tokens and assigns these to a ColorLibrary, identified by its token
  apiAssignFormulasToLibrary(libraryToken: string, formulaTokens: string[]): Promise<boolean> {
    this.assumeOnline();
    if (libraryToken && formulaTokens.length > 0) {
      const params = {sess: this.session_token, libTok: libraryToken, formToks: formulaTokens};
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/assign_formulas_to_library', params)
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            this.current_user.updateLibraryTimestamp(data.timestamp);
            this.saveCurrentUser();
          }
          return data.success;
        })
        .catch((error) => {
          this.handleOffline(null, error);
          return null;
        });
    } else {
      return Promise.resolve(false);
    }
  }

  // Takes a list of one or more ColorFormula tokens and removes these from a ColorLibrary, identified by its token
  apiRemoveFormulasFromLibrary(libraryToken: string, formulaTokens: string[]): Promise<boolean> {
    this.assumeOnline();
    if (libraryToken && formulaTokens.length > 0) {
      const params = {sess: this.session_token, libTok: libraryToken, formToks: formulaTokens};
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/remove_formulas_from_library', params)
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            this.current_user.updateLibraryTimestamp(data.timestamp);
            this.saveCurrentUser();
          }
          return data.success;
        })
        .catch((error) => {
          this.handleOffline(null, error);
          return null;
        });
    } else {
      return Promise.resolve(false);
    }
  }

  // Takes a list of one or more ColorApplication tokens and assigns these to a ColorLibrary, identified by its token
  apiAssignAppsToLibrary(libraryToken: string, appToken: string[]): Promise<any> {
    this.assumeOnline();
    if (libraryToken && appToken.length > 0) {
      const params = {sess: this.session_token, libTok: libraryToken, appToks: appToken};
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/assign_apps_to_library', params)
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            this.current_user.updateLibraryTimestamp(data.timestamp);
            this.saveCurrentUser();
          }
          return data;
        })
        .catch((error) => {
          this.handleOffline(null, error);
          return null;
        });
    } else {
      return Promise.resolve(false);
    }
  }

  // Takes a list of one or more ColorApplication tokens and removes these from a ColorLibrary, identified by its token
  apiRemoveAppsFromLibrary(libraryToken: string, appTokens: string[]): Promise<any> {
    this.assumeOnline();
    if (libraryToken && appTokens.length > 0) {
      const params = {sess: this.session_token, libTok: libraryToken, appToks: appTokens};
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/remove_apps_from_library', params)
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            this.current_user.updateLibraryTimestamp(data.timestamp);
            this.saveCurrentUser();
          }
          return data;
        })
        .catch((error) => {
          this.handleOffline(null, error);
          return null;
        });
    } else {
      return Promise.resolve(false);
    }
  }

  // Returns and inventory report for this salon
  apiGetReportInventory(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/inventory_grid', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }

  // Returns a report of all clients for this salon (or user)
  apiGetReportClients(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/clients', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a report of all clients for this salon (or user)
  apiGetReportRegister(period: string = 'month'): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/register', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token,
        period: period
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a report of all clients for this salon (or user)
  apiGetReportDailyRegister(register_date: DateTime): Promise<any> {
    this.assumeOnline();
    const date = `${register_date.year}-${register_date.month}-${register_date.day}`;
    return getWithRetry(this.http, this.endpoint_url + '/api/report/daily_register', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token,
        date: date
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ({success: false});
      });
  }


  // Returns a report of all clients for this salon (or user)
  apiGetReportSession(css: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/color_session', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token,
        css: css
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ({success: false});
      });
  }


  // Returns a report of all invoices for this salon or user
  apiGetReportBilling(period: string = 'mtd'): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/billing', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token,
        period: period
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a report of all clients for this salon (or user)
  apiGetReportInvoice(invoice_token: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/invoice', {
      params: {
        sess: this.session_token,
        inv: invoice_token,
        saln: this.current_salon.api_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a history report for a single client
  apiGetReportClientHistory(client_token: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/client_history', {
      params: {
        sess: this.session_token,
        cli: client_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a history report for a single client
  apiGetReportStylistHistory(stylist_token: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/stylist_history', {
      params: {
        sess: this.session_token,
        usr: stylist_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }

  // Returns a history report for a single client
  apiGetReportClientReviewHistory(client_token: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/report/client_review_history', {
      params: {
        sess: this.session_token,
        cli: client_token
      }
    })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return ([]);
      });
  }


  // Returns a list of clients clients that have recently been accessed in this app
  getRecentClients(numClients = 0): CLiCSClient[] {
    if (this.clients != null) {
      let recent = this.clients.filter(client => client.last_access > 0);
      recent = recent.sort(function (a, b) {
        return (b.last_access - a.last_access)
      });
      if (numClients == 0)
        return recent;
      else
        return recent.slice(0, numClients);
    } else {
      return ([] as CLiCSClient[]);
    }
  }

  // Returns true if major app login has been satisfied, else false
  isLoggedIn(): boolean {
    // TODO: add occasional validation / expiration of the login_token, depending on client and app mode
    return this.login_token !== null && this.login_token != '' && this.login_token != 'null';
  }

  // Returns true if minor app sign-on has been satisfied, else false
  isSignedOn(): boolean {
    return this.current_user !== null && this.session_token != null && this.session_token != '';
  }

  // Create or update a client on the back-end
  apiSaveClient(client: CLiCSClient): Promise<any> {
    const _that = this;
    this.assumeOnline();
    let new_client = client.token == null;  // Add this client to the list after save?
    let sln = null;
    if (this.current_salon) {
      sln = this.current_salon.api_token;
    }

    // TODO: if updating existing client call postWithRetry, if new then this.http.post
    return this.http.post(this.endpoint_url + '/api/control_panel/save_client',
      {sess: this.session_token, client: JSON.stringify(client), cli: client.token, saln: sln})
      .toPromise()
      .then((data: any) => {
        if (new_client) {
          client.token = data.token;
          // NOTE: previous version used postWithRetry and would send the request multiple times. Code below handles identical return client.
          // In case creating an exact duplicate client was attempted, check for an existing client in the local client array
          const existing = _that.clients.find( el => el.token == data.token )
          if (existing == undefined) {
            const new_cli_obj = new CLiCSClient(client);
            _that.clients.push(new_cli_obj);
            _that.current_user.setClient(new_cli_obj);
          } else {
            _that.current_user.setClient(existing);
          }
        } else {
          const index = _that.clients.findIndex((el) => {
            return el.token == client.token;
          });
          if (index >= 0) {
            // Update local copy of client
            _that.clients[index].first_name = client.first_name;
            _that.clients[index].last_name = client.last_name;
            _that.clients[index].nickname = client.nickname;
            _that.clients[index].phone = client.phone;
            _that.clients[index].email = client.email;
            _that.current_user.setClient(_that.clients[index]);
          }
        }
        _that.clients.sort(function (el1, el2) {
          const first1 = el1.first_name.toUpperCase();
          const first2 = el2.first_name.toUpperCase();
          const last1 = el1.last_name.toUpperCase();
          const last2 = el2.last_name.toUpperCase();
          if (first1 == first2)
            return last1 > last2 ? 1 : -1;
          else
            return first1 > first2 ? 1 : -1;
        });
        localStorage.setItem('clients', JSON.stringify(_that.clients));
        _that.events.publish('client:saved');
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Removes a client if the
  apiRemoveClient(clientToken: string): Promise<any> {
    this.assumeOnline();
    const index = this.clients.findIndex((el) => {
      return el.token == clientToken;
    });
    if (index >= 0) {
      return postWithRetry(this.http, this.endpoint_url + '/api/control_panel/remove_client',
        {sess: this.session_token, cli: clientToken})
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            this.clearClient();  // No longer a client selected
            this.clients.splice(index, 1);
            localStorage.setItem('clients', JSON.stringify(this.clients));
            this.events.publish('client:removed');
          }
          return (data);
        })
        .catch(error => {
          this.handleOffline();
          return false;
        });
    } else {
      return Promise.resolve({success: false, message_key: 'mk_client_not_found'});
    }
  }


  // Returns selected client object or null. Corrects for client not if my clients list.
  activeClient(): CLiCSClient {
    if (this.current_user !== null) {
      return this.current_user.getClient();
    } else
      return null;
  }

  // Clear the currently selected client
  clearClient(quietMode: boolean = false): void {
    if (this.current_user) {
      this.current_user.setClient(null);
      this.prior_client_token = null;
      if (quietMode == false) {
        this.events.publish('navrequest', {top: 'clients', page: 'clients'});
        this.events.publish('client:updated');
        this.events.publish('client:selected', null);
      }
    }
  }

  // Clear all clients, forcing a reload from the API
  clearAllClients(): void {
    if (this.clients) {
      this.clients.length = 0;
      this.clients = null;
    }
    localStorage.removeItem('clients');
  }

  // If there is a default client listed, sets them as the current client
  selectDefaultClient(): Promise<CLiCSClient> {
    const activeClient = this.activeClient();
    if (!!activeClient && activeClient.default == true) {
      return Promise.resolve(activeClient);
    } else {
      let defaultClient: CLiCSClient = null;
      if (!!this.clients) {
        defaultClient = this.clients.find(el => { return el.default == true; });
      }
      if (!!defaultClient && (!activeClient || activeClient.token != defaultClient.token)) {
        return this.selectClient(defaultClient.token).then((result) => {
          return defaultClient;
        });
      } else {
        return Promise.resolve(null);
      }
    }
  }

  // Returns true of a client is selected. Does NOT correct for disallowed client
  selectClient(client_token: string): Promise<boolean> {
    let _that = this;
    if (this.current_user) {
      let prev = this.current_user.getClient();
      if (prev)
        this.prior_client_token = prev.token;
      else
        this.prior_client_token = null;
    }
    let client = this.clients.find((el) => {
      return el.token == client_token;
    });
    if (!!client) {
      client.last_access = Math.floor(Date.now() / 1000);
      this.current_user.setClient(client);
      // Don't reload CS if there's already one for the client
      return this.apiGetClientNotes(client).then(() => {
        if (!!client.cs || client.cs.isAnonymous()) {
          _that.apiGetColorSession(client).then((cs) => {
            _that.events.publish('client:selected', client);
            return true;
          }, () => {
            console.log("apiGetColorSession failed in selectClient");
            return false;
          });
        } else {
          console.log("Using existing CS for selected client");
          _that.events.publish('client:selected', client);
          return Promise.resolve(true);
        }
      });
    } else {
      console.log("Unable to find matching client in select_client");
      return Promise.resolve(false);
    }
  }

  // Match a client and update the client record in the clients array and in the current user if this is the current
  // client. Typically used to add a token to an anonymous CS.
  updateClient(updated_client: CLiCSClient): void {
    const index = this.clients.findIndex((el) => { return(el.token == updated_client.token) });
    if (index >= 0) {
      this.clients[index].loadObj(updated_client);
      let cur_client = this.activeClient();
      if (!!cur_client) {
        if (cur_client.token == updated_client.token) {
          this.current_user.setClient(this.clients[index]);
          this.saveCurrentUser();
        }
      }
    }
  }

  // Revert to the previously-selected client (for this user)
  rollbackClient(): boolean {
    if (this.prior_client_token) {
      for (let client of this.clients) {
        if (client.token == this.prior_client_token) {
          client.last_access = Math.floor(Date.now() / 1000);
          this.current_user.setClient(client);
          this.saveToLocal();
          this.apiGetColorSession(client).then((cs) => {
            this.events.publish('client:selected', client);
          });
          return true;
        }
      }
    }
    return false;
  }

  // Returns true of a client is selected. Does NOT correct for disallowed client
  clientIsSelected(): boolean {
    if (this.current_user) {
      return (this.current_user.getClient() !== null);
    } else {
      return null;
    }
  }

  // Returns just the name of the active client or an empty string
  clientName(): string {
    let result = '';
    if (this.current_user != null) {
      let client = this.current_user.getClient();
      if (client !== null) {
        result = client.name();
      }
    }
    return result;
  }

  // Finds a formula in the current color session. Looks up by token if passed, otherwise by local request ident attr
  findClientFormula(token: string = null, request_ident: string = null): CLiCSFormulaRequest {
    let result: CLiCSFormulaRequest = null;

    let cli = this.current_user.getClient();
    if (!!cli) {
      if (cli.cs) {
        if (!!token && token.trim() != '') {
          for (let formula of cli.cs.formulas) {
            if (formula.token == token) {
              result = formula;
              break;
            }
          }
        }

        if (!result && !!request_ident && request_ident.trim() != '') {
          for (let formula of cli.cs.formulas) {
            if (formula.request_ident == request_ident) {
              result = formula;
              break;
            }
          }
        }
      }
    }
    return result;
  }

  // Returns a color formula from the presently-loaded library
  findLibraryFormula(token: string): Promise<CLiCSColorFormula> {
    return this.getRepo(true).then((repo) => {
      return (repo.findFormula(token));
    });
  }

  // Returns a color formula from the presently-loaded library
  findConversionFormula(token: string): Promise<CLiCSColorFormula> {
    return this.getConversionRepo().then((repo) => {
      return (repo.findFormula(token));
    });
  }

  // Called periodically from a TimerObservable in navigation-bar component, checks connection when offline
  checkConnection(): void {
    let _that = this;
    if (this.online == false || this.possibly_offline == true)
      this.apiPingServer().then((data) => {
        if (data === true) {
          _that.online = true;
          _that.possibly_offline = false;
        }
      });
  }

  // Invite a stylist / staff member to CLICS FOR THE CURRENT SALON
  apiInviteStaff(invitee: CLiCSClient): Promise<any> {
    this.assumeOnline();
    const sln = !!this.current_salon ? this.current_salon.api_token : null;

    return this.http.post(this.endpoint_url + '/api/control_panel/invite_staff',
      {sess: this.session_token, invitee: JSON.stringify(invitee), saln: sln})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // If a existing users were found send the chosen invitee
  apiConfirmInvite(invite_code: string, user_ident: number): Promise<any> {
    this.assumeOnline();
    return this.http.post(this.endpoint_url + '/api/control_panel/confirm_invitation',
      {sess: this.session_token, invite_code: invite_code, user_ident: user_ident})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // This is the main call to retrieve the currently-loaded color libraries. If the library has been loaded and is
  // cached the cached library is immediately returned. If the library is not yet loaded. The repo is updated by calling
  // checkManifest() which may clear the repo and then calling this method.
  // NOTE: force logic has not been tested as of 4/2023
  async getRepo(fetchUserLibraries: boolean = false, force: boolean = false): Promise<any> {
    this.assumeOnline();
    if (!this.repo || this.repo.empty()) {
      this.loadRepo();
      if (!this.repo) {
        this.repo = new CLiCSRepository();
      }
    }

    if (!this.repo.hasSystemLibraries() || force) {
      console.log("getRepo - loading system collections");
      await this.apiGetSystemRepository();
    }

    // Conversion collections only loaded for the CLICS mobile app, not loaded for Goldwell
    if ((!this.modeCtrl || this.modeCtrl.getAppType() == 'clics') && (!this.repo.hasConversionLibraries() || force)) {
      console.log("getRepo - loading conversion collections");
      await this.apiGetConversionRepository();
    }

    if (fetchUserLibraries && !!this.current_user && (!this.repo.hasUserLibraries() || force)) {
      console.log("getRepo - loading user collections");
      await this.apiGetUserRepository();
    }

    if (this.current_salon && (!this.repo.hasSalonLibraries() || force)) {
      console.log("getRepo - loading salon libraries");
      await this.apiGetSalonRepository();
    }

    this.saveRepo();
    return Promise.resolve(this.repo);
  }


  // 2/2023 returns the same repo as getRepo() but first Checks whether conversion formulas are loaded into the that repo
  // object.
  getConversionRepo(force: boolean = false): Promise<any> {
    this.assumeOnline();
    if (this.repo == null || this.repo.empty())
      this.loadRepo();
    if (!force && this.repo != null && !this.repo.empty() && this.repo.hasConversionLibraries()) {
      return Promise.resolve(this.repo);
    } else {
      return this.apiGetConversionRepository().then((data) => {
        if (data.success) {
          this.saveRepo();
        }
        return Promise.resolve(this.repo);
      });
    }
  }

  // Adds system library formulas and APPs to the local repo. USES SALON to determine the libraries to return.
  apiGetSystemRepository(): Promise<any> {
    console.log("Retrieving system repo from server");
    this.assumeOnline();
    let saln = null;
    if (!!this.current_salon && !!this.current_salon.api_token && this.current_salon.api_token != '') {
      saln = this.current_salon.api_token;
    } else {
      console.warn('apiGetSystemRepository called with no salon selected.');
    }
    const opts = {params: {sess: this.session_token, brand: this.mobileAppType(), saln: saln}};
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_system_library_repo', opts)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.repo.incorporate(data.library_repo);
        }
        return (data);
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // As of 2/2023 removed the separate conversion repository. All system, user, salon, and conversion libraries are now
  // loaded into the single repo object
  apiGetConversionRepository(): Promise<any> {
    console.log("Retrieving conversion repo from server");
    this.assumeOnline();
    const opts = {params: {sess: this.session_token, brand: this.mobileAppType()}};
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_conversion_library_repo', opts)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.repo.incorporate(data.library_repo);
        }
        return (data);
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // TODO: integrate 'salon', 'shared' and 'purchased' libraries
  apiGetUserRepository(): Promise<any> {
    console.log("Retrieving user repo from server");
    if (!this.repo || this.repo.empty()) {
      this.loadRepo();
    }
    this.assumeOnline();
    const opts = {params: {sess: this.session_token, brand: this.mobileAppType()}};
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_user_library_repo', opts)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.repo.incorporate(data.library_repo);
          this.repo.userQueryApplied();
          this.current_user.updateLibraryTimestamp(data.timestamp);
          this.saveCurrentUser();
        }
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // TODO: integrate 'shared' and 'purchased' libraries

  // TODO: if salon repo is empty this will call the server each time the Lab Colors page is loaded. Appropriate?
  apiGetSalonRepository(): Promise<any> {
    console.log("Retrieving salon repo from server");
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_salon_library_repo', {
      params: {
        sess: this.session_token,
        saln: this.current_salon.api_token
      }
    })
      .toPromise()
      .then((data) => {
        if (data.success) {
          if (data.library_repo.length > 0) {
            this.repo.incorporate(data.library_repo);
          }
          this.repo.salonQueryApplied();
        }
        return (data);
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Returns an array of Product info objects. Checks for a products list in local storage and returns it if found.
  // If not found gets a list of products from the server and stores that in local storage then returns the list to
  // the caller. Products are primarily maintained in teh MixingBowlProvider.
  apiGetProducts(force: boolean = false): Promise<any> {
    let products: CLiCSProduct[] = this.getProducts();  // Attempt to retrieve from local storage

    // TODO: manifest checking here
    if (!force && (!!products && products.length > 0)) {
      return Promise.resolve(products);
    } else {
      this.assumeOnline();
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_products', {params: {sess: this.session_token}})
        .toPromise()
        .then((data: any) => {
          let products: CLiCSProduct[] = [];
          if (data.success) {
            for (let prod of data.products) {
              products.push(new CLiCSProduct(prod));
            }
            this.saveProducts(products);
          }
          return (products);
        })
        .catch(error => {
          this.handleOffline(null, error);
          return false;
        });
    }
  }

  // Returns an array of hair color application type objects. Checks whether the list is in local and, if not,
  // it downloads the list from the server.
  apiGetAppTypes(): Promise<any> {
    let app_types: CLiCSAppType[] = this.getAppTypes();  // Attempt to retrieve from local storage

    // TODO: manifest checking here
    if (app_types != null && app_types.length > 0) {
      return Promise.resolve(app_types);
    } else {
      this.assumeOnline();
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_app_types', {params: {sess: this.session_token}})
        .toPromise()
        .then((data: any) => {
          app_types = [];
          if (data.success) {
            for (let app_type of data.app_types) {
              app_types.push(new CLiCSAppType(app_type));
            }
            this.saveAppTypes(app_types);
          }
          return (app_types);
        })
        .catch(error => {
          this.handleOffline(null, error);
          return false;
        });
    }
  }

  // Loads the content object from localStorage or from the online server if not in storage.
  apiGetContent(): Promise<any> {
    let content: CLiCSContent = this.getContent();  // Attempt to retrieve from local storage

    // TODO: manifest checking here
    if (content != null && content.empty() == false) {
      return Promise.resolve(content);
    } else {
      this.assumeOnline();
      return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/get_content', {params: {sess: this.session_token}})
        .toPromise()
        .then((data: any) => {
          if (data.success) {
            if (this.content == null)
              this.content = new CLiCSContent();
            this.content.loadContent(data.content);
            this.saveContent(this.content);
          }
          return (this.content);
        })
        .catch(error => {
          this.handleOffline(null, error);
          return false;
        });
    }
  }

  // Post a trouble ticket to the server
  apiAddServiceTicket(subject: string,
                      description: string,
                      category: string,
                      text_ok: boolean = false,
                      email_ok: boolean = false,
                      phone_ok: boolean = false): Promise<boolean> {
    this.assumeOnline();
    return this.http.post(this.endpoint_url + '/api/support/add_ticket',
      {
        params: {
          sess: this.session_token,
          saln: this.current_salon.api_token,
          subject: subject,
          description: description,
          category: category,
          text_ok: text_ok,
          email_ok: email_ok,
          phone_ok: phone_ok
        }
      })
      .toPromise()
      .then((data: any) => {
        return (data.content);
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  apiCheckBarcode(barcode: string): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/inventory/check_barcode',
      {
        params: {sess: this.session_token, barcode: barcode}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  apiRegisterCanister(barcode: string, shipment_id: number = null): Promise<any> {
    this.assumeOnline();
    let params = {
      sess: this.session_token,
      barcode: barcode,
      saln: this.current_salon.api_token
    }
    if (!!shipment_id) {
      params['shipment_id'] = shipment_id;
    }
    return postWithRetry(this.http, this.endpoint_url + '/api/inventory/register_canister', params)
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  apiRegisterDamaged(barcode: string, damage: any): Promise<any> {
    this.assumeOnline();
    let params = {
      sess: this.session_token,
      barcode: barcode,
      damage: damage,
      saln: this.current_salon.api_token
    }
    return postWithRetry(this.http, this.endpoint_url + '/api/inventory/register_damaged', params)
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Reports to the server that a shipment identified by tracking number has been received at a salon
  apiReportReceivedShipment(tracking_no: string): Promise<any> {
    this.assumeOnline();
    return postWithRetry(this.http, this.endpoint_url + '/api/inventory/shipment_received',
      {sess: this.session_token, tracking_no: tracking_no, saln: this.current_salon.api_token})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  apiRequestValidateLocation(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/common/request_validate_location',
      {
        params: {sess: this.session_token, saln: this.current_salon.api_token}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  apiCancelValidateLocation(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/common/cancel_validate_location',
      {
        params: {sess: this.session_token, saln: this.current_salon.api_token}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Fakes a successful validation for the current salon. Normally done by scanning barcode on dispenser
  apiFakeLocationValidation(): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/common/fake_validate_location',
      {
        params: {sess: this.session_token, saln: this.current_salon.api_token}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Passes a location validation token which defines the inventory scan. Any canister not scanned will be marked as
  // missing. Simulate parameter set true to just check how many would be set missing.
  apiRecordMissingCanisters(validation_token: string, simulate: boolean = false): Promise<any> {
    this.assumeOnline();
    return postWithRetry(this.http, this.endpoint_url + '/api/inventory/record_missing',
      {sess: this.session_token, validation_token: validation_token, simulate: simulate})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // passes a location validation token which defines the inventory scan's start time.
  apiRecordPartialScan(validation_token: string): Promise<any> {
    this.assumeOnline();
    return postWithRetry(this.http, this.endpoint_url + '/api/inventory/record_partial',
      {sess: this.session_token, validation_token: validation_token})
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Returns {success: false, new_missing: 0, all_missing: 0, all_active: 0, in_machines: 0}
  apiGetInventoryReport(salon: CLiCSSalon): Promise<any> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/inventory/get_inventory_report',
      {
        params: {sess: this.session_token, saln: this.current_salon.api_token}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // Sends a login code via SMS to the phone number listed IF that phone match one user only
  apiSendLoginCode(phoneNumber: string) {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/send_login_code',
      {
        params: {phone: phoneNumber}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.handleOffline(null, error);
        return false;
      });
  }

  // returns a list of timer objects that are used to refresh the list in teh timerAlert provider
  apiGetRinseTimers(): Promise<any> {
    this.assumeOnline();
    return this.http.get(this.endpoint_url + '/api/common/get_rinse_timers', {params: {sess: this.session_token}})
      .toPromise()
      .then((data: any) => {
        return (data);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Sets the active "lab" formula, possibly with one that needs editing. Destroys current formula dead.
  // Always creates a new object, a copy of a formula
  setCurrentFormula(new_formula: any): void {
    this.current_formula = new CLiCSColorFormula(new_formula);
    localStorage.setItem('current_formula', JSON.stringify(this.current_formula));
  }

  // Returns the active lab formula. Creates one if needed.
  getCurrentFormula(): CLiCSColorFormula {
    if (this.current_formula == null) {
      this.current_formula = new CLiCSColorFormula();
    }
    return this.current_formula;
  }

  clearCurrentFormula() {
    this.current_formula = null;
    localStorage.removeItem('current_formula');
  }

  // Returns the active lab color application of creates a new one.
  getCurrentColorApplication(): CLiCSColorApplication {
    alert('using getCurrentColorApplication');
    if (this.current_color_application == null) {
      this.current_color_application = new CLiCSColorApplication();
    }
    return this.current_color_application;
  }

  clearCurrentColorApplication() {
    this.current_color_application = null;
    localStorage.removeItem('current_color_session');
  }

  setCurrentColorApplication(app_object: any = null) {
    if (app_object) {
      this.current_color_application = new CLiCSColorApplication(app_object);
      localStorage.setItem('current_color_session', JSON.stringify(this.current_color_application));
    } else
      this.clearCurrentColorApplication();
  }

  setUseLabApp(value: boolean = true) {
    this.use_lab_app = value;
  }

  getUseLabApp(): boolean {
    if (this.use_lab_app) {
      this.use_lab_app = false;
      return true;
    } else
      return false;
  }

  // Clears the loaded queue information
  clearQueues(): void {
    this.dispenser_queue.length = 0;
    this.swatch_queue.length = 0;
    this.quick_queue.length = 0;
    this.unweighed_queue.length = 0;
  }

  // Clears the color library and reloads it
  reloadLibraries(clearOnly: boolean = false): Promise<boolean> {
    this.clearLibrary();
    this.clearProducts();
    if (clearOnly) {
      return Promise.resolve(true);
    } else {
      return this.apiGetSystemRepository().then((data) => {
        this.saveRepo();
        return this.apiGetUserRepository().then((data) => {
          this.saveRepo();
          return this.apiGetSalonRepository().then((data) => {
            this.saveRepo();
            return this.apiGetConversionRepository().then((data) => {
              this.saveRepo();
              return true;
            });
          });
        });
      });
    }
  }

  // Starting level is changed in the client profile but also recorded in the color session. This records a changed
  // level to the current client's color session. New level is saved to the back-end.
  recordStartingLevel(newLevel: number = undefined) {
    let client = this.current_user.getClient();
    if (client) {
      if (newLevel == undefined && client.client_hair_profile) {
        newLevel = client.client_hair_profile.starting_level;
      }
      if (newLevel && newLevel != client.cs.starting_level) {
        client.cs.setStartingLevel(newLevel);
        this.apiRecordStartingLevel(client.cs);
      }
    }
  }

  // Replace the color session with new settings, formula, etc.
  replaceColorSession(newCS: any): Promise<any> {
    let client = this.current_user.getClient();
    if (client && (client.cs == null || client.cs.isQueued() == false)) {
      // client.loadColorSession(newCS, true);  << doesn't work consistently
      client.cs.clear(true);
      client.cs.loadObj(newCS, true);
      client.cs.touch();  // set updated_at to NOW  @@@ SD'A review this for sync issues, consider softTouch()

      return this.apiUpdateColorSession(client.cs).then((data) => {
        localStorage.setItem('current_user', JSON.stringify(this.current_user));
        this.recordStartingLevel();
        return {success: true, mssg: '', cs: client.cs};
      }, (reason) => {
        return {success: false, mssg: reason, cs: client.cs};
      }).catch(error => {
        this.handleOffline(null, error);
        return {success: false, mssg: error._body, cs: client.cs};
      });
    } else {
      let mssg: string = '';
      if (client)
        mssg = "The client's application is actively queued and cannot be replaced. Please complete or cancel the client's application first.";
      else
        mssg = "Sorry! Something went wrong when using this application. Please select the client again from the List page and repeat this operation.";
      return Promise.resolve({success: false, mssg: mssg});
    }
  }

  // Use this for next client
  stageColorSession(newAPP: any) {
    if (newAPP instanceof CLiCSColorSession) {
      this.stagedColorApplication = new CLiCSColorSession(newAPP);
    } else {
      this.stagedColorApplication = new CLiCSColorApplication(newAPP);
    }
  }

  // Forget colors formula or color application that was
  forgetStagedColors() {
    this.stagedColorApplication = null;
    this.stagedColorFormula = null;
  }

  // Lookup page content of a particular type
  getPageContent(pageName: string, contentType: string): Promise<string> {
    return this.apiGetContent().then((content) => {
      let contentStr: string = "";
      if (content && content.empty() == false)
        contentStr = content.pageContent(pageName, contentType);
      return (Promise.resolve(contentStr));
    }, (result) => {
      console.log(`Failed to retrieve ${contentType} content for page ${pageName}`);
      return (Promise.resolve(""));
    });
  }

  // Return salons. TODO: load from local if null
  getSalons(): CLiCSSalon[] {
    if (!!this.salons && this.salons.length > 0)
      return this.salons;
    else {
      // Attempt to load salons from local storage
      let val = localStorage.getItem('salons');
      if (!!val && val != 'null') {
        let items = JSON.parse(val);
        this.salons = [];
        for (let item of items) {
          this.salons.push(new CLiCSSalon(item));
        }
      }
      if (!!this.salons)
        return this.salons;
      else
        return [];
    }
  }

  // Any salons for this user?
  hasSalons(): boolean {
    return (!!this.salons && this.salons.length > 0);
  }

  // Selects a salon from salons where the token matches the passed token
  setCurrentSalon(salon_token: string): Promise<any> {
    let _that = this;
    if (this.hasSalons()) {
      const newSalon = this.salons.find((saln) => {
        return (saln.api_token == salon_token);
      });
      if (!this.current_salon || newSalon.api_token != this.current_salon.api_token) {
        this.repo.clearByScope('system');
      }
      this.current_salon = newSalon;

      // Assign the user the correct role for the chosen salon
      if (!!this.current_salon) {
        this.current_user.role = this.current_salon.user_role || this.current_user.role;
      }
      this.saveCurrentSalon();
      // Load color library repository

      _that.getRepo(false).then((result) => {
        _that.apiGetUserRepository().then((result) => {
          _that.saveToLocal();
          return true;
        });
      });
    } else {
      return Promise.resolve(false);
    }
  }

  // Store the current salon choice so this can be restored on startup
  saveCurrentSalon(): void {
    if (this.current_salon == null)
      localStorage.removeItem('current_salon');
    else
      localStorage.setItem('current_salon', JSON.stringify(this.current_salon));
  }

  // Remove the current salon specifier. IMPORTANT: this should be associated with navigation to the Login or Salon
  // choice page!
  clearCurrentSalon() {
    this.current_salon = null;
    this.saveCurrentSalon();
  }

  // Switch server to 'staging' or 'production'... only if logged out
  setServerEndpoint(server_ident: string) {
    if (this.isLoggedIn() == false) {
      if (this.endpoint_description != server_ident) {
        this.clearCurrentSalon();
        this.clearLibrary();
      }
      if (server_ident == 'staging') {
        this.endpoint_url = STAGING_ENDPOINT;
        this.endpoint_description = 'staging';
      } else {
        this.endpoint_url = PRODUCTION_ENDPOINT;
        this.endpoint_description = 'production';
      }
      this.saveEndpoint();
      return true;
    } else
      return false;
  }

  // Save the endpoint, description only, use it to restore the endpoint later
  saveEndpoint(): void {
    localStorage.setItem('endpoint', this.endpoint_description);
  }

  restoreEndpoint(): void {
    const endpoint = localStorage.getItem('endpoint');
    if (endpoint != null)
      this.endpoint_description = endpoint;
    if (this.endpoint_description == 'staging')
      this.endpoint_url = STAGING_ENDPOINT;
    else {
      this.endpoint_url = PRODUCTION_ENDPOINT;
    }
  }

  // Set up a listener to follow the device theme
  followDeviceTheme() {
    // Use matchMedia to check the user preference
    if (!this.themeListener) {
      this.themeListener = window.matchMedia('(prefers-color-scheme: dark)');
      this.respondToPreferredTheme(this.themeListener.matches);
      this.themeListener.addListener((mediaQuery) => this.respondToPreferredTheme(mediaQuery.matches)); // TODO: deprecated. needed? Update?
      this.getTheme();
    }
  }

  // If autoTheme is set then change the theme based on the device
  respondToPreferredTheme(shouldAdd) {
    if (!!this.autoTheme) {
      document.body.classList.toggle('dark', shouldAdd);
      this.theme = shouldAdd ? 'dark' : 'light';
      this.setTheme(this.theme);
      console.log(`Setting theme to ${this.theme} based on device theme`);
    }
  }

  // When activated, detects the device them and changes local theme to suit
  setAutoTheme(activate: boolean) {
    if (!!activate) {
      this.autoTheme = true;
      const listener = window.matchMedia('(prefers-color-scheme: dark)');
      this.respondToPreferredTheme(listener.matches);

      this.followDeviceTheme();
    } else {
      this.autoTheme = false;
    }
    this.getTheme();
    this.setTheme(this.theme);
  }

  // Update the app theme
  setTheme(new_theme: string, save: boolean = true): string {
    let index = ['light', 'dark'].indexOf(new_theme);
    if (index >= 0) {
      this.theme = new_theme;
      if (!!save) {
        this.saveTheme();
      }
    }
    if (this.theme == 'dark') {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
      this.statusBar.backgroundColorByHexString('#282828');
      // this.statusBar.styleLightContent();
      this.statusBar.styleBlackTranslucent();
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
      const color = !!this.autoTheme ? '#FFFFFF' : '#CCCCCC';
      this.statusBar.backgroundColorByHexString(color);
      this.statusBar.styleDefault();
    }
    return this.theme;
  }

  // Update the app theme
  getTheme(): string {
    if (!this.theme) {
      this.setTheme('light');
    }
    return this.theme;
  }

  // Called in admin profile page, handles change of default client
  handleDispenseModeChange(): Promise<boolean> {
    const new_mode = this.modeCtrl.getDispenseMode();

    // "Stash" a client for when we return to a non-simple mode
    if (!!this.current_user && new_mode == 'simple' && this.dispenseMode != 'simple') {
      this.current_user.stashClient(this.activeClient());
    }

    this.dispenseMode = new_mode;  // used internally only
    if (this.dispenseMode == 'simple') {
      return this.selectDefaultClient().then((client) => {
        const activeClient = this.activeClient();
        if (!!activeClient && !activeClient.cs.isEmpty() && !activeClient.cs.isQueued()) {
          this.apiQueueColorSession(activeClient.cs).then((result) => {
            return true;
          });
        } else {
          return true;
        }
      });
    } else {
      let stashedClient: any = null;
      if (!!this.current_user)
        stashedClient = this.current_user.getStashedClient();
      if (!!stashedClient) {
        return this.selectClient(stashedClient.token).then((result) => {
          return true;
        });
      } else {
        return Promise.resolve(true);
      }
    }
  }

  // Store a color application (or CS) or a color formula (or FR) along with an action and modified flag
  // data_obj_to_stash may include {modified: boolean, data: any, action: string}
  stashData(data_obj_to_stash: any, source: string = null) {
    if (!!data_obj_to_stash) {
      this.clearStash();
      if ('modified' in data_obj_to_stash)
        this.dataStash.modified = !!data_obj_to_stash.modified;
      if ('data' in data_obj_to_stash)
        this.dataStash.data = data_obj_to_stash.data;
      else
        this.dataStash.data = null;
      if ('ca' in data_obj_to_stash)
        this.dataStash.ca = data_obj_to_stash.ca;
      else
        this.dataStash.ca = null;
      if ('cf' in data_obj_to_stash)
        this.dataStash.cf = data_obj_to_stash.cf;
      else
        this.dataStash.cf = null;
      if ('action' in data_obj_to_stash)
        this.dataStash.action = data_obj_to_stash.action;
      this.dataStash.source = source;
      if ('target' in data_obj_to_stash)
        this.dataStash.target = data_obj_to_stash.target;
      this.dataStash.updated_at = Date.now(); // milliseconds
    }
    return this.dataStash;
  }

  // Store a color application (or CS) or a color formula (or FR) along with an action and modified flag
  clearStash() {
    this.dataStash = {modified: false, ca: null, cf: null, data: null, action: 'NONE', source: null, updated_at: null, reload: false, target: null};
  }

  // Return the data stash object
  getStash(): any {
    // Expire dataStash after 5 minutes
    if (!this.dataStash || (!!this.dataStash.updated_at && (Date.now() - 300000) > this.dataStash.updated_at))
      this.clearStash();
    return this.dataStash;
  }

  // True if this dataStash has any meaningful data
  hasStash(): boolean {
    this.getStash();  // Force expiration if out of date
    return (!!this.dataStash &&
      (!!this.dataStash.data || !!this.dataStash.ca || !!this.dataStash.cf || this.dataStash.action != 'NONE'));
  }

  // Special case use of stash, action == RELOAD to prompt the application page to reload the formula once
  stashRequestReload(): void {
    this.dataStash.reload = true;
  }

  // Special case use of stash, action == RELOAD to prompt the application page to reload the formula once
  stashRecommendsReload(): boolean {
    const result = !!this.dataStash.reload;
    if (result)
      this.dataStash.reload = false;
    return result;
  }

  // Called when app wakes from sleep. Does something if N hours has passed
  resume(isStartup: boolean = false) {
    const validate_delay: number = 10 * 60 * 60 * 1000;  // 10 hours
    const update_delay: number = 4 * 60 * 60 * 1000;  // 4 hours

    // If more then 10 hours has passed since last resume, validate the login
    if (this.lastWakeTime == null || Date.now() > this.lastWakeTime.getTime() + validate_delay) {
      this.validateLogin().then((success) => {
        if (success == false) {
          this.events.publish('navrequest', {top: 'logout', page: 'LOGOUT'});
        }
        this.checkMessaging();
      });
    }
    this.lastWakeTime = new Date();

    // If it's been 4 hours or more since last update check manifest
    if (this.lastUpdateTime == null || Date.now() > this.lastUpdateTime.getTime() + update_delay) {
      if (!!this.current_user) {
        this.lastUpdateTime = new Date();
        this.checkManifest().then((result) => {
          this.getRepo();
        });
      }
    }
  }

  // Checks the server for a message and if available (and newer) display on the screen. If displayIfAvailable is false
  // then stores the message in the messaging provider for a future call to displayMessageIfAvailable()
  checkMessaging(displayIfAvailable: boolean = false): Promise<any> {
    // TODO: update platform criteria for desktop Web browser and disable this
    return this.messagingCtrl.checkMessaging(this.endpoint_url, this.session_token, displayIfAvailable);
  }

  // Abandon prior color formula and replace with a blank one
  // Confirm that the local login token is valid
  private validateLogin(): Promise<boolean> {
    this.assumeOnline();
    return getWithRetry(this.http, this.endpoint_url + '/api/control_panel/validate', {
      params: {
        ltok: this.login_token,
        appid: this.app_ident,
        apptype: this.mobileAppType()
      }
    })
      .toPromise()
      .then((data: any) => {
        if (data.success == false) {
          // Log out the user
          console.log('validateLogin unloading login session data');
          this.login_token = null;
          this.current_user = null;
          this.current_salon = null;
          this.saveToLocal();
          this.unloadSessionData(true, true);
        } else {
          // Update user's training level and correct bad dispense mode
          console.log('validateLogin succeeded');
          if ('training_level' in data) {
            this.current_user.training_level = data.training_level;
            this.current_user.dispense_mode = data.dispense_mode;
          }
          this._assertMaxDispenseMode();
        }
        return (data.success == true);
      })
      .catch(error => {
        this.handleOffline();
        return false;
      });
  }

  // Limits the dispense mode to the highest value permitted for the user or by app settings.
  // forceMax sets the mode to the highest level allowable for the user.
  private _assertMaxDispenseMode(forceMax = false) {
    if (!this.current_user) {
      console.warn("assertMaxDispenseMode() called with no current user");
      this.modeCtrl.setDispenseMode('expert');
      return;
    }
    // if (!!forceMax) {
    //   if (!!this.modeCtrl.isPermitted('allow_expert_mode'))
    //     this.modeCtrl.setDispenseMode('expert', true);
    //   else {
    //     if (!!this.modeCtrl.isPermitted('allow_advanced_mode')) {
    //       this.modeCtrl.setDispenseMode('advanced', true);
    //     } else {
    //       this.modeCtrl.setDispenseMode('simple', true);
    //     }
    //   }
    // }

    // const curMode = this.modeCtrl.getDispenseMode();
    // switch (this.current_user.dispense_mode) {
    //   case 'advanced':
    //     if (this.modeCtrl.isPermitted('allow_advanced_mode')) {
    //       if (curMode == 'expert') {
    //         this.modeCtrl.setDispenseMode('advanced', true);
    //       }
    //     } else { // Advanced mode not permitted
    //       if (this.modeCtrl.isPermitted('allow_expert_mode')) {
    //         if (curMode == 'advanced') {
    //           this.modeCtrl.setDispenseMode('expert', true);
    //         }
    //       } else {
    //         this.modeCtrl.setDispenseMode('simple', true);
    //       }
    //     }
    //     break;
    //   case 'simple':
    //     if (curMode == 'expert' || curMode == 'advanced') {
    //       this.modeCtrl.setDispenseMode('simple', true);
    //     }
    //     break;
    //   case 'expert':
    //     // if expert mode allow any current mode
    //     break;
    //   default:
    //     // undefined mode, lock to advanced as a reasonable default
    //     console.warn(`Undefined user dispense mode: ${this.current_user.dispense_mode} found in assertMaxDispenseMode()`);
    //     this.modeCtrl.setDispenseMode('expert', true);
    //     break;
    // }

    // NOTE: 1/2023 only allow expert mode
    this.modeCtrl.setDispenseMode('expert', true);
    this.events.publish('dispense_mode:change');
  }


  // Validate salon by location and by user, clear if doesn't match
  private validateSalon(): boolean {
    // Is a current salon set?
    // Do I own / manage this salon?
    // Do I have permission to access this salon?
    // Is this a virtual salon?
    // Is my location correct for the current salon
    return true;
  }

  // Looks up a flash message for I18N
  // TODO: flesh this out
  static translateMessage(message_key: string): string {
    // TODO: do I18N translation, return '' for empty string
    return message_key;
  }

  private handleOffline(message_key: string = null, error: any = null): void {
    if (this.possibly_offline) {
      this.online = false;
    }
    this.possibly_offline = true;
    if (this.online == false) {
      if (message_key === null)
        message_key = "mk_app_offline";
      this.flash.setError(message_key);
    }
    if (error) {
      console.log(`Error accessing server: ${error._body}`);
    }
  }

  // Set up for online operation, use handleOffline if unable to connect
  private assumeOnline() {
    if (!!this.flash) {
      this.flash.clear();
    }
  }
}
