import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ClientSearchPage} from './client-search';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ClientSearchPage],
  providers: [
    NavParams,
  ],
})
export class ClientSearchPageModule {}
