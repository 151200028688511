import { Component, AfterContentChecked, ViewChild, ViewEncapsulation } from '@angular/core';
import {NavController, ModalController, IonicSlides} from '@ionic/angular';
import {SwiperComponent} from 'swiper/angular';
import SwiperCore, {SwiperOptions, Pagination, Navigation} from 'swiper';

/**
 * Generated class for the WelcomeSlidesPage page.
 */
SwiperCore.use([Pagination, Navigation])
@Component({
  selector: 'page-welcome-slides',
  templateUrl: 'welcome-slides.html',
  styleUrls: ['welcome-slides.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeSlidesPage {
  @ViewChild('swiper') swiper: SwiperComponent;

  config: SwiperOptions = {
    centeredSlides: true,
    slidesPerView: 1,
    pagination: {clickable: true},
    navigation: true,
    centeredSlidesBounds: true
  }

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController) {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
