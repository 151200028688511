import {Component} from '@angular/core';
import {AlertController, NavController, NavParams, Platform, ToastController, ModalController} from '@ionic/angular';
import {BarcodeScanner, BarcodeScannerOptions} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';

/**
 * Not a "loop" in exactly the same way that other canister scan loops operate. Prompts with checkboxes for the
 * issue(s), then you scan the canister (records to the server), then you return to the main page showing what has
 * been scanned and you can remove / undo, scan another damaged canister or Finish.
 *
 */

@Component({
  selector: 'page-inventory-damaged-loop',
  templateUrl: 'inventory-damaged-loop.html',
  styleUrls: ['inventory-damaged-loop.scss'],
})
export class InventoryDamagedLoopPage {
  scannedData: any = null;
  encodedData: string = null;
  report: string = null;
  buttonText: string = 'Scan Damaged Canister'
  lastScanUndone: boolean = false;

  reviewList: boolean = false;

  damage: any[] = [
    {key: 'stem', label: 'Broken or missing stem or nozzle', value: false},
    {key: 'dent', label: 'Severely dented can', value: false},
    {key: 'label', label: 'Damaged / unreadable label', value: false},
    {key: 'leak', label: 'Product leaked from canister', value: false},
    {key: 'dispense', label: 'Canister won\'t dispense', value: false},
    {key: 'other', label: 'Other damage', value: false}
  ]

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private barcodeCtrl: BarcodeScanner,
    private events: EventsService,
    private plt: Platform,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    // private insomnia: Insomnia,
    private toastCtrl: ToastController,
    private clicsService: CLiCSService) {}

  // Scans a single canister
  scanDamagedCanister() {
    let _that = this;

    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Center CLICS barcode inside the scan area',
      resultDisplayDuration: 500,
      formats: 'CODE_128',
      orientation: 'portrait',
    };

    this.barcodeCtrl.scan(options).then(barcodeData => {
      _that.scannedData = barcodeData;
      _that.report = null;
      if (!_that.scannedData || _that.scannedData['text'] == '') {
        console.log("no barcode data")
        return
      } else {
        console.log('Barcode data', _that.scannedData['text']);
      }
      _that.clicsService.apiRegisterDamaged(_that.scannedData['text'], _that.damage).then((data) => {
        if (!!data.success) {
          _that.report = '<strong>Damage:</strong>'
          for (const item of _that.damage) {
            if (!!item.value) {
              _that.report += `<br>${item.label}`
            }
          }
          _that.report += `<br><br>Product: ${data.name}<br>Weight remaining: ${data.weight}<br>Marked empty: ${data.empty ? 'Yes' : 'No'}`
        } else {
          _that.report = ''
        }
        _that.reviewList = true;
      }, (reason) => {
        _that.report = 'Could not access barcode data';
      });
    }).catch(err => {
      console.log('Error', err);

      // MIGRATION NOTE: 'windows' is no longer a recognized platform, previous
      // logic specified !this.plt.is('windows') as well
      if (this.plt.is('desktop')) {
        this.report = 'Barcode scanning is not available on this platform'
      } else {
        this.report = 'Please enable camera access to scan barcodes'
      }
    });
  }

  undoLastScan() {
    let _that = this;
    this.resetDamage();
    this.clicsService.apiRegisterDamaged(this.scannedData['text'], this.damage).then((data) => {
      if (!!data.success) {
        _that.report = '<strong>NOT DAMAGED</strong><br><br>'
        _that.report += `Product: ${data.name}<br>Weight remaining: ${data.weight}<br>Marked empty: ${data.empty ? 'Yes' : 'No'}`
        _that.reviewList = true;
        _that.lastScanUndone = true;
      } else {
        _that.report = ''
        _that.reviewList = false;
      }
    }, (reason) => {
      _that.report = 'Could not access barcode data';
      _that.reviewList = false;
    });
  }

  finishScan() {
    this.modalCtrl.dismiss();
    this.resetDamage();
    this.reviewList = false;
    this.events.publish('navrequest', {top: 'admin', page: 'admin_inventory'});
  }

  nextCanister() {
    this.resetDamage();
    this.reviewList = false;
  }

  // Set the damage element when a checkbox selection is changed
  setDamage(key, value) {
    let damage_el = this.damage.find((el) => {
      return el.key == key;
    });
    if (!!damage_el) {
      damage_el.value = Boolean(value); // TODO: does $event passed to value arg indicate checked status?
    }
  }

  resetDamage() {
    for (let item of this.damage) {
      item.value = false;
    }
    this.lastScanUndone = false;
  }

  // Returns true unless any damage category is selected
  notReadyForScan(): boolean {
    let result = true;
    for (const item of this.damage) {
      if (item.value == true) {
        result = false;
        break;
      }
    }
    return result;
  }

}
