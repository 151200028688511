import {Component} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {EventsService} from '../../services/events/events.service';

/**
 * Generated class for the ColorFilterPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-color-filter',
  templateUrl: 'color-filter.html',
  styleUrls: ['color-filter.scss'],
})
export class ColorFilterPage {
  filterSpec: any = {
    cleared: false,
    scope: 'colors',
    tags: '',
    library: {
      libCLiCS: false,
      libSaved: false,
      // libConversions: false,  << these do not live in the color library
      libSalon: false,
      libFollowing: false,
      libTrending: false,
      libDesigned: false
    },
    baseTone: {
      min: 1,
      max: 12
    },
    level: { lower: 1, upper: 12 }
  };

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private modalCtrl: ModalController,
              private events: EventsService) {
    let ipSpec = this.navParams.get('filterSpec');
    if (ipSpec) {
      this.filterSpec = ipSpec;
    }
  }

  ionViewWillEnter() {
    this.filterSpec.scope = this.navParams.get('scope');
    if (this.filterSpec.scope == undefined)
      this.filterSpec.scope = 'colors';
  }

  // Update tags & cleared flag
  validateSpec() {
    if (this.filterSpec.baseTone.min > 12)
      this.filterSpec.baseTone.min = 12;
    if (this.filterSpec.baseTone.min < 0)
      this.filterSpec.baseTone.min = 0;
    if (this.filterSpec.baseTone.max < this.filterSpec.baseTone.min)
      this.filterSpec.baseTone.max = this.filterSpec.baseTone.min;
    if (this.filterSpec.baseTone.max > 12)
      this.filterSpec.baseTone.max = 12;

    this.filterSpec.cleared = true;
    this.filterSpec.tags = '';
    if (this.filterSpec.library.libCLiCS == true) {
      this.filterSpec.cleared = false;
      this.filterSpec.tags += 'system,';
    }
    if (this.filterSpec.library.libSaved == true || this.filterSpec.library.libDesigned == true) {
      this.filterSpec.cleared = false;
      this.filterSpec.tags += 'user,';
    }
    if (this.filterSpec.library.libSalon == true) {
      this.filterSpec.cleared = false;
      this.filterSpec.tags += 'salon,';
    }
    if (this.filterSpec.tags.length > 1)
      this.filterSpec.tags = this.filterSpec.tags.slice(0, -1);
    if (this.filterSpec.baseTone.min > 0 || this.filterSpec.baseTone.max < 12)
      this.filterSpec.cleared = false;
  }

  // Clear filter spec
  clearSpec() {
    this.filterSpec.cleared = true;
    this.filterSpec.tags = "";
    this.filterSpec.library.libCLiCS = false;
    this.filterSpec.library.libSaved = false;
    this.filterSpec.library.libSalon = false;
    this.filterSpec.library.libFollowing = false;
    this.filterSpec.library.libTrending = false;
    this.filterSpec.library.libDesigned = false;
    this.filterSpec.baseTone.min = 1;
    this.filterSpec.baseTone.max = 12;
    this.filterSpec.level.lower = 1;
    this.filterSpec.level.upper = 12;
  }

  doSetFilter() {
    this.validateSpec();
    this.modalCtrl.dismiss();
    this.events.publish('filter:set', this.filterSpec);
  }

  doClearFilter() {
    this.clearSpec();
    this.modalCtrl.dismiss();
    this.events.publish('filter:clear', this.filterSpec);
  }
}
