import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {ColorDetailPage} from './color-detail';

@NgModule({
  imports: [
      IonicModule,
      CommonModule,
      ComponentsModule,
  ],
  declarations: [ColorDetailPage],
  providers: [
    NavParams,
  ],
})
export class ColorDetailPageModule {}
