import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {PhotoZoomPage} from './photo-zoom';
import {ComponentsModule} from "../../components/components.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule
  ],
  declarations: [PhotoZoomPage],
  providers: [NavParams],
})
export class PhotoZoomPageModule {}
