import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {LabAppPage} from './lab-app';
import {LabAppPageRoutingModule} from './lab-app-routing.module';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    LabAppPageRoutingModule,
  ],
  declarations: [LabAppPage],
  providers: [
    NavParams,
  ],
})
export class LabAppPageModule {}
