import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {HelpPage} from './help';
import {ComponentsModule} from "../../components/components.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [HelpPage],
  providers: [
    NavParams,
    InAppBrowser,
  ],
})
export class HelpPageModule {}
