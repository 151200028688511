import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";
import {AmountSliderComponent} from "../amount-slider/amount-slider";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Generated class for the ConfirmDeveloperComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'confirm-developer',
  templateUrl: 'confirm-developer.html',
  styleUrls: ['confirm-developer.scss'],
})
export class ConfirmDeveloperComponent {
  @ViewChild('amountSelect') amountSelect: AmountSliderComponent;
  @Input() unit: string = "g";
  @Input() start: number = 0;
  @Input() min: number = 1;
  @Input() max: number = 100;
  @Input() minStrength = 1;
  @Input() confirmText = 'Add';
  @Input() action = 'disp';
  @Input() showOz: boolean = true;
  @Output() requestDispense = new EventEmitter();
  @Output() cancel = new EventEmitter();

  valStr = "";  // used for display
  value: number = 0;
  ozVal: number = 0.0;
  ozValStr: string = "";
  productName: string = "";

  strength: number = 20;

  repeatTimer: any = null;
  waitInterval: number = 500;
  repeatInterval: number = 85;

  permissions: any = null;
  settings: any = null;

  constructor(private mixingBowl: MixingBowlProvider,
    private modeCtrl: ModeControllerProvider,
    ) {
    this.value = this.start;

    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
        this.permissions = this.modeCtrl.getPermissions();
    });

    if(!!this.permissions.show_milliliters){
        this.unit = "mL"
    }
  }

  ngOnInit() {
    this.value = this.start;
    this._update();
  }

  // Update display strings
  _update() {
    this.valStr = `${this.value}${this.unit}`;
    if (this.showOz) {
      this.ozVal = this.value * 0.035274;
      this.ozValStr = `(${this.ozVal.toFixed(2)}oz)`;
    }
    this.amountSelect.setAmount(this.value);
  }

  doIncrement() {
    if (this.repeatTimer) {
      this.incrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doIncrement();
    }, this.repeatInterval);
  }

  endIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  doDecrement() {
    if (this.repeatTimer) {
      this.decrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doDecrement();
    }, this.repeatInterval);
  }

  endDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  doStrIncrement() {
    if (this.repeatTimer) {
      this.incrStrength();
    }
    this.repeatTimer = setTimeout(() => {
      this.doStrIncrement();
    }, this.repeatInterval);
  }

  endStrIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  doStrDecrement() {
    if (this.repeatTimer) {
      this.decrStrength();
    }
    this.repeatTimer = setTimeout(() => {
      this.doStrDecrement();
    }, this.repeatInterval);
  }

  endStrDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  incrVal() {
    this.value += 1;
    if (this.value > this.max)
      this.value = this.max;
    this._update();
  }

  decrVal() {
    this.value -= 1;
    if (this.value < this.min)
      this.value = this.min;
    this._update();
  }

  incrStrength() {
    this.strength += 1;
    if (this.strength > 40)
      this.strength = 40;
  }

  decrStrength() {
    this.strength -= 1;
    if (this.strength < 0)
      this.strength = 0;
  }

  updateStrength(data) {
    this.strength = data.amount;
  }

  updateVal(data) {
    this.value = data.amount;
    this._update();
  }

  // Convert the requested product to a formula request and emit the request
  doRequestDispense() {
    if (this.value > 0.0) {
      let formulaText = `${this.value}${this.unit} D${this.strength}`;
      const paramItems = [{
        type: 'compOrig',
        value: `D${this.strength}`,
        mod: `${this.value}`
      }]

      let fr = new CLiCSFormulaRequest(
        {
          requested: this.value,
          rgb: '#f0f0f0',
          mode: 'basic',
          formula_text: formulaText,
          params: paramItems,
          title: `D${this.strength}`
        }
      );

      this.requestDispense.emit(fr);
    }
  }

  // Cancel this dialogue
  doCancel() {
    this.cancel.emit();
  }

}
