import {CLiCSColorFormula} from "./color-formula";
import {CLiCSColorApplication} from "./color-application";
import {CLiCSLibrary} from "./library";
import {CLiCSUser} from "./user";

/*****************
 The repository object collects libraries and their associated items (Color Formulas and Color Applications).
 The library "headers" are stored separately from the library items so that only one copy of an item is stored
 (* see note below).

 * NOTE: Color Applications are stored with their contained Color Formula objects. At this point there is no attempt
 * to combine these with the main Color Formula list, but this may be of interest in the future.
 *****************/

export class CLiCSRepository {
  libraries: CLiCSLibrary[] = [];  // Only stores the header information
  formulas: CLiCSColorFormula[] = [];
  apps: CLiCSColorApplication[] = [];
  private formula_xref: any[] = [];
  private app_xref: any[] = [];

  // Manage user library updates
  user_token: string = null;
  user_timestamp: Date = null;

  libraryTitles: string[] = [];
  selectedFormulas: CLiCSColorFormula[] = [];  // Filtered array with all formulas that are selected

  salonLibraryQueried: boolean = false;  // indicates that we tried to load a salon library (whether or not one was available)
  userLibraryQueried: boolean = false;  // indicates that we tried to load a user library (whether or not one was available)

  castTarget: string = null;  // Page identifier used to set behavior that select certain libraries, omits others

  constructor(data: any = null) {
    if (data) {
      if (typeof data == 'string')
        this.loadStr(data);
      else {
        if (typeof data == 'object')
          this.loadObj(data);
      }
    }
  }

  // Loads this from a data object
  loadObj(data: any) {
    this.clear();
    for (const library of data.libraries) {
      let newLib = new CLiCSLibrary(library);
      if ('scope' in data)
        newLib.setScope(data.scope);
      this.libraries.push(newLib);
    }
    for (const formula of data.formulas)
      this.formulas.push(new CLiCSColorFormula(formula));
    for (const app of data.apps)
      this.apps.push(new CLiCSColorApplication(app));
    for (const fx of data.formula_xref)
      this.formula_xref.push({cl: fx.cl, cf: fx.cf, active: true});
    for (const ax of data.app_xref)
      this.app_xref.push({cl: ax.cl, ca: ax.ca, active: true});
    this._loadLibraryTitles();
  }

  // Loads this object from a data string (JSON)
  loadStr(dataStr: string) {
    let data = JSON.parse(dataStr);
    this.loadObj(data);
  }

  // Similar to loadObj but appends repo-formatted data to this repo, checking for duplicates
  incorporate(data: any) {
    for (const library of data.libraries) {
      const index = this.libraries.findIndex((el) => {
        return el.token == library.token;
      });
      let newLib = new CLiCSLibrary(library);
      newLib.setScope(data.scope);
      if (index < 0)
        this.libraries.push(newLib);
      else
        this.libraries[index] = newLib;
    }
    for (const formula of data.formulas) {
      const index = this.formulas.findIndex((el) => {
        return el.token == formula.token;
      });
      if (index < 0)
        this.formulas.push(new CLiCSColorFormula(formula));
      else
        this.formulas[index] = new CLiCSColorFormula(formula);
    }
    for (const app of data.apps) {
      const index = this.formulas.findIndex((el) => {
        return el.token == app.token;
      });
      if (index < 0)
        this.apps.push(new CLiCSColorApplication(app));
      else
        this.apps[index] = new CLiCSColorApplication(app);
    }
    for (const fx of data.formula_xref) {
      const index = this.formula_xref.findIndex((el) => {
        return (el.cl == fx.cl && el.cf == fx.cf);
      });
      if (index < 0) {
        this.formula_xref.push({cl: fx.cl, cf: fx.cf, active: true});
      }
    }
    for (const ax of data.app_xref) {
      const index = this.app_xref.findIndex((el) => {
        return (el.cl == ax.cl && el.ca == ax.ca);
      });
      if (index < 0) {
        this.app_xref.push({cl: ax.cl, ca: ax.ca, active: true});
      }
    }
    this._loadLibraryTitles();
  }

  _loadLibraryTitles() {
    this.libraryTitles.length = 0;
    let newColorsLib: string = null;
    for (const library of this.libraries) {
      if (!!library.active) {
        if (library.title.toLowerCase() == 'new colors')
          newColorsLib = library.title;
        else
          this.libraryTitles.push(library.title);
      }
    }
    this.libraryTitles.sort();
    if (!!newColorsLib) {
      this.libraryTitles.unshift(newColorsLib);
    }
  }

  // Clears all saved library items, erases filter values, and marks all lists as modified (to require reload)
  clear(): void {
    this.libraries.length = 0;
    this.formulas.length = 0;
    this.apps.length = 0;
    this.formula_xref.length = 0;
    this.app_xref.length = 0;
    this.libraryTitles.length = 0;
    this.salonLibraryQueried = false;
    this.userLibraryQueried = false;
  }

  empty(): boolean {
    return (this.libraries.length == 0 && this.formulas.length == 0 && this.apps.length == 0);
  }

  // Looks for all libraries that match a particular scope then removes the associated items, xref, and libraries.
  // This is often used when logging out a user - remove any user-related scopes e.g. this.clearByScope('salon')
  // Scopes: 'salon', 'user', 'system', 'conversion'
  clearByScope(scope: string) {
    let index: number = this._findLibraryByScope(scope);
    let xrefIndex: number = null;

    // Repeat for all libraries matching the scope
    while (index >= 0) {
      const library = this.libraries[index];

      // iterate through formula_xrefs, removing those only assigned to this library
      xrefIndex = this.formula_xref.findIndex((el) => {
        return(el.cl == library.id);
      });
      while(xrefIndex >= 0) {
        let xref = this.formula_xref[xrefIndex];

        // find other xrefs for this formula
        let otherXrefIndex = this.formula_xref.findIndex((el) => {
          return(el.cf == xref.cf && el.cl != xref.cl);
        });

        // if only present in this xref then remove the formula
        if (otherXrefIndex < 0) {
          this._removeFormulaById(xref.cf);
        }

        // always remove this formula_xref
        this.formula_xref.splice(xrefIndex, 1);

        // find next xref
        xrefIndex = this.formula_xref.findIndex((el) => {
          return(el.cl == library.id);
        });
      }

      // iterate through app_xrefs, removing those only assigned to this library
      xrefIndex = this.app_xref.findIndex((el) => {
        return(el.cl == library.id);
      });
      while(xrefIndex >= 0) {
        let xref = this.app_xref[xrefIndex];

        // find other xrefs for this formula
        let otherXrefIndex = this.app_xref.findIndex((el) => {
          return(el.ca == xref.ca && el.cl != xref.cl);
        });

        // if only present in this xref then remove the formula
        if (otherXrefIndex < 0) {
          this._removeAppById(xref.ca);
        }

        // always remove this formula_xref
        this.app_xref.splice(xrefIndex, 1);

        // find next xref
        xrefIndex = this.app_xref.findIndex((el) => {
          return(el.cl == library.id);
        });
      }

      // Remove the library object itself and find the next matching library
      this.libraries.splice(index, 1);
      index = this._findLibraryByScope(scope);
    }

    this._loadLibraryTitles();
    if (scope == "salon")
      this.salonLibraryQueried = false;
    if (scope == "user")
      this.userLibraryQueried = false;
  }

  // Search for formula records matching the ID number and remove them from this.formulas (there should be 0 or 1)
  _removeFormulaById(id: number) {
    let index = this._findFormulaIndexById(id);
    while (index >= 0) {
      this.formulas.splice(index, 1);
      index = this._findFormulaIndexById(id);
    }
  }

  // Search for formula records matching the ID number and remove them from this.apps (there should be 0 or 1)
  _removeAppById(id: number) {
    let index = this._findAppIndexById(id);
    while (index >= 0) {
      this.apps.splice(index, 1);
      index = this._findAppIndexById(id);
    }
  }

  // Search for formula's index by ID
  _findFormulaIndexById(id: number) {
    return (this.formulas.findIndex((el) => {
      return (el.id == id);
    }));
  }

  // Search for APP's index by ID
  _findAppIndexById(id: number) {
    return (this.apps.findIndex((el) => {
      return (el.id == id);
    }));
  }

  // Search for the first library matching scope string, returns index or -1
  _findLibraryByScope(scope: string) {
    return (this.libraries.findIndex((el) => {
      return (el.scope == scope);
    }));
  }

  _populateLibraryObject(target: CLiCSLibrary, source: CLiCSLibrary) {
    for (const fx of this.formula_xref) {
      if (fx.cl == source.id) {
        const index = this._findFormulaIndexById(fx.cf);
        if (index >= 0) {
          if (this.formulas[index].archived_at == null) {
            this.formulas[index].scope = source.scope;
            const select_index = this.selectedFormulas.findIndex(el => el.token == this.formulas[index].token)
            target.addFormula(this.formulas[index], null, false, false, select_index >= 0);
            if (select_index >= 0) {
              target.selectedFormulas.push(this.formulas[index]);
            }
          }
        }
      }
    }
    for (const ax of this.app_xref) {
      if (ax.cl == source.id) {
        const index = this._findAppIndexById(ax.ca);
        if (index >= 0) {
          if (this.apps[index].archived_at == null) {
            this.apps[index].scope = source.scope;
            target.addApplication(this.apps[index]);
          }
        }
      }
    }
  }

  // Use this instead of library.libraryTitles[] directly. Set product_line to filter by libraries for the indicated
  // product line.
  getLibraryTitles(product_line: string = null): string[] {
    let result: string[] = [];
    if (!!product_line) {
      for (const library of this.libraries) {
        if (!!library.active && library.product_line == product_line) {
          result.push(library.title);
        }
      }
      result.sort();
    } else {
      result = this.libraryTitles;
    }
    return result;
  }

  // Returns a library object populated with formulas and APPs assigned to that library
  getCombinedLibrary(title: string = 'ALL COLORS', productLine: string = null): CLiCSLibrary {
    let result = new CLiCSLibrary();
    result.setScope('all');
    result.title = title.trim();
    result.selectedFormulas = [];
    for (const library of this.libraries) {
      if (!!library.active && (!productLine || library.product_line == productLine)) {
        this._populateLibraryObject(result, library);
      }
    }
    result.applyApplicationSearch();  // populate filteredApps
    result.sortFormulas();
    result.sortApplications();
    return result;
  }

  // Returns a library object populated with formulas and APPs assigned to that library
  // NOTE: filters by castAs selection (active)
  getLibraryByTitle(title: string): CLiCSLibrary {
    let result: CLiCSLibrary = null;
    const library = this.libraries.find((el) => {
      return (el.title == title && !!el.active);
    });

    if (library != undefined) {
      result = new CLiCSLibrary(library);
      // result.setScope(library.scope);
      // result.title = library.title;
      // result.token = library.token;
      this._populateLibraryObject(result, library);
      return result;
    } else
      return null;
  }

  // Returns a library object populated with formulas and APPs assigned to that library
  getLibraryByToken(token: string): CLiCSLibrary {
    let result: CLiCSLibrary = null;
    const library = this.libraries.find((el) => {
      return (el.token == token);
    });

    if (library != undefined) {
      result = new CLiCSLibrary(library);
      this._populateLibraryObject(result, library);
      return result;
    } else
      return null;
  }

  // Returns the first library object with the default flag set. The default library cannot be removed and receives
  // any orphaned formulas or apps.
  getDefaultLibrary(assertIfNotFound = false): CLiCSLibrary {
    let result = new CLiCSLibrary();
    let library = this.libraries.find((el) => {
      return (el.default == true && el.owned == true && el.active);
    });

    if (library != undefined) {
      result.setScope(library.scope);
      result.title = library.title;
      result.token = library.token;
      this._populateLibraryObject(result, library);
      return result;
    } else {
      if (assertIfNotFound) {
        library = this.libraries.find((el) => {
          return (el.owned == true);
        });
        if (library != undefined) {
          result.setScope(library.scope);
          result.title = library.title;
          result.token = library.token;
          this._populateLibraryObject(result, library);
          return result;
        } else
          return null;
      } else
        return null;
    }
  }

  // Returns all libraries to be included in the Create Menu (Simple Mode). Hides the user's default library if it's
  // empty or we're in Simple mode
  getCreateMenuLibraries(appMode: string, productLine: string = null): CLiCSLibrary[] {
    let result = [];
    for (let lib of this.libraries) {
      if (!lib.default || (!lib.empty() && appMode != 'simple')) {
        if (!!productLine) {
          if (lib.product_line == productLine) {
            result.push(lib);
          }
        } else {
          result.push(lib);
        }
      }
    }
    return result;
  }

  // Returns a formula identified by token, or null
  findFormula(token: string): CLiCSColorFormula {
    let result = null;
    const index = this.formulas.findIndex(el => (el.token == token));
    if (index >= 0)
      result = this.formulas[index];
    return result;
  }

  // Returns an APP identified by token, or null
  findApplication(token: string): CLiCSColorApplication {
    let result = null;
    const index = this.apps.findIndex(el => (el.token == token));
    if (index >= 0)
      result = this.apps[index];
    return result;
  }

  // Finds a formula in this library then either toggles or hard-sets the selection. If value is NULL then this
  // toggles the selection. Returns formula or null.
  selectFormula(token: string, newValue: any = null): any {
    let formula = this.findFormula(token);
    if (formula) {
      if (newValue == null)
        formula.selected = !formula.selected;
      else
        formula.selected = (newValue == true);
    }
    this.selectedFormulas = this.formulas.filter(el => (el.selected == true));
    return formula;
  }

  // Finds a formula in this library then either toggles or hard-sets the selection. If value is NULL then this
  // toggles the selection. Returns formula or null.
  clearFormulaSelection(libraryName: string = null) {
    if (libraryName && libraryName != "") {
      const chosenLibrary = this.libraries.find(el => el.title == libraryName);
      if (chosenLibrary) {
        for (let xf of this.formula_xref) {
          const index = this._findFormulaIndexById(xf.cf);
          if (index >= 0)
            this.formulas[index].selected = false;
        }
      } else
        libraryName = null;
    }
    if (libraryName == null) {
      for (let formula of this.formulas) {
        formula.selected = false;
      }
    }
    this.selectedFormulas = this.formulas.filter(el => (el.selected == true));
  }

  // Returns true if user libraries are loaded in this object
  hasUserLibraries(): boolean {
    const index = this.libraries.findIndex(el => (el.scope == "user"));
    return index >= 0 || this.userLibraryQueried;
  }

  // Returns true if user libraries are loaded in this object or if an attempt to get salon libraries was made,
  // possibly with no salon libraries loaded.
  hasSalonLibraries(): boolean {
    const index = this.libraries.findIndex(el => (el.scope == "salon"));
    return index >= 0 || this.salonLibraryQueried;
  }

  // Returns true if system libraries are loaded in this object
  hasSystemLibraries(): boolean {
    const index = this.libraries.findIndex(el => (el.scope == "system"));
    return index >= 0;
  }

  // Returns true if conversion libraries are loaded in this object
  hasConversionLibraries(): boolean {
    const index = this.libraries.findIndex(el => (el.scope == "conversion"));
    return index >= 0;
  }

  // Called externally to mark that we attempted to load salon libraries
  salonQueryApplied() {
    this.salonLibraryQueried = true;
  }

  // Called externally to mark that we attempted to load salon libraries
  userQueryApplied() {
    this.userLibraryQueried = true;
  }

  // Adds a new library to this repo
  addLibrary(newLib: CLiCSLibrary) {
    const index = this.libraries.findIndex(el => (el.token == newLib.token));
    if (index < 0) {
      this.libraries.push(new CLiCSLibrary(newLib));
    }
    this._loadLibraryTitles();
  }

  // Finds a library object, removes it, then removes all xref values associating formulas / apps with that library
  removeLibrary(libraryToken: string, defaultLibraryToken: string = null): any {
    let result: any = {success: false, defaultLibrary: null, apps: [], formulas: [], message: ''};
    const index = this.libraries.findIndex(el => (el.token == libraryToken));
    if (index >= 0) {
      if (this.libraries[index].default == false && this.libraries[index].owned == true) {
        // Library is Ok to delete! Call this deceasedLibrary and also find the default library
        const deceasedLibrary = this.getLibraryByToken(libraryToken);

        // Establish "default" library to move items to. This may be a library returned by the server
        let defaultLibrary: CLiCSLibrary = null;
        if (defaultLibraryToken)
          defaultLibrary = this.libraries.find(el => (el.token == defaultLibraryToken));
        if (defaultLibrary == null)
          defaultLibrary = this.getDefaultLibrary(true);

        // Record all objects included in this library
        let movedApps: CLiCSColorApplication[] = [];
        let movedFormulas: CLiCSColorFormula[] = [];

        for (let xf of this.formula_xref) {
          if (xf.cl == deceasedLibrary.id) {
            let mfIndex = this._findFormulaIndexById(xf.cf);
            if (mfIndex >= 0) {
              movedFormulas.push(new CLiCSColorFormula(this.formulas[mfIndex]));
            }
          }
        }
        for (let xa of this.app_xref) {
          if (xa.cl == deceasedLibrary.id) {
            let maIndex = this._findAppIndexById(xa.ca);
            if (maIndex >= 0) {
              movedApps.push(new CLiCSColorApplication(this.apps[maIndex]));
            }
          }
        }

        // Remove all cross-reference entries for this library
        this.formula_xref = this.formula_xref.filter(el => (el.cl != deceasedLibrary.id));
        this.app_xref = this.app_xref.filter(el => (el.cl != deceasedLibrary.id));

        // Include all objects previously in this library in the default library (unless already there)
        if (defaultLibrary) {
          for (let formula of movedFormulas) {
            this.includeFormulaInLibrary(defaultLibrary, formula);
          }
          for (let app of movedApps) {
            this.includeAppInLibrary(defaultLibrary, app);
          }
        }

        // Remove the library object from the registry and reload library titles
        this.libraries.splice(index, 1);
        this._loadLibraryTitles();

        // Return info for downstream processing
        result.success = true;
        result.defaultLibrary = defaultLibrary;
        result.apps = movedApps;
        result.formulas = movedFormulas;
      } else {
        result.message = 'Cannot remove default collection'
      }
    }
    return result;
  }

  // Finds a library object and renames it
  renameLibrary(libraryToken: string, newName: string) {
    const index = this.libraries.findIndex(el => (el.token == libraryToken));
    if (index >= 0) {
      this.libraries[index].title = newName;
      this._loadLibraryTitles();
    }
  }

  // Adds a new APP to the repo and associates it with a library
  addApplication(newApp: CLiCSColorApplication, libraryToken: string) {
    if (newApp.id == null) {
      newApp.id = (Math.random() * 10000) + 100000;
      console.log("Repository::addApplication - no ID present for APP, random ID created");
    }
    const index = this.apps.findIndex(el => (el.token == newApp.token));
    if (index < 0) {
      this.apps.push(new CLiCSColorApplication(newApp));
      let library = this.libraries.find(el => (el.token == libraryToken));
      if (library) {
        this.app_xref.push({cl: library.id, ca: newApp.id, active: true});
        console.log("New APP added to repo");
      }
    }
  }

  // Adds a new formula to the repo and associates it with a library. If the formula already exists in the repository
  // it is updated.
  addFormula(newFormula: CLiCSColorFormula, libraryToken: string) {
    if (newFormula.id == null) {
      newFormula.id = (Math.random() * 10000) + 100000;
      console.log("Repository::addFormula - no ID present for formula, random ID created");
    }

    const index = this.formulas.findIndex(el => (el.token == newFormula.token));
    let library = this.libraries.find(el => (el.token == libraryToken));
    if (index < 0) {
      // Add a new formula to the repo and associate it with a library
      this.formulas.push(new CLiCSColorFormula(newFormula));
      if (library) {
        this.formula_xref.push({cl: library.id, cf: newFormula.id, active: true});
        console.log("New formula added to repo");
      }
    } else {
      // Update the existing formula, preserving the ID
      const id = this.formulas[index].id;
      this.formulas[index].load(newFormula);
      this.formulas[index].id = id;
      if (library) {
        const index = this.formula_xref.findIndex(el => (el.cl == library.id && el.cf == newFormula.id));
        if (index < 0) {
          this.formula_xref.push({cl: library.id, cf: newFormula.id, active: true});
          console.log("Formula included in library " + library.title);
        }
      }
    }
  }

  // Checks all selected formulas and adds them to the library identified by token
  // Restricts allowable formulas to those owned by the current user
  // Returns the matched formulas for use elsewhere
  includeSelectedFormulasInLibrary(libraryToken: string, selectedFormulas: CLiCSColorFormula[]) {
    let result: any = {libraryToken: null, formulaTokens: []};
    let library = this.libraries.find(el => (el.token == libraryToken));
    if (library && library.owned == true) {
      result.libraryToken = library.token;
      for (let formula of selectedFormulas) {
        result.formulaTokens.push(formula.token);
        const index = this.formula_xref.findIndex(el => (el.cl == library.id && el.cf == formula.id));
        if (index < 0) {
          this.formula_xref.push({cl: library.id, cf: formula.id, active: true});
        }
      }
    }
    return result;
  }


  // Takes a library object or token and assigns a passed color formula to it UNLESS it is already associated with
  // that library. Does NOT add the formula to this.formulas.
  includeFormulaInLibrary(library: CLiCSLibrary = null, formula: CLiCSColorFormula, libraryToken: string = null) {
    if (library == null) {
      library = this.libraries.find(el => (el.token == libraryToken));
    }
    if (library && library.owned == true) {
      const index = this.formula_xref.findIndex(el => (el.cl == library.id && el.cf == formula.id));
      if (index < 0) {
        this.formula_xref.push({cl: library.id, cf: formula.id, active: true});
        return formula.token;
      }
    }
    return null;
  }

  // Takes a library object or token and assigns a passed color formula to it UNLESS it is already associated with
  // that library. Does NOT add the APP to this.apps.
  includeAppInLibrary(library: CLiCSLibrary = null, app: CLiCSColorApplication, libraryToken: string = null) {
    if (library == null) {
      library = this.libraries.find(el => (el.token == libraryToken));
    }
    if (library && library.owned == true) {
      const index = this.app_xref.findIndex(el => (el.cl == library.id && el.ca == app.id));
      if (index < 0) {
        this.app_xref.push({cl: library.id, ca: app.id, active: true});
        return app.token;
      }
    }
    return null;
  }


  // Checks all selected formulas and removes them from the library identified by token IF the library is owned
  // Returns the matched formulas for use elsewhere
  removeSelectedFormulasFromLibrary(libraryToken: string): any {
    let result: any = {libraryToken: null, formulaTokens: [], defaultLibToken: null, orphanedFormulas: []};
    let library = this.libraries.find(el => (el.token == libraryToken));
    if (library && library.owned == true) {
      result.libraryToken = library.token;
      let selected: CLiCSColorFormula[] = this.formulas.filter(el => (el.selected == true && el.owned == true));
      for (let formula of selected) {
        result.formulaTokens.push(formula.token);
        this.formula_xref = this.formula_xref.filter(el => (el.cl != library.id || el.cf != formula.id));
      }
    }

    // Check for any formulas that were removed from all folders and include them in the default library
    let orphans = this.repairSelectedOrphanFormulas();
    result.defaultLibToken = orphans.libraryToken;
    result.orphanedFormulas = orphans.formulaTokens;

    return result;
  }

  // Checks all selected formulas and removes them from the library identified by token IF the library is owned
  // Returns the matched formulas for use elsewhere
  removeFormulaFromLibrary(libraryToken: string, formulaToken: string): any {
    let result: any = {libraryToken: null, formulaTokens: [], defaultLibToken: null, orphanedFormulas: []};
    let library = this.libraries.find(el => (el.token == libraryToken));
    if (library && library.owned == true) {
      result.libraryToken = library.token;
      let formula = this.formulas.find(el => (el.token == formulaToken));
      if (formula) {
        result.formulaTokens.push(formula.token);
        this.formula_xref = this.formula_xref.filter(el => (el.cl != library.id || el.cf != formula.id));

        // Check for any formulas that were removed from all folders and include them in the default library
        let orphans = this.repairOrphanFormula(formula);
        result.defaultLibToken = orphans.libraryToken;
        result.orphanedFormulas = orphans.formulaTokens;
      }
    }

    return result;
  }

  // After removing from folders we may want to check that no selected formulas have been removed from all libraries.
  repairSelectedOrphanFormulas(): any {
    let result: any = {libraryToken: null, formulaTokens: []};
    let defaultLibrary = this.libraries.find(el => (el.owned && el.default));
    if (defaultLibrary) {
      result.libraryToken = defaultLibrary.token;
      let selected: CLiCSColorFormula[] = this.formulas.filter(el => (el.selected == true && el.owned == true));
      for (let formula of selected) {
        const index = this.formula_xref.findIndex(el => (el.cf == formula.id));
        if (index < 0) {
          this.formula_xref.push({cl: defaultLibrary.id, cf: formula.id, active: true});
          result.formulaTokens.push(formula.token);
        }
      }
    }
    return result;
  }

  // Checks whether the passed formula is missing from all folders and, if so, adds it back to the default folder
  repairOrphanFormula(formula: CLiCSColorFormula): any {
    let result: any = {libraryToken: null, formulaTokens: []};
    let defaultLibrary = this.libraries.find(el => (el.owned && el.default));
    if (defaultLibrary) {
      result.libraryToken = defaultLibrary.token;
      const index = this.formula_xref.findIndex(el => (el.cf == formula.id));
      if (index < 0) {
        this.formula_xref.push({cl: defaultLibrary.id, cf: formula.id, active: true});
        result.formulaTokens.push(formula.token);
      }
    }
    return result;
  }

  // Removes a formula from the repo, including the cross-reference entries
  // NOTE: same as prior archiveFormula
  // NOTE: updated to mark the formula as deleted rather than destroying and unlinking it
  // NOTE: we cannot determine the proper state of the deleted field since we don't know if the formula was used.
  removeFormula(formulaToken: string): any {
    let result = null;
    let index = this.formulas.findIndex(el => (el.token == formulaToken && !el.archived_at));
    while (index >= 0) {
      if (this.formulas[index].owned) {
        this.formulas[index].archived_at = new Date(Date.now());
        result = formulaToken;
      }
      index = this.formulas.findIndex(el => (el.token == formulaToken && !el.archived_at));
    }
    return result;
  }

  // Removes a color application from the repo, including the cross-reference entries
  // NOTE: updated to mark the APP as deleted rather than destroying and unlinking it
  removeApp(appToken: string) {
    const index = this.apps.findIndex(el => (el.token == appToken));
    if (index >= 0) {
      const app = this.apps[index];
      app.archived_at = new Date(Date.now());
      return app.token;
    }
    return null;
  }

  // Archives (deletes) all selected formulas. They remain in the repo but with the archive bit set
  archiveSelectedFormulas(): any {
    let result = {formulaTokens: []};
    for (let formula of this.formulas) {
      if (formula.selected && formula.owned) {
        this.removeFormula(formula.token);
        result.formulaTokens.push(formula.token);
      }
    }
    return result;
  }

  // Returns a list of user-owned libraries which may be written to
  // NOTE: filters by castAs selection (active)
  ownedLibraries(user: CLiCSUser = null): CLiCSLibrary[] {
    let result: CLiCSLibrary[] = [];
    result = this.libraries.filter(el => (el.owned == true));
    return result.sort(CLiCSRepository.sortObjectsWithTitles);
  }

  // Returns an array of library OBJECTS that contain the referenced color formula
  librariesIncludingFormula(formulaToken: string): CLiCSLibrary[] {
    let result: CLiCSLibrary[] = [];
    const formula = this.formulas.find(el => (el.token == formulaToken));
    if (formula) {
      for (let fx of this.formula_xref) {
        if (fx.cf == formula.id) {
          let library = this.libraries.find(el => (el.id == fx.cl));
          if (library)
            result.push(new CLiCSLibrary(library));
        }
      }
    }
    return result.sort(CLiCSRepository.sortObjectsWithTitles);
  }

  // Tests whether a formula or application in the user's owned libraries uses the passed name
  nameExistsInOwnedLibraries(nameToCheck: string): boolean {
    let result = false;
    let matchedFormulas = this.formulas.filter((el) => {
      return (el.isOwned() && el.deleted == false && el.archived_at == null && el.title.toLowerCase() == nameToCheck.toLowerCase());
    });
    result = matchedFormulas.length > 0;
    if (result == false) {
      let matchedApps = this.apps.filter((el) => {
        return (el.owned == true && el.deleted == false && el.archived_at == null && el.title.toLowerCase() == nameToCheck.toLowerCase());
      });
      result = matchedApps.length > 0;
    }
    return result;
  }


  // Takes two objects with title strings and sorts them, placing numerical titles first
  static sortObjectsWithTitles(a, b) {
    // Sort numerical names by their value
    let aMatch = a.title.match(/^(\d+\.?\d*)/);
    let bMatch = b.title.match(/^(\d+\.?\d*)/);
    if (aMatch && bMatch) {
      const aNum = parseFloat(aMatch[1]);
      const bNum = parseFloat(bMatch[1]);
      if (aNum < bNum)
        return -1;
      if (aNum > bNum)
        return 1;
    }
    if (a.title < b.title)
      return -1;
    if (a.title > b.title)
      return 1;
    return 0;
  }

  // Returns an array of DISTINCT manufacturer names and IDs {name: , id:} for conversion libraries
  getConversionManufacturers(): any[] {
    let result = [];
    const conversion_libs = this.libraries.filter((el) => {
      return el.conversion == true;
    });
    for (let lib of conversion_libs) {
      if (!!lib.manufacturer && !!lib.manufacturer_id) {
        let index = result.findIndex((el) => { return el.id == lib.conversion_manufacturer_id });
        if (index < 0) {
          result.push({name: lib.conversion_manufacturer, id: lib.conversion_manufacturer_id});
        }
      }
    }
    // Return sorted list by name
    return result.sort((el1: any, el2: any): number => {
      if (el1.name > el2.name)
        return 1;
      else {
        if (el1.name < el2.name)
          return -1;
        else
          return 0;
      }
    });
  }

  // Get library name and token that are conversions of the manufacturer indicated by ID
  getConversionLibraryTitles(conversionManufacturerId: number): any[] {
    let result = [];
    const conversion_libs = this.libraries.filter((el) => {
      return (el.conversion == true && el.conversion_manufacturer_id == conversionManufacturerId);
    });

    for (let lib of conversion_libs) {
      result.push({title: lib.title, token: lib.token});
    }

    return result.sort((el1: any, el2: any): number => {
      if (el1.title > el2.title)
        return 1;
      else {
        if (el1.title < el2. title)
          return -1;
        else
          return 0;
      }
    });
  }

  // CLICS Collections are library flagged with a cc_ord value. They are normal libraries otherwise.
  getClicsCollectionLibraryTitles(): any[] {
    let result = [];

    let cc_libs = this.libraries.filter((el) => {
      return (!!el.cc_ord);
    });

    // Sort by cc ordinal, library title
    cc_libs.sort((el1, el2) => {
      if (el1.cc_ord > el2.cc_ord)
        return 1;
      else {
        if (el1.cc_ord < el2.cc_ord)
          return -1;
        else {
          if (el1.title > el2.title)
            return 1;
          else {
            if (el1.title < el2.title)
              return -1;
            else
              return 0;
          }
        }
      }
    });

    for (let lib of cc_libs) {
      result.push({title: lib.title, token: lib.token})
    }
    return result;
  }

  // Modifies this repo in place, REMOVING all libraries that are not part of the target page indicated
  // (CLICS, My Colors, Conversions). This is a permanent change and may trigger the app to reload the repo, sensing
  // that certain libraries are missing. See castAs() for an option that changes behavior without modifying the
  // contents of the repo.
  rebaseAs(target: string = 'colors') {
    switch (target.toLowerCase()) {
      case 'clics':
      case 'system':
        this.clearByScope('user');
        this.clearByScope('salon');
        this.clearByScope('conversion');
        break;
      case 'colors':
      case 'my_colors':
      case 'user':
        this.clearByScope('system');
        this.clearByScope('conversion');
        break;
      case 'conversions':
      case 'conv':
        this.clearByScope('user');
        this.clearByScope('salon');
        this.clearByScope('system');
        break;
      default:
        break;
    }
  }

  // MARKS AS ACTIVE all formulas, applications, and libraries applications that apply to the target page (clics,
  // my colors, conversions). Certain functions here filter returned content by these active flags. Use decast() to
  // temporarily set all objects as active. Use recast() to restore prior cast state. Use castAs(null) to remove
  // cast, setting all as active.
  castAs(target: string = null) {
    this.castTarget = target;
    this.decast()

    if (!!this.castTarget) {
      switch (this.castTarget.toLowerCase()) {
        case 'clics':
        case 'system':
          this._castByScope('user');
          this._castByScope('salon');
          this._castByScope('conversion');
          break;
        case 'colors':
        case 'my_colors':
        case 'my colors':
        case 'user':
          this._castByScope('system');
          this._castByScope('conversion');
          break;
        case 'conversions':
        case 'convert':
        case 'conv':
          this._castByScope('user');
          this._castByScope('salon');
          this._castByScope('system');
          break;
        default:
          break;
      }
    }

    // Reporting... this may be removed
    const libLength = this.libraries.filter(el => !!el.active).length;
    const formulaLength = this.formulas.filter(el => !!el.active && !el.archived_at).length;
    console.log(`castAs(${target}) resulted in ${libLength} libraries and ${formulaLength} formulas`);

    this._loadLibraryTitles();
    return this.castTarget;
  }

  // the business end of castAs(), REMOVES active flag on objects matching the passed scope (castAs sets all active
  // then "deactivates" scopes that don't apply.
  _castByScope(scope: string) {
    let xrefIndex: number = null;
    let cf: CLiCSColorFormula = null;
    let ca: CLiCSColorApplication = null;

    for (let lib of this.libraries) {
      if (lib.scope != scope) {
        continue;
      } else {
        lib.active = false;
      }

      // iterate through formula_xrefs, removing those only assigned to this library
      xrefIndex = this.formula_xref.findIndex(el => el.cl == lib.id && el.active === true);
      while(xrefIndex >= 0) {
        let xref = this.formula_xref[xrefIndex];

        // find other xrefs for this formula
        let otherXrefIndex = this.formula_xref.findIndex(el => el.cf == xref.cf && el.cl != xref.cl && el.active === true);

        // if only present in this xref then remove the formula
        if (otherXrefIndex < 0) {
          cf = this.formulas.find((el) => el.id == xref.cf);
          if (!!cf) {
            cf.active = false;
          }
        }

        // always remove this formula_xref
        xref.active = false;

        // find next xref
        xrefIndex = this.formula_xref.findIndex(el => el.cl == lib.id && el.active === true);
      }

      // iterate through app_xrefs, removing those only assigned to this library
      xrefIndex = this.app_xref.findIndex((el) => {
        return(el.cl == lib.id && el.active == true);
      });
      while(xrefIndex >= 0) {
        let xref = this.app_xref[xrefIndex];

        // find other xrefs for this formula
        let otherXrefIndex = this.app_xref.findIndex((el) => {
          return(el.ca == xref.ca && el.cl != xref.cl);
        });

        // if only present in this xref then remove the formula
        if (otherXrefIndex < 0) {
          ca = this.apps.find((el) => el.id == xref.ca);
          if (!!ca) {
            ca.active = false;
          }
        }

        // always remove this formula_xref
        xref.active = false;

        // find next xref
        xrefIndex = this.app_xref.findIndex((el) => {
          return(el.cl == lib.id && el.active == true);
        });
      }
    }
  }

  // Remove castAs selections. Make all objects visible. Does NOT clear this.castTarget.
  decast() {
    for (let lib of this.libraries) {
      lib.active = true;
    }
    for (let formula of this.formulas) {
      formula.active = true;
    }
    for (let app of this.apps) {
      app.active = true;
    }
    for (let xr of this.formula_xref) {
      xr.active = true;
    }
    for (let xr of this.app_xref) {
      xr.active = true;
    }
  }

  // Restore cast filters set in castAs
  recast() {
    this.castAs(this.castTarget);
  }

  // Returns an array of scope names that a particular formula is found in
  // NOTE: UNTESTED
  scopesFromFormula(formulaToken: string): string[] {
    let result: string[] = [];
    const formula = this.formulas.find(el => el.token == formulaToken);
    if (!!formula) {
      const xrs = this.formula_xref.filter(el => el.id == formula.id);
      for (let xr of xrs) {
        let library = this.libraries.find(el => el.id == xr.cl);
        if (!!library && !result.includes(library.scope)) {
          result.push(library.scope);
        }
      }
    }
    return result;
  }

  scopeFromLibrary(libraryToken: string): string {
    let library = this.libraries.find(el => el.token == libraryToken);
    if (!!library) {
      return library.scope;
    } else {
      return null;
    }
  }

  // Returns true is there are any selected formulas
  hasSelection(): boolean {
    return(this.selectedFormulas.length > 0);
  }

  // Returns true is there are any selected formulas
  multipleSelection(): boolean {
    return(this.selectedFormulas.length > 1);
  }

}
