import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";
import {Keyboard} from "@awesome-cordova-plugins/keyboard/ngx";
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';

/**
 * Generated class for the FormulaIngredientComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    selector: 'formula-ingredient',
    templateUrl: 'formula-ingredient.html',
    styleUrls: ['formula-ingredient.scss'],
})
export class FormulaIngredientComponent implements OnInit {
    ingredientName: string = '???';
    formulaIdent: string = null;
    ingredientQuantityStr: string = '0'; // received input changes
    ingredientQuantity: number = 0; // converted from input string
    quantityStr: string = '0';      // Formatted string passed to other code
    priorQuantity: number = 0.0;    // Used to track changes

    repeatTimer: any = null;
    waitInterval: number = 500;
    repeatInterval: number = 85;

    showDeveloperRatio: boolean = false;

    permissions: any = null;
    settings: any = null;

    // TODO: include a defaults provider to lookup the default amounts for components?
    @Input() name: string;
    @Input() qty: string;
    @Input() max: number = 100;
    @Input() unit: string = "g";
    @Input() increment_amount: number = 1.0;
    @Input() developerRatio: string = "1";
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();

    constructor(
        private keyboard: Keyboard,    
        private modeCtrl: ModeControllerProvider,
        ) {
        // Load permissions from modeCtrl
        this.permissions = this.modeCtrl.getPermissions();

        this.modeCtrl.init().then(result => {
            this.permissions = this.modeCtrl.getPermissions();
        });

        if(!!this.permissions.show_milliliters){
            this.unit = "mL"
        }
    }

    ngOnInit() {
        if (this.name != undefined)
            this.ingredientName = this.name;
        this.showDeveloperRatio = MixingBowlProvider.isDeveloperComponent(this.ingredientName);
        if (this.qty != undefined) {
            this.ingredientQuantity = parseFloat(parseFloat(this.qty).toFixed(2));
            this.ingredientQuantityStr = this.ingredientQuantity.toString();
        }
        this.formatString();
    }

    // Provides an interface for direct setting of the quantity property by outside code
    setQuantity(newQty: any) {
        if (typeof(newQty) == "number")
            this.ingredientQuantity = newQty;
        else {
            if (typeof(newQty) == "string")
                this.ingredientQuantity = parseFloat(newQty);
        }
        this.formatString();
        this.updateIfChanged(null);
    }

    // Converts quantityString to float then updates it
    formatString() {
        if (isNaN(this.ingredientQuantity)) {
            this.ingredientQuantity = 0;
        }
        if (this.ingredientQuantity % 1 == 0) {
            this.quantityStr = this.ingredientQuantity.toFixed(0);
            this.ingredientQuantityStr = this.quantityStr;
        }
        else {
            this.quantityStr = this.ingredientQuantity.toFixed(1);
            this.ingredientQuantityStr = this.quantityStr;
        }
    }

    startIncrement() {
        if (this.repeatTimer == null) {
            this.repeatTimer = setTimeout(()=> {this.doIncrement();}, this.waitInterval);
        }
        else {
            this.endIncrement();
        }
    }

    doIncrement() {
        if (this.repeatTimer) {
            this.increment();
        }
        this.repeatTimer = setTimeout(() => {this.doIncrement();}, this.repeatInterval);
    }

    endIncrement() {
        if (this.repeatTimer) {
            clearTimeout(this.repeatTimer);
            this.repeatTimer = null;
        }
    }

    startDecrement() {
        if (this.repeatTimer == null) {
            this.repeatTimer = setTimeout(()=> {this.doDecrement();}, this.waitInterval);
        }
        else {
            this.endDecrement();
        }
    }

    doDecrement() {
        if (this.repeatTimer) {
            this.decrement();
        }
        this.repeatTimer = setTimeout(() => {this.doDecrement();}, this.repeatInterval);
    }

    endDecrement() {
        if (this.repeatTimer) {
            clearTimeout(this.repeatTimer);
            this.repeatTimer = null;
        }
    }

    updateQuantity(data) {
      this.ingredientQuantity = data.amount;
      this.ingredientQuantityStr = this.ingredientQuantity.toString();
      this.formatString();
      this.valueChange.emit(this.paramData());
    }

    increment() {
        if (isNaN(this.ingredientQuantity))
            this.ingredientQuantity = 0;
        if (this.increment_amount % 1 == 0)
            this.ingredientQuantity -= this.ingredientQuantity % 1;
        this.ingredientQuantity += this.increment_amount;
        if (this.ingredientQuantity > 300) {
            this.ingredientQuantity = 300;
            this.endIncrement();
        }
        this.ingredientQuantityStr = this.ingredientQuantity.toString();
        this.formatString();
        this.valueChange.emit(this.paramData());
    }

    decrement() {
        if (isNaN(this.ingredientQuantity))
            this.ingredientQuantity = 0;
        if (this.increment_amount % 1 == 0)
            this.ingredientQuantity -= this.ingredientQuantity % 1;
        this.ingredientQuantity -= this.increment_amount;
        if (this.ingredientQuantity < 1.0) {
            this.ingredientQuantity = 1.0;
            this.endDecrement();
        }
        this.ingredientQuantityStr = this.ingredientQuantity.toString();
        this.formatString();
        this.valueChange.emit(this.paramData());
    }

    // prevents unwanted keys from being recorded, handle Enter key
    filterKeys(event) {
        if (event.key == 'Enter') {
            this.updateAmount();
            this.keyboard.hide();
            return false;
        }
        const dotAllowed = this.ingredientQuantityStr.match(/.*\..*/g) == null; // only one period per number
        const good_match = event.key.match(/^[\d]$/) !== null;
        return ((good_match && this.ingredientQuantityStr.length < 5) || event.key == 'Backspace' || event.key == 'ArrowRight' || event.key == 'ArrowLeft' || (event.key == '.' && dotAllowed));
    }

    // Checks for change in value and signals a change (used on keyup event)
    updateIfChanged(event) {
        if (this.ingredientQuantityStr != '0.' &&
            this.ingredientQuantityStr != '.0' &&
            this.ingredientQuantityStr.match(/\A\..*/g) == null &&
            this.ingredientQuantityStr.match(/.*\.\z/g) == null)
        {
            this.ingredientQuantity = parseFloat(this.ingredientQuantityStr);
            if (this.ingredientQuantity != this.priorQuantity) {
                this.priorQuantity = this.ingredientQuantity;
                this.updateAmount();
            }
        }
    }

    // Update the amount and publish a change event
    updateAmount() {
        this.formatString();
        this.valueChange.emit(this.paramData());
    }

    // Request removal by emitting a delete event
    deleteMe() {
        this.delete.emit(this.paramData());
    }

    // Returns an object with information about this ingredient
    paramData(): any {
        return {type: 'compOrig', value: this.ingredientName, mod: this.ingredientQuantity.toFixed(1)}
    }
}
