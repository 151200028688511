import {Component, ViewChild} from '@angular/core';
import {NavParams, ModalController, ActionSheetController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CreateColorPage} from '../create-color/create-color';
import {DispenseDeveloperComponent} from '../../components/dispense-developer/dispense-developer';
import {FormulaMixComponent} from '../../components/formula-mix/formula-mix';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSFormulaRequest} from '../../../lib/request';
import {SettingsProvider} from "../../services/settings/settings";

/**
 * Presents a modal page that permits choosing head location and amount of product
 */

@Component({
  selector: 'page-formula-choices',
  templateUrl: 'formula-choices.html',
  styleUrls: ['formula-choices.scss'],
})
export class FormulaChoicesPage {
  @ViewChild(DispenseDeveloperComponent) devDispense: DispenseDeveloperComponent;
  @ViewChild(FormulaMixComponent) formulaMixer: FormulaMixComponent;
  @ViewChild('locationSelect') locationSelect;

  loaded: boolean = false;

  formula: CLiCSFormulaRequest = null;
  reference_formula: CLiCSFormulaRequest = null; // To determine which settings have been changed
  locations: any[] = null;

  location: string = '';
  locationButtonText: string = 'Location';

  amount: number = this.mixingBowl.default_formula_amt;
  unit: string = "g";
  coverage: string = 'perm';  // sensible default
  cobonded: boolean = false;
  components: string[] = [];
  inputValueStr = "";
  showCoverage = true;
  modified: boolean = false; // A change occurred that changes the formula (customize, coverage, CoB, etc)
  dirty: boolean = false;  // A change that doesn't change the formula (total amount, location)
  minFormulaWeight: number = null;
  fast: boolean = false; // display "DISPENSE" instead of "USE"

  includeDev: boolean = true;
  permissions: any = null; // Permissions, based on the current dispenseMode
  settings: any = null; // Dynamic settings chosen by user

  modalDismissSubscription: Subscription = null;

  constructor(private navParams: NavParams,
              private events: EventsService,
              public mixingBowl: MixingBowlProvider,
              private editQueue: EditQueueProvider,
              private modalCtrl: ModalController,
              private clicsService: CLiCSService,
              private settingsCtrl: SettingsProvider,
              public modeCtrl: ModeControllerProvider,
              private actionSheetCtrl: ActionSheetController
  ) {
    this.permissions = this.modeCtrl.getPermissions();
    this.settings = this.settingsCtrl.settings;

    this.modeCtrl.init().then(result => {
        this.permissions = this.modeCtrl.getPermissions();
        this.settings = this.settingsCtrl.settings;
    });

    if(!!this.permissions.show_milliliters){
        this.unit = "mL";
    }
  }

  ionViewWillEnter() {
    this.loaded = false;
    if (!!this.navParams.data.formula) {
      this.formula = new CLiCSFormulaRequest(this.navParams.data.formula);
      if (this.formula.amount <= 0.0) {
        this.formula.setAmount(this.mixingBowl.default_formula_amt);
      }
    }
    this.permissions = this.modeCtrl.getPermissions();
    this.settings = this.settingsCtrl.settings;
    this.reference_formula = new CLiCSFormulaRequest(this.formula);
    this.locations = this.mixingBowl.getLocations();
    this.modified = !!this.navParams.data.modified;
    this.fast = !!this.navParams.data.fast;
    this.loadFromFormula();  // loads data
    this.includeDev = this.formula.hasDeveloper();

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidEnter() {
    this.loaded = true;
    // this.devDispense.setAmount(this.amount);
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  closeModal() {
    this.modalCtrl.dismiss({modified: false, formula: this.formula, dirty: false});
  }

  // Returns true if the formula MIXTURE has been changed. Amount and location are not counted.
  additiveModified() {
    return (
      this.reference_formula.coverage != this.formula.coverage ||
      this.reference_formula.cobonded != this.formula.cobonded
    );
  }

  // Modified: changes that disassociate a MORE formula from its parent, dirty: other changes
  saveChanges() {
    let modified = this.formulaMixer.dirty || this.modified || this.additiveModified();
    if (modified) {
      this.formula.dispense_more_parent = null;
      this.formula.loadObj(this.formulaMixer.workingFormula);
      if (this.permissions.show_cobonder_choice === false) {
        this.formula.setCobonded(false);  // Force no cobonder
      }
    }
    // this.formula.includeDevInTitle();
    const a = this.formula.setLocation(this.location);
    const c = this.formula.setCoverage(this.coverage);
    const d = this.formula.setCobonded(this.cobonded);

    this.modalCtrl.dismiss({
      modified: this.modified || this.referenceModified(),
      formula: this.formula,
      dirty: this.referenceDirty()
    });
  }

  // Was a visible change to the formula made?
  referenceModified(): boolean {
    return (
      this.additiveModified() ||
      this.reference_formula.coverage != this.formula.coverage ||
      this.reference_formula.developerStrength() != this.formula.developerStrength() ||
      this.reference_formula.devRatio(2) != this.formula.devRatio(2)
    )
  }

  referenceDirty(): boolean {
    return (
      this.reference_formula.amount != this.formula.amount ||
      this.reference_formula.location != this.formula.location ||
      this.reference_formula.title != this.formula.title
    )
  }

  cancelChanges() {
    this.modalCtrl.dismiss({modified: false, formula: this.formula, dirty: false});
    this.loadFromFormula();
  }

  loadFromFormula() {
    if (this.formula) {
      this.location = this.formula.location;
      this.setButtonTextFromLocation();
      this.amount = this.formula.amount;
      this.coverage = this.formula.getCoverage();
      this.cobonded = this.formula.cobonded;
      this.components = this.formula.formulaStringArray();
      if (!!this.formulaMixer)
        this.formulaMixer.reloadFormula(); // @@@ SD'A
    }
  }

  onAmountChange(data: any) {
    this.amount = data.amount;
    this.inputValueStr = `: ${this.amount}${this.unit}`;
  }

  addFromColorLibrary() {
    this.modalCtrl.dismiss({modified: false, formula: this.formula, dirty: false});
    this.events.publish('navrequest', {top: 'lab', page: 'lab_color'});
  }

  createNewColor() {
    this.modalCtrl.dismiss({modified: false, formula: this.formula, dirty: false});
    this.events.publish('navrequest', {top: 'lab', page: 'create_color'});
  }

  makeColorLockStep() {
    // CRITICAL: create a formula with Color Lock only and return to the caller
    alert('add color lock...');
  }

  // Open a model to edit this formula. Returned formula is updated locally, not downstream until Save is pressed
  async doCustomize() {
    let modified = this.formulaMixer.dirty || this.modified;
    if (modified) {
      this.formulaMixer.workingFormula.adjustParamsFromAmount();
      this.formula.loadObj(this.formulaMixer.workingFormula);
    }

    this.clicsService.stashData({cf: this.formula, action: 'EDIT'});

    const customizeColorModal = await this.modalCtrl.create({
      component: CreateColorPage,
      componentProps: {context: 'client'},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    customizeColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      if (data.modified) {
        this.editQueue.logReport();
        // let modifiedItem: EditQueueItem = this.editQueue.getUseFormulaItem();
        let modifiedItem = this.clicsService.getStash();
        if (!!modifiedItem.cf) {
          this.clicsService.clearStash();
          this.formula.loadFormula(modifiedItem.cf, true);
          this.formula.pedigree = 'M';  // "hard" modified
          const newAmount = this.formula.totalAmount();
          // Clamp amount to 300g
          if (newAmount > 300.0) {
            this.formula.setAmount(300.0);
            this.formula.adjustParamsFromAmount();
          } else {
            this.formula.setAmount(newAmount);
          }
          this.loadFromFormula();
          this.minWeight(true); // force recalc
        }
        this.modified = true;
      }
    });

    await customizeColorModal.present();
  }

  // Get the min result
  minWeight(force: boolean = false): number {
    if (!this.minFormulaWeight || force) {
      this.minFormulaWeight = this.mixingBowl.minAllowableWeight(this.formula);
      if (this.minFormulaWeight < 10)
        this.minFormulaWeight = 5;
    }
    return this.minFormulaWeight;
  }

  // Are we at the minimum weight for this formula?
  atMinWeight(): boolean {
    return (this.minFormulaWeight == this.amount && this.minFormulaWeight > 5)
  }

  updateCobonded(event) {
    this.cobonded = event.detail.checked;
    if (this.loaded)
      this.formula.setCobonded(this.cobonded);
  }

  updateCoverage(event) {
    this.coverage = event.detail.value;
    if (this.loaded)
      this.formula.setCoverage(this.coverage);
  }

  async chooseLocation() {
    let buttons: any[] = [];

    for (let location of this.locations) {
      buttons.push({
        text: location.label,
        handler: () => {
          this.setLocation(location.value, location.label);
        }
      });
    }

    buttons.push({
      text: 'Cancel',
      role: 'cancel'
    })

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Sets a location with the value of the location entry
  setLocation(locationValue: string, locationLabel: string = null) {
    if (this.location != locationValue)
      this.dirty = true;
    this.location = locationValue;
    if (!!locationLabel) {
      if (locationLabel.toLowerCase() == '(none)') {
        this.locationButtonText = 'Location';
      } else {
        this.locationButtonText = locationLabel;
      }
    } else {
      this.setButtonTextFromLocation();
    }
  }

  setButtonTextFromLocation() {
    const curLoc = this.locations.find((el) => {
      return (el.value == this.location);
    });
    if (!!curLoc) {
      if (curLoc.value == '') {
        this.locationButtonText = 'Location';
      } else {
        this.locationButtonText = curLoc.label;
      }
    }
  }

  settingOk(settingName: string) {
    if (settingName in this.settings) {
      return this.settings[settingName];
    } else {
      return false;
    }
  }
}
