import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

/*
  Replicates behavior that is similar to Events from @ionic-angular with the
  caveat that
*/
@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private channels: { [key: string]: Subject<any>; } = {};

  constructor() {}

  // Subscribe to a specified event topic and provide observer(s)
  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }

  // Unsubscribe from a specified subscription if non-null
  unsubscribe(subscription: Subscription): Subscription {
    if (!!subscription) {
      subscription.unsubscribe();
      return null;
    } else {
      return subscription;
    }
  }

  // Unsubscribe all observers from a specified topic, return true if successful
  // false otherwise
  destroy(topic: string): boolean {
    const t = this.channels[topic];
    if (!!t) {
      t.complete();
      delete this.channels[topic];
      return true;
    } else {
      return false;
    }
  }

  // Publish data to subscribers of the specified topic
  publish(topic: string, data?: any): void {
    const subject = this.channels[topic];
    if (!!subject) {
      subject.next(data);
    }
  }

  // Get and return the Subject for the specified topic
  channel(topic: string): Subject<any> {
    return this.channels[topic];
  }

  // Get and return the topics for all channels
  channelTopics(): string[] {
    return Object.keys(this.channels);
  }
}
