import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CLiCSColorFormula} from "../../../lib/color-formula";
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {SettingsProvider} from '../../services/settings/settings';

/**
 * Similar to app-formula component this allows for a blank
 */
@Component({
    selector: 'app-create-formula',
    templateUrl: 'app-create-formula.html',
    styleUrls: ['app-create-formula.scss'],
})
export class AppCreateFormulaComponent {
    @Input() formula: CLiCSColorFormula;
    @Input() hideAmount: boolean = false;
    @Input() unit: string = "g";
    @Output() openFormulaChoices = new EventEmitter();
    @Output() openColorDetail = new EventEmitter();

    permissions: any = null;
    settings: any = null;

    constructor( 
      private modeCtrl: ModeControllerProvider,
      public settingsCtrl: SettingsProvider
      ) {
      this.modeCtrl.init().then(result => {
          this.permissions = this.modeCtrl.getPermissions();
      });

      if(!!this.permissions.show_milliliters){
          this.unit = "mL";
      }
    }

    // Instruct parent to open choice
    doFormulaChoices(token: any, request_ident: any): void {
        this.openFormulaChoices.emit({formula_token: token, request_ident: request_ident});
    }

    // Instruct parent to open details page
    doColorDetail(token: any, request_ident: any): void {
        this.openColorDetail.emit({formula_token: token, request_ident: request_ident});
    }
}
