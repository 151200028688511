import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {ComponentsModule} from '../../components/components.module';
import {CreateMenuPage} from './create-menu';
import {CreateMenuPageRoutingModule} from './create-menu-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    CreateMenuPageRoutingModule,
  ],
  declarations: [CreateMenuPage],
  providers: [
    NavParams,
    TapticEngine,
  ],
})
export class CreateMenuPageModule {}
