
export class FlashMessage {
    private message : string = '';
    private message_viewed_at : number = 0;
    error: boolean = true;
    language: string = 'en';

    constructor(language: string = 'en') {
        this.language = language;
    }

    setMessage(message_key : string, additional_message: string = '', is_error: boolean = false) {
        this.error = is_error;
        this.message = FlashMessage.translateMessage(message_key) + additional_message;
        this.message_viewed_at = 0;
        return this.message;
    }

    setError(message_key : string, additional_message: string = '') {
        this.error = true;
        this.message = FlashMessage.translateMessage(message_key) + additional_message;
        this.message_viewed_at = 0;
        return this.message;
    }

    clear() {
        this.error = false;
        this.message_viewed_at = 0;
        this.message = '';
    }

    // Return the current message - keep it active for 2.5 secs
    getMessage(): string {
        if (this.hasMessage()) {
            if (this.message_viewed_at == 0) {
                this.message_viewed_at = Date.now();
            }
        }
        return this.message;
    }

    // Expires a message if it has been read and enough time has passed
    expire(): void {
        if (this.message_viewed_at > 0) {
            if (Date.now() > this.message_viewed_at + 2500) {
                this.clear();
            }
        }
    }

    hasMessage(): boolean {
        // Clear out the message if expired
        this.expire();
        return this.message != '';
    }

    // Returns error class name if this is an error message
    displayClass(): string {
        if (this.error)
            return 'error';
        else
            return '';
    }

    // Lookup a message key translation or pass the key directly
    static translateMessage(message_key: string): string {
        // TODO: look up I18N messages
        if (message_key.match(/\Amk_.+\b/i) !== null)
             return message_key;
        else {
            // Translation lookup happens here
            let message = message_key.replace(/mk_/, '');
            return message.replace(/_/g, ' ');
        }
    }
}
