

export class CLiCSSalon {
    name: string;
    address_1: string = '';
    address_2: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    country: string = '';
    long: number = null;
    lat: number = null;
    api_token: string = '';
    user_role: number = null;

    constructor(dataObj: object = null) {
        if (dataObj != null) {
            this.loadObj(dataObj);
        }
    }

    loadObj(data: any) {
        if (!!data) {
            this.name = data.name;
            this.address_1 = data.address_1;
            this.address_2 = data.address_2;
            this.city = data.city;
            this.state = data.state;
            this.zip = data.zip;
            this.country = data.country;
            this.long = parseFloat(data.long);
            this.lat = parseFloat(data.lat);
            this.api_token = data.api_token;
            if ('user_role' in data) {
              this.user_role = data.user_role;  // Role for logged in user, not signed on guest
            }
        }
    }

    loadStr(dataStr: string) {
        let data = JSON.parse(dataStr);
        this.loadObj(data);
    }
}
