/*
* Used to record and manage loaded manifest versions against new versions.
*/

export class CLiCSManifest {
    categories: any[] = [];  // Object as: { category: 'products', major: 0, minor: 1 }
    manifestList: string[] = ['products', 'library', 'salons', 'settings', 'content', 'app_types', 'conversions'];
    dirty: boolean = false;

    constructor(data: any = null) {
        if (data) {
            for (let cat of data.categories) {
                this.categories.push(cat);
            }
        }
    }

    // Updates local categories array to make sure all active categories are recorded
    _update(): void {
        let found: boolean;
        for (let catName of this.manifestList) {
            found = false;
            for (let cat of this.categories) {
                if (cat.category == catName)
                    found = true;
            }
            if (found == false) {
                this.categories.push({category: catName, major: 0, minor: 0});
                this.dirty = true;
            }
        }
    }

    // Check whether a category has been updated since last read
    isCategoryUpdated(category: string, major: number, minor: number): boolean {
        this._update();  // make sure this category list is complete
        let updated: boolean = false;
        for (let cat of this.categories) {
            if (cat.category == category) {
                updated = (major > cat.major || (major == cat.major && minor > cat.minor));
                break;
            }
        }
        return updated;
    }

    // After a content update succeeds, call this to update the local data. This object should then be updated in local
    // storage. Update 8/2019: now updates the version regardless of whether or not the new version is higher than the
    // prior recorded version. This permits downgrading when switching between servers.
    updateCategory(category: string, major: number, minor: number): boolean {
        let updated: boolean = false;
        for (let cat of this.categories) {
            if (cat.category == category) {
              updated = true;
              cat.major = major;
              cat.minor = minor;
              this.dirty = true;
              break;
            }
        }
        return updated;
    }

    isModified(): boolean {
        let result = this.dirty;
        this.dirty = false;
        return result;
    }

    // Returns a version string for the indicated category
    version(category: string) {
        let index = this.categories.findIndex((el) => {
            return(el.category == category);
        });
        if (index >= 0)
            return `${this.categories[index].major}.${this.categories[index].minor}`
        else
            return "";
    }
}
