import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LabAppPage} from './lab-app';

const routes: Routes = [
  { path: '', component: LabAppPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LabAppPageRoutingModule {}
