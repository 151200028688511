import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryFullConfirmPage} from './inventory-full-confirm';
import {InventoryFullConfirmPageRoutingModule} from './inventory-full-confirm-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    InventoryFullConfirmPageRoutingModule,
  ],
  declarations: [InventoryFullConfirmPage],
  providers: [
    NavParams,
    BarcodeScanner,
  ],
})
export class InventoryFullConfirmPageModule {}
