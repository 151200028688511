/*
    Defines a product or, more strictly, a component.
 */

export class CLiCSProduct {
    token: string = null;
    name: string = null;  // standard name pH
    description: string = null;  // full name pH Balancer
    manufacturer: string = null;
    concentration: number = null;
    type: string = null;
    color: string = null;
    common: string = null;
    subtype: string = null;
    quick: boolean = false;  // Is this product available for Quick Dispense?
    // canister: number = null;  // Canister type ID number
    // punch: boolean = false;  // Does this require a hole punch (gravity feed)?

    constructor(data: any = null) {
        if (data) {
           if (typeof data == 'string')
               this.loadStr(data);
           else {
               if (typeof data == 'object')
                   this.loadObj(data);
           }
        }
    }

    // Loads this from a data object
    loadObj(data: any) {
        this.token = data.token;
        this.name = data.name;
        this.description = data.description;
        this.manufacturer = data.manufacturer;
        this.concentration = data.concentration;
        this.type = data.type;
        this.color = data.color;
        this.common = data.common;
        this.subtype = data.subtype;
        this.quick = data.quick;
    }

    // Loads this object from a data string (JSON)
    loadStr(dataStr: string) {
        let data = JSON.parse(dataStr);
        this.loadObj(data);
    }

 }
