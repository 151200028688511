import {Component, Inject, ViewChild} from '@angular/core';
import {AlertController, ModalController, NavParams, LoadingController, ToastController, ActionSheetController} from '@ionic/angular';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {Subscription} from 'rxjs';
import {CreateColorPage} from '../create-color/create-color';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {SettingsProvider} from '../../services/settings/settings';
import {CLiCSFormula} from '../../../lib/formula';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSFormulaRequest} from '../../../lib/request';
import {CLiCSLibrary} from '../../../lib/library';
import {CLiCSColorFormula} from '../../../lib/color-formula';
import {CLiCSColorApplication} from '../../../lib/color-application';
import {CLiCSColorSession} from '../../../lib/session';
import {FormulaChoicesPage} from "../formula-choices/formula-choices";
import {CLiCSClient} from "../../../lib/client";
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import * as Rollbar from 'rollbar';
import {RollbarService} from '../../../lib/rollbar';
import {CLiCSUser} from "../../../lib/user";

/**
 * Generated class for the ColorDetailPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-color-detail',
  templateUrl: 'color-detail.html',
  styleUrls: ['color-detail.scss'],
})
export class ColorDetailPage {
  @ViewChild('titleInput') titleFormInput;

  formula: CLiCSFormula = null;
  adjustedFormula: CLiCSFormulaRequest = null; // for displaying the formula in the amount requested
  components: string[] = [];
  rgb: string = '#ffffff';
  requestedView: string = null;
  title: string = 'Color Details';
  source: string = 'client';  // Who called this page? May be 'client' or 'library' or 'history' - determines behaviours
  scope: string = null;       // What is the scope of the formula? 'app' (color session) or
  promptForSwatch: boolean = false;
  formulaOwnedByMe: boolean = false;
  editItem: EditQueueItem = null;
  memberLibraries: CLiCSLibrary[] = [];
  defaultWeight: number = 20;
  minFormulaWeight: number = 20;
  maxFormulaWeight: number = 40;

  hideCustomizeLink: boolean = false;
  permissions: any = null;
  settings: any = null;
  context: string = 'any';

  modalDismissSubscription: Subscription = null;
  csUpdatedSubscription: Subscription = null;
  csUpdateFailSubscription: Subscription = null;
  loading: any = null;
  preventFastDispense: boolean = false;

  origClient: CLiCSClient = null;
  showNameInput: boolean = false;

  libraryToken: string = null;
  libraryTitle: string = null;

  constructor(
    private navParams: NavParams,
    private clicsService: CLiCSService,
    private events: EventsService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private editQueue: EditQueueProvider,
    private modalCtrl: ModalController,
    public mixingBowl: MixingBowlProvider,
    public modeCtrl: ModeControllerProvider,
    private taptic: TapticEngine,
    private keyboard: Keyboard,
    private settingsCtrl: SettingsProvider,
    private loadingCtrl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    @Inject(RollbarService) public rollbar: Rollbar
  ) { }

  ionViewWillEnter() {
    this.permissions = this.modeCtrl.getPermissions();
    this.settings = this.settingsCtrl.settings;
    this.modeCtrl.init().then(response => {
      this.permissions = this.modeCtrl.getPermissions();
    });
    let token = this.navParams.get('formula_token');
    let ident = this.navParams.get('request_ident');
    this.context = this.navParams.get('context');
    this.preventFastDispense = this.navParams.get('fromModal');
    this.source = this.navParams.get('source');
    if (this.source == 'HISTORY') {
      this.source = 'history';
    }
    this.scope = this.navParams.get('scope');
    this.requestedView = this.navParams.get('view');

    this.hideCustomizeLink = this.navParams.get('noCustomize') || false;

    if (this.source == undefined)
      this.source = 'client';

    if (this.source != 'history')
      this.clear();

    let imported = false;
    // Try clicsService data stash first...
    if (this.clicsService.hasStash()) {
      let stash = this.clicsService.getStash();
      if (!!stash.cf && stash.action == 'SHOW') {
        this.formula = stash.cf;
        this._updateLocalFormula();
        imported = true;
        this.clicsService.clearStash();
        this.adjustFormulaML();
      }
    }

    // If nothing then try the editQueue...
    if (!imported) {
      this.editItem = this.editQueue.getShowFormulaItem();
      if (this.editItem) {
        this.formula = this.editItem.itemObject;
        this._updateLocalFormula();
        imported = true;
        this.adjustFormulaML();
      }
    }

    // Finally, try default locations to find the formula
    if (!imported) {
      switch (this.source) {
        case 'library':
        case 'clapp':
          this.clicsService.findLibraryFormula(token)
            .then((formula) => {
              this.formula = formula;
              this._updateLocalFormula();
              this.adjustFormulaML();
            });
          break;
        case 'conversion':
          this.clicsService.findConversionFormula(token)
            .then((formula) => {
              this.formula = formula;
              this._updateLocalFormula();
              this.adjustFormulaML();
            });
          break;
        case 'lab':
          // scope is either app (CS from Create App) or formula (CF from Create Formula)
          if (this.scope) {
            switch (this.scope) {
              case 'app':
                let cs = this.clicsService.getCurrentColorApplication();
                if (cs) {
                  this.formula = cs.findFormula(token);
                  this._updateLocalFormula();
                  this.adjustFormulaML();
                }
                break;
              case 'formula':
                this.formula = this.clicsService.getCurrentFormula();
                this._updateLocalFormula();
                this.adjustFormulaML();
            }
          }
          break;
        case 'history':
          let cs_token = this.navParams.get('color_session_token');
          if (cs_token != undefined) {
            try {
              let cs = this.clicsService.current_user.current_client.findHistorySession(cs_token);
              if (cs) {
                this.clear();
                this.formula = cs.findFormula(token);
                this._updateLocalFormula();
                this.adjustFormulaML();
              }
            } catch (error) {
              alert('Unable to find that color formula');
            }
          }
          break;
        default:
          this.formula = this.clicsService.findClientFormula(token, ident);
          this._updateLocalFormula();
          this.adjustFormulaML();
          break;
      }
    }

    // If this is a history joint then show the formula adjusted for the amount that was requested
    if (!!this.formula && this.formula.isFormulaRequest() && this.source == 'history') {
      this.adjustedFormula = new CLiCSFormulaRequest(this.formula);
      console.log(`Adjusted formula amount ${this.adjustedFormula.amount}, dispensed: ${this.adjustedFormula.dispensed}`);
      if (this.adjustedFormula.amount > 0.0) {
        this.adjustedFormula.assertFormulaText(true, this.adjustedFormula.dispensedAmount());
      }
    }

    this.formulaOwnedByMe = !!this.formula && this.formula.owned === true;
    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });

    this.adjustFormulaML();
  }

  ionViewDidEnter() {
    this.minFormulaWeight = this.mixingBowl.minAllowableWeight(this.formula);
    if (this.minFormulaWeight < 10)
      this.minFormulaWeight = 10;
    else {
      if (this.minFormulaWeight > 10) {
        this.maxFormulaWeight = this.minFormulaWeight + 20;
      }
      if (this.minFormulaWeight > this.defaultWeight)
        this.defaultWeight = this.minFormulaWeight;
    }
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
    this.csUpdateFailSubscription = this.events.unsubscribe(this.csUpdateFailSubscription);
    this.csUpdatedSubscription = this.events.unsubscribe(this.csUpdatedSubscription);
  }

  // Does some post-processing for view-accessible elements when this.formula changes
  _updateLocalFormula() {
    if (this.formula) {
      this.formula.assertFormulaText();
      this.rgb = this.formula.getRGB();
      if (this.formula.title)
        this.title = this.formula.title;
      this.components = this.formula.formulaStringArray();
      this.memberLibraries = this.clicsService.repo.librariesIncludingFormula(this.formula.token);
      this.formulaOwnedByMe = this.formula.owned;
    }
  }

  // In response to "Done" we simply revert back to the prior page
  goBack() {
    // TODO: consider an event emitter that has different context depending on the calling page?
    this.events.publish('navrequest', {"page": "back"});
  }

  async doCustomize() {
    // Show color in modal pop-up
    this.clicsService.stashData({cf: this.formula, action: 'EDIT'});

    const customizeColorModal = await this.modalCtrl.create({
      component: CreateColorPage,
      componentProps: {context: this.source},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    customizeColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;
      if (data.modified) {
        this.closeModal(true);
      }
    });

    await customizeColorModal.present();
  }

  // determines the amount for swatching this formula then calls doSwatchColor()
  async prepareSwatch() {
    if (this.preventFastDispense) {
      return;
    }
    let swatchFormula = new CLiCSFormulaRequest(this.formula);
    if (!swatchFormula.hasDeveloper()) {
      swatchFormula.setDeveloper(20, 1.0, this.mixingBowl);
      // swatchFormula.includeDevInTitle();
    }
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'adding...',
      duration: 6000
    });
    await this.loading.present();
    const swatchAmount = this.mixingBowl.minAllowableWeight(swatchFormula);
    await this.doSwatchColor({amount: Math.max(swatchAmount, 20)}, swatchFormula);
  }

  async doSwatchColor(data, swatchFormula: CLiCSFormula = null) {
    let formula = swatchFormula || this.formula;
    if (!!data) {
      this.clicsService.apiQueueSwatch(formula, data.amount)
        .then((result) => {
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }

          const buttons = [
            {
              text: 'Ok',
              handler: () => {
                this.closeModal();
              }
            }
          ];
          if (result.success) {
            this.alertCtrl.create({
              header: 'Ready to Dispense',
              subHeader: 'Your swatch is ready to dispense from any available CLICS Dispenser machine',
              buttons: buttons,
              mode: 'ios'
            }).then(alert => alert.present());
          } else {
            this.alertCtrl.create({
              header: 'Oops...',
              subHeader: `Sorry! Something went wrong and your swatch did not get queued properly. Please try again. Message: ${result.message_key}`,
              buttons: buttons,
              mode: 'ios'
            }).then(alert => alert.present());
          }
        }, (result) => {
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }

          this.alertCtrl.create({
            header: 'Dang!',
            subHeader: 'Something went wrong and your swatch did not get queued properly. Please try again.',
            buttons: ['Ok'],
            mode: 'ios'
          }).then(alert => alert.present());
        });
    }
    this.promptForSwatch = false;
  }

  clear() {
    this.title = 'Color Details';
    this.rgb = '#ffffff';
    this.formula = null;
    this.components = [];
    this.editItem = null;
  }

  closeModal(isModified: boolean = false, deletedFormulas: string[] = null) {
    if (!!deletedFormulas && deletedFormulas.length > 0)
      this.modalCtrl.dismiss({modified: isModified, deleted: deletedFormulas});
    else
      this.modalCtrl.dismiss({modified: isModified});
  }

  async promptForRemove() {
    if (this.source == 'clapp') {  // Link disabled
      return;
    }
    const alert = await this.alertCtrl.create({
      header: 'Remove Formula',
      message: `This will permanently remove this formula from the color ${this.modeCtrl.collectionStr(true)}. Continue?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: () => {
            this.removeFormula();
          }
        }
      ]
    });
    await alert.present();
  }

  // Removes the formula if it is owned by this user
  async removeFormula() {
    // CRITICAL: must call API, update library in CLiCSService, publish library:modified event.
    // CRITICAL: must handle removed formula in Clapp context !!!
    this.clicsService.apiRemoveColorFormulas([this.formula.token]).then((data) => {
      if (data.success && data.tokens.length > 0) {
        let tokens: string[] = [];
        for (const token of data.tokens) {
          tokens.push(token);
          this.clicsService.repo.removeFormula(token);
          this.clicsService.saveRepo();
        }
        this.events.publish('library:modified');
        this.closeModal(true, tokens);
      } else {
        this.alertCtrl.create({
          header: 'Could not remove color formula',
          subHeader: 'Sorry - something went wrong and the color formula was not removed. Please try again.',
          buttons: ['Ok'],
        }).then(alert => alert.present());
      }
    });
  }

  // Close modal with "modified" flag set (a hack)
  useFormulaFromHistory() {
    let ca = new CLiCSColorApplication();
    ca.saveAppFormula(new CLiCSColorFormula(this.formula), null);
    this.clicsService.stashData({modified: true, ca: ca, action: 'PAYLOAD'});
    if (this.requestedView === 'modal') {
      this.modalCtrl.dismiss({ca: ca, action: 'PAYLOAD', modified: true});
    }
  }

  removeFromLibrary(libraryToken: string) {
    const result: any = this.clicsService.repo.removeFormulaFromLibrary(libraryToken, this.formula.token);
    this.clicsService.saveRepo();
    this.clicsService.apiRemoveFormulasFromLibrary(result.libraryToken, result.formulaTokens).then((response) => {
      if (result.orphanedFormulas.length > 0) {
        this.clicsService.apiAssignFormulasToLibrary(result.defaultLibToken, result.orphanedFormulas).then(result => {
          this._updateLocalFormula();
        });
      } else
        this._updateLocalFormula();
    });
  }

  // Performs a Simple-mode-style immediate dispense
  // NOTE: this feature has been removed as of 4/2023
  async doFastDispense() {
    if (this.preventFastDispense) {
      return;
    }
    let _that = this;
    let cs: CLiCSColorSession = null;

    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'please wait...',
      duration: 2000
    });
    await this.loading.present();

    if (this.preventFastDispense) {
      return;
    }

    this.origClient = this.clicsService.current_user.current_client;
    let fr = new CLiCSFormulaRequest(this.formula); // Cast formula as a formula request

    // Set default client for the current user (herself)
    this.clicsService.selectDefaultClient().then(defaultClient => {
      if (!defaultClient) {
        // TODO: alert here could not load default client and exit
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
        return;
      }

      // Get default client CS
      _that.clicsService.apiGetColorSession(defaultClient).then((result) => {
        cs = result;
        const defaultAmount = _that.mixingBowl.getDefaultAmount();
        const minAmount = _that.mixingBowl.minAllowableWeight(fr);
        fr.setAmount(Math.max(minAmount, defaultAmount));

        if (!fr.hasDeveloper()) {
          fr.setDeveloper(20, 1.0, _that.mixingBowl);
        }

        // Open Formula Choices modal, passing the formula
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
        _that.openFormulaChoices(fr, cs, defaultClient, false).then((result) => {
          if (!!result) {
            this.requestUpdate(cs);
            this.modalCtrl.dismiss({fastDispense: true});
          }
        });
      });
    });
  }

  // Use this formula in a client application by returning the formula token to the parent page
  async useFormulaFromLibrary() {
    this.modalCtrl.dismiss({useFormula: true, formula: this.formula})
  }

  // Open formula choices modal and apply any changed made to the formulas
  async openFormulaChoices(formula: CLiCSFormulaRequest, cs: CLiCSColorSession, client: CLiCSClient, alreadyModified: boolean = false): Promise<boolean> {
    let _that = this;
    if (!!formula) {
      if (formula.notStarted()) {
        const formulaSettingsModal = await this.modalCtrl.create({
          component: FormulaChoicesPage,
          componentProps: {formula: formula, modified: alreadyModified, fast: true},
          backdropDismiss: false,
          cssClass: this.clicsService.theme,
        });

        formulaSettingsModal.onDidDismiss().then((overlayEvent) => {
          const data = overlayEvent.data;
          if (!!data) {
            let formula = data.formula;
            if (!!formula) {
              formula.assertFormulaText(true);
              cs.addFormula(formula);
              _that.updateAndDispense(cs);  // Updates CS then closes this modal
              return true;
            } else {
              const mssg = "Formula modified in Choices was not returned";
              console.error(mssg);
              _that.rollbar.error(mssg, {data: data, client: client.name()});
              _that.confirmWithToast('No formula found', true);
              return false;
            }
          } else {
            _that.clicsService.selectClient(this.origClient.token);
            return false;
          }
        });
        await formulaSettingsModal.present();
      }
    } else {
      this.taptic.notification({type: "warning"});
      console.log(`Could not open formula choices for formula ${formula.token} due to dispense status of ${formula.dispense_status}`);
      await this.clicsService.selectClient(this.origClient.token);
      return false;
    }
  }

  // Does a request update and sets a dispense flag
  async updateAndDispense(color_session: any = null) {
    this.requestUpdate(color_session);
    this.modalCtrl.dismiss({fastDispense: true});
  }

  // Publishes a color_session:update event and blocks server timer checks
  requestUpdate(color_session: CLiCSColorSession) {
    if (!!color_session) {
      color_session.reindex();
      this.clicsService.activeClient().cs.updateFromObject(color_session);
      this.clicsService.activeClient().cs.reindex();
    }
    this.events.publish('color_session:update', color_session);
  }

  // Show a toast with a message
  async confirmWithToast(mssg: string, error = false) {
    let classStr = 'clics-toast';
    if (error) {
      classStr += ' error';
    }

    const duration = error ? 5000 : 3000;
    let toast = await this.toastCtrl.create({
      message: mssg,
      duration: duration,
      cssClass: classStr
    });
    await toast.present();
  }

  // Here we save a color - typically a FR from history - to a color collections. We prompt for a name and for the
  // collection to save it to. When done we update the original FR to have the title of the saved formula and also a
  // pedigree of P.
  async doSaveColor() {
    // Don't re-save a personal formula
    if (['p', 'P'].includes(this.adjustedFormula.pedigree)) {
      return;
    }

    // Make sure the formula is named
    if (this.formula.title == null || this.formula.title == "") {
      const alert = await this.alertCtrl.create({
        header: "Add a Name",
        subHeader: `Please add a descriptive name to your color formula so we can save it to your color ${this.modeCtrl.collectionsStr(true)}`,
        buttons: ['Ok'],
        mode: 'ios'
      });
      alert.onDidDismiss().then(() => {
        this.revealNameInput();
      });
      await alert.present();
      return;
    }

    if (!this.libraryToken) {
      await this.chooseLibrary();
    } else {
      let color = this.formula;
      const _that = this;
      color.assertFormulaText(true);
      color.owned = true;
      this.clicsService.apiSaveColorFormula(color, this.libraryToken).then(async (data) => {
        if (data.success) {
          // Update the title and pedigree on an existing FR
          await _that.clicsService.apiSetPedigree(_that.adjustedFormula, data.cf).then(async (ped_data) => {
            if (ped_data.success) {
              _that.formula.title = ped_data.title;
              _that.adjustedFormula.title = ped_data.title;
              _that.adjustedFormula.pedigree = ped_data.pedigree;
            }
            // TODO: save the formula here

            const alert = await _that.alertCtrl.create({
              header: "Formula Saved",
              subHeader: `Your formula was successfully saved to your color ${this.modeCtrl.collectionStr(true)}`,
              buttons: ['Ok']
            });
            alert.onDidDismiss().then((overlayEvent) => {
              _that.libraryToken = null;
              _that.modalCtrl.dismiss();
            });
            await alert.present();
          });
          _that.clicsService.repo.addFormula(data.cf, data.library_token);
          _that.clicsService.saveRepo();
        } else {
          _that.libraryToken = null;
          const alert = await _that.alertCtrl.create({
            header: "Oops...",
            subHeader: "Sorry! Something went wrong when trying to save your color formula. Please review the formula name and components and try again.",
            buttons: ['Ok']
          });
          await alert.present();
        }
      });
    }
  }

  revealNameInput() {
    this.showNameInput = true;
    setTimeout(() => {
      this.titleFormInput.setFocus();
    }, 150);
  }

  setFormulaTitle(event) {
    this.title = event.detail.value.trim();
    this.formula.title = this.title;
  }

  saveFormulaTitle() {
    this.title = this.formula.title;
    this.showNameInput = false;
    this.keyboard.hide();
  }

  async chooseLibrary() {
    let buttons = [];

    // Add buttons from user's libraries
    const current_user: CLiCSUser = this.clicsService.current_user;
    if (current_user) {
      const libs = this.clicsService.repo.ownedLibraries();
      for (let library of libs) {
        buttons.push({
          text: library.title,
          handler: () => {
            this.setLibraryTokenAndSave(library.token, library.title);
          }
        })
      }

      if (this.modeCtrl.isPermitted('add_collection')) {
        buttons.push({
          text: `Add new ${this.modeCtrl.collectionStr(true)}...`,
          role: 'destructive',
          handler: () => {
            actionSheet.dismiss().then(() => {
                this.addLibrary();
              }
            );
            return false;
          }
        });
      }

      buttons.push({
        text: 'Cancel',
        role: 'cancel',
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: `Choose ${this.modeCtrl.collectionStr()} to Save To`,
        buttons: buttons,
        mode: 'ios'
      });

      await actionSheet.present();
    }
  }

  async addLibrary() {
    const alert = await this.alertCtrl.create({
      header: `Create New ${this.modeCtrl.collectionStr()}`,
      inputs: [
        {
          name: 'title',
          type: 'text',
          label: `${this.modeCtrl.collectionStr()} Name`,
          placeholder: `Enter name for new color ${this.modeCtrl.collectionStr(true)}`,
          value: ''
        }
      ],
      buttons: [
        {
          text: `Create ${this.modeCtrl.collectionStr()}`,
          handler: (data) => {
            alert.dismiss().then(() => {
              this.createLibraryAndSave(data.title);
            });
            return false;
          }
        },
        {
          text: 'Cancel',
          role: "cancel",
          handler: () => {
            alert.dismiss().then(() => {
              this.chooseLibrary();
            });
            return false;
          }
        }
      ],
      backdropDismiss: true,
      mode: 'ios'
    });
    await alert.present();
  }

  // Set the library token and continue with save operation
  async setLibraryTokenAndSave(token: string, title: string) {
    this.libraryToken = token;
    this.libraryTitle = title;
    await this.doSaveColor();
  }

  async createLibraryAndSave(libraryTitle: string) {
    if (libraryTitle && libraryTitle !== "") {
      this.clicsService.apiAddUserLibrary(libraryTitle).then(async (result) => {
        if (result.success) {
          this.libraryToken = result.library.token;
          this.libraryTitle = result.library.title;
          let newLibrary = new CLiCSLibrary(result.library);
          newLibrary.setScope('user');
          newLibrary.owned = true;
          this.clicsService.repo.addLibrary(newLibrary);
          this.clicsService.saveRepo();
          await this.doSaveColor();
        } else {
          const toast = await this.toastCtrl.create({
            message: `${this.modeCtrl.collectionStr()} creation failed, please try again`,
            duration: 3000,
            position: 'bottom'
          });
          await toast.present().then(() => {
            this.addLibrary();
          });
        }
      });
    }
  }

  saveIsDisabled(): boolean {
    return (!this.adjustedFormula || (!!this.adjustedFormula && this.adjustedFormula.pedigree === 'p' || this.adjustedFormula.pedigree === 'P'));
  }

  adjustFormulaML() {
    if(!!this.permissions.show_milliliters){
      const formulaParts = this.formula.formula_text.split( " + ");
      const regex = /(?<=\d)([g])(?=\s)/;
      var newFormula: string = "";

      formulaParts.forEach(element => {
        element = element.replace(regex, "mL ");
        newFormula = newFormula + element + " + ";
      });
      newFormula = newFormula.slice(0, -2);

      this.formula.formula_text = newFormula;
    }
  }

}
