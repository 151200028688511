import { Injectable } from '@angular/core';

/*
  Maintains content listed by message keys (mk_??? strings). Downloads content updates and translations.

  If no corresponding message key if found the mk string is broken up into parts
*/
@Injectable({
  providedIn: 'root',
})
export class ContentProvider {
  constructor() {
  }

  translateMessage(message_key: string): string {
    // TODO: look up I18N messages
    if (message_key.match(/\Amk_.+\b/i) !== null) {
      return message_key;
    } else {
      // Translation lookup happens here
      let message = message_key.replace(/mk_/, '');
      return message.replace(/_/g, ' ');
    }
  }

  // Similar to translateMessage but replaces the passed string with translation instead of returning a translated string
  replaceMessage(message_key: string) {
    // TODO: look up I18N messages
    if (message_key.match(/\Amk_.+\b/i) == null)
        message_key.replace(/mk_/, '').replace('_', ' ');
  }

  // Returns an icon tag for the given rating value
  ratingIconName(rating: number): string {
    // Returns an icon name for the given rating value
    let result = 'help';
    if (Number.isInteger(rating) == false)
      rating = Math.round(rating);

    switch (rating) {
      case 5:
        result = 'heart';
        break;
      case 4:
        result = 'star';
        break;
      case 3:
        result = 'thumbs-up';
        break;
      case 2:
        result = 'thumbs-down';
        break;
      case 1:
        result = 'sad';
        break;
      case 0:
        result = 'qr-scanner';
        break;
      default:
        break;
    }
    return result;
  }

  // Returns a class based on the rating value
  ratingIconClass(rating: number): string {
    if (Number.isInteger(rating) == false)
      rating = Math.round(rating);

    return 'rating3';
  }

}
