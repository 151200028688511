import {Component} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {InAppBrowser, InAppBrowserOptions} from '@ionic-native/in-app-browser/ngx';
import {Subscription} from 'rxjs';
import {WelcomeSlidesPage} from '../welcome-slides/welcome-slides';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {FeedbackCategoriesPage} from "../feedback-categories/feedback-categories";

/**
 * Generated class for the HelpPage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
  selector: 'page-help',
  templateUrl: 'help.html',
  styleUrls: ['help.scss'],
})
export class HelpPage {
  theme: string = "light";
  helpContent: string = "";
  inputStr: string = "hello";
  entryText: string = "";
  modalDismissSubscription: Subscription = null;

  constructor(private navParams: NavParams,
              private clicsService: CLiCSService,
              private modalCtrl: ModalController,
              private modeCtrl: ModeControllerProvider,
              private events: EventsService,
              private inAppBrowser: InAppBrowser) {
  }

  ionViewWillEnter() {
    let pageName = this.navParams.get('pageName');
    console.log('Help page name: ' + pageName);
    if (pageName) {
      this.clicsService.getPageContent(pageName, 'help').then((content) => {
        this.helpContent = content;
        if (this.helpContent == "")
          this.helpContent = "<h4>No help content is available for this page</h4>";
      });
    }

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });

    this.theme = this.clicsService.getTheme();
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  updateEntry(eventData: any) {
    this.inputStr = eventData;
    this.entryText = `:: ${this.inputStr} ::`;
  }

  async showWelcomeSlides() {
    const welcomeModal = await this.modalCtrl.create({
      component: WelcomeSlidesPage,
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    welcomeModal.onDidDismiss().then((overlayEvent) => {
      this.modalCtrl.dismiss();
      localStorage.setItem('welcomed', 'true');
    });

    await welcomeModal.present();
    // this.modalCtrl.dismiss();
  }

  // Opens an external browser to the training home page for this brand
  openTrainingPage() {
    const options: InAppBrowserOptions = {
      // location : 'yes',
      // hidden : 'no',
      // clearsessioncache : 'no',
      // zoom : 'yes',//Android only ,shows browser zoom controls
      // hardwareback : 'yes',
      // mediaPlaybackRequiresUserAction : 'no',
      clearcache : 'yes',
      shouldPauseOnSuspend : 'no', //Android only
      closebuttoncaption : 'Close', //iOS only
      disallowoverscroll : 'no', //iOS only
      toolbar : 'yes', //iOS only
      enableViewportScale : 'no', //iOS only
      allowInlineMediaPlayback : 'no', //iOS only
      presentationstyle : 'fullscreen', //iOS only pagesheet formsheet fullscreenb
      fullscreen : 'yes' //Windows only
    };
    // this.inAppBrowser.create(`https://academy.clics.com/${this.modeCtrl.getAppType()}`, '_system', options);
    this.inAppBrowser.create(`https://academy.clics.com/${this.modeCtrl.getAppType()}?u=${this.clicsService.current_user.api_token}&a=c84fd377d834ad3d7b02641576bcb693`, '_system', options);
    this.modalCtrl.dismiss();
    return false;
  }

  // Opens an external browser to the training home page for this brand
  openAccountPage() {
    const options: InAppBrowserOptions = {
      clearcache : 'yes',
      shouldPauseOnSuspend : 'no', //Android only
      closebuttoncaption : 'Close', //iOS only
      disallowoverscroll : 'no', //iOS only
      toolbar : 'yes', //iOS only
      enableViewportScale : 'no', //iOS only
      allowInlineMediaPlayback : 'no', //iOS only
      presentationstyle : 'fullscreen', //iOS only pagesheet formsheet fullscreenb
      fullscreen : 'yes' //Windows only
    };
    if (this.clicsService.mobileAppType() == 'goldwell') {
      this.inAppBrowser.create('https://goldwell.clics.com', '_system', options);
    } else {
      this.inAppBrowser.create('https://account.clics.com', '_system', options);
    }
    this.modalCtrl.dismiss();
    return false;
  }

  async showFeedbackForm() {
    const modal = await this.modalCtrl.create({
      component: FeedbackCategoriesPage,
      componentProps: {newRequest: true},
      cssClass: this.clicsService.theme,
    });

    await modal.present();
  }

}
