import {Component, Input} from '@angular/core';

@Component({
  selector: 'color-tile',
  templateUrl: 'color-tile.html',
  styleUrls: ['color-tile.scss'],
})
export class ColorTileComponent {
  @Input() backgroundColor: string = '#ffffff';
  @Input() level: number = 6;  // used to select overlay graphic

  showTexture: boolean = true;

  constructor() {
  }

  toggleTexture() {
    this.showTexture = !this.showTexture;
  }
}
