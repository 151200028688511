import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, LoadingController, NavParams} from '@ionic/angular';
import {Validators, FormBuilder, AbstractControl, ValidatorFn, FormGroup, FormControl} from '@angular/forms';
import {Contacts, Contact} from '@ionic-native/contacts';
import {Subscription} from 'rxjs';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClient} from '../../../lib/client';


/**
 * Generated class for the ClientAddPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-client-add',
  templateUrl: 'client-add.html',
  styleUrls: ['client-add.scss'],
})
export class ClientAddPage implements OnInit {
  client: CLiCSClient = null;
  button_text: string = 'Add Client';
  title = 'Add New Client';
  clientForm = null;
  loading: any = null;
  sendingClient: boolean = false;
  deviceContact: Contact = null;
  modalDismissSubscription: Subscription = null;

  @ViewChild('lastNameInput') lastNameInput;
  @ViewChild('phoneInput') phoneInput;
  @ViewChild('emailInput') emailInput;
  @ViewChild('nickNameInput') nickNameInput;

  constructor(
    public navParams: NavParams,
    private clicsService: CLiCSService,
    private formBuilder: FormBuilder,
    private contacts: Contacts,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private events: EventsService,
    private loadingCtrl: LoadingController) {
  }

  // Allows blank fields OR one or more passed validators
  optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value ? Validators.compose(validators)(control) : null;
    };
  }

  ionViewWillEnter() {
    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  ngOnInit() {
    this.clientForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern('^(\\+?\\d{1,3}\\s*\\-?\\s*)?\\(?([0-9]{3})\\)?\\s*[-.●]?([0-9]{3})[-.●]?([0-9]{4})$')] ),
      email: new FormControl('', Validators.email),
      nickname: new FormControl('')
    });

    if (this.navParams.data.edit) {
      this.client = this.clicsService.current_user.getClient();
      this.button_text = 'Update Client';
      this.clientForm.get('firstName').value = this.client.first_name;
      this.clientForm.get('lastName').value = this.client.last_name;
      this.clientForm.get('email').value = this.client.email;
      this.clientForm.get('phone').value = this.client.phone;
      this.clientForm.get('nickname').value = this.client.nickname;

      // Since editing the client "touch" all required fields so error messages can be seen right away
      this.clientForm.get('firstName').touched = true;
      this.clientForm.get('lastName').touched = true;
      this.clientForm.get('phone').touched = true;

      this.title = 'Edit Client';
    }
  }

  closeModal(paramObject: any = null) {
    if (paramObject)
      this.modalCtrl.dismiss(paramObject);
    else
      this.modalCtrl.dismiss();
  }

  // Calls save_client API to create or update client record
  async saveClient() {
    if (this.sendingClient) return;

    const _that = this;
    let isNewClient = false;
    if (this.client == null) {
      this.client = new CLiCSClient();
      isNewClient = true;
    }
    this.client.first_name = this.clientForm.get('firstName').value;
    this.client.last_name = this.clientForm.get('lastName').value;
    this.client.nickname = this.clientForm.get('nickname').value;
    this.client.phone = this.clientForm.get('phone').value;
    this.client.email = this.clientForm.get('email').value;

    if (this.client.first_name.length > 0 && this.client.last_name.length > 0) {
      this.sendingClient = true;
      this.clicsService.apiSaveClient(this.client).then((data) => {
        _that.sendingClient = false;

        if (data.success) {
          if (isNewClient) {
            this.clicsService.apiGetColorSession(this.client).then((cs) => {
              this.closeModal({cli: data.token});
            });
          } else {
            this.closeModal({cli: data.token});
          }
        } else {
          this.alertCtrl.create({
            header: 'Could Not Save Client',
            message: `Sorry - a problem occurred while trying to save this client's information. Please try again.`,
            buttons: [
              {
                text: 'Ok'
              },
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  this.closeModal();
                }
              }
            ],
            mode: 'ios'
          }).then(alert => alert.present());
        }
      });
    }
  }

  // Show an alert prompting to remove client
  async promptRemoveClient() {
    const alert = await this.alertCtrl.create({
      header: 'Remove this client?',
      message: `Are you sure you want to permanently remove ${this.client.name()} from your list of clients? This cannot be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.closeModal();
          }
        },
        {
          text: 'Remove Client',
          handler: async () => {
            if (this.client) {
              this.loading = await this.loadingCtrl.create({
                spinner: 'bubbles',
                message: 'removing client...',
                duration: 4000
              });
              await this.loading.present();

              this.clicsService.apiRemoveClient(this.client.token).then((data) => {
                this.loading.dismiss();
                this.loading = null;
                if (data !== false && data.success == true)
                  this.events.publish('navrequest', {top: 'clients', page: 'clients'});
                this.closeModal();
              }, (reason) => {
                this.closeModal();
              });
            }
          }
        }
      ],
      mode: 'ios'
    });
    await alert.present();
  }

  _clearForm() {
    this.clientForm.controls['firstName'].setValue('');
    this.clientForm.controls['lastName'].setValue('');
    this.clientForm.controls['email'].setValue('');
    this.clientForm.controls['phone'].setValue('');
    this.clientForm.controls['nickname'].setValue('');
  }

  // Open a contacts dialog then load the contact information into the form fields
  loadFromContacts() {
    this.contacts.pickContact().then((contact: Contact) => {
      this._clearForm();
      this.clientForm.controls['firstName'].setValue(contact.name.givenName);
      this.clientForm.controls['lastName'].setValue(contact.name.familyName);
      this.clientForm.controls['nickname'].setValue(contact.nickname);
      if (contact.emails == null) {
        this.clientForm.controls['email'].setValue("");
      } else {
        for (let email of contact.emails) {
          if (email.value && email.value != "") {
            this.clientForm.controls['email'].setValue(email.value);
            break;
          }
        }
      }
      let targetPhone: any = null;
      if (contact.phoneNumbers != null) {
        for (let phone of contact.phoneNumbers) {
          targetPhone = phone;
          if (phone.type == "mobile")
            break;
        }
      }
      if (targetPhone == null)
        this.clientForm.controls['phone'].setValue("");
      else
        this.clientForm.controls['phone'].setValue(targetPhone.value);

      this.clientForm.setValidators(Validators.compose([Validators.required]));
      this.clientForm.enable();
    });
  }

  // If the keypress is Enter then go to the next input
  advanceOnEnter(event: any, nextInput: string) {
    if (event.key == 'Enter') {
      switch (nextInput) {
        case 'lastNameInput':
          setTimeout(() => {
            this.lastNameInput.setFocus();
          }, 150);
          break;
        case 'phoneInput':
          setTimeout(() => {
            this.phoneInput.setFocus();
          }, 150);
          break;
        case 'emailInput':
          setTimeout(() => {
            this.emailInput.setFocus();
          }, 150);
          break;
        case 'nickNameInput':
          setTimeout(() => {
            this.nickNameInput.setFocus();
          }, 150);
          break;
      }
    }
  }

  clientShared(): boolean {
    return (!!this.client && this.client.shared == true);
  }
}
