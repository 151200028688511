import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SettingsProvider} from '../../services/settings/settings';
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";


/**
 * Used to prompt for amounts
 */
@Component({
  selector: 'dispense-confirm',
  templateUrl: 'dispense-confirm.html',
  styleUrls: ['dispense-confirm.scss'],
})
export class DispenseConfirmComponent {
  @Input() title: string = 'Choose Amount to Dispense';
  @Input() confirmText: string = 'Queue Swatch';
  @Input() min: number = 10;
  @Input() max: number = 30;
  @Input() optionMin: number = 0;
  @Input() optionMax: number = 40;
  @Input() optionDefault: number = 20;
  @Input() default: number = 20;
  @Input() option: string = null; // Title for option switch (displays if set)
  @Input() optionState: boolean = null;
  @Input() minWeightText: string = 'minimum weight for this formula';
  @Input() showMinWeightText: boolean = true;
  @Input() unit: string = "g";
  @Output() confirmation = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('dispense_confirm_modal') dispenseModal: ElementRef;

  value: number = 20;
  valStr: string = `${this.value}${this.unit}`;
  optionModValue = 20;  // Developer strength, etc.
  optionValStr = 'D20'; // Developer strength, etc.

  permissions: any = null;
  settings: any = null;

  constructor(
    private modeCtrl: ModeControllerProvider,
    ) {
    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
        this.permissions = this.modeCtrl.getPermissions();
    });

    if(!!this.permissions.show_milliliters){
        this.unit = "mL"
    }
  }

  ngOnInit() {
    this.value = this.default;
    this.optionModValue = this.optionDefault;
    this.updateModValue();
    this.updateValue(null);
  }

  updateValue(data) {
    if (!!data) {
      this.value = data.amount;
    }
    this.valStr = `${this.value}${this.unit}`;
  }

  updateModValue() {
    this.optionValStr = `D${this.optionModValue}`;
  }

  doDispense() {
    this.confirmation.emit({
      amount: this.value,
      option: (this.optionState && !!this.option),
      optionMod: this.optionValStr
    });
  }

  doCancel() {
    this.cancel.emit(null);
  }

  // True if at min weight but not original default
  atMinWeight() {
    // @ts-ignore
    return(this.value == this.min && this.showMinWeightText == true && this.min != 10 );
  }
}
