import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {ComponentsModule} from '../../components/components.module';
import {InventoryDamagedLoopPage} from './inventory-damaged-loop';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [InventoryDamagedLoopPage],
  providers: [
    NavParams,
    BarcodeScanner,
  ],
})
export class InventoryDamagedLoopPageModule {}
