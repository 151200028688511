import {Component, Output, EventEmitter, Input} from '@angular/core';

/**
 * Generated class for the RecordMenuComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'record-menu',
  templateUrl: 'record-menu.html',
  styleUrls: ['record-menu.scss'],
})
export class RecordMenuComponent {
  // altContent: string = null;
  @Input() showAltContent: boolean = false;
  @Input() showFilter: boolean = true;
  @Input() showAll: boolean = false;
  @Output() openSearch = new EventEmitter();
  @Output() openFilter = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() viewGrid = new EventEmitter();
  @Output() viewList = new EventEmitter();
  @Output() openSettings = new EventEmitter();
  @Output() altAction = new EventEmitter();

  constructor() {
  }

  doOpenSearch() {
    this.openSearch.emit();
  }

  doOpenFilter() {
    this.openFilter.emit();
  }

  doAddItem() {
    this.addItem.emit();
  }

  doEditItem() {
    this.editItem.emit();
  }

  doViewGrid() {
    this.viewGrid.emit();
  }

  doOpenSettings() {
    this.openSettings.emit();
  }

  doViewList() {
    this.viewList.emit();
  }

  doAltAction(event: any) {
    this.altAction.emit({target: event});
  }
}
