// Defines a user - typically a stylist or owner/manager. May be listed

// import {isUndefined} from "util";
import {CLiCSClient} from './client';

export class CLiCSUser {
  first_name: string = '';
  last_name: string = '';
  full_name = '';
  email: string = '';
  is_admin: boolean = false;
  role: number = 0;
  salon_role: number = null;
  image: string = null;
  api_token: string = '';
  current_client: CLiCSClient = null;
  stashed_client: CLiCSClient = null;
  libraries: any[] = [];  // list of user libraries { title: string, token: string, sharing_mode: string }
  training_level: number = 0;
  dispense_mode: string = 'expert';
  num_dispenses_on_login: number = null;  // Number of dispenses as of last login

  lib_ts: number = 0;
  client_ts: number = 0;

  constructor(dataObj: object = null) {
    if (dataObj != null) {
      this.loadObj(dataObj);
    }
  }

  loadObj(data: any) {
    if (data !== undefined) {
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.full_name = String(data.first_name) + ' ' + String(data.last_name);
      this.is_admin = Boolean(data.is_admin);
      this.role = Number(data.role);
      this.api_token = String(data.api_token);
      if ('email' in data)
        this.email = String(data.email);
      if ('lib_ts' in data)
        this.lib_ts = data.lib_ts;
      if ('client_ts' in data)
        this.client_ts = data.client_ts;
      if ('training_level' in data)
        this.training_level = Number(data.training_level);
      if ('dispense_mode' in data)
        this.dispense_mode = data.dispense_mode; // Recommended or max dispense mode
      if ('num_dispenses' in data) {
        if (!!data.num_dispenses) {
          this.num_dispenses_on_login = Number(data.num_dispenses);
        } else {
          this.num_dispenses_on_login = null;
        }
      }
      if ('salon_role' in data) {
        this.salon_role = Number(data.salon_role);
      }
      if (data.current_client != null && data.current_client != '') {
        this.current_client = new CLiCSClient(data.current_client);
      }
      if (!!data.libraries) {
        for (let library of data.libraries) {
          this.loadLibrary(library);
        }
      }
    }
  }

  // Load a single library by data
  loadLibrary(libraryData: any) {
    this.libraries.push(
      {
        title: libraryData.title,
        token: libraryData.token,
        sharing_mode: libraryData.sharing_mode
      }
    );
  }

  // Pass a list of { title: string, token: string, sharing_mode: string } library descriptors
  loadLibraries(libraryData: any[]) {
    this.libraries = [];
    for (let library of libraryData) {
      this.loadLibrary(library);
    }
  }

  clearLibraries() {
    this.libraries = [];
  }

  loadStr(dataStr: string) {
    let data = JSON.parse(dataStr);
    this.loadObj(data);
  }

  // Used to set the current client for this user
  setClient(client: CLiCSClient) {
    this.current_client = client;
  }

  // Returns the current client for this user or null
  getClient(): CLiCSClient {
    return this.current_client;
  }

  // Sets the client timestamp if a more recent timestamp is passed
  updateClientTimestamp(newClientTimestamp: number) {
    if (newClientTimestamp != null && newClientTimestamp > this.client_ts)
      this.client_ts = newClientTimestamp;
  }

  // Sets the color library timestamp if a more recent timestamp is passed
  updateLibraryTimestamp(newLibraryTimestamp: number) {
    if (newLibraryTimestamp != null && newLibraryTimestamp > this.lib_ts)
      this.lib_ts = newLibraryTimestamp;
  }

  // Records a client that can be retrieved, once, later
  stashClient(client: CLiCSClient) {
    this.stashed_client = client;
  }

  // If there is a stashed client, returns it then clears it
  getStashedClient(): CLiCSClient {
    const client = this.stashed_client;
    this.stashed_client = null;
    return client;
  }

  isChairRenter(): boolean {
    return this.role == 9;
  }

  // Returns true if the user is a staff, receptionist, manager or higher. Includes owner.
  isStaffMember(): boolean {
    return this.role >= 20;
  }
}

