import {Component, Inject} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, ToastController} from '@ionic/angular';
import * as Rollbar from 'rollbar';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {Subscription} from 'rxjs';
import {LabColorPage} from '../lab-color/lab-color';
import {ConversionLibraryPage} from '../conversion-library/conversion-library';
import {FormulaChoicesPage} from '../formula-choices/formula-choices';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSClient} from '../../../lib/client';
import {CLiCSColorSession} from '../../../lib/session';
import {CLiCSFormulaRequest} from '../../../lib/request';
import {RollbarService} from '../../../lib/rollbar';
import {CLiCSRepository} from '../../../lib/repository';
import {CLiCSFormula} from '../../../lib/formula';

/**
 * Generated class for the CreateMenuPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-create-menu',
  templateUrl: 'create-menu.html',
  styleUrls: ['create-menu.scss'],
})
export class CreateMenuPage {
  isModal: boolean = false;
  client: CLiCSClient = null;
  cs: CLiCSColorSession = null;
  loading: any = null;
  bypassReload: boolean = false;  // ignore next call to reload CS
  dispenseOnUpdate: boolean = false;
  useConversionLibrary: boolean = false;
  repo: CLiCSRepository = null;

  targetFormula: any = null;
  targetFormulaWeight: number = null;
  targetLightener: any = null;
  targetLightenerWeight: number = null;
  targetColorLock: any = null;
  targetColorLockWeight: number = null;
  targetDeveloper: any = null;
  devAmount: number = 40;

  additiveOption: string = null; // e.g. 'Include Developer'
  additiveLocation: string = null; // e.g. 'Crown'
  additive: string = null; // unique identifier
  additiveName: string = null; // if set, prompt for additive
  additiveDev: boolean = true; // use developer with lightener
  additiveDevStrength: number = 20; // strength of developer included
  additiveDevAmount: number = 0; // amount of developer included
  minAdditive = 10;
  maxAdditive = 300;

  labTarget: string;

  permissions: any = null;
  settings: any = null;

  unit: string = "g";

  colorSessionUpdatedSubscription: Subscription = null;
  colorSessionQueueSuccessSubscription: Subscription = null;
  colorSessionQueueFailureSubscription: Subscription = null;
  colorSessionUpdateFailureSubscription: Subscription = null;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public clicsService: CLiCSService,
    private events: EventsService,
    private modalCtrl: ModalController,
    private mixingBowl: MixingBowlProvider,
    private taptic: TapticEngine,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    public modeCtrl: ModeControllerProvider,
    @Inject(RollbarService) public rollbar: Rollbar) {
    this.permissions = this.modeCtrl.getPermissions();

    if(!!this.permissions.show_milliliters){
      this.unit = "mL";
    }
  }

  ionViewWillEnter() {
    const _that = this;
    this.isModal = this.navParams.get('view') === 'modal';
    this.repo = this.clicsService.repo;

    // Recall the lab target from local storage
    this.labTarget = localStorage.getItem('labTarget');
    if (!this.labTarget && this.modeCtrl.getAppType() == 'goldwell') {
      this.labTarget = 'Topchic';
    } else {
      if (!!this.labTarget && this.modeCtrl.getAppType() == 'clics') {
        this.labTarget = null;
      }
    }

    // If permitted or forced to load create menu directly, get client and client CS
    if (this.modeCtrl.modeRedirect('createmenu') == false || this.modeCtrl.isPermitted('force_create_menu_page')) {
      if (this.modeCtrl.isSimpleMode()) {
        this.clicsService.selectDefaultClient().then((client) => {
          _that.client = client;
          _that.clicsService.apiGetColorSession(this.client).then(cs => {
            _that.cs = cs;
          });
        });
      } else {
        let client = this.navParams.get('client');
        let cs = this.navParams.get('cs');
        if (!!client && !!cs) {
          this.client = client;
          this.cs = cs;
        } else {
          this.client = this.clicsService.activeClient();
          this.clicsService.apiGetColorSession(this.client).then(cs => {
            _that.cs = cs;
          });
        }
      }
      this._loadRepository();  // Load all colors, get library object, list library titles
    }

    // Initial permissions/settings load, then reads overrides, then loads any changes
    this.permissions = this.modeCtrl.getPermissions();
    this.settings = this.modeCtrl.getCreateMenuSettings();
    this.modeCtrl.init().then(result => {
      _that.permissions = _that.modeCtrl.getPermissions();
      _that.settings = _that.modeCtrl.getCreateMenuSettings();
    });
  }

  ionViewDidEnter() {
    this.colorSessionUpdatedSubscription = this.events.subscribe('color_session:updated', (data) => {
      if (!!data.data && data.data.success) {
        this.cs.updated_at = new Date(data.data.timestamp * 1000);
        this.cs.event_timestamp = data.data.timestamp;
      }
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }

      if (this.dispenseOnUpdate) {
        this.dispenseOnUpdate = false;
        if (this.cs.formulas.length > 0)
          if (this.cs.isQueued())
            this.events.publish('navrequest', {top: 'dispenser', page: 'queue'});
          else
            this.events.publish('color_session:queue', this.cs);
      }
    });

    this.colorSessionUpdateFailureSubscription = this.events.subscribe('color_session:update:failure', (data) => {
      this.confirmWithToast('Something went wrong adding your formula', true);
    });

    this.colorSessionQueueSuccessSubscription = this.events.subscribe('color_session:queue:success', () => {
      this.events.publish('navrequest', {top: 'dispenser', page: 'queue'})
    });

    this.colorSessionQueueFailureSubscription = this.events.subscribe('color_session:queue:failure', (data) => {
      this.confirmWithToast(`Something went wrong queueing your dispense: ${data.message_key}`, true);
    });
  }

  ionViewWillLeave() {
    this.colorSessionUpdatedSubscription = this.events.unsubscribe(this.colorSessionUpdatedSubscription);
    this.colorSessionQueueSuccessSubscription = this.events.unsubscribe(this.colorSessionQueueSuccessSubscription);
    this.colorSessionQueueFailureSubscription = this.events.unsubscribe(this.colorSessionQueueFailureSubscription);
    this.colorSessionUpdateFailureSubscription = this.events.unsubscribe(this.colorSessionUpdateFailureSubscription);
  }

  // Handle a Color Session update event
  handleCSUpdate(data: any) {
  }

  // Adds the CS (APP) item to the edit queue and navigates to the Lab Colors library page
  async addLibraryColor(collectionName: string = null) {
    const _that = this;
    const targetLibrary = this.useConversionLibrary ? ConversionLibraryPage : LabColorPage;

    const addColorModal = await this.modalCtrl.create({
      component: targetLibrary,
      componentProps: {context: 'client', view: 'modal', target: this.labTarget, library: collectionName, single: 'false'},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    addColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      if (!!data.ca && data.ca.formulas.length > 0) {
        for (let formula of data.ca.formulas) {
          formula.setAmount(this.mixingBowl.getDefaultAmount());
          if (!formula.hasDeveloper()) {
            formula.setDeveloper(20, 1.0, this.mixingBowl);
          }
          formula.assertToken(true); // Never use CF token
          formula.assertFormulaText(true);
          formula.pedigree = 'C';
          this.cs.addFormula(formula);
        }
        this.updateAndDispense(this.cs);
      } else {
        if (_that.loading) {
          _that.loading.dismiss();
          _that.loading = null;
        }
        _that.modalCtrl.dismiss();
      }
    });

    await addColorModal.present();
  }

  // Publishes a color_session:update event and blocks server timer checks
  requestUpdate(color_session: any = null) {
    if (!color_session) {
      if (!!this.cs)
        color_session = this.cs;
      else
        color_session = this.clicsService.activeClient().cs;
    }

    if (!!color_session) {
      color_session.reindex();
      this.clicsService.activeClient().cs.updateFromObject(color_session);
      this.clicsService.activeClient().cs.reindex();
      // Ensure new formulas have a token
      for (let fr of color_session.formulas) {
        fr.assertToken();
      }
    }

    this.events.publish('color_session:update', color_session);
  }

  // Open formula choices modal and apply any changed made to the formulas
  async openFormulaChoices(target_formula: CLiCSFormulaRequest, alreadyModified: boolean = false) {
    const _that = this;
    let formula = target_formula;
    if (!!formula) {
      if (formula.notStarted()) {
        const formulaSettingsModal = await this.modalCtrl.create({
          component: FormulaChoicesPage,
          componentProps: {formula: formula, modified: alreadyModified},
          backdropDismiss: true,
          cssClass: this.clicsService.theme,
        });

        formulaSettingsModal.onDidDismiss().then((overlayEvent) => {
          const data = overlayEvent.data;

          if (!!data && data.modified) {
            let formula = data.formula;
            if (!!formula) {
              formula.assertFormulaText(true);
              this.cs.addFormula(formula);
              this.updateAndDispense(this.cs);
            } else {
              const mssg = "Formula modified in Choices was not returned";
              console.error(mssg);
              this.rollbar.error(mssg, {data: data, client: this.client.name()});
              this.confirmWithToast('No formula found', true);
            }
          }
        });

        await formulaSettingsModal.present();
      }
    } else {
      this.taptic.notification({type: "warning"});
      console.log(`Could not open formula choices for formula ${target_formula.token} due to dispense status of ${target_formula.dispense_status}`);
    }
  }

  async goDispense() {
    const _that = this;

    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'queuing your app...',
      duration: 5000
    });
    await this.loading.present();

    this.bypassReload = true;

    // Generate error logs if the CS being queued is null or has no token
    if (!this.cs) {
      this.clicsService.selectDefaultClient().then((client) => {
        this.clicsService.apiGetColorSession(this.clicsService.activeClient()).then((cs) => {
          _that.cs = cs;
          _that.updateAndDispense();
        });
      });
    } else {
      if (!this.cs.token) {
        this.rollbar.warn("Clapp page goDispense(): local CS has no token, retrieving CS token for dispense", {
          local_cs: this.cs,
          system_cs: this.clicsService.activeClient().cs
        });
        this.clicsService.apiGetColorSession(this.client).then((cs) => {
          if (!!cs && !!cs.token) {
            _that.cs.token = cs.token;
            _that.updateAndDispense(this.cs);
          } else {
            this.rollbar.error("Failed to queue CS from CreateMenu page", {cs: this.cs});
          }
        });
      } else {
        this.events.publish('color_session:queue', this.cs);
      }
    }
  }
  // Does a request update and sets a dispense flag
  async updateAndDispense(color_session: any = null) {
    this.dispenseOnUpdate = true;

    if (!this.isModal) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'adding...',
        duration: 6000
      });
      await this.loading.present();
    }

    this.requestUpdate(color_session);
    if (!!this.isModal) {
      this.modalCtrl.dismiss({reset: false});
    }
  }

  // Show a toast with a message
  async confirmWithToast(mssg: string, error = false) {
    let classStr = 'clics-toast';
    if (error) {
      classStr += ' error';
    }

    const duration = error ? 5000 : 3000;
    let toast = await this.toastCtrl.create({
      message: mssg,
      duration: duration,
      cssClass: classStr
    });
    await toast.present();
  }

  // Displays a spinner, allowing for the color library to load
  _loadRepository() {
    // Load local list of library names
    let loading: any = null;

    let tout = setTimeout(async () => {
      loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: `Loading Color ${this.modeCtrl.collectionStr()}`,
        duration: 30000
      });
      await loading.present();
    }, 50);
    this.clicsService.getRepo(true).then((repo) => {
      clearTimeout(tout);
      if (loading)
        loading.dismiss();
    });
  }

  changeLibrary(event: any) {
    if (event.conversion) {
      this.clicsService.getConversionRepo().then((result) => {
        this.useConversionLibrary = true;
        this.repo = result;
      });
    } else {
      this.useConversionLibrary = false;
      this.repo = this.clicsService.repo;
    }
  }

  // Prompts for amount of chosen additive to insert as a formula
  promptForAdditive(componentName: string) {
    const product = this.mixingBowl.findProduct(componentName);
    if (!!product) {
      this.additive = componentName;
      if (product.name == 'Li') {
        this.targetLightener = new CLiCSFormula();
        this.additiveLocation = null;
        this.targetLightenerWeight = 40;
        this.additiveDev = true;
      }
      if (product.name == 'CL') {
        this.targetColorLock = new CLiCSFormula();
        this.additiveLocation = null;
        this.targetColorLockWeight = 40;
        this.additiveDev = false;
      }
    }
  }

  resetAdditivePrompt() {
    this.additiveOption = null
    this.additiveDevStrength = 20
    this.additiveDev = false
    this.targetLightenerWeight = null
    this.targetColorLockWeight = null
    this.targetFormulaWeight = null
    this.targetLightener = null
    this.targetColorLock = null
    this.targetFormula = null
    this.additiveName = null
  }

  // Insert or update a lightener formula, based on the existence of a non-empty target formula
  saveLightenerFormula(data) {
    if (this.targetLightener.isEmpty()) {
      this.insertAdditiveFormula(data);
    }
  }

  // Insert or update a lightener formula, based on the existence of a non-empty target formula
  saveColorLockFormula(data) {
    if (this.targetColorLock.isEmpty()) {
      this.insertAdditiveFormula(data);
    }
  }

  insertAdditiveFormula(data) {
    this.additiveName = null;
    this.additiveOption = null;
    let paramItems: any[] = [];

    const product = this.mixingBowl.findProduct(this.additive);
    let title = product.description;
    let formulaText = `${data.amount}${this.unit} ${product.name}`;
    let location: string = null;

    if (product.name == 'Li') {
      if (data.option) {  // includes developer
        title = ''; // Allow the server to assert the formula text as the title
        formulaText = data.formulaStr;
        location = data.location;
        paramItems = [{
          type: 'compOrig',
          value: product.name,
          mod: `${data.liAmount}`
        }, {
          type: 'compOrig',
          value: data.optionMod,
          mod: `${data.devAmount}`
        }]
      } else {  // does not include developer
        title = 'Li';
        formulaText = data.formulaStr;
        paramItems = [{
          type: 'compOrig',
          value: product.name,
          mod: `${data.liAmount}`
        }]
      }
    } else {
      paramItems = [{
        type: 'compName',
        value: product.name,
        mod: `${data.amount}`
      }]
    }

    let request = new CLiCSFormulaRequest(
      {
        title: title,
        requested: data.amount,
        rgb: '#f0f0f0',
        mode: 'basic',
        formula_text: formulaText,
        location: location,
        params: paramItems
      }
    );
    this.targetLightener = null;
    this.cs.addFormula(request);
    this.updateAndDispense(this.cs);
  }

  closeModal(cancelled: boolean = false) {
    this.modalCtrl.dismiss({reset: false, cancelled: cancelled});
  }

  // Add a developer-only formula to the APP
  promptForDeveloper() {
    this.targetDeveloper = new CLiCSFormula();
    this.devAmount = this.mixingBowl.getDefaultAmount(this.client.client_hair_profile.length) / 2.0;
  }

  async doAddDeveloper(fr) {
    this.targetDeveloper = null;
    this.cs.addFormula(fr);

    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'adding...',
      duration: 6000
    });
    await this.loading.present();

    this.updateAndDispense(this.cs);
  }

  resetApp() {
    this.modalCtrl.dismiss({reset: true});
  }

  setLabTarget(newTarget: string) {
    this.labTarget = newTarget;
    localStorage.setItem('labTarget', this.labTarget);
  }
}
