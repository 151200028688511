import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LabColorPage} from './lab-color';

const routes: Routes = [
  { path: '', component: LabColorPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LabColorPageRoutingModule {}
