import {Component, ViewChild} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController, NavParams, ToastController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {FeedbackCategoriesPage} from '../feedback-categories/feedback-categories';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';

/**
 * Generated class for the FeedbackPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-feedback',
  templateUrl: 'feedback.html',
  styleUrls: ['feedback.scss'],
})
export class FeedbackPage {
  @ViewChild('subjectInput') subjectInput;
  @ViewChild('descriptionInput') desscriptionInput;

  subject: string = '';
  description: string = '';
  category: string = 'general_other';
  topic: string = 'General/Other';
  text_ok: boolean = false;
  email_ok: boolean = false;
  phone_ok: boolean = false;
  loading = null;
  modalDismissSubscription: Subscription = null;
  ready: boolean = false;

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private clicsService: CLiCSService,
              private toastCtrl: ToastController,
              private alertCtrl: AlertController,
              private loadingCtrl: LoadingController,
              private modalCtrl: ModalController,
              private events: EventsService) {
  }

  ionViewWillEnter() {
    this.category = this.navParams.get('category') || 'general_other';
    this.topic = this.navParams.get('topic') || 'General/Other';

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.dismiss();
    });
  }

  changeCategory() {
    this.modalCtrl.create({
      component: FeedbackCategoriesPage,
      componentProps: {category: this.category},
      cssClass: this.clicsService.theme
    }).then((modal) => {
      modal.onDidDismiss().then((overlayEvent) => {
        const data = overlayEvent.data;
        this.category = data.category;
        this.topic = data.topic;
        console.log(`Support category set to ${this.category}`);
      });
      modal.present();
    });
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async sendFeedback() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: '',
      duration: 30000
    });
    await this.loading.present();

    if (this.description != null && this.description != "" && this.subject != null && this.subject != "") {
      this.clicsService.apiAddServiceTicket(this.subject, this.description, this.category, this.text_ok, this.email_ok, this.phone_ok)
        .then((data) => {
          this.modalCtrl.dismiss();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }

          this.alertCtrl.create({
            header: 'Message Sent',
            message: `We'll get back to you shortly`,
            mode: 'ios'
          }).then(alert => alert.present());

          this.description = null;
          this.subject = null;
        });
    }
  }

  readyToSend(): boolean {
    this.ready = (!!this.description && this.description != "" && this.subject && this.subject != "");
    return this.ready;
  }

  updateSubject(event: any) {
    this.subject = event.target.value;
    this.readyToSend();
  }

  updateDescription(event: any) {
    this.description = event.target.value;
    this.readyToSend();
  }
}
