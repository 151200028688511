import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {EventsService} from '../events/events.service';

/*
  Maintains the dispenseMode and provides rules and redirects based on the current mode. Permissions may be over-
  ridden by setting in project-specific JSON files (e.g. branding.json)
*/
@Injectable({
  providedIn: 'root',
})
export class ModeControllerProvider {
  private dispenseMode: string = null;  // 'simple' (no clients) 'advanced' (normal, client list)

  featureMatrix: any[] = [
    {ident: 'create_color', simple: false, advanced: false, expert: true},
    {ident: 'view_all_formulas', simple: false, advanced: false, expert: true},
    {ident: 'customize', simple: false, advanced: false, expert: true},
    {ident: 'access_profile', simple: false, advanced: true, expert: true},
    {ident: 'access_hair', simple: false, advanced: true, expert: true},          // access Hair Profile page
    {ident: 'access_history', simple: false, advanced: true, expert: true},
    {ident: 'access_lab', simple: true, advanced: true, expert: true},
    {ident: 'design_clics', simple: true, advanced: true, expert: true},
    {ident: 'design_goldwell', simple: false, advanced: false, expert: false},
    {ident: 'design_colorance', simple: false, advanced: false, expert: false},
    {ident: 'design_topchic', simple: false, advanced: false, expert: false},
    {ident: 'design_conversions', simple: true, advanced: true, expert: true},
    {ident: 'design_my_colors', simple: true, advanced: true, expert: true},
    {ident: 'access_consultation', simple: true, advanced: true, expert: true},
    {ident: 'use_photos', simple: true, advanced: true, expert: true},
    {ident: 'service_settings', simple: false, advanced: true, expert: true},     // show Settings | Service menu
    {ident: 'quick_dispense', simple: false, advanced: true, expert: true},
    {ident: 'dispense_history', simple: false, advanced: true, expert: true},
    {ident: 'add_collection', simple: false, advanced: false, expert: true},
    {ident: 'show_add_to', simple: false, advanced: false, expert: true},         // display Add To... or Remove... when in Lab Colors
    {ident: 'show_swatch', simple: true, advanced: true, expert: true},           // if true allow user to create swatches
    {ident: 'use_location', simple: false, advanced: true, expert: true},         // hide location formula choice
    {ident: 'show_coverage_choices', simple: true, advanced: true, expert: true}, // in Formula Choices, show Perm Demi Toner options
    {ident: 'show_cobonder_choice', simple: true, advanced: true, expert: true},  // in Formula Choices, show CoBonder switch
    {ident: 'show_lab_filter', simple: true, advanced: true, expert: true},       // in Lab Colors, show the Filter icon
    {ident: 'show_create_sidebar', simple: true, advanced: true, expert: true},   // in Simple Mode Create Menu page, show the navigation sidebar
    {ident: 'show_queue_quick', simple: true, advanced: true, expert: true},      // on Queue page show Pending Quick Dispenses
    {ident: 'show_queue_swatch', simple: true, advanced: true, expert: true},     // on Queue page show Pending Swatches
    {ident: 'show_elapsed_timer', simple: true, advanced: true, expert: true},    // on CLAPP page, show Elapsed timer
    {ident: 'show_rinse_timer', simple: true, advanced: true, expert: true},      // on CLAPP page, show Rinse In timer
    {ident: 'show_starting_level', simple: true, advanced: true, expert: true},   // on CLAPP page show starting level
    {ident: 'force_create_menu_page', simple: false, advanced: false, expert: false}, // launch simple-mode-style create menu page when clicking on Create in CLAPP
    {ident: 'allow_advanced_mode', simple: false, advanced: false, expert: false},   // Allow advanced mode choice if user has proper training level NOTE: advanced mode deprecated 2023
    {ident: 'allow_expert_mode', simple: true, advanced: true, expert: true},     // Allow expert mode choice if user has proper training level
    {ident: 'clics_dev_strengths', simple: true, advanced: true, expert: true},   // Displays CLICS-specific developer strength values in formula choices
    {ident: 'gw_dev_strengths', simple: false, advanced: false, expert: false},   // Displays Goldwell-specific developer strength values in formula choices
    {ident: 'enable_color_scanner', simple: false, advanced: false, expert: false}, // Shows button (iOS only) to invoke the color scanner
    {ident: 'enable_grouped_formulas', simple: false, advanced: false, expert: false}, // Show group icon in place of Design icon when multiple selected
    {ident: 'show_milliliters', simple: false, advanced: false, expert: false},   // Refer to grams (g) as milliliters (mL)
    {ident: 'euro_grey', simple: false, advanced: false, expert: false},          // Use grey instead of gray
    {ident: 'show_pedigree', simple: true, advanced: true, expert: true},         // Show formula pedigree icons
    {ident: 'show_formula_info', simple: true, advanced: true, expert: true},     // Show formula info on CLAPP (COB, PERM)
    {ident: 'show_product_line', simple: false, advanced: false, expert: false}   // Show the product line name
  ];

  // Settings for Create Menu Page, overrides menu creation logic...
  create_menu_settings: any = {
    allow_all_colors: true,
    use_included: false,
    show_color_lock: true,
    show_lightener: true,
    show_developer: true,
    show_reset_app: false,
    included_collections: [],
    excluded_collections: []
  }

  app_preferences: any = {
    collection_title: `Collection`,
    collections_title: 'Collections'
  }

  brand: string = null;       // may be overridden by branding.json
  default_amt: number = 40;   // default formula amount

  constructor(private events: EventsService,
              public http: HttpClient
  ) {
  }

  // Initialize this object. this.brand is used as an "is initialized" flag
  init(force = false): Promise<any> {
    if (!this.brand || force) {
      this.loadFromLocal();
      if (this.dispenseMode == null)
        this.dispenseMode = 'expert';
      return this.loadOverrides('./assets/data/branding.json');
    } else {
      return Promise.resolve(true);
    }
  }

  // Load settings from local storage
  loadFromLocal(): void {
    const val = localStorage.getItem('dispense_mode');
    if (val != null && val != 'null') {
      this.dispenseMode = val;
    }
    if (!this.dispenseMode)
      this.dispenseMode = 'expert';
  }

  saveToLocal(): void {
    localStorage.setItem('dispense_mode', this.dispenseMode);
  }

  // Loads permissions overrides from an external file
  loadOverrides(filePath: string): Promise<any> {
    let _that = this;
    // Load permissions overrides from
    return fetch(filePath).then(res => {
      return res.json();
    }, reason => {
      console.error(reason)
      return Promise.resolve(false);
    }).then(json => {
      // Load brand and default formula amount
      _that.brand = json.brand;
      _that.default_amt = json.default_amt;

      // Load permission overrides
      if (!!json && !!json.permissions) {
        for (let permission of json.permissions) {
          _that.setOverride(permission.ident, permission.simple, permission.advanced, permission.expert);
        }
      }

      // Load create menu settings
      if (!!json) {
        const cm_settings = json.create_menu_settings;
        this.create_menu_settings.allow_all_colors = cm_settings.allow_all_colors;
        this.create_menu_settings.use_included = cm_settings.use_included;
        this.create_menu_settings.show_color_lock = cm_settings.show_color_lock;
        this.create_menu_settings.show_lightener = cm_settings.show_lightener;
        this.create_menu_settings.show_reset_app = cm_settings.show_reset_app;
        this.create_menu_settings.included_collections.length = 0;
        for (let collection of cm_settings.included_collections) {
          this.create_menu_settings.included_collections.push(collection.title);
        }
        this.create_menu_settings.excluded_collections.length = 0;
        if (!!cm_settings.excluded_collections) {
          for (let collection of cm_settings.excluded_collections) {
            this.create_menu_settings.excluded_collections.push(collection.title);
          }
        }
      }

      if (!!json) {
        const app_prefs = json.app_preferences;
        this.app_preferences.collection_title = app_prefs.collection_title;
        this.app_preferences.collections_title = app_prefs.collections_title;
      }

      return Promise.resolve(true);
    }, reason => {
      console.error(reason)
      return Promise.resolve(false);
    });
  }

  getDispenseMode(): string {
    return this.dispenseMode;
  }

  // Set the dispense mode and issue an event. Quiet = true suppresses the dispense_mode:change event. x
  setDispenseMode(new_mode: string, quiet: boolean = false): string {
    if (['simple', 'advanced', 'expert'].includes(new_mode)) {
      this.dispenseMode = new_mode;
      this.saveToLocal();
      if (!quiet) {
        this.events.publish('dispense_mode:change');
      }
    }
    return this.dispenseMode;
  }

  // If a page cannot be accessed in the current mode, redirect accordingly
  modeRedirect(page_ref: string): boolean {
    let redirected: boolean = false;
    switch (page_ref) {
      case 'lab_app':
      case 'convert':
      case 'pics':
        if (this.isSimpleMode()) {
          redirected = true;
          this.events.publish('navrequest', {top: 'createmenu', page: 'createmenu'});
        }
        break;
      case 'history':
      case 'application':
      case 'profile':
      case 'clients':
      case 'quick':
      case 'dispensed':
        if (this.isSimpleMode()) {
          redirected = true;
          this.events.publish('navrequest', {top: 'dispenser', page: 'queue'});
        }
        break;
      case 'admin_service':
        if (this.isSimpleMode()) {
          redirected = true;
          this.events.publish('navrequest', {top: 'admin', page: 'admin_profile'});
        }
        break;
      case 'createmenu':
        if (this.notSimpleMode() && !this.isPermitted('force_create_menu_page')) {
          redirected = true;
          this.events.publish('navrequest', {top: 'autoclient'});
        }
        break;
      case 'create':
        if (this.isSimpleMode()) {
          redirected = true;
          this.events.publish('navrequest', {top: 'dispenser', page: 'queue'});
        } else {
          if (this.dispenseMode == 'Advanced') {
            redirected = true;
            this.events.publish('navrequest', {top: 'lab', page: 'lab_color'});
          }
        }
    }
    return redirected;
  }

  // Indicates an allowed action or feature based on ident string for that feature
  isPermitted(feature: string): boolean {
    if (!feature) {
      return true;
    }
    const match = this.featureMatrix.find((el) => {
      return el.ident == feature;
    });
    if (!!match) {
      return match[this.dispenseMode];
    }
    return false;
  }

  // Returns an object with permissions as boolean params
  getPermissions(): any {
    let result: object = {mode: this.dispenseMode};
    for (let feature of this.featureMatrix) {
      result[feature.ident] = feature[this.dispenseMode];
    }
    return result;
  }

  isSimpleMode(): boolean {
    return this.dispenseMode == 'simple'
  }

  notSimpleMode(): boolean {
    return this.dispenseMode != 'simple'
  }

  isExpertMode(): boolean {
    return this.dispenseMode == 'expert';
  }

  // Sets a permission override flag. This value takes precedence over
  setOverride(ident: string, simple: boolean, advanced: boolean, expert: boolean): void {
    let match = this.featureMatrix.find((el) => {
      return el.ident == ident;
    });
    if (!!match) {
      match.simple = simple;
      match.advanced = advanced;
      match.expert = expert;
    }
  }

  // Returns settings for the Create Menu page
  getCreateMenuSettings(): any {
    return this.create_menu_settings;
  }

  getAppType(): string {
    return this.brand || 'clics';
  }

  // Returns the preferred string for what we call a "Collection" (or "Family" or "Library")
  collectionStr(downCase: boolean = false): string {
    if (downCase) {
      return (this.app_preferences.collection_title.toLowerCase());
    } else {
      return (this.app_preferences.collection_title);
    }
  }

  // Returns the preferred string for what we call "Collections" (or "Families" or "Libraries")
  collectionsStr(downCase: boolean = false): string {
    if (downCase) {
      return (this.app_preferences.collections_title.toLowerCase());
    } else {
      return (this.app_preferences.collections_title);
    }
  }
}
