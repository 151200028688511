import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {WelcomeSlidesPage} from './welcome-slides';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [
    IonicModule,
    SwiperModule
  ],
  declarations: [WelcomeSlidesPage],
})
export class WelcomeSlidesPageModule {}
