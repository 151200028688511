import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Shows a basic formula with color chiclet as in a list
 */
@Component({
  selector: 'formula-item',
  templateUrl: 'formula-item.html',
  styleUrls: ['formula-item.scss'],
})
export class FormulaItemComponent {
  @Input() formula: CLiCSFormulaRequest;
  @Input() unit: string = "g";
  @Output() openColorDetail = new EventEmitter();
  @Output() handleSelectChange = new EventEmitter();

  permissions: any = null;
  settings: any = null;

  constructor(
    private modeCtrl: ModeControllerProvider,
    ) {
    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
      this.permissions = this.modeCtrl.getPermissions();
    });

    if(!!this.permissions.show_milliliters){
      this.unit = "mL";
    }
  }

  doOpenDetail() {
    this.openColorDetail.emit({formulaToken: this.formula.token});
  }

  selectionChanged() {
    this.handleSelectChange.emit({selected: this.formula.selected});
  }
}
