import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

/**
 * Generated class for the DispenseProductComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'dispense-developer',
  templateUrl: 'dispense-developer.html',
  styleUrls: ['dispense-developer.scss', '../dispense-product/dispense-product.scss'],
})
export class DispenseDeveloperComponent implements OnInit {
  @Input() unit: string = "g";
  @Input() start: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() action = 'disp';
  @Input() showOz: boolean = true;
  @Output() requestDispense = new EventEmitter();

  valStr = "";  // used for display
  value: number = 0;
  ozVal: number = 0.0;
  ozValStr: string = "";
  productName: string = "";

  strength: number = 20;

  repeatTimer: any = null;
  waitInterval: number = 500;
  repeatInterval: number = 85;

  permissions: any = null;
  settings: any = null;

  constructor(
    private mixingBowl: MixingBowlProvider,
    private modeCtrl: ModeControllerProvider,
    ) {
    this.value = this.start;

    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
        this.permissions = this.modeCtrl.getPermissions();
    });

    if(!!this.permissions.show_milliliters){
        this.unit = "mL"
    }
  }

  ngOnInit() {
    this.value = this.start;
    this._update();
  }

  // Update display strings
  _update() {
    this.valStr = `${this.value}${this.unit}`;
    if (this.showOz) {
      this.ozVal = this.value * 0.035274;
      this.ozValStr = `(${this.ozVal.toFixed(2)}oz)`;
    }
  }

  startIncrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doIncrement();
      }, this.waitInterval);
    } else {
      this.endIncrement();
    }
  }

  doIncrement() {
    if (this.repeatTimer) {
      this.incrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doIncrement();
    }, this.repeatInterval);
  }

  endIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  startDecrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doDecrement();
      }, this.waitInterval);
    } else {
      this.endDecrement();
    }
  }

  doDecrement() {
    if (this.repeatTimer) {
      this.decrVal();
    }
    this.repeatTimer = setTimeout(() => {
      this.doDecrement();
    }, this.repeatInterval);
  }

  endDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  startStrIncrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doStrIncrement();
      }, this.waitInterval);
    } else {
      this.endStrIncrement();
    }
  }

  doStrIncrement() {
    if (this.repeatTimer) {
      this.incrStrength();
    }
    this.repeatTimer = setTimeout(() => {
      this.doStrIncrement();
    }, this.repeatInterval);
  }

  endStrIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  startStrDecrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doStrDecrement();
      }, this.waitInterval);
    } else {
      this.endStrDecrement();
    }
  }

  doStrDecrement() {
    if (this.repeatTimer) {
      this.decrStrength();
    }
    this.repeatTimer = setTimeout(() => {
      this.doStrDecrement();
    }, this.repeatInterval);
  }

  endStrDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  incrVal() {
    this.value += 1;
    if (this.value > this.max)
      this.value = this.max;
    this._update();
  }

  decrVal() {
    this.value -= 1;
    if (this.value < this.min)
      this.value = this.min;
    this._update();
  }

  incrStrength() {
    this.strength += 1;
    if (this.strength > 40)
      this.strength = 40;
  }

  decrStrength() {
    this.strength -= 1;
    if (this.strength < 0)
      this.strength = 0;
  }

  updateStrength(data) {
    this.strength = data.amount;
  }

  updateVal(data) {
    this.value = data.amount;
    this._update();
  }

  // Convert the requested product to a formula request and emit the request
  doRequestDispense() {
    if (this.value > 0.0) {
      let fractionalName = `D${this.strength.toFixed(0)}`;
      let realProducts = this.mixingBowl.getRealFromFractional(fractionalName, this.value);

      if (realProducts.length > 0) {
        let fr = new CLiCSFormulaRequest(
          {
            "title": fractionalName,
            "amount": this.value,
            "mode": 'basic',
            "formula_text": "",
          });

        // Add in the products
        for (let item of realProducts) {
          fr.addParam('compName', item.product.name, item.weight.toFixed(2));
          fr.addParam('compId', item.product.token, item.weight.toFixed(2));
          if (fr.formula_text != "")
            fr.formula_text += " + ";
          fr.formula_text += `${item.weight.toFixed(2)}${this.unit} ${item.product.name}`;
        }

        this.requestDispense.emit(fr);
      }
    }
  }

}
