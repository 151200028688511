import {Component} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import {FeedbackPage} from '../feedback/feedback';
import {CLiCSService} from '../../services/clics.service';

/**
 * Generated class for the FeedbackCategoriesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-feedback-categories',
  templateUrl: 'feedback-categories.html',
  styleUrls: ['feedback-categories.scss'],
})
export class FeedbackCategoriesPage {
  category: string = 'general_other';
  topic: string = "General";  // public readable category string
  newRequest: boolean = null;

  constructor(
    private navCtrl: NavController,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private clicsService: CLiCSService)
  {
    const initialCategory = this.navParams.get('category');
    if (!!initialCategory) {
      this.category = initialCategory;
    }
    this.newRequest = this.navParams.get('newRequest');
  }

  dismiss(cancel: boolean = false) {
    this.modalCtrl.dismiss({category: this.category, topic: this.topic})
    if (!cancel && !!this.newRequest) {
      this.modalCtrl.create({
        component: FeedbackPage,
        componentProps: {category: this.category, topic: this.topic},
        cssClass: this.clicsService.theme,
      }).then(modal => modal.present());
    }
  }

  updateTopic(event: any) {
    switch (event.detail.value) {
      case 'general':
        this.topic = 'General/Other';
        break;
      case 'general_other':
        this.topic = 'General/Other';
        break;
      case 'mobile_app_howto':
        this.topic = 'Mobile App How-To';
        break;
      case 'formula_education':
        this.topic = 'Formula/Education';
        break;
      case 'technical_support':
        this.topic = 'Technical Support';
        break;
      case 'inventory':
        this.topic = 'Inventory';
        break;
      case 'billing_invoices':
        this.topic = 'Billing/Invoices';
        break;
      default:
        this.topic = event;
        break;
    }
  }
}
