/* This page is a stand-alone, optionally modal, hair profile page. It has been modified from the original
*  ProfilePage so that the header and footer could be replaced here
*/

import {Component, ViewChild} from '@angular/core';
import {ActionSheetController, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {CLiCSClient} from '../../../lib/client';
import {CLiCSUser} from '../../../lib/user';
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";

@Component({
  selector: 'page-hair-profile',
  templateUrl: 'hair-profile.html',
  styleUrls: ['hair-profile.scss'],
})
export class HairProfilePage {
  readonly levelMin: number = 1;
  readonly levelMax: number = 12;
  client: CLiCSClient = null;
  clientName: string = "";
  disabled: boolean = true;  // Don't update the server profile while disabled

  clientHairProfile: any = {
    density: '',
    hair_type: '',
    length: '',
    percent_gray: '',
    porosity: '',
    starting_level: this.levelMin,
    precolor: false,
    updated_at: ''
  };
  showSetLevelPrompt: boolean = false;
  modalDismissSubscription: Subscription = null;
  permissions: any = null;

  @ViewChild('precolor_cb') precolorCheckbox;

  constructor(public clicsService: CLiCSService,
              private modalCtrl: ModalController,
              private events: EventsService,
              private actionSheetCtrl: ActionSheetController,
              private modeCtrl: ModeControllerProvider,
              private mixingBowl: MixingBowlProvider) {
    this.client = this.clicsService.current_user.getClient();

    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();
    this.modeCtrl.init().then(result => {
      this.permissions = this.modeCtrl.getPermissions();
    });
  }

  ionViewWillEnter() {
    this.disabled = true;
    this.clientName = this.clicsService.clientName();
    this._getClientHairProfile();

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
        this.closeModal();
    });
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  _getClientHairProfile() {
    // const profile = await this.clicsService.apiGetClientHairProfile(this.client);
    if (this.client) {
      this.clientHairProfile = this.client.client_hair_profile;
      let upDate = new Date(this.clientHairProfile.updated_at);
      this.clientHairProfile.updated_at = !!this.clientHairProfile.updated_at ? upDate.toDateString() : '';
      this.events.publish('color_session:level'); // Update the level in the color session
      this.disabled = false;
    }
  }

  // This method will only have a parameter value if it is emitted from the
  // "dispense-confirm" component, and it will be a new value for "starting_level"
  updateProfile(key, $event: any) {
    let value = $event;
    if (key != 'starting_level') {
      value = $event.detail.value;
    }
    console.log(`update hair profile  Key: ${key} Val: ${value}  Disabled: ${this.disabled ? "Yes" : "No"}`);
    switch(key) {
      case 'density': {
        this.clientHairProfile.density = value;
        break;
      }
      case 'hair_type': {
        this.clientHairProfile.hair_type = value;
        break;
      }
      case 'length': {
        this.clientHairProfile.length = value;
        break;
      }
      case 'percent_gray': {
        this.clientHairProfile.percent_gray = value;
        break;
      }
      case 'porosity': {
        this.clientHairProfile.porosity = value;
        break;
      }
      case 'starting_level': {
        this.clientHairProfile.starting_level = parseFloat(value);
        this.events.publish('color_session:level', this.clientHairProfile.starting_level);
        break;
      }
      case 'precolor': {
        this.clientHairProfile.precolor = this.precolorCheckbox.checked;
        break;
      }
      default: {
        break;
      }
    }

    if (this.disabled == false) {
      this.clicsService.apiUpdateClientHairProfile(this.client, this.clientHairProfile);
      this.showSetLevelPrompt = false;
    }
  }

  // Opens select to prompt for
  async promptForStartingLevel() {
    var buttons = [];

    const current_user: CLiCSUser = this.clicsService.current_user;
    const min_level = this.modeCtrl.getAppType() == 'goldwell' ? 2 : 1;
    const max_level = this.modeCtrl.getAppType() == 'goldwell' ? 10 : 11;
    if (current_user) {
      for (let i = max_level; i >= min_level; i--) {
        buttons.push({
          text: this.mixingBowl.getLevelString(i, this.modeCtrl.getAppType() == 'goldwell'),
          value: i,
          handler: () => {
            this.updateProfile("starting_level", String(i));
          }
        });
      }

      buttons.push({
        text: "Cancel",
        role: 'cancel'
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Select Starting Level',
        buttons: buttons,
        mode: 'ios'
      });
      await actionSheet.present();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  getEventValue(event: any): any {
    return event.details.value;
  }
}
