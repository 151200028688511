import {Component} from '@angular/core';
import {NavController, NavParams, Platform, ModalController} from '@ionic/angular';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {FlashMessage} from '../../../lib/flash';
import {CLiCSUser} from '../../../lib/user';
import {PageControllerProvider} from '../../services/page-controller/page-controller';
import {CLiCSService} from '../../services/clics.service';

/**
 * Generated class for the LoginTextPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-login-text',
  templateUrl: 'login-text.html',
  styleUrls: ['login-text.scss'],
})
export class LoginTextPage {
  flash: FlashMessage = null;
  user: CLiCSUser = null;
  message: string = null;
  use_alt: boolean = false;

  codeRequested: boolean = false;
  code: string = "";
  phone: string = "";
  app_mode: string = '';

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private pageCtrl: PageControllerProvider,
              private platform: Platform,
              private keyboard: Keyboard,
              private modalCtrl: ModalController,
              private clicsService: CLiCSService) {
    this.flash = this.clicsService.flash;
  }

  ionViewWillEnter() {
    this.user = this.clicsService.current_user;
    this.app_mode = this.clicsService.app_mode;
    if (this.app_mode == null)
      this.app_mode = 'personal';
  }

  requestCode(): void {
    this.clicsService.apiSendLoginCode(this.phone).then((data) => {
      if (!!data.success) {
        this.codeRequested = true;
      } else {
        this.use_alt = true;
        if (data.matches == 0) {
          this.message = "We could not find a login associated with that phone number. To proceed, please press Cancel then choose Log In with Password."
        } else {
          if (data.matches > 1)
            this.message = "Multiple logins were found associated that phone number. To proceed, please press Cancel then choose Log In with Password."
          else
            this.message = "Sorry - something went wrong. Please try again or press Cancel then choose Log In with Password."
        }
      }
    });
  }

  doLogin(): void {
    this.modalCtrl.dismiss({cancelled: false, code: this.code});
  }

  closeKbdIfDone(event, phone = false, code = false) {
    if (event.key == 'Enter') {
      if (this.platform.is('cordova') || this.platform.is('ios') || this.platform.is('android')) {
        this.keyboard.hide();
      }
      if (phone) {
        this.requestCode();
      } else {
        if (code) {
          this.doLogin();
        }
      }
      return false;
    }
  }

  recordCode(event: any) {
    this.code = event.target.value;
  }

  recordPhone(event: any) {
    this.phone = event.target.value;
  }

  // Returns the correct logo image file for the currently selected theme
  logoImage() {
    if (this.clicsService.theme == 'dark')
      return 'large_logo_white.png';
    else
      return 'large_logo.png';
  }

  cancel() {
    this.modalCtrl.dismiss({cancelled: true, email: '', password: ''})
  }

  notReady(): boolean {
    return (!this.phone || this.phone == '');
  }

}
