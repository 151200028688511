import {HttpClient} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {AlertController, PopoverController} from '@ionic/angular';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {ModeControllerProvider} from '../mode-controller/mode-controller';

/*
  This checks for a message based on the stored message version, updates the local version number, and displays
  the message if a newer one if found.
*/
@Injectable({
  providedIn: 'root',
})
export class MessagingProvider {
  private messageVersion: number = null;
  modeCtrl: ModeControllerProvider = null;

  constructor(
    public http: HttpClient,
    private alertCtrl: AlertController,
    private injector: Injector,
    private appVersion: AppVersion) {
    this.modeCtrl = this.injector.get(ModeControllerProvider);
  }

  // Check for a new message and, if found, display it
  // TODO: handle comparison of versioning
  checkMessaging(endpoint: string, sess: string, displayIfAvailable: boolean = true): Promise<any> {
    const _that = this;
    this._getMessageVersion();
    return this.apiGetMessaging(this.messageVersion || 0, endpoint, sess).then((data) => {
      if (!!data && data.success) {
        const existingMessage = _that.getMessage(false);
        if (!existingMessage || !!existingMessage && existingMessage.version < data.version) {
          if (!_that.messageVersion || data.version >= _that.messageVersion) {
            _that.appVersion.getVersionNumber().then((ver) => {
              if ((!data.max_release || ver <= data.max_release)) {
                localStorage.setItem('messaging_obj', JSON.stringify(data));
              } else {
                _that._updateMessageVersion(data.version);
              }
            }, (reason) => {
              // Likely not a Cordova platform
              console.log("Not a Cordova platform, messaging ignored");
              _that._updateMessageVersion(data.version);
            });
          }
          // Displays newly retrieved message or previously stored message
          if (displayIfAvailable) {
            this.displayMessageIfAvailable().then(data => {
              return Promise.resolve(data);
            });
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve(null);
        }
      } else {
        return Promise.resolve(null);
      }
    });
  }

  // IF a message was retrieved this returns that message as an object, or null. Message is then cleared.
  getMessage(deleteMessage: boolean = true) {
    const val = localStorage.getItem('messaging_obj');
    let message = null;
    if (!!val && val != 'null')
      message = JSON.parse(val);
    if (!!message && deleteMessage) {
      localStorage.removeItem('messaging_obj');
    }
    return message;
  }

  // Takes a message version, an API endpoint, and the current session token and requests any new message.
  apiGetMessaging(lastVersion: number, endpoint: string, sess: string): Promise<any> {
    const _that = this;
    const appType = this.modeCtrl.getAppType();
    return this.http.get(endpoint + '/api/control_panel/get_messaging',
      {
        params: {sess: sess, version: `${lastVersion}`, apptype: appType}
      })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        return false;
      });
  }

  _getMessageVersion() {
    const val = localStorage.getItem('messaging_ver');
    if (!!val && val != 'null')
      this.messageVersion = parseInt(val);
  }

  _updateMessageVersion(newVersion: number) {
    this.messageVersion = newVersion;
    localStorage.setItem('messaging_ver', `${this.messageVersion}`);
  }

  // Display the message on the current page
  async displayMessageIfAvailable() {
    const data: any = this.getMessage(false);
    if (!!data) {
      if (data.message.length > 0 || data.title.length > 0) {
        const timeNow = (new Date().getTime() / 1000).toFixed(0);
        if (!data.reveal_at || data.reveal_at < timeNow) {
          const alert = await this.alertCtrl.create({
            header: data.title,
            message: data.message,
            cssClass: 'messaging-popover',
            buttons: [
              {
                text: 'remind me later',
                handler: value => {
                  this.remindMe(data);
                }
              },
              {
                text: 'OK',
                handler: value => {
                  this._updateMessageVersion(data.version);  // Update local version since we've now acknowledged it
                  this.clearMessage();
                }
              }
            ],
            mode: 'ios'
          });
          await alert.present();
        }
      }
    }
  }

  // Clear the message from local memory
  clearMessage() {
    localStorage.removeItem('messaging_obj');
  }

  remindMe(messageData: any) {
    const oneDayLater = ((new Date().getTime() / 1000) + 86400).toFixed(0);
    messageData.reveal_at = oneDayLater;
    localStorage.setItem('messaging_obj', JSON.stringify(messageData));
    this._updateMessageVersion(messageData.version - 1);
  }

}
