/*
    Common base class for CLiCSColorApplication and CLiCSColorSession.
    NOTE: data type for formulas[] redefined in inherited classes
 */

import {CLiCSAppType} from "./app-type";
import {CLiCSFormula} from "./formula";
import {CLiCSFormulaRequest} from "./request";
import {MixingBowlProvider} from "../app/services/mixing-bowl/mixing-bowl";

export class CLiCSApplication {
  token: string = null;
  title: string = null;
  starting_level: number = null;
  app_type_ident: string = null;
  app_type_name: string = null;
  formulas: Array<CLiCSFormula> = [];
  top_group: number = null; // highest group_id presently in use for this CS

  constructor(data: any, preserveToken: boolean = false) {
    if (data != null) {
      if (typeof data === 'object')
        this.loadObj(data, preserveToken);
      else {
        if (typeof data === 'string') {
          this.loadStr(data, preserveToken);
        }
      }
    }
  }

  loadObj(data: any, preserveToken: boolean = false) {
    if (data !== undefined) {
      if ('token' in data && preserveToken == false)
        this.token = data.token;
      if ('title' in data)
        this.title = data.title;
      if ('app_type_ident' in data)
        this.app_type_ident = data.app_type_ident;
      if ('app_type_name' in data)
        this.app_type_name = data.app_type_name;
      if ('top_group' in data)
        this.top_group = data.top_group;
      if (data.starting_level)
        this.setStartingLevel(data.starting_level);
      this.assertDefaultAppTypeName();
    }
  }

  loadStr(dataStr: string, preserveToken: boolean = false) {
    let data = JSON.parse(dataStr);
    this.loadObj(data, preserveToken);
  }

  // If no app type is specified the default name is "Library Colors"
  assertDefaultAppTypeName() {
    if ((this.app_type_name == null || this.app_type_name == '') && this.app_type_ident == null) {
      this.app_type_name = 'Library Colors';
    }
  }

  // Clears the data from this app / color session
  clear(preserveToken: boolean = false) {
    if (preserveToken == false)
      this.token = null;
    this.title = null;
    this.app_type_ident = null;
    this.app_type_name = null;
    this.starting_level = null;
    this.formulas.length = 0;
  }

  // Sets the starting level associated with this app
  setStartingLevel(new_level: any): number {
    new_level = parseFloat(new_level);
    if (new_level < 0) new_level = 0.0;
    if (new_level > 12) new_level = 12.0;
    this.starting_level = parseFloat(new_level.toFixed(1));
    return this.starting_level;
  }

  // returns a default is not set
  getStartingLevel(): number {
    if (this.starting_level == null)
      this.starting_level = 6.0;
    return this.starting_level;
  }

  // Sets app type from object
  setAppType(new_type: CLiCSAppType) {
    if (new_type) {
      this.app_type_ident = new_type.ident;
      this.app_type_name = new_type.name;
    } else {
      this.app_type_ident = null;
      this.app_type_name = null;
    }
    this.assertDefaultAppTypeName();
  }

  // Call this to ensure a positive amount
  assertAmounts(defaultAmount: number = null, mixingBowlProvider: any = null) {
    let minAmount: number = null;
    for (let formula of this.formulas) {
      minAmount = null;
      if (!!mixingBowlProvider) {
        minAmount = mixingBowlProvider.minAllowableWeight(formula);
      }
      if (!!minAmount && minAmount > defaultAmount) {
        formula.assertAmount(minAmount);
      } else {
        formula.assertAmount(defaultAmount);
      }
    }
  }

  // Call this to ensure a positive amount
  assertMinimums(mixingBowlProvider) {
    let minAmount: number = null;
    for (let formula of this.formulas) {
      minAmount = mixingBowlProvider.minAllowableWeight(formula);
      if (!!minAmount && minAmount > formula.amount) {
        formula.setAmount(minAmount);
      }
    }
  }

  // Call this to limit bowl dispense amount
  assertMaximums(mixingBowlProvider) {
    const maxAmount: number = mixingBowlProvider.maxAllowableBowlWeight();
    for (let formula of this.formulas) {
      if (!!maxAmount && formula.amount > maxAmount) {
        formula.setAmount(maxAmount);
      }
    }
  }

  // Find a matching formula and remove it from this CA. If can't match by token attempts to math by request_ident
  removeFormula(formula_token: string, request_ident: string = null): void {
    let index = -1;
    index = this.findFormulaIndex(formula_token, request_ident);
    if (index >= 0)
      this.formulas.splice(index, 1);
  }

  // Find the index of a formula in the formulas array. Returns -1 if not found, else index in array
  findFormulaIndex(token: string, request_ident: string = null): number {
    let index: number = -1;

    // Attempt to match by valid token
    if (token != null && token.trim() != "")
      index = this.formulas.findIndex(el => (el.token == token));

    // If not found, attempt to find by request_ident (supported by Formula Request)
    if (index < 0 && request_ident != null)
      index = this.formulas.findIndex(el => (el.request_ident == request_ident));

    return index;
  }

  // Searches this CS for a formula matching the passed token or request_ident (FRs only)
  findFormula(token: string, request_ident: string = null): CLiCSFormula {
    const index = this.findFormulaIndex(token, request_ident);
    if (index >= 0)
      return this.formulas[index];
    else
      return null;
  }

  isBlank(): boolean {
    return (this.formulas.length == 0 && (this.title == null || this.title == '') && (this.starting_level == 6 || this.starting_level == null));
  }

  isEmpty(): boolean {
    return this.formulas.length == 0;
  }

  // Names getting wonky but this means, essentially, "unedited" - avoids overloaded CS isBlank()
  isClear(): boolean {
    return (this.formulas.length == 0 && (this.title == null || this.title == '') && (this.starting_level == 6 || this.starting_level == null));
  }

  // Sorts the formulas by the ordinal value for each formula
  sortByOrdinal() {
    this.formulas.sort((a, b) => {
      return (a.ordinal - b.ordinal);
    });
  }

  isColorSession() {
    return false;
  }

  // Groups all formulas in this CS by assigning a >0 group_id to the formulas. This works in one of two ways. If no
  // formulas have a positive group_id assigned yet then all formulas are assigned the passed group_id. If any formulas
  // have the special 999 group ID then these only are assigned the next higher group_id. Alternately, an intended
  // group_id like 999 may be passed to override the next logic.
  // If mixingBowl is set the developer on all grouped formulas will be made the same. This is default for grouped
  // formulas. Set mixingBowl to null to allow separate formulas.
  groupUngroupedFormulas(group_id = null, mixingBowl: MixingBowlProvider = null, limit: number = null) {
    let highest = 0;
    let selectOnly = false;
    let nextGroupId = 0;

    for (let formula of this.formulas) {
      if (!!formula.group_id) {
        if (formula.group_id == 999) {
          selectOnly = true;
        } else {
          if (formula.group_id > highest) {
            highest = formula.group_id;
          }
        }
      }
    }
    // Determine the value to assign to all unassigned or special 999 formulas
    if (!!group_id) {
      nextGroupId = group_id;
    } else {
      nextGroupId = highest + 1;
    }

    // Assign chosen value to special or all ungrouped formulas
    for (let formula of this.formulas) {
      if (selectOnly) {
        if (formula.group_id == 999) {
          formula.group_id = nextGroupId;
        }
      } else {
        if (!formula.group_id || formula.group_id == 0) {
          formula.group_id = nextGroupId;
        }
      }
    }

    // make the formulas have the same
    if (!!mixingBowl && !!nextGroupId && nextGroupId > 0) {
      const group = this.formulas.filter(el => el.group_id);
      if (group.length > 1) {
        let formula = group[0];
        for (let matey of group) {
          if (matey.token == formula.token) {
            continue;
          }
          matey.setDeveloperFromFormula(formula, mixingBowl);
        }
      }
    }
  }

  // Returns true if these formulas includes a group. If "special" then only return true if there's a group that includes
  // group_id 999.
  hasGroups(special: boolean = false): boolean {
    let result = false;
    for (let formula of this.formulas) {
      if (special) {
        if (!!formula.group_id && formula.group_id == 999) {
          result = true;
          break;
        }
      } else {
        if (!!formula.group_id && formula.group_id > 0) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  // Returns the next (unused) group number to use for grouped formulas. CS.top_group is returned from the server. Also
  // looks at the local formulas in case a higher group_id has been used.
  nextGroup(): number {
    let curTopGroup = this.top_group || 0;
    for (const fr of this.formulas) {
      if (!!fr.group_id && fr.group_id < 999 && fr.group_id > curTopGroup) {
        curTopGroup = fr.group_id;
      }
    }
    return curTopGroup + 1;
  }

  className(): string {
    return 'CLiCSApplication'
  }
}
