import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'clics-icon',
  templateUrl: './clics-icon.component.html',
  styleUrls: ['./clics-icon.component.scss'],
})
export class ClicsIconComponent implements OnInit, OnChanges {
  @Input() useIcon: boolean = true;
  @Input() iconOnly: boolean = false;
  @Input() theme: string = 'light';
  @Input() reverse: boolean = false; // reverse the theme for icon images
  @Input() text: string = '?';
  @Input() active: boolean = false; // If this object is actively selected images need to choose active version
  @Input() special: boolean = false; // Use special text color #C0365f. Overrides active color.
  @Input() gw: boolean = false; // Use Goldwell icon red if active

  label: string = '';  // Label is the text to display. Allows two values of [text] to display the same label
  isDual: boolean = false;
  isImage: boolean = false;
  source: string = null;
  darkImg: string = null;
  lightImg: string = null;

  // Look up the icon from the text. Dual indicates that both text and icon should be displayed
  actions: any[] = [
    {text: 'ADD TO...', type: 'img', src: 'add_to.png', dual: false},
    {text: 'ADD TO', type: 'img', src: 'add_to.png', dual: false},
    {text: 'ADD PHOTO', type: 'img', src: 'add_photo.png', dual: false},
    {text: 'APPLICATION', type: 'img', src: 'application.png', dual: false},
    {text: 'BACK', type: 'icon', src: 'arrow-back-outline', dual: false},
    {text: 'CANCEL', type: 'icon', src: 'close-outline', dual: false},
    {text: 'CLEAR', type: 'icon', src: 'trash-outline', dual: false},
    {text: 'CLICS', type: 'icon', src: 'ribbon-outline', dual: true},
    {text: 'CLIENTS', type: 'icon', src: 'people-outline', dual: false},
    {text: 'CLIENTS@NAV', type: 'icon', src: 'people-circle-outline', dual: false},
    {text: 'CLICS<SUP>&reg;</SUP>', type: 'icon', src: 'ribbon-outline', dual: true},
    {text: 'CLICS<SUP>®</SUP>', type: 'icon', src: 'ribbon-outline', dual: true},
    {text: 'CLOSE', type: 'icon', src: 'close-circle-outline', dual: false},
    {text: 'COLORANCE', type: 'img', src: 'colorance-mock.png', dual: true},
    {text: 'COMBINE', type: 'img', src: 'group.png', dual: false},
    {text: 'COMPARE', type: 'icon', src: 'bookmark-outline', dual: false},
    {text: 'CONSULT', type: 'img', src: 'consultation.png', dual: false},
    {text: 'CONSULTATION', type: 'img', src: 'consultation.png', dual: false},
    {text: 'CONVERSIONS', type: 'icon', src: 'git-compare-outline', dual: true},
    {text: 'CREATE', type: 'img', src: 'create.png', dual: false},
    {text: 'DELETE', type: 'icon', src: 'trash-outline', dual: false},
    {text: 'DESIGN', type: 'img', src: 'design.png', dual: false},
    {text: 'DESIGN@NAV', type: 'icon', src: 'color-palette', dual: false},
    {text: 'DISPENSE', type: 'img', src: 'dispense.png', dual: false},
    {text: 'DISPENSED', type: 'icon', src: 'albums-outline', dual: false},
    {text: 'DISPENSER', type: 'icon', src: 'water', dual: false},
    {text: 'EDIT', type: 'icon', src: 'create-outline', dual: false},
    {text: 'EDUCATION', type: 'icon', src: 'school-outline', dual: false},
    {text: 'FAST DISPENSE', type: 'icon', src: 'rocket-outline', dual: false},
    {text: 'GOLDWELL', type: 'icon', src: 'ribbon-outline', dual: true},
    {text: 'HAIR', type: 'img', src: 'hair.png', dual: false},
    {text: 'HELP', type: 'icon', src: 'help-circle-outline', dual: false},
    {text: 'HISTORY', type: 'img', src: 'history.png', dual: false},
    {text: 'INVENTORY', type: 'img', src: 'inventory.png', dual: false},
    {text: 'INVOICES', type: 'icon', src: 'cash-outline', dual: false},
    {text: 'LIST', type: 'icon', src: 'list-outline', dual: false},
    {text: 'MAKE PROFILE', type: 'img', src: 'make_profile.png', dual: false},
    {text: 'MODIFIED', type: 'img', src: 'modified.png', dual: false},
    {text: 'MY ACCOUNT', type: 'icon', src: 'person-circle-outline', dual: true},
    {text: 'MY COLORS', type: 'icon', src: 'diamond-outline', dual: true},
    {text: 'NOTES', type: 'img', src: 'notes.png', dual: false},
    {text: 'PHOTO', type: 'icon', src: 'camera-outline', dual: false},
    {text: 'PROFILE', type: 'img', src: 'profile.png', dual: false},
    {text: 'QUEUE', type: 'icon', src: 'layers-outline', dual: false},
    {text: 'QUICK', type: 'icon', src: 'paper-plane-outline', dual: false},
    {text: 'REGISTER', type: 'icon', src: 'bar-chart-outline', dual: false},
    {text: 'REMOVE...', type: 'img', src: 'remove.png', dual: false},
    {text: 'REMOVE', type: 'icon', src: 'trash-outline', dual: false},
    {text: 'REPLACE', type: 'img', src: 'replace.png', dual: false},
    {text: 'REPORTS', type: 'icon', src: 'document-text-outline', dual: false},
    {text: 'RESET', type: 'img', src: 'reset.png', dual: false},
    {text: 'RINSE', type: 'img', src: 'rinse.png', dual: false},
    {text: 'RINSING', type: 'img', src: 'rinsing.png', dual: false}, // TODO: add a duplicate for DRY or STYLE
    {text: 'SAVE', type: 'icon', src: 'save-outline', dual: false},
    {text: 'SOFT', type: 'icon', src: 'add-outline', dual: false},
    {text: 'SERVICE', type: 'img', src: 'hair2.png', dual: false},
    {text: 'SETTINGS', type: 'img', src: 'settings.png', dual: false},
    {text: 'SETTINGS@FOOTER', type: 'icon', src: 'cog', dual: false},
    {text: 'SHOW ALL', type: 'icon', src: 'apps-outline', dual: false},
    {text: 'SIGN OUT', type: 'icon', src: 'log-out-outline', dual: false},
    {text: 'SLIDES', type: 'icon', src: 'albums-outline', dual: false},
    {text: 'SUPPORT', type: 'icon', src: 'chatbubbles-outline', dual: false},
    {text: 'SWATCH', type: 'icon', src: 'eyedrop-outline', dual: false},
    {text: 'TOPCHIC', type: 'img', src: 'topchic_mock.png', dual: true},
    {text: 'USE', type: 'icon', src: 'checkmark-outline', dual: false}
  ]

  constructor() { }

  ngOnInit() {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  // Reload after a possible change of inputs
  update() {
    const matched = this.actions.find((el) => {
      return(el.text == this.text.toUpperCase());
    });
    if (!!matched) {
      // Strip out anything past an @ sign
      const regex = /([^@]+)(@.+)?/
      const matchedText = this.text.match(regex);
      if (!!matchedText) {
        this.label = matchedText[1];
      } else {
        this.label = this.text;
      }
      this.isDual = (matched.dual && this.useIcon == false);
      this.isImage = (matched.type == 'img');
      if (matched.type == 'icon') {
        this.source = matched.src;
      } else {
        if (this.special) {
          this.source = `/assets/images/icons/special/${matched.src}`;
        } else {
          const activePath = !!this.gw ? 'activegw' : 'active';
          if ((this.theme == 'dark' && this.reverse == false) || (this.theme == 'light' && this.reverse == true)) {
            if (this.active) {
              this.source = `/assets/images/icons/${activePath}/${matched.src}`;
            } else {
              this.source = `/assets/images/icons/dark/${matched.src}`;
            }
          } else {
            if (this.active) {
              this.source = `/assets/images/icons/${activePath}/${matched.src}`;
            } else {
              this.source = `/assets/images/icons/light/${matched.src}`;
            }
          }
        }
      }
    } else {
      this.label = this.text;
      this.isDual = false;
      this.isImage = false;
      this.useIcon = false;
      this.source = 'help-outline';
    }
  }

}
