import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {PageControllerProvider} from '../../services/page-controller/page-controller';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSClient} from '../../../lib/client';

/**
 * Generated class for the NavigationBarComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'navigation-bar',
  templateUrl: 'navigation-bar.html',
  styleUrls: ['navigation-bar.scss'],
})
export class NavigationBarComponent implements OnInit {
  text: string;
  theme: string = 'light';
  current_root: string = 'clients';
  client_name: string = '';
  nav_button_classes = {};
  nav_button_active = {};
  first = true;
  stagingServerSelected: boolean = false;
  permissions: any = null;
  clientSelectedSubscription: Subscription = null;
  navigationEndSubscription: Subscription = null;

  enabled: boolean = true;
  enableTimer: any = null;

  full_view: boolean = true;  // False = Limited View for Jensen & Co.

  constructor(
    public clicsService: CLiCSService,
    private router: Router,
    public events: EventsService,
    private pageCtrl: PageControllerProvider,
    public modeCtrl: ModeControllerProvider
  ) {
    this.nav_button_classes = {
      "clients": "bar-button bar-button-md bar-button-default bar-button-default-md",
      "dispenser": "bar-button bar-button-md bar-button-default bar-button-default-md",
      "createmenu": "bar-button bar-button-md bar-button-default bar-button-default-md",
      "lab": "bar-button bar-button-md bar-button-default bar-button-default-md",
      "lookbook": "bar-button bar-button-md bar-button-default bar-button-default-md",
      "signout": "bar-button bar-button-md bar-button-default bar-button-default-md"
    };
    this.nav_button_active = {
      "clients": true,
      "dispenser": false,
      "createmenu": false,
      "lab": false,
      "lookbook": false,
      "signout": false
    };
    this.navigationEndSubscription = this.router.events.subscribe((event: any) => {
      if (event.constructor.name == 'NavigationEnd') {
        this.setNavButtonClasses();
      }
    });

    this.setNavButtonClasses();
    this.stagingServerSelected = this.clicsService.endpoint_description == 'staging';
  }

  ngOnInit() {
    if (this.clicsService.clientIsSelected()) {
      this.client_name = this.clicsService.clientName();
    }
    this.stagingServerSelected = this.clicsService.endpoint_description == 'staging';
    this.permissions = this.modeCtrl.getPermissions();
    this.modeCtrl.init().then(result => {
      this.permissions = this.modeCtrl.getPermissions();
    });
    this.theme = this.clicsService.getTheme();
  }

  ionViewWillEnter() {
    this.clientSelectedSubscription = this.events.subscribe('client:selected', (client: CLiCSClient) => {
      console.log("#$%#$% Client Selected");
      if (client)
        this.client_name = `${client.name()}`;
      else
        this.client_name = '';
    });
  }

  ionViewDidEnter() {
    this.theme = this.clicsService.getTheme();
  }

  ionViewDidLeave() {
    this.clientSelectedSubscription = this.events.unsubscribe(this.clientSelectedSubscription);
  }

  doNavigation(route_name: string) {
    if (!this.enabled) {
      return;
    }
    this.current_root = route_name;
    if (route_name == 'autoclient')
      this.pageCtrl.autoNavigateToClient();
    else
      this.pageCtrl.doTopNavigation(route_name);
  }

  // Returns a class string for top-level nav collections
  setNavButtonClasses() {
    let curRoot = this.pageCtrl.getCurrentRoot();
    for (let key in this.nav_button_classes) {
      if (this.nav_button_classes.hasOwnProperty(key)) {
        if (key == curRoot) {
          this.nav_button_classes[key] = "bar-button bar-button-md bar-button-default bar-button-default-md active";
          this.nav_button_active[key] = true;
        } else {
          this.nav_button_classes[key] = "bar-button bar-button-md bar-button-default bar-button-default-md";
          this.nav_button_active[key] = false;
        }
      }
    }
  }

  // Returns the correct logo image file for the currently selected theme
  logoImage() {
    if (this.clicsService.theme == 'dark') {
      return 'header_logo_white.png';
    } else {
      return 'header_logo.png';
    }
  }

  goHomePage() {
    if (!this.enabled) {
      return;
    }
    this.events.publish('navrequest', {top: 'clients', page: 'clients'});
  }

  // Disables navigation for no more than 30 seconds
  disableNavigation() {
    this.enableTimer = setTimeout(() => {
      this.enableNavigation();
    }, 30000);
    this.enabled = false;
  }

  // Re-enables navigation
  enableNavigation() {
    this.enabled = true;
    if (!!this.enableTimer) {
      clearTimeout(this.enableTimer);
      this.enableTimer = null;
    }
  }

  stagingSelected(): boolean {
    return this.clicsService.endpoint_description == 'staging';
  }
}
