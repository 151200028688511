import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateMenuPage} from './create-menu';

const routes: Routes = [
  { path: '', component: CreateMenuPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateMenuPageRoutingModule {}
