import {Component} from '@angular/core';
import {NavParams, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClientNote} from '../../../lib/client-note';

/**
 * Create or edit a client note
 *
 */
@Component({
  selector: 'page-client-note-add',
  templateUrl: 'client-note-add.html',
  styleUrls: ['client-note-add.scss'],
})
export class ClientNoteAddPage {
  note: CLiCSClientNote = new CLiCSClientNote();
  noteText: string = "";  // Editable text string

  clientName: string = null;
  title: string = "Add Note";
  editable: boolean = false;
  token: string = null;

  modalDismissSubscription: Subscription = null;

  constructor(private navParams: NavParams,
              private clicsService: CLiCSService,
              private modalCtrl: ModalController,
              private events: EventsService) {
  }

  ionViewWillEnter() {
    this.clientName = this.navParams.get('clientname');
    this.note = this.navParams.get('note');

    if (this.note == undefined) {
      this.note = new CLiCSClientNote();
    }

    if (this.note.token != null) {
      this.editable = true;
      this.title = "Edit Note";
      this.noteText = this.clicsService.current_user.getClient().getNote(this.note.token).noteText;
    } else {
      this.editable = false;
      this.title = "Add Note";
    }

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  closeModal(data = null) {
    this.modalCtrl.dismiss(data);
  }

  saveNoteForSelectedClient() {
    if (this.noteText.length > 0) {
      this.note.setText(this.noteText);
      this.note.setDate();
      this.closeModal({ action: 'save', note: this.note });
      this.noteText = "";
    } else {
      this.closeModal();
    }
  }

  deleteNote() {
    // this.clicsService.apiDeleteClientNote(this.note.token);
    this.closeModal({action: 'delete', note: this.note});
    this.noteText = "";
  }

  setNoteText(event: any) {
    if (!!event.detail.value) {
      this.noteText = event.detail.value;
    }
  }
}
