import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/**
 * Generated class for the AmountSliderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'amount-slider',
  templateUrl: 'amount-slider.html',
  styleUrls: ['amount-slider.scss'],
})
export class AmountSliderComponent implements OnInit{
  @Input() amount: number = 0;
  @Input() min: number = 20;
  @Input() max: number = 300;
  @Input() showTitle: boolean = true;
  @Input() title: string = 'Total Amount';
  @Input() step: number = 1;
  @Input() restrictMax: boolean = false;
  @Input() noGradient: boolean = false;
  @Input() unit: string = "g";
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  inputValueStr: string = null;
  valueChanged: boolean = false;

  repeatTimer: any = null;
  waitInterval: number = 500;
  repeatInterval: number = 85;

  constructor() {
  }

  ngOnInit() {
    this.min = parseFloat(`${this.min}`);
    this.max = parseFloat(`${this.max}`);
    this.step = parseFloat(`${this.step}`);
    if (this.amount < this.min) {
      this.amount = this.min;
    }
  }

  onAmountChange() {
    this.inputValueStr = `: ${this.amount}${this.unit}`;
    this.valueChanged = true;
    if (!!this.onChange)
      this.onChange.emit({amount: this.amount, min: this.min, max: this.max});
  }

  // Get the current value of the thingy
  getAmount(): number {
    return this.amount;
  }

  // Get the current value of the thingy
  setAmount(amount: number) {
    if (amount < this.min) {
      amount = this.min;
    }
    if (amount > this.max) {
      amount = this.max;
    }
    if (this.amount != amount) {
      this.amount = amount;
      this.onAmountChange();
    }
  }

  // Indicates the value was changed since this was instantiated
  wasChanged(): boolean {
    return this.valueChanged;
  }

  startIncrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doIncrement();
      }, this.waitInterval);
    } else {
      this.endIncrement();
    }
  }

  doIncrement() {
    if (this.repeatTimer) {
      this.increment();
    }
    this.repeatTimer = setTimeout(() => {
      this.doIncrement();
    }, this.repeatInterval);
  }

  endIncrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  increment() {
    this.amount += this.step;
    if (this.restrictMax && this.amount > this.max) {
      this.amount = this.max;
    }
    this.onAmountChange();
  }

  startDecrement() {
    if (this.repeatTimer == null) {
      this.repeatTimer = setTimeout(() => {
        this.doDecrement();
      }, this.waitInterval);
    } else {
      this.endDecrement();
    }
  }

  doDecrement() {
    if (this.repeatTimer) {
      this.decrement();
    }
    this.repeatTimer = setTimeout(() => {
      this.doDecrement();
    }, this.repeatInterval);
  }

  endDecrement() {
    if (this.repeatTimer) {
      clearTimeout(this.repeatTimer);
      this.repeatTimer = null;
    }
  }

  decrement() {
    this.amount -= this.step;
    if (this.amount < this.min) {
      this.amount = this.min;
    }
    this.onAmountChange();
  }
}
