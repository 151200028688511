import {Component, OnInit, OnDestroy, EventEmitter, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {HelpPage} from '../../pages/help/help';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {PageControllerProvider} from '../../services/page-controller/page-controller';

/**
 * Generated class for the PageFooterComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'page-footer',
  templateUrl: 'page-footer.html',
  styleUrls: ['page-footer.scss'],
})
export class PageFooterComponent implements OnInit, OnDestroy {
  current_root: string = 'undefined';
  navigationChangedSubscription: Subscription = null;
  activeClass: string = 'none';
  theme: string = 'light';

  @Input() isModal: boolean = false;
  @Input() pageRoute: string = null;
  @Output() closeModalNotify: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private events: EventsService,
    private router: Router,
    private pageCtrl: PageControllerProvider,
    private modalCtrl: ModalController,
    public clicsService: CLiCSService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const routeName = this.pageCtrl.current_route.route;
      if (!!routeName) {
        this.activeClass = routeName;
      } else {
        this.activeClass = 'none';
      }
    });
  }

  ngOnInit() {
    if (!!this.pageRoute) {
      this.current_root = this.pageRoute;
    } else {
      this.navigationChangedSubscription = this.events.subscribe('navigation:changed', (route_name) => {
        this.current_root = route_name;
      });
    }
    this.theme = this.clicsService.getTheme();
  }

  ionViewWillEnter() {
    this.theme = this.clicsService.getTheme();
  }

  ngOnDestroy() {
    if (this.pageRoute == undefined) {
      this.navigationChangedSubscription = this.events.unsubscribe(this.navigationChangedSubscription);
    }
  }

  // Show a help modal page
  async showHelp() {
    const helpModal = await this.modalCtrl.create({
      component: HelpPage,
      componentProps: {pageName: this.pageCtrl.getCurrentPageRef()},
      backdropDismiss: false,
    });

    await helpModal.present();
  }

  doNavigation(route_name: string) {
    if (this.isModal) {
      try {
        this.closeModalNotify.emit('closeModal');
      } catch (e) {
        console.warn('caught closeModalNotify exception')
      }
    }
    this.pageCtrl.doTopNavigation(route_name);
  }

  doPageNavigation(page_name: string) {
    if (this.isModal) {
      try {
        this.closeModalNotify.emit('closeModal');
      } catch (e) {
        console.warn('caught closeModalNotify exception')
      }
    }
    this.pageCtrl.doPageNavigation(page_name);
  }

  doSettingsNavigation(modal: boolean = false) {
    this.pageCtrl.doSettingsNavigation();
  }

  // Depending on the app state, go to signon page or the salon select page
  goStartPage() {
    this.clicsService.clearClient();
    this.pageCtrl.goStartPage();
  }

  doGoBack() {
    if (this.isModal) {
      try {
        this.closeModalNotify.emit('closeModal');
        this.events.publish('navigation:changed', this.pageCtrl.getCurrentRoot());
        this.events.publish('page:changed', this.pageCtrl.getCurrentPageRef());
      } catch (e) {

      }
    } else {
      this.current_root = '';
      this.pageCtrl.doBackNavigation();
    }
  }

  canGoBack(): boolean {
    return(this.pageCtrl.history.length > 1);
  }
}
