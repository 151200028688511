import {Component, Input} from '@angular/core';
import {ActionSheetController, AlertController, ModalController, NavController, NavParams, Platform, ToastController} from '@ionic/angular';
import {ActionSheet, ActionSheetOptions} from '@awesome-cordova-plugins/action-sheet/ngx';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {Subscription} from 'rxjs';
import {ColorDetailPage} from '../color-detail/color-detail';
import {CreateColorPage} from '../create-color/create-color';
import {FormulaChoicesPage} from '../formula-choices/formula-choices';
import {LabColorPage} from '../lab-color/lab-color';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSFormula} from '../../../lib/formula';
import {CLiCSColorApplication} from '../../../lib/color-application';
import {CLiCSUser} from '../../../lib/user';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSRepository} from '../../../lib/repository';
import {CLiCSColorFormula} from "../../../lib/color-formula";

/**
 * Generated class for the CreateAppPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-create-app',
  templateUrl: 'create-app.html',
  styleUrls: ['create-app.scss'],
})
export class CreateAppPage {
  context: string;
  action: string;

  editItem: EditQueueItem = null;
  ca: CLiCSColorApplication = null;
  formulas: CLiCSColorFormula[] = [];
  library_token: string = null;
  startingLevel: number = 6;

  levels: any[] = [];
  pageTitle: string = "New App";
  appTypeValue: string = null;

  basicSettings: boolean = false;  // Whether the basic settings are satisfied and we can continue
  editMode: boolean = false;       // In edit mode the app page is revealed and basic setting hidden
  dirty: boolean = false;

  modalDismissSubscription: Subscription = null;

  @Input() title: string;

  constructor(private navCtrl: NavController,
              private navParams: NavParams,
              private clicsService: CLiCSService,
              private events: EventsService,
              private keyboard: Keyboard,
              private alertCtrl: AlertController,
              private toastCtrl: ToastController,
              private modalCtrl: ModalController,
              public  mixingBowl: MixingBowlProvider,
              private actionSheetCtrl: ActionSheetController,
              private editQueue: EditQueueProvider,
              private actionSheet: ActionSheet,
              private plt: Platform,
              private modeCtrl: ModeControllerProvider) {
    this.levels = this.mixingBowl.getStartingLevels();
  }

  ionViewWillEnter() {
    console.log(`Create Application entered with context: ${this.context}`);
    if (this.action != 'formula choices') {
      this.dirty = false; // Dirty flag used for dismiss warning

      this.action = 'idle';
      this.context = this.navParams.get('context');
      console.log(`Create Application context set to ${this.context}`);

      // NOTE: @@@ refactor
      // attempt to get CA from nav params, else check Edit Queue
      this.ca = this.navParams.get('ca');
      if (!this.ca) {
        this.editItem = this.editQueue.discover('APP');  // TODO: do we need to cast to CLiCSColorApplication in getEditAppItem?
        if (this.editItem == null) {
          // Check for a returned USE FORMULA ITEM
          this.editItem = this.editQueue.getUseFormulaItem();
          if (this.editItem) {
            let returned_formula = this.editItem.itemObject;
            // Now attempt to reload a prior APP object
            this.editItem = this.editQueue.discover('APP');
            if (this.editItem != null) {
              // FOUND! update or add the color formula in the returned formula object
              returned_formula.title += ' (modified)';
              this.editItem.itemObject.saveAppFormula(returned_formula, returned_formula.sumOfParts());
            }
          } else {
            // Nothing in queue, create a new EQI / CA
            this.editItem = this.editQueue.createNew('CLiCSColorApplication');
            console.log("No queued CA item found in create-app; creating new queued CA object")
          }
        }
        if (this.editItem != null) {
          console.log(`>>> Create App page using ${this.editItem.toString()}`);
          this.ca = this.editItem.itemObject;
        } else
          throw 'CreateAppPage::ionViewWillEnter() - unable to load an APP object';
      }

      this.library_token = null;
      if (!!this.ca) {
        console.log(`Using CA with title ${this.ca.title}, has ${this.ca.formulas.length} formulas`);
        this.formulas = this.ca.formulas.sort((a, b) => {
          return (a.ordinal - b.ordinal);
        });
      }

      // Allow calling page to pass a starting level
      let newLevel = this.navParams.get('startingLevel');
      if (newLevel) {
        if (this.ca != null) {
          this.setStartingLevel(newLevel);
          this.startingLevel = this.ca.getStartingLevel();
        }
        else
          this.startingLevel = parseFloat(newLevel);
      }
    }

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      if (this.context && (this.context != "any" && this.context != ""))
        this.closeModal();
    });
  }

  ionViewDidEnter() {
    if (this.ca != null) {
      this.startingLevel = this.ca.getStartingLevel();
      this.title = this.ca.title;
      this.appTypeValue = this.ca.app_type_ident;
    }
    this.setPageTitle();
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  customizing() {
    return(this.editQueue.currentAction('APP') === 'EDIT');
  }


  // Place the current APP in the Edit Queue and navigate to the Lab Colors page
  async doAddColor() {
    this.editQueue.push(this.ca, 'EDIT');

    const addColorModal = await this.modalCtrl.create({
      component: LabColorPage,
      componentProps: {context: 'client', view: 'modal'},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    addColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;

      if (data.modified) {
        this.dirty = true;
      }
    });

    await addColorModal.present();
  }

  // Place the current APP in the Edit Queue and navigate to the Lab Colors page
  async doCreateColor() {
    this.editQueue.pushItem(this.editItem, 'EDIT');

    const createColorModal = await this.modalCtrl.create({
      component: CreateColorPage,
      componentProps: {context: 'client'},
      backdropDismiss: false,
      cssClass: this.clicsService.theme,
    });

    createColorModal.onDidDismiss().then((overlayEvent) => {
      const data = overlayEvent.data;
      if (data.modified) {
        this.dirty = true;
      }
    });

    await createColorModal.present();
  }

  chooseAndSave() {
    if (this.library_token)
      this.doSaveApp();
    else
      this.chooseLibrary();
  }

  async chooseLibrary() {
    let current_user: CLiCSUser = this.clicsService.current_user;
    if (current_user) {
      var buttons = [];

      const libs = this.clicsService.repo.ownedLibraries(current_user)
      for (let library of libs) {
        buttons.push({
          text: library.title,
          handler: () => {
            this.setLibraryTokenAndSave(library.token);
          }
        });
      }

      if (this.modeCtrl.isPermitted('add_collection')) {
        buttons.push({
          text: `Add new color ${this.modeCtrl.collectionStr(true)}...`,
          role: 'destructive',
          handler: () => {
            this.actionSheetCtrl.dismiss().then(() => {
              this.addLibrary();
            });
          }
        });
      }

      buttons.push({
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          this.toastCtrl.create({
            message: 'App save cancelled',
            duration: 3000,
            position: 'bottom'
          }).then(toast => toast.present());
        }
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: `Choose ${this.modeCtrl.collectionStr()} to Save To`,
        buttons: buttons,
        mode: 'ios'
      });

      await actionSheet.present();
    }
  }

  // Set the library token and continue with save operation
  setLibraryTokenAndSave(token: string) {
    this.library_token = token;
    this.doSaveApp();
  }

  async addLibrary() {
    const alert = await this.alertCtrl.create({
      header: `Create New ${this.modeCtrl.collectionStr()}`,
      inputs: [
        {
          name: 'title',
          type: 'text',
          label: `${this.modeCtrl.collectionStr()} Name`,
          placeholder: `Enter name for new ${this.modeCtrl.collectionStr(true)}`,
          value: ''
        }
      ],
      buttons: [
        {
          text: `Create ${this.modeCtrl.collectionStr()}`,
          handler: (data) => {
            this.alertCtrl.dismiss().then(() => {
              this.createLibraryAndSave(data.title);
            });
          }
        },
        {
          text: 'Cancel',
          role: "cancel",
          handler: () => {
            this.alertCtrl.dismiss().then(() => {
              this.chooseLibrary();
            });
          }
        }
      ],
      backdropDismiss: true,
      mode: 'ios'
    });
    await alert.present();
  }

  createLibraryAndSave(libraryTitle: string) {
    if (libraryTitle && libraryTitle !== "") {
      this.clicsService.apiAddUserLibrary(libraryTitle).then((result) => {
        if (result.success) {
          this.library_token = result.library.token;
          this.doSaveApp();
        } else {
          this.chooseLibrary();
        }
      });
    }
  }

  doSaveApp() {
    this.clicsService.getRepo().then((result) => {
      let repo: CLiCSRepository = result;
      if (repo == null || repo.nameExistsInOwnedLibraries(this.ca.title)) {
        this.alertCtrl.create({
          header: "Name Already in Use",
          subHeader: `It appears that this name is already in use in your color collection. Please choose a different name for this application`,
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());
      } else {
        this.clicsService.apiSaveColorApplication(this.ca, this.library_token).then((app) => {
          if (app) {
            let message: string = 'App Saved';
            this.ca.id = app.id;
            if (this.ca.token && this.ca.token === app.token)
              message = 'App Updated';
            else {
              this.ca.token = app.token;
              this.clicsService.repo.addApplication(app, this.library_token);
              this.clicsService.saveRepo();
            }

            this.alertCtrl.create({
              header: message,
              subHeader: `Your app was successfully saved to your color ${this.modeCtrl.collectionStr(true)}`,
              buttons: ['Ok'],
              mode: 'ios'
            }).then(alert => alert.present());

            this.clicsService.clearCurrentColorApplication();
            if (this.context == 'library')
              this.modalCtrl.dismiss({modified: true});
            else
              this.events.publish('navrequest', {top: "lab", page: 'lab_app'});
          } else {
            this.alertCtrl.create({
              header: "Oops...",
              subHeader: "Sorry! Something went wrong when trying to save your app. Please check that the app has a valid name, starting level, and app type and try again.",
              buttons: ['Ok'],
              mode: 'ios'
            }).then(alert => alert.present());
          }
        });
      }
    });
  }

  // True if there are components and
  readyToUse() {
    return(this.ca && this.ca.starting_level && this.ca.app_type_ident && this.formulas.length > 0);
  }

  // True if there are components and
  readyToSave() {
    return(this.readyToUse() && this.ca.title != null && this.ca.title != "");
  }

  async confirmUseApp() {
    var buttons = [
      {
        text: 'Select a client to use this app for...',
        handler: () => {
          this.doUseApp(true);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
      }
    ];

    if (this.clicsService.clientIsSelected()) {
      buttons.unshift({
        text: `Use this app for ${this.clicsService.clientName()}`,
        handler: () => {
          this.doUseApp();
        }
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: buttons,
      mode: 'ios'
    });

    await actionSheet.present();
  }

  // Stage app for use with current or next client
  doUseApp(selectNewClient: boolean = false) {
    this.modalCtrl.dismiss({modified: true, ca: this.ca, action: 'EDIT'});
  }

  setPageTitle() {
    if (this.ca && this.ca.title && this.ca.title != '')
      if (this.ca.token)
        this.pageTitle = `<span>App:</span> ${this.ca.title}`;
      else
        this.pageTitle = `<span>Creating:</span> ${this.ca.title}`;
    else
      this.pageTitle = 'New App'
  }

  // prevents unwanted keys from being recorded, handle Enter key
  closeKbdIfNameDone(event) {
    if (event.key == 'Enter') {
      this.keyboard.hide();
      return false;
    }
  }

  updateAppTitle() {
    if (this.ca) {
      this.ca.title = this.title.trim();
      this.setPageTitle();
      // this.reviewBasicSettings();
    }
  }

  setAppType(data) {
    this.ca.setAppType(this.mixingBowl.findAppType(data));
    // this.reviewBasicSettings();
  }

  setStartingLevel(data) {
    this.ca.setStartingLevel(parseFloat(data));
  }

  revealAppDetails() {
    this.editMode = true;  // editMode reveals app edit panel
    if (this.ca.token)
      this.pageTitle = `<span>App:</span> ${this.ca.title}`;
    else
      this.pageTitle = `<span>Creating:</span> ${this.ca.title}`;
    // this.clicsService.setUseLabApp();
  }

  async confirmReset() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Reset',
      message: 'Clear the current app and start over?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.resetCS();
          }
        }
      ],
      mode: 'ios'
    });

    await alert.present();
  }

  resetCS() {
    this.ca.clear();
    this.formulas = [];
    this.title = null;
    this.setPageTitle();
    this.editMode = false;
    this.appTypeValue = null;
    this.startingLevel = this.ca.getStartingLevel();
  }

  reorderItems(event) {
    let ord = 1;
    this.formulas = event.detail.complete(this.formulas);
    for (let formula of this.formulas) {
      formula.ordinal = ord;
      ord += 1;
    }
    this.dirty = true;
  }

  async openLocationChoices(params) {
    // let formula = this.clicsService.findClientFormula(params.formula_token);
    let formula = this.ca.findFormula(params.formula_token);
    if (formula) {
      var inputs: any[] = [];

      const locations: any[] = this.mixingBowl.getLocations();
      const null_location_value = locations[0].value;
      for (let location of locations) {
        inputs.push({
          type: 'radio',
          label: location.label,
          value: location.value,
          checked: (formula.location == null && location.value == null_location_value) || formula.location === location.value
        });
      }

      const alert = await this.alertCtrl.create({
        header: 'Choose Location',
        inputs: inputs,
        buttons: [
          {
            text: "Cancel",
            handler: data => {}
          },
          {
            text: "Choose",
            handler: data => {
              if (data == null_location_value)
                formula.setLocation(null);
              else
                formula.setLocation(data);
            }
          }
        ],
        mode: 'ios'
      });
      await alert.present();
    }
  }

  //
  async openFormulaChoices(data) {
    if (this.ca) {
      let formula = this.ca.findFormula(data.formula_token, data.request_ident);

      this.action = 'formula choices';

      // MIGRATION NOTE: modal options were not passed at time of update
      // backdropDismiss: true,
      // cssClass: this.clicsService.theme
      const formulaSettingsModal = await this.modalCtrl.create({
        component: FormulaChoicesPage,
        componentProps: {context: this.context, formula: formula},
      });

      formulaSettingsModal.onDidDismiss().then((overlayEvent) => {
        const data = overlayEvent.data;

        if (data) {
          this.action = 'idle';
          let current_formula = this.ca.findFormula(data.formula.token, data.formula.request_ident);
          if (current_formula) {
            current_formula.loadObj(data.formula);
            current_formula.assertFormulaText(true);
            current_formula.updateInfo();
          }

          // If major or minor change to the formula, tell the server
          if (data && (data.modified || data.dirty)) {
            this.dirty = true;
          }
        }

        this.formulas = this.ca.formulas.sort((a, b) => {
          return (a.ordinal - b.ordinal);
        });
      });

      await formulaSettingsModal.present();
    }
  }

  async openColorDetailModal(data) {
    this.clicsService.stashData({cf: this.ca.findFormula(data.formula_token), action: "SHOW"});

    const colorDetailModal = await this.modalCtrl.create({
      component: ColorDetailPage,
      backdropDismiss: true,
      cssClass: this.clicsService.theme,
    });

    await colorDetailModal.present();
  }

  // Remove a formula from this color session. FR is destroyed on server
  removeFormula(formula: CLiCSFormula): void {
    this.ca.removeFormula(formula.token, formula.request_ident);
    this.formulas = this.ca.formulas;
    this.dirty = true;
  }

  // NOTE: this is no longer used... an app type select page/panel will replace the "basic mode" panel
  // Are the required settings provided so we can enter edit mode?
  reviewBasicSettings() {
    this.basicSettings = (this.ca.title != null && this.ca.title != "" && this.ca.app_type_ident != null);
  }

  setEditMode(value: boolean) {
    this.editMode = value;
    if (this.editMode == false)
      this.reviewBasicSettings();
  }

  // Discard the item being edited and return to the prior page
  cancelCustomize() {
    if (this.customizing()) {
      let navInfo = this.editQueue.expireItem(this.editItem);
      if (navInfo.success)
        this.events.publish('navrequest', {top: navInfo.topNav, page: navInfo.pageNav});
      else
        this.events.publish('navrequest', {page: 'back'});
    }
  }

  // Update the object in the queue and navigate back to the calling page
  // TODO: change this to use EditQueue::editAndReturn()
  updateCustomize() {
    if (this.customizing()) {
      this.editItem.update(this.ca, null, null, 'use');
      this.editQueue.edit(this.editItem);
      this.modalCtrl.dismiss({modified: true});
    }
  }

  // Determines if cordova is running and chooses the best display option
  showCreateChoice() {
    if (this.plt.is('cordova'))
      this.showCreateChoiceNative();
    else
      this.showCreateChoiceIonic();
  }

  // Open an action sheet with choices for editing the client app
  showCreateChoiceNative() {
    let buttonLabels = ['Color Collection', 'Create New Color'];

    let options: ActionSheetOptions = new class implements ActionSheetOptions {
      addCancelButtonWithLabel: string = 'Cancel';
      addDestructiveButtonWithLabel: string;
      androidEnableCancelButton: boolean = true;
      androidTheme: 1 | 2 | 3 | 4 | 5 = 1;
      buttonLabels: string[] = buttonLabels;
      destructiveButtonLast: boolean = false;
      position: [number, number];
      subtitle: string = '';
      title: string = 'Add Color Choices';
      winphoneEnableCancelButton: boolean = true;
    };

    this.actionSheet.show(options).then((buttonIndex: number) => {
      switch (buttonIndex) {
        case 1:
          this.doAddColor();
          break;
        case 2:
          this.doCreateColor();
          break;
        default:
          break;
      }
    });
  }

  async showCreateChoiceIonic() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'App Creation Choices',
      mode: 'ios',
      buttons: [
        {
          text: `Color ${this.modeCtrl.collectionStr()}`,
          handler: () => {
            this.doAddColor();
          }
        },
        {
          text: 'Create New Color',
          handler: () => {
            this.doCreateColor();
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });

    await actionSheet.present();
  }

  // If edits were made, prompt to discard changes
  async confirmCancel() {
    if (this.dirty) {
      let mssg: string = 'Discard all changes and close this page?';

      if (this.context == 'library')
        mssg = 'Discard all changes and return to application collections?';
      if (this.context == 'client')
        mssg = 'Discard all changes and return to the client application?';

      const alert = await this.alertCtrl.create({
        header: 'Confirm Cancel',
        message: mssg,
        buttons: [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.closeModal();
            }
          }
        ],
        mode: 'ios'
      });
      await alert.present();
    } else {
      this.closeModal();
    }
  }


  // Close modal view, return unmodified result
  closeModal() {
    this.dirty = false;
    this.modalCtrl.dismiss({modified: false, ca: null, action: 'EDIT'});
  }

}
