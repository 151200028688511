import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ScanPage} from './scan';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ScanPage],
})
export class ScanPageModule {}
