import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryIncomingTrackingPage} from './inventory-incoming-tracking';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [InventoryIncomingTrackingPage],
  providers: [
    BarcodeScanner,
  ],
})
export class InventoryIncomingTrackingPageModule {}
