import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CLiCSColorApplication} from "../../../lib/color-application";
import {CLiCSColorFormula} from "../../../lib/color-formula";
import {DomSanitizer} from "@angular/platform-browser";
import {CLiCSApplication} from "../../../lib/application-base";
import {CLiCSFormula} from "../../../lib/formula";

/**
 * Generated class for the AppListingComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-listing',
  templateUrl: 'app-listing.html',
  styleUrls: ['app-listing.scss'],
})
export class AppListingComponent {
  @Input() app: CLiCSApplication = null;
  @Input() title: string = null;
  @Output() openSelectedApp = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {
  }

  tileBgStyle(formula: CLiCSFormula) {
    let html = 'background-color: ' + formula.rgb;
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  doOpenApp(token: any): void {
    this.openSelectedApp.emit({token: token});
  }

  appTitle(): string {
    const result: string = !!this.title ? this.title : this.app.title;
    return result || ' ';
  }


}
