import {Component} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController, Platform} from '@ionic/angular';
import {BarcodeScanner, BarcodeScannerOptions} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {InventoryLoopPage} from '../inventory-loop/inventory-loop';
import {CLiCSService} from '../../services/clics.service';

/**
 * Generated class for the InventoryIncomingTrackingPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-inventory-incoming-tracking',
  templateUrl: 'inventory-incoming-tracking.html',
  styleUrls: ['inventory-incoming-tracking.scss'],
})
export class InventoryIncomingTrackingPage {
  report: string = null;
  trackingResult: string = null;
  scannedData: any = null;
  giveUp: boolean = false; // show the continue without tracking option
  showRetryButtons: boolean = false;
  loadingTimeout: any = null;

  constructor(
    private navCtrl: NavController,
    private barcodeCtrl: BarcodeScanner,
    private clicsService: CLiCSService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private plt: Platform) {
  }

  scanTrackingNumber() {
    let _that = this;
    this.showRetryButtons = false;

    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Center tracking number barcode inside the scan area',
      resultDisplayDuration: 500,
      formats: 'CODE_128',
      orientation: 'landscape',
    };

    this.barcodeCtrl.scan(options).then(async (barcodeData) => {
      _that.scannedData = barcodeData;
      if (!_that.scannedData || _that.scannedData['text'] == '') {
        console.log("no barcode data")
        return
      } else {
        console.log('Barcode data', _that.scannedData['text']);
        _that.trackingResult = _that.scannedData['text'];
      }

      let loading: any = null;
      loading = await _that.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Looking up shipment',
        duration: 30000
      });
      await loading.present();

      _that.loadingTimeout = setTimeout(() => {_that.timedOut();}, 30000);
      _that.clicsService.apiReportReceivedShipment(_that.scannedData['text']).then((data) => {
        clearTimeout(_that.loadingTimeout);
        loading.dismiss();
        if (!!data.success) {
          console.log("Shipment ID: " + data.shipment_id);

          _that.modalCtrl.create({
            component: InventoryLoopPage,
            componentProps: {full_inventory: false, shipment_id: data.shipment_id, incoming_scan: true},
            cssClass: _that.clicsService.theme,
          }).then(modal => modal.present());

          _that.modalCtrl.dismiss();
        } else {
          if (data.reason == 'salon') {
            _that.showRetryButtons = false;
            _that.report = `That tracking information is not for ${_that.clicsService.current_salon.name}. Please cancel and select your present salon location.`;
          } else {
            _that.showRetryButtons = true;
            _that.report = "This does not appear to be a known tracking number"
          }
          this.giveUp = true;
        }
        _that.trackingResult = _that.scannedData['text'];
      }, (reason) => {
        loading.dismiss();
        clearTimeout(_that.loadingTimeout);
        _that.report = 'could not look up barcode data';
        _that.trackingResult = _that.scannedData['text'];
        _that.showRetryButtons = true;
      }).catch((reason) => {
        loading.dismiss();
        clearTimeout(_that.loadingTimeout);
        _that.report = 'unable to look up barcode data';
        _that.trackingResult = _that.scannedData['text'];
        _that.showRetryButtons = true;
      });
    }, (reason) => {
      _that.report = 'could not access barcode scanner';
    }).catch(err => {
      console.log('Error', err);

      // MIGRATION NOTE: 'windows' is no longer a recognized platform, previous
      // logic specified !this.plt.is('windows') as well
      if (this.plt.is('desktop')) {
        this.report = 'Barcode scanning is not available on this platform'
      } else {
        this.report = 'Please enable camera access to scan barcodes'
      }
    });
  }

  timedOut() {
    this.report = 'Timed out waiting for a response';
    this.showRetryButtons = true;
  }

  // Repeat the barcode scan after clearing messages
  rescan() {
    this.report = null;
    this.trackingResult = ' ';
    this.giveUp = true;
    this.showRetryButtons = false;
    this.scanTrackingNumber();
  }

  async bypassScanning() {
    this.showRetryButtons = false;

    const modal = await this.modalCtrl.create({
      component: InventoryLoopPage,
      componentProps: {full_inventory: false, incoming_scan: true},
      cssClass: this.clicsService.theme,
    });
    await modal.present();

    this.modalCtrl.dismiss();
  }

  cancel() {
    clearTimeout(this.loadingTimeout);
    this.report = null;
    this.trackingResult = null;
    this.giveUp = false;
    this.showRetryButtons = false;
    this.modalCtrl.dismiss();
  }

}
