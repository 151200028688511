import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ColorFilterPage} from './color-filter';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ColorFilterPage],
  providers: [NavParams],
})
export class ColorFilterPageModule {}
