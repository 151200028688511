import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {RouteReuseStrategy} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {CLiCSPanel} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
// import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {ApplicationInfoPageModule} from './pages/application-info/application-info.module';
import {ClientAddPageModule} from './pages/client-add/client-add.module';
import {ClientNotesPageModule} from './pages/client-notes/client-notes.module';
import {ClientNoteAddPageModule} from './pages/client-note-add/client-note-add.module';
import {ClientSearchPageModule} from './pages/client-search/client-search.module';
import {ColorDetailPageModule} from './pages/color-detail/color-detail.module';
import {ColorFilterPageModule} from './pages/color-filter/color-filter.module';
import {ConfirmInvitationPageModule} from './pages/confirm-invitation/confirm-invitation.module';
import {ConsultationPageModule} from './pages/consultation/consultation.module';
import {ConversionLibraryPageModule} from './pages/conversion-library/conversion-library.module';
import {CreateAppPageModule} from './pages/create-app/create-app.module';
import {CreateColorPageModule} from './pages/create-color/create-color.module';
import {CreateMenuPageModule} from './pages/create-menu/create-menu.module';
import {FeedbackPageModule} from './pages/feedback/feedback.module';
import {FeedbackCategoriesPageModule} from './pages/feedback-categories/feedback-categories.module';
import {FormulaChoicesPageModule} from './pages/formula-choices/formula-choices.module';
import {HairProfilePageModule} from './pages/hair-profile/hair-profile.module';
import {HelpPageModule} from './pages/help/help.module';
import {InventoryFullConfirmPageModule} from './pages/inventory-full-confirm/inventory-full-confirm.module';
import {InventoryDamagedLoopPageModule} from './pages/inventory-damaged-loop/inventory-damaged-loop.module';
import {InventoryIncomingTrackingPageModule} from './pages/inventory-incoming-tracking/inventory-incoming-tracking.module';
import {InventoryLoopPageModule} from './pages/inventory-loop/inventory-loop.module';
import {LabAppPageModule} from './pages/lab-app/lab-app.module';
import {LabColorPageModule} from './pages/lab-color/lab-color.module';
import {LoginPasswordPageModule} from './pages/login-password/login-password.module';
import {LoginTextPageModule} from './pages/login-text/login-text.module';
import {PhotoZoomPageModule} from './pages/photo-zoom/photo-zoom.module';
import {ScanPageModule} from './pages/scan/scan.module';
import {StaffInvitationPageModule} from './pages/staff-invitation/staff-invitation.module';
import {StepsDisplayPageModule} from './pages/steps-display/steps-display.module';
import {WelcomeSlidesPageModule} from './pages/welcome-slides/welcome-slides.module';
import { Network } from '@ionic-native/network/ngx';

import {CLiCSService} from './services/clics.service';
import {ColorScannerProvider} from './services/color-scanner/color-scanner';
import {ContentProvider} from './services/content/content';
import {EditQueueProvider} from './services/edit-queue/edit-queue';
import {EventsService} from './services/events/events.service';
import {MessagingProvider} from './services/messaging/messaging';
import {MixingBowlProvider} from './services/mixing-bowl/mixing-bowl';
import {ModeControllerProvider} from './services/mode-controller/mode-controller';
import {PageControllerProvider} from './services/page-controller/page-controller';
import {PhotoStoreProvider} from './services/photo-store/photo-store';
import {SettingsProvider} from './services/settings/settings';
import {TimerAlertProvider} from './services/timer-alert/timer-alert';
import {RollbarService, rollbarFactory, RollbarErrorHandler} from '../lib/rollbar';

@NgModule({
  declarations: [
    CLiCSPanel
  ],
  imports: [
    BrowserModule,
    // IonicModule.forRoot({mode: 'ios'}),  // TODO: is this setting used? #30compile
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ApplicationInfoPageModule,
    ClientAddPageModule,
    ClientNotesPageModule,
    ClientNoteAddPageModule,
    ClientSearchPageModule,
    ColorDetailPageModule,
    ColorFilterPageModule,
    ConfirmInvitationPageModule,
    ConsultationPageModule,
    ConversionLibraryPageModule,
    CreateAppPageModule,
    CreateColorPageModule,
    CreateMenuPageModule,
    FeedbackPageModule,
    FeedbackCategoriesPageModule,
    FormulaChoicesPageModule,
    HairProfilePageModule,
    HelpPageModule,
    InventoryFullConfirmPageModule,
    InventoryDamagedLoopPageModule,
    InventoryIncomingTrackingPageModule,
    InventoryLoopPageModule,
    LabAppPageModule,
    LabColorPageModule,
    LoginPasswordPageModule,
    LoginTextPageModule,
    PhotoZoomPageModule,
    ScanPageModule,
    StaffInvitationPageModule,
    StepsDisplayPageModule,
    WelcomeSlidesPageModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
    CLiCSService,
    AppVersion,
    ColorScannerProvider,
    ContentProvider,
    EditQueueProvider,
    EventsService,
    MessagingProvider,
    MixingBowlProvider,
    ModeControllerProvider,
    PageControllerProvider,
    PhotoStoreProvider,
    SettingsProvider,
    TimerAlertProvider,
    StatusBar,
    // SplashScreen,
    Network
  ],
  bootstrap: [
    CLiCSPanel
  ],
})
export class AppModule {}
