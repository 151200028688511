/*
    Defines a hair color application type description
 */

export class CLiCSAppType {
    ident: string = null;
    name: string = '';
    description: string = '';
    steps: string = '';

    constructor(data: any = null) {
        if (data) {
            if (typeof data == 'string')
                this.loadStr(data);
            else {
                if (typeof data == 'object')
                    this.loadObj(data);
            }
        }
    }

    // Loads this from a data object
    loadObj(data: any) {
        this.ident = data.ident;
        this.name = data.name;
        this.description = data.description;
        this.steps = data.steps;
    }

    // Loads this object from a data string (JSON)
    loadStr(dataStr: string) {
        let data = JSON.parse(dataStr);
        this.loadObj(data);
    }

}
