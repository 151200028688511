import {ChangeDetectorRef, Inject, Injectable, Injector} from '@angular/core';
import * as Rollbar from 'rollbar';
import {CLiCSService} from '../clics.service';
import {EventsService} from '../events/events.service';
import {PageControllerProvider} from '../page-controller/page-controller';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {CLiCSUser} from '../../../lib/user';
import {CLiCSColorApplication} from '../../../lib/color-application';
import {CLiCSColorSession} from '../../../lib/session';
import {CLiCSFormulaRequest} from '../../../lib/request';
import {CLiCSFormula} from '../../../lib/formula';
import {CLiCSColorFormula} from '../../../lib/color-formula';
import {CLiCSClient} from '../../../lib/client';
import {RollbarService} from '../../../lib/rollbar';

/*
  Maintains a queue of objects that can be pulled into the Lab editor. The state of each is maintained based on
  calls by various pages. The objects stored here are for passing back and for and should be expired once no longer
  needed (e.g. a definitive copy exists elsewhere in the app that may be otherwise changed or reloaded).

  Matches are always checked against the current user as well as type, etc. NOTE: this may be overkill if the queue is
  reliably cleared.

  Formula objects may be tagged with "belongs_to" which is the token of a container object - typically a
  Color Session / APP. When a container object is expired the contained formulas are also removed from the queue.

  The edit queue is intended to be ephemeral and is cleared when a users signs out or logs out.
*/
@Injectable({
  providedIn: 'root',
})
export class EditQueueProvider {
  clicsService: CLiCSService;
  pageCtrl: PageControllerProvider;
  currentIndex: number = 0;
  private queue: EditQueueItem[] = [];

  constructor(private injector: Injector,
              private events: EventsService,
              @Inject(RollbarService) public rollbar: Rollbar) {
    this.clicsService = this.injector.get(CLiCSService);
    this.pageCtrl = this.injector.get(PageControllerProvider);
    // Clear client-assigned objects from the edit queue
    this.events.subscribe('client:selected', (client: CLiCSClient) => {
      if (client) {
        this.clearClientObject(client.token);
      } else {
        this.clearClientObject();
      }
    });
  }

  isEmpty(): boolean {
    return(this.queue.length == 0);
  }

  // Add an object or move it to the top of the list if already there. Action is 'USE', 'EDIT', or 'SEND'.
  // Belongs to is the token of an owner object and is only used when pertinent to the action (e.g. when action is
  // 'EDIT' for a FR you'll want to reference the CS that it belongs to). Client token is the token of the client
  // for the belongs_to object.
  add(object: any, action: string = null, belongs_to: string = null, clientToken: string = null) {
    let item: EditQueueItem = null;
    if (!!object) {
      let index = this._findByObjectToken(object.token);
      if (index >= 0) {
        item = this.queue[index]
                   .setAction(action)
                   .setOwnerObject(belongs_to)
                   .setClientToken(clientToken)
                   .update(object, this.pageCtrl.getCurrentRoot(), this.pageCtrl.getCurrentPageRef());
        this._moveToTop(index);
      } else {
        item = this.push(
          object,
          action,
          null,
          clientToken
        );
      }
    } else {
      const mssg = "EditQueue.add() received NULL object";
      console.warn(mssg);
      this.rollbar.warn(mssg, {object: object, action: action, belongs_to: belongs_to, clientToken: clientToken});
    }
    return(item);
  }

  // Push is like add except that it always adds the item to the queue, it doesn't find an existing instance
  push(object: any, action: string = null, belongs_to: string = null, clientToken: string = null) {
    let item: EditQueueItem = new EditQueueItem(
      object,
      action,
      this.clicsService.current_user,
      this.pageCtrl.getCurrentRoot(),
      this.pageCtrl.getCurrentPageRef(),
      clientToken
    );
    this.queue.unshift(item);
    return (item);
  }

  // Pushes a separate copy of an EditQueueItem, persisting the token of the EQI
  pushItem(editItem: EditQueueItem, action: string = null) {
    let item: EditQueueItem = new EditQueueItem(
      EditQueueItem.copyObject(editItem.itemObject),
      action == null ? editItem.action : action,
      null,
      this.pageCtrl.getCurrentRoot(),
      this.pageCtrl.getCurrentPageRef(),
      editItem.clientToken
    );
    item.userToken = editItem.userToken;
    item.token = editItem.token;
    this.queue.unshift(item);
    return (item);
  }

  // Returns the first object FOR THE CURRENT CLIENT if that object is of the specified type. Optionally, this can be
  // limited also to for a specified client. If the returned item's action is USE then the item is removed from the
  // queue. If the item's action is EDIT then a copy of the item is returned and the original is kept in the queue.
  discover(objectType: string, actions: string[] = null): EditQueueItem {
    let result: any = null;
    let itemIndex = this._findTop();

    // If the first queue item matches the item type (and client?) then return it
    if (itemIndex >= 0 && this.queue[itemIndex].objectType == objectType.toUpperCase()) {
      // Check match of desired action
      if (actions != null && actions.indexOf(this.queue[itemIndex].action) < 0)
        itemIndex = -1;

      if (itemIndex >= 0) {
        switch (this.queue[itemIndex].action.toUpperCase()) {
          case 'USE':
          case 'PAYLOAD':
            result = this.queue[itemIndex];
            this.expire(itemIndex);
            break;
          case 'EDIT':
            result = EditQueueItem.copyObject(this.queue[itemIndex]);
            break;
          case 'SEND':
            result = EditQueueItem.copyObject(this.queue[itemIndex]);
            this.expire(itemIndex);
            break;
          default:  // action should be handled explicitly
            result = EditQueueItem.copyObject(this.queue[itemIndex]);
            break;
        }
      }
    }
    return result;
  }

  // Gets the top item but does not remove it
  topItemInfo() {
    let result = {objectType: 'None', objectClass: 'None', action: 'None'};
    if (this.queue.length > 0) {
      result.objectType = this.queue[0].objectType;
      result.action = this.queue[0].action;
    }
    return result;
  }

  // Returns the action string for the topmost object of the specified type (for the current user)
  currentAction(objectType: string, clientToken: string = null): string {
    let index = this._findByType(objectType, clientToken);
    return (index >= 0) ? this.queue[index].action : null;
  }

  // Update the object with the modified copy passed, mark item as edited, set action to USE and navigate to the
  // item origin page
  edit(editItem: EditQueueItem): void {
    let updateResult = this.updateItem(editItem.finishEdit());
    if (updateResult.success) {
      this.queue[updateResult.index].setAction('USE');
      this._moveToTop(updateResult.index);
    } else {
      throw 'EditQueue::edit() - could not match edit item'
    }
  }

  // Update the object with the modified copy passed, mark item as edited, set action to PAYLOAD and navigate to the
  // item origin page
  payload(editItem: EditQueueItem): void {
    let updateResult = this.updateItem(editItem.finishEdit());
    if (updateResult.success) {
      this.queue[updateResult.index].setAction('PAYLOAD');
      this._moveToTop(updateResult.index);
    } else {
      throw 'EditQueue::payload() - could not match edit item'
    }
  }

  // Update the object with the modified copy passed, mark item as edited, set action to USE and navigate to the
  // item origin page
  editAndReturn(editItem: EditQueueItem): void {
    let updateResult = this.updateItem(editItem.finishEdit());
    if (updateResult.success) {
      this.queue[updateResult.index].setAction('USE');
      this._moveToTop(updateResult.index);
      this.events.publish('navrequest', updateResult.navigation);
    } else {
      throw 'EditQueue::editAndReturn() - could not match edit item'
    }
  }

  // Abandon edit and , set action to USE and navigate to the
  // item origin page
  cancel(editItem: EditQueueItem): void {
    let index = this._findByItemToken(editItem.token);
    if (index >= 0) {
      let nav = this.queue[index].cancelEdit().setAction('USE').getNavigation();
      this._moveToTop(index);
    } else {
      throw 'EditQueue::cancel() - could not match edit item'
    }
  }

  // Abandon edit and , set action to USE and navigate to the
  // item origin page
  cancelAndReturn(editItem: EditQueueItem): void {
    let index = this._findByItemToken(editItem.token);
    if (index >= 0) {
      let nav = this.queue[index].cancelEdit().setAction('USE').getNavigation();
      this._moveToTop(index);
      this.events.publish('navrequest', nav);
    } else {
      throw 'EditQueue::cancelAndReturn() - could not match edit item'
    }
  }

  // Find an item and move it to the top of the queue or add it to the queue, then navigate to the
  send(topNav: string, pageNav: string, editItem: EditQueueItem = null) {
    if (editItem) {
      if (this._moveToTop(this._findByItemToken(editItem.token)) == false) {
        this.queue.unshift(editItem);
      }
    }
    this.events.publish('navrequest', {top: topNav, page: pageNav});
  }

  // Remove an item from the queue
  expire(objectIndex: number): EditQueueItem {
    if (objectIndex >= 0 && objectIndex < this.queue.length) {
      let result = this.queue[objectIndex];
      this.queue.splice(objectIndex, 1);

      // Remove any items "owned" by the expired item
      for (let i = this.queue.length - 1; i >= 0; i -= 1) {
        if (this.queue[i].belongs_to === result.objectToken()) {
          this.queue.splice(i, 1);
        }
      }
      return(result);
    }
    else
      return(null);
  }

  // Removes an edit queue item from the queue and returns an object with navigation info
  expireItem(editItem: EditQueueItem): any {
    let index = this._findByItemToken(editItem.token);
    let matchedItem: EditQueueItem = null;
    if (index >= 0) {
      matchedItem = this.queue[index];
      this.queue.splice(index, 1);
    }
    if (matchedItem)
      return {success: true, topNav: matchedItem.topNav, pageNav: matchedItem.pageNav};
    else
      return {success: false, topNav: editItem.topNav, pageNav: editItem.pageNav};
  }

  // Same as expireItem but searches for item by ItemObject token
  expireObject(editItem: EditQueueItem): any {
    let index = this._findByObjectToken(editItem.itemObject.token);
    let matchedItem: EditQueueItem = null;
    if (index >= 0) {
      matchedItem = this.queue[index];
      this.queue.splice(index, 1);
    }
    if (matchedItem)
      return {success: true, topNav: matchedItem.topNav, pageNav: matchedItem.pageNav};
    else
      return {success: false, topNav: editItem.topNav, pageNav: editItem.pageNav};
  }

  // Updates an edit queue item with the copy passed.
  updateItem(editItem: EditQueueItem): any {
    let index = this._findByItemToken(editItem.token);
    if (index >= 0) {
      this.queue[index].updateFromItem(editItem);
    }
    else {
      this.queue.unshift(editItem);
      index = 0;
    }
    return {success: index >= 0, index: index, navigation: {top: this.queue[index].topNav, page: this.queue[index].pageNav}};
  }

  // Used when a new client is chosen, removes all objects owned by clients EXCEPT those matching the optional
  // clientTokenToIgnore argument.
  clearClientObject(clientTokenToIgnore: string = null) {
    let i = this.queue.length;
    while (i--) {
      if (this.queue[i].clientToken !== null && (clientTokenToIgnore == null || clientTokenToIgnore != this.queue[i].clientToken))
        this.queue.splice(i, i);
    }
  }

  // Clears all Edit Queue Items from the queue. Should only be used if workflow will not want to persist prior objects.
  clearAll() {
    if (!!this.queue)
      this.queue.length = 0;
    else
      this.queue = [];
  }

  // When an object is not supplied by the server, such as a new Color Session, a NEW object can be instantiated
  // right on the EditQueue at inception using createNew().
  createNew(itemClass: string): EditQueueItem {
    let newEditItem: EditQueueItem = null;
    switch (itemClass) {
        case "CLiCSColorApplication":
          newEditItem = new EditQueueItem(
            new CLiCSColorApplication(),
            'NEW',
            this.clicsService.current_user,
            this.pageCtrl.getCurrentRoot(),
            this.pageCtrl.getCurrentPageRef()
          );
          break;
        case "CLiCSColorSession":
          newEditItem = new EditQueueItem(
            new CLiCSColorSession(),
            'NEW',
            this.clicsService.current_user,
            this.pageCtrl.getCurrentRoot(),
            this.pageCtrl.getCurrentPageRef()
          );
          break;
        case "CLiCSFormulaRequest":
          newEditItem = new EditQueueItem(
            new CLiCSFormulaRequest(),
            'NEW',
            this.clicsService.current_user,
            this.pageCtrl.getCurrentRoot(),
            this.pageCtrl.getCurrentPageRef()
          );
          break;
        case "CLiCSFormula":
          newEditItem = new EditQueueItem(
            new CLiCSFormula(),
            'NEW',
            this.clicsService.current_user,
            this.pageCtrl.getCurrentRoot(),
            this.pageCtrl.getCurrentPageRef()
          );
          break;
        case "CLiCSColorFormula":
          newEditItem = new EditQueueItem(
            new CLiCSColorFormula(),
            'NEW',
            this.clicsService.current_user,
            this.pageCtrl.getCurrentRoot(),
            this.pageCtrl.getCurrentPageRef()
          );
          break;
        default:
          throw('EditQueue::createNew() - unknown object class: ' + itemClass);
    }
    if (newEditItem != null)
      this.queue.unshift(newEditItem);
    return newEditItem;
  }


  //~~~~~~~~~~~~~~~~~~ OLD STYLE CALLS ~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // These functions required a priori knowledge of the state of the queue when a page accesses it.
  // This has been replaced with discover(), which returns the top item in the queue of a certain type, from which
  // the action can be inferred. New methods also include editAndReturn(), cancelAndReturn(), send(),
  // clearClientObjects(), and createNew().

  // Get the top APP item that is EDIT or NEW, return a copy of the item for external editing. An optional target
  // class name may be passed to cast the returned object to a specific class.
  getEditAppItem(targetClassName: string = null): EditQueueItem {
    const validClasses = ['CLiCSColorApplication', 'CLiCSColorSession'];
    if (targetClassName != null && validClasses.indexOf(targetClassName) < 0)
      throw('EditQueueProvider::getEditAppItem() - unknown targetClassName: ' + targetClassName);

    let index = this._findByType('APP', null, ['EDIT', 'NEW']);
    if (index >= 0) {
      this._moveToTop(index);
      return EditQueueItem.copyObject(this.queue[0].startEdit(), null, targetClassName);
    }
    else
      return(null);
  }

  // Get the top FORMULA item with EDIT or NEW from the queue. Removes the item from the queue unless told otherwise
  getEditFormulaItem(targetClassName: string = null, removeAfter: boolean = true): EditQueueItem {
    const validClasses = ['CLiCSFormulaRequest', 'CLiCSFormula', 'CLiCSColorFormula'];
    let result: EditQueueItem = null;
    if (targetClassName != null && validClasses.indexOf(targetClassName) < 0)
      throw('EditQueueProvider::getEditFormulaItem() - unknown targetClassName: ' + targetClassName);

    let index = this._findByType('FORMULA', null, ['EDIT', 'NEW']);
    if (index >= 0) {
      result = EditQueueItem.copyObject(this.queue[index].startEdit(), null, targetClassName);
      if (removeAfter) {
        this.expire(index);
      } else {
        this._moveToTop(index);
      }
    }
    return(result);
  }

  // Retrieve the top APP item in the queue. Optionally compares client token to affirm correct assignment
  getUseAppItem(clientToken: string = null, removeAfter: boolean = true): EditQueueItem {
    let result = null;
    let index = this._findByType('APP', clientToken, ['USE']);
    if (index >= 0) {
      result = removeAfter ? this.expire(index).startUse() : this.queue[index].startUse();
    }
    return result;
  }

  // Retrieve the top formula item from the queue
  getUseFormulaItem(removeAfter: boolean = true): EditQueueItem {
    let result = null;
    let index = this._findByType('FORMULA', null, ['USE']);
    if (index >= 0) {
      result = removeAfter ? this.expire(index).startUse() : this.queue[index].startUse();
    }
    return result;
  }

  // Checks the FIRST item in the queue for a SHOW action APP object; if found return and remove from queue
  getShowAppItem(clientToken: string = null) {
    let result = null;
    let index = this._findByType('APP', clientToken, ['SHOW']);
    if (index == 0) {
      result = this.expire(index);
    }
    return result;
  }

  // Checks the FIRST item in the queue for a SHOW action APP object; if found return and remove from queue
  getShowFormulaItem(clientToken: string = null) {
    let result = null;
    let index = this._findByType('FORMULA', clientToken, ['SHOW']);
    if (index == 0) {
      result = this.expire(index);
    }
    return result;
  }

  // Returns a string reporting the state of the queue
  report(): string[] {
    let i: number = 0;
    let result: string[] = [`EditQueue contains ${this.queue.length} items`];
    while (i < this.queue.length) {
      let eqi = this.queue[i];
      result.push(`${i}. ` + eqi.toString());
      i++;
    }
    return(result);
  }

  // Generate a report and log it to the console
  logReport(separator: string = "-----") {
    let report = this.report();
    if (report.length > 1)
      console.log(separator);
    for (let ligne of report)
      console.log(ligne);
    if (report.length > 1)
      console.log(separator);
  }

  // Returns true if there's an APP at the top of the queue from Client History
  hasClientUseApp(): boolean {
    let result: boolean = false;

    if (this.queue.length > 0) {
      const item = this.queue[0];
      return (item.objectType == 'APP' && item.action == 'USE' && item.topNav == 'clients' && item.pageNav == 'history');
    }
    else
      return result;
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // Move the item at the index to the top of the queue. Returns true if index is in range, else false.
  private _moveToTop(index: number) {
    if (index < 0 || index >= this.queue.length)
      return(false);
    else {
      if (index > 0) {
        let item = this.queue[index];
        this.queue.splice(index, 1);
        this.queue.unshift(item);
      }
      return(true);
    }
  }

  // Get the topmost item for this user (of any type)
  private _findTop(): number {
    let index = -1;
    let user: CLiCSUser = this.clicsService.current_user;
    for (let i = 0; i < this.queue.length; i += 1) {
      if (this.queue[i].userToken == user.api_token) {
        index = i;
        break;
      }
    }
    return index;
  }

  // Find the index of the editItem token in the queue. Returns -1 or item index
  private _findByItemToken(itemToken: string): number {
    return (this.queue.findIndex((el): boolean => {
      return el.token == itemToken;
    }));
  }

  // Find the index of the object and return that. Returns -1 or item index
  private _findByObjectToken(objectToken: string): number {
    let user: CLiCSUser = this.clicsService.current_user;
    return (this.queue.findIndex((el): boolean => {
      return el.objectToken() == objectToken && el.userToken == user.api_token;
    }));
  }

  // For this user, find the first object of the specified type, optionally assigned to the specified client,
  // optionally having one of the defined actions.
  private _findByType(objectType: string, clientToken: string = null, actions: string[] = null): number {
    let user: CLiCSUser = this.clicsService.current_user;
    let index = -1;
    for (let i = this.currentIndex; i < this.queue.length; i += 1) {
      if (this.queue[i].objectType == objectType &&
          this.queue[i].userToken == user.api_token &&
          (clientToken == null || this.queue[i].clientToken == clientToken) &&
          (actions == null || actions.indexOf(this.queue[i].action) >= 0))
      {
        index = i;
        break;
      }
    }
    return index;
  }

}
