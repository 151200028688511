import { Component, Input, Output, EventEmitter } from '@angular/core';
import {CLiCSService} from "../../services/clics.service";
import {PhotoStoreProvider} from "../../services/photo-store/photo-store";
import {CLiCSClientPhoto} from "../../../lib/photo";
import {CLiCSClient} from "../../../lib/client";

/**
 * Generated class for the PhotoGridComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'photo-grid',
  templateUrl: 'photo-grid.html',
  styleUrls: ['photo-grid.scss'],
})
export class PhotoGridComponent {
  // NOTE: these inputs do not work, even with static values. Unclear why
  @Input() css: string = null;
  @Input() emptyMssg: string = null;
  @Output() photoClick = new EventEmitter();

  photos: CLiCSClientPhoto[] = null;
  client: CLiCSClient = null;

  constructor(
    private clicsService: CLiCSService,
    private photoStore: PhotoStoreProvider
  ) {
    this.client = this.clicsService.activeClient();
    if (this.emptyMssg == null) {
      if (!!this.css) {
        this.emptyMssg = 'There are no photos for this application'
      } else {
        this.emptyMssg = 'This client has no photos yet'
      }
    }
  }

  // Sets the color session token
  setClient(client: CLiCSClient) {
    this.client = client;
    this.photos = null;
    if (!!this.client) {
      this.photos = this.photoStore.clientPhotos(this.client.token);
    } else {
      if (!!this.css) {
        this.photos = this.photoStore.sessionPhotos(this.css, true).all;
      }
    }
  }

  // Sets the color session token
  setCSS(css: string) {
    this.css = css;
    this.photos = null;
    if (!!this.css) {
      this.photos = this.photoStore.sessionPhotos(this.css, true).all;
    } else {
      if (!!this.client) {
        this.photos = this.photoStore.clientPhotos(this.client.token);
      }
    }
  }

  // Sets the message shown when the photo list is empty
  setEmptyMssg(message: string) {
    if (!message) {
      this.emptyMssg = ' ';
    } else {
      this.emptyMssg = message;
    }
  }

  doClick(photoToken: string = null) {
    // Instruct parent to open photo
    this.photoClick.emit(photoToken);
  }

}
