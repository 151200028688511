/*
    Stores the information for a single photo image for a client, including Color Session (APP) association, when
    taken (in the context of the APP session), and URLs to various sized images.

    This object can store Base64 image data for a recently taken photo, in which case this is returned as the src
    string. When the object reloads from the server the URLs for the full and thumb size photos are used instead.
*/

export class CLiCSClientPhoto {
    public token: string = null;
    public full_url: string;
    public thumb_url: string;
    public taken_at: Date;
    public when_taken: string = null;  // 'before', 'during', 'after' (applies to CS-linked photos only
    public css: string = null;  // Color Session token
    public cli: string = null;  // Client token
    public is_profile: boolean = false;  // True if this is the client's profile photo

    public attempts: number = 0;  // Number of upload attempts
    public lastAttempt: Date = null;

    public request_ident: string = null; // Locally generated token for identifying server image
    public last_update_at: Date = null;  // Last time this object was created, changed, uploaded, etc.
    private imageData: string = null;    // Base64 data string, optional

    constructor(data: any = null) {
        if (data != null) {
            if (typeof data == 'object')
                this.loadObj(data);
            else {
                if (typeof data == 'string') {
                    this.loadStr(data)
                }
            }
        }
        this.assertLocalIdent();
        this.touch();
    }

    touch() {
        this.last_update_at = new Date();
    }

    // Load data from another object. Optionally ignore token
    loadObj(data: any) {
        if (data !== undefined) {
            if ('token' in data)
                this.token = '' + data.token;
            if ('full_url' in data)
                this.full_url = data.full_url;
            if ('thumb_url' in data)
                this.thumb_url = data.thumb_url;
            if ('when_taken' in data)
                this.when_taken = data.when_taken;
            if ('cli' in data)
                this.cli = data.cli;
            if ('css' in data)
                this.css = data.css;
            if ('is_profile' in data)
                this.is_profile = !!data.is_profile;
            if ('taken_at' in data && !!data.taken_at)
                this.taken_at = new Date(data.taken_at);
            else {
                // legacy attribute TODO: remove this
                if ('date_time' in data && !!data.date_time)
                    this.taken_at = data.date_time;
                else
                    this.taken_at = new Date();
            }
        }
    }

    loadStr(dataStr: string) {
        let data = JSON.parse(dataStr);
        this.loadObj(data);
    }

    // Sets a local identifier that's used to match the local object to one saved on the back-end server. The server
    // returns, but does not store, the request ident value when a request is made to save this photo.
    assertLocalIdent() {
        if (this.request_ident == null) {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            this.request_ident = text;
        }
    }

    // Set one or both URLs for an image and clear out the dataStorage in this object
    setUrl(fullUrl: string, thumbUrl: string, keepImageData: boolean = false) {
        this.full_url = fullUrl;
        this.thumb_url = thumbUrl;
        if (keepImageData == false)
            this.imageData = null;
    }

    // Sets the image data for this object adding a type header if required. This is usually done when a new photo is
    // added from the camera or the device photo library. imageData here is ephemeral. It is uploaded to
    // the server and kept on-hand locally for display but when this object is reloaded from the server the URLs are
    // uses as "src".
    setImageData(base64ImageData: string) {
        if (base64ImageData.substring(0, 10) != 'data:image')
            this.imageData = 'data:image/jpeg;base64,' + base64ImageData;
        else
            this.imageData = base64ImageData;
    }

    getImageData(): string {
        return(this.imageData);
    }

    // Returns the src string that can be passed to an img element
    imgSrc(size: string = 'full') {
        if (this.imageData) {
            if (this.imageData.substring(0, 10) != 'data:image')
                return ('data:image/jpeg;base64, ' + this.imageData);
            else
                return (this.imageData);
        }
        else {
            if (size.toLowerCase() === 'thumb')
                return(this.thumb_url);
            else
                return(this.full_url);
        }
    }

    isNew(): boolean {
        return(!!this.imageData && !this.token);
    }

    // Increment attempt and set the lastAttempt timestamp
    addAttempt() {
      this.attempts += 1;
      this.lastAttempt = new Date();
    }

    // Returns true if it's ok to retry upload for this photo. Currently, we immediately retry up to 3 times. After that
    // we delay 3 minutes between attempts. This is still rather aggressive.
    readyForAttempt() {
      if (this.attempts < 3 || !this.lastAttempt) {
        return true;
      } else {
        const nextAttempt = new Date(this.lastAttempt.getTime() + 3 * 60000); // 3 minutes
        return(nextAttempt <= new Date());
      }
    }
}
