import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../../components/components.module';
import {FormulaChoicesPage} from './formula-choices';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [FormulaChoicesPage],
  providers: [
    NavParams,
  ],
})
export class FormulaChoicesPageModule {}
