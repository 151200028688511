import {NgModule} from '@angular/core';
import {IonicModule, NavParams} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ConfirmInvitationPage} from './confirm-invitation';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ConfirmInvitationPage],
  providers: [
    NavParams,
  ],
})
export class ConfirmInvitationPageModule {}
