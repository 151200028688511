import {Component, Input} from '@angular/core';

/**
 * Displays a report header that includes a title (optional), a list of attributes (label + value), and a grid layout
 * of label/value pairs (optional)
 *
 */
@Component({
  selector: 'report-header',
  templateUrl: 'report-header.html',
  styleUrls: ['report-header.scss'],
})
export class ReportHeaderComponent {

  @Input() data: any = null;

  constructor() {

  }

}
