import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClient} from '../../../lib/client';

/**
 * Generated class for the ClientHeaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'client-header',
  templateUrl: 'client-header.html',
  styleUrls: ['client-header.scss'],
})

export class ClientHeaderComponent {
  @Input() clientName: string;
  @Output() openClientNotes = new EventEmitter();

  constructor(public events: EventsService,
              private clicsService: CLiCSService) {
  }

  doClearClient() {
    this.clicsService.clearClient();
  }

  doEditClient() {
    this.openClientNotes.emit();
  }

}
