import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {CLiCSFormulaRequest} from "../../../lib/request";
import {MixingBowlProvider} from "../../services/mixing-bowl/mixing-bowl";
import {ModeControllerProvider} from "../../services/mode-controller/mode-controller";
import {AmountSliderComponent} from "../amount-slider/amount-slider";

/**
 * Generated class for the FormulaMixComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'formula-mix',
  templateUrl: 'formula-mix.html',
  styleUrls: ['formula-mix.scss'],
})
export class FormulaMixComponent implements OnInit {
  @Input() formula: CLiCSFormulaRequest;
  @Input() minAllowable: number = 0.0;
  @Input() mixingBowl: MixingBowlProvider;
  @Input() minDevStrength: number = 1;
  @Input() unit: string = "g";
  @Output() formulationChange = new EventEmitter();
  @ViewChild('strengthSlide') strengthSlide: AmountSliderComponent;
  @ViewChild('colorSlider') colorSlider: AmountSliderComponent;
  @ViewChild('devSlider') devSlider: AmountSliderComponent;

  workingFormula: CLiCSFormulaRequest = null;

  minAllowableColor: number = 0.0;
  maxAllowableColor: number = 150.0;
  maxAllowableDev: number = 150.0;
  minAllowableDev: number = 0.0;
  minAllowableTotal: number = 10.0;

  origColorAmount: number = 0.0;
  includeDev: boolean = true;

  colorAmount: number = 0.0;
  devStrength: number = 20;
  devRatio: number = 1.0;  // dev amt / color amt
  devAmount: number = 0.0;
  totalAmount: number = 0.0;

  blockColor: boolean = false;
  blockDev: boolean = false;
  blockTotal: boolean = false;

  initialized: boolean = false;
  dirty: boolean = false;

  permissions: any = null;
  settings: any = null;
  showCustomRatio: boolean = false;
  revertData: any = {
    colorAmount: 0,
    devAmount: 0
  }

  constructor(
    private modeCtrl: ModeControllerProvider,
    ) {
    // Load permissions from modeCtrl
    this.permissions = this.modeCtrl.getPermissions();

    this.modeCtrl.init().then(result => {
        this.permissions = this.modeCtrl.getPermissions();
    });

    if(!!this.permissions.show_milliliters){
        this.unit = "mL"
    }
  }

  ngOnInit() {
    this.reloadFormula();
    this.initialized = true;
  }

  // Update the local working copy of the formula from the external source formula
  reloadFormula() {
    if (!!this.formula) {
      this.workingFormula = new CLiCSFormulaRequest(this.formula);
    } else {
      this.workingFormula = new CLiCSFormulaRequest();  // Intended to show blank formula if no formula passed
    }
    this.recalculate();
    this.updateLimits();
  }

  // calculates min's and max's from minAllowable and ratio.
  recalculate() {
    this.origColorAmount = this.workingFormula.colorAmount(); // this is the param amount
    this.totalAmount = parseFloat(this.workingFormula.amount.toFixed(1));
    this.includeDev = this.workingFormula.hasDeveloper();
    if (this.includeDev) {
      this.devStrength = this.workingFormula.developerStrength();
      this.devRatio = this.workingFormula.developerRatio();
      // Handle bad formula numbers
      if (this.devStrength == undefined || this.devRatio == undefined) {
        this.devStrength = 20.0;
        this.devRatio = 1.0;
        this.workingFormula.setDeveloper(this.devStrength, this.devRatio, this.mixingBowl);
      }
    }
    else {
      this.devStrength = 20;
      this.devRatio = 1.0;
    }
    if (this.includeDev) {
      this.colorAmount = parseFloat((this.totalAmount / (1 + this.devRatio)).toFixed(1));
      this.devAmount = parseFloat((this.colorAmount * this.devRatio).toFixed(1));
    } else {
      this.colorAmount = this.totalAmount;
      this.devAmount = 0.0;
    }
  }

  setAmount(newAmount: number) {
    this.workingFormula.amount = newAmount;
  }

  // True if the minimum allowable total is selected on the total amount slider
  atMinWeight(): boolean {
    return (this.workingFormula.amount <= this.minAllowable);
  }

  signalFormulationChange() {
    this.formulationChange.emit({formula: this.workingFormula});
  }

  // Sets a temporary block on all sliders
  blockAll() {
    this.blockTotal = true;
    this.blockColor = true;
    this.blockDev = true;
  }

  // In response to a color ot total amount admjustment
  updateLimits() {
    this.minAllowableColor = 0.5;
    this.maxAllowableColor = 300 / (1 + this.devRatio);
    if (this.includeDev) {
      this.minAllowableDev = this.colorAmount * 0.5;
      this.maxAllowableDev = Math.min(this.colorAmount * 4.0, parseFloat((300.0 * (this.devRatio / (this.devRatio + 1))).toFixed(1)));
    } else {
      this.minAllowableDev = 0.0;
      this.maxAllowableDev = 150.0;
    }
    this.minAllowableTotal = this.mixingBowl.minAllowableWeight(this.workingFormula);
    if (this.includeDev) {
      const devPct = this.devRatio / (1 + this.devRatio);
      const colorPct = 1.0 - devPct;
      this.minAllowableDev = parseFloat(Math.ceil(this.minAllowableTotal * devPct).toFixed(0));
      this.minAllowableColor = parseFloat(Math.ceil(this.minAllowableTotal * colorPct).toFixed(0));
    } else {
      this.minAllowableColor = this.minAllowableTotal;
    }
  }

  handleAdjustColor(event) {
    this.dirty = true;
    this.colorAmount = parseFloat(event.amount.toFixed(1));
    if (!this.blockColor) {
      if (this.includeDev) {
        this.blockDev = true;
        this.devAmount = parseFloat((this.colorAmount * this.devRatio).toFixed(1));
        this.totalAmount = this.devAmount + this.colorAmount;
      } else {
        this.totalAmount = this.colorAmount;
      }
      this.workingFormula.setAmount(this.totalAmount);
      this.updateLimits();
    } else {
      this.blockColor = false;
    }
  }

  handleAdjustDev(event) {
    this.dirty = true;
    this.devAmount = parseFloat(event.amount.toFixed(1));
    if (!this.blockDev) {
      this.totalAmount = this.devAmount + this.colorAmount;
      if (this.includeDev) {
        this.devRatio = parseFloat((this.devAmount / this.colorAmount).toFixed(1));
        this.workingFormula.setDeveloper(this.devStrength, this.devRatio, this.mixingBowl);
        if (this.totalAmount > 300.0) {
          this.blockDev = true;
          this.blockColor= true;
          this.totalAmount = 300.0;
          this.devAmount = parseFloat((300.0 * (this.devRatio / (this.devRatio + 1))).toFixed(1));
          this.colorAmount = parseFloat((300.0 / (this.devRatio + 1)).toFixed(1));
        }
        this.updateLimits();
      }
      this.workingFormula.setAmount(this.totalAmount);
    } else {
      this.blockDev = false;
    }
  }

  handleAdjustTotal(event) {
    this.dirty = true;
    this.totalAmount = parseFloat(event.amount.toFixed(1));
    if (!this.blockTotal) {
      this.blockColor = true;
      if (this.includeDev) {
        this.blockDev = true;
        this.colorAmount = parseFloat((this.totalAmount / (1 + this.devRatio)).toFixed(1));
        this.devAmount = parseFloat((this.colorAmount * this.devRatio).toFixed(1));
        this.updateLimits();
      } else {
        this.colorAmount = this.totalAmount;
      }
      this.workingFormula.setAmount(this.totalAmount);
      this.workingFormula.adjustParamsFromAmount();
    } else {
      this.blockTotal = false;
    }
  }

  // A change was made to the strength of the developer
  handleAdjustStrength(event) {
    this.dirty = true;
    this.devStrength = event.amount;
    this.workingFormula.setDeveloper(this.devStrength, this.devRatio, this.mixingBowl); // TODO: detach realFromFractional in MixingBowlProvider
  }

  setStrength(amount: number) {
    this.strengthSlide.setAmount(amount);
  }

  handleDevToggle() {
    this.dirty = true;
    if (this.includeDev) {
      if (!this.workingFormula.hasDeveloper()) {
        this.workingFormula.adjustParamsFromAmount();
        this.workingFormula.setDeveloper(this.devStrength, this.devRatio, this.mixingBowl); // TODO: detach realFromFractional in MixingBowlProvider
        this.workingFormula.assertAmount();
        this.workingFormula.adjustParamsFromAmount();
        this.recalculate();

        if (this.workingFormula.amount > 300.0) {
          this.blockDev = true;
          this.blockColor= true;
          this.totalAmount = 300.0;
          this.devAmount = parseFloat((300.0 * (this.devRatio / (this.devRatio + 1))).toFixed(1));
          this.colorAmount = parseFloat((300.0 / (this.devRatio + 1)).toFixed(1));
          this.workingFormula.setAmount(this.totalAmount);
        }
        this.updateLimits();
      }
    } else {
      this.workingFormula.removeDeveloper();
      this.devAmount = 0.0;
      this.handleAdjustDev({amount: 0.0})
      this.recalculate();
    }
  }

  developerTitle(): string {
    if (this.devRatio % 1 == 0)
      return ("Developer Lotion (" + this.devRatio.toFixed(0) + ":1)")
    else
      return ("Developer Lotion (" + this.devRatio.toFixed(1) + ":1)")
  }

  makeRatio(ratioAmount: number) {
    const event = {amount: this.colorAmount * ratioAmount};
    this.handleAdjustDev(event);
  }

  openCustomRatio() {
    this.revertData.colorAmount = this.colorSlider.getAmount();
    this.revertData.devAmount = this.devSlider.getAmount();
    this.showCustomRatio = true;
  }

  closeCustomRatio(revert = false) {
    if (revert) {
      this.colorSlider.setAmount(this.revertData.colorAmount);
      this.devSlider.setAmount(this.revertData.devAmount);
    }
    this.showCustomRatio = false;
  }

  isCustomRatio(): boolean {
    const ratio = this.workingFormula.devRatio();
    return (ratio != 1.0 && ratio != 2.0);
  }

  customRatioStr(): string {
    const ratio = this.workingFormula.devRatio(1);
    if (ratio == 1.0 || ratio == 2.0) {
      return('');
    } else {
      return (' ' + ratio + ':1');
    }
  }

  matchesDevStrength(strength: number): boolean {
    const setStrength = this.workingFormula.developerStrength();
    return setStrength == strength;
  }
}
