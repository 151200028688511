import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ConsultationPage} from './consultation';
import {ComponentsModule} from "../../components/components.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
  ],
  declarations: [ConsultationPage],
})
export class ConsultationPageModule {}
