import {Component, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CLiCSService} from '../../services/clics.service';
import {EventsService} from '../../services/events/events.service';
import {CLiCSClient} from '../../../lib/client';

@Component({
  selector: 'page-consultation',
  templateUrl: 'consultation.html',
  styleUrls: ['consultation.scss'],
})
export class ConsultationPage {
  client: CLiCSClient = null;
  clientName: string = "";
  questions: any[] = [];
  client_responses: string[] = [];
  consultation_id: number = null;
  custom_responses: any = {};
  date: any = null;
  consultations: any[] = [];
  currentIndex: number = 0;
  modalDismissSubscription: Subscription = null;
  inputTimer: any = null;

  constructor(private clicsService: CLiCSService,
              private events: EventsService,
              private modalCtrl: ModalController) {
    this.client = this.clicsService.current_user.getClient();
  }

  ionViewWillEnter() {
    this.clientName = this.client.name();

    this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
      this.closeModal();
    });

    this.clicsService.apiGetConsultations(this.client).then(consultations => {
      this.consultations = consultations
      this.currentIndex = consultations.length - 1
      this.currentConsultation()
    });
  }

  newConsultation() {
    // if there is already a consultation from today direct the user there,
    // otherwise get questions in order to avoid creating/saving a blank consultation
    if (this.consultations.length > 0 && this.fromToday(this.consultations[this.consultations.length - 1].date)) {
      this.currentIndex = this.consultations.length - 1;
      this.currentConsultation();
    } else {
      this.clicsService.apiGetConsultationQuestions().then(questions => {
        this.questions = questions
        this.currentIndex = this.consultations.length + 1
      });
    }
  }

  saveConsultation(closeModal = false) {
    this.clicsService.apiPostConsultation(this.client, this.client_responses, this.custom_responses).then(result => {
      if (closeModal) {
        this.modalCtrl.dismiss();
      }
    });
  }

  previousConsultation() {
    this.currentIndex -= 1;
    this.currentConsultation();
  }

  nextConsultation() {
    this.currentIndex += 1;
    this.currentConsultation();
  }

  currentConsultation() {
    const consultation = this.consultations[this.currentIndex];
    if (!!consultation) {
      this.consultation_id = consultation.consultation_id;
      this.questions = consultation.questions;
      this.client_responses = consultation.client_responses.map(String);
      this.custom_responses = consultation.custom_responses;
      this.date = consultation.date;
    }
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  updateRadio(question: any, event: any) {
    if (typeof(event.detail.value) == "string") {
      return;
    }
    const id = event.detail.value;
    question.responses.forEach(response => {
      this._delete(response.id)
    });
    this.client_responses.push("" + id);
    this._armForSave();
  }

  updateCheckbox(id: number, event: any) {
    const index = this.client_responses.indexOf("" + id);
    if (index === -1) {
      this.client_responses.push("" + id)
    } else {
      this.client_responses.splice(index, 1)
    }
    this._armForSave();
  }

  _delete(id: number) {
    const index = this.client_responses.indexOf("" + id);
    if (index > -1) {
      this.client_responses.splice(index, 1)
    };
  }

  // Returns the id of a single client response that matches one of the possible responses for a given question. Used
  // to set the radio-group value. May return null.
  questionResponseId(question): number {
    let matching_question_response = null;
    for (let answer of this.client_responses) {
      matching_question_response = question.responses.find((el) => {
        return el.id == answer;
      });
      if (!!matching_question_response)
        break;
    }
    if (!!matching_question_response)
      return matching_question_response.id;
    else
      return null;
  }

  isChosenResponse(id: number) {
    let result = false
    result = this.client_responses.includes("" + id);
    return result;
  }

  hasCustomResponse(id: number) {
    return this.custom_responses[id] != null;
  }

  hasResponse(responses: any[]) {
    const ids = responses.map((row) => row.id);
    return ids.some(i => this.client_responses.includes("" + i));
  }

  fromToday(date: string): boolean {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) { month = '0' + month };
    if (day.length < 2) { day = '0' + day };
    return date === [year, month, day].join('-');
  }

  closeModal() {
    if (this.client_responses.length > 0 || this.custom_responses.length > 0) {
      this.saveConsultation(true)
    } else {
      this.modalCtrl.dismiss();
    }
  }

  // (re)sets a time to save when expired
  _armForSave() {
    if (!!this.inputTimer) {
      clearTimeout(this.inputTimer);
      this.inputTimer = null;
    }
    this.inputTimer = setTimeout(() => {this.saveConsultation()}, 5000);
  }

  // Change response when edited by user
  setOtherResponse(response_id: any, event: any) {
    this.custom_responses[response_id] = event.detail.value;
    this._armForSave();
  }
}
